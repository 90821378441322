import { CreatePreviewRequestBody } from './model';
import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { insertPropertyIdToPath } from '../../api/apiUtils';

export function createPreview(
    payload: CreatePreviewRequestBody,
    propertyId: string
) {
    return axios.post(
        insertPropertyIdToPath(API_URL.createPreview, propertyId),
        payload
    );
}

export function deletePreview(previewId: string, propertyId: string) {
    return axios.delete(
        insertPropertyIdToPath(API_URL.deletePreview, propertyId) +
            `/${previewId}`
    );
}
