import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { isEmpty } from 'lodash';
import LogoutBroadcastChannel from '../auth/logoutBroadcastChannel';
import { fetchMaintenanceStatus } from '../redux/maintenance/action';
import { store } from '../redux/store';
import ApiResponseCode from './apiResponseCode';
import { selectUserRole } from '../redux/login/reducer';
import { selectGenericComment } from '../redux/propertyValue/reducer';
import { selectCurrentPropertyDetails } from '../redux/propertylist/reducer';
import { isSuperRole } from '../utils/loginUtils';

const axiosInstance = axios.create();

const URL_PATHS_TO_BLOCK_FOR_COMMENTS = ['cmp_template/', 'cmp_property/'];
const HTTP_METHODS_REQUIRING_USER_COMMENT = ['post', 'delete'];

async function getToken() {
    const auth = await Auth.currentSession();
    console.log('jwtToken exp time - ', auth.getIdToken().getExpiration());
    return auth.getIdToken().getJwtToken();
}

const addCommentsToPayload = (config: AxiosRequestConfig) => {
    const state = store.getState();
    const comment = selectGenericComment(state);
    const role = selectUserRole(state);
    const isDishBusinessProperty =
        selectCurrentPropertyDetails(
            state
        ).is_part_of_dish_business_organization;

    if (
        isSuperRole(role) &&
        URL_PATHS_TO_BLOCK_FOR_COMMENTS.some((url) =>
            config.url?.includes(url)
        ) &&
        HTTP_METHODS_REQUIRING_USER_COMMENT.includes(config.method as Method) &&
        !isEmpty(comment) &&
        !isDishBusinessProperty
    ) {
        if (config.data instanceof FormData) {
            config.data.append('comment', comment);
        } else if (isEmpty(config.data)) {
            config.data = { comment: comment };
        } else config.data['comment'] = comment;
    }
    return config;
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
    async function (config: any) {
        // Do something before request is sent
        const jwtToken = await getToken();
        config.headers['Authorization'] = `bearer ${jwtToken}`;

        const modifiedConfig = addCommentsToPayload(config);

        return modifiedConfig;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (
            error === ApiResponseCode.NO_CURRENT_USER ||
            error?.code === ApiResponseCode.REFRESH_TOKEN_EXPIRED ||
            error.response?.status === 401
        ) {
            LogoutBroadcastChannel.triggerLogout();
            return;
        } else if (error.response?.status === 503) {
            store.dispatch(fetchMaintenanceStatus());
            return;
        }
        return Promise.reject(
            error?.response?.data ? error?.response?.data : error
        );
    }
);

export default axiosInstance;
