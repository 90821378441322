import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';
import Button, { ButtonSize, ButtonVariation } from './button';

const SaveBarTexts = {
    cancel: 'Cancel',
    save: 'Save'
};

const Container = styled(Card)(() => ({
    position: 'sticky',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 100,
    bottom: 0
}));

const ButonContainer = styled('div')(() => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    alignItems: 'center',
    marginRight: pxToRem(80)
}));

interface Props {
    handleSave: () => void;
    handleCancel: () => void;
    enableSaveButton: boolean;
}

function StickySaveBar(props: Props) {
    const { handleSave, handleCancel, enableSaveButton } = props;

    return (
        <Container elevation={16}>
            <ButonContainer>
                <Button
                    buttonVariant={ButtonVariation.TEXT}
                    size={ButtonSize.LARGE}
                    onClick={handleCancel}
                    sx={{ width: '8vw' }}
                >
                    {SaveBarTexts.cancel}
                </Button>

                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    onClick={handleSave}
                    disabled={!enableSaveButton}
                    sx={{ width: '11vw' }}
                >
                    {SaveBarTexts.save}
                </Button>
            </ButonContainer>
        </Container>
    );
}

export default StickySaveBar;
