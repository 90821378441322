import { UserRole } from '../users/model';

export interface ActivityLogFilters {
    organizations?: OrganizationFilter[];
    property_groups?: PropertyGroupFilter[];
    properties?: PropertyFilter[];
    users?: UserFilter[];
    resources?: string[];
    actions?: Action[];
}

export const emptyActivityLogFilters: ActivityLogFilters = {
    organizations: [],
    property_groups: [],
    properties: [],
    users: [],
    resources: [],
    actions: []
};

interface OrganizationFilter {
    id: string;
    name: string;
}

interface PropertyGroupFilter {
    id: string;
    name: string;
    organization_id: string;
}

interface PropertyFilter {
    id: string;
    name: string;
    organization_id: string;
    property_group_id: string;
}

interface UserFilter {
    email: string;
    first_name: string;
    last_name: string;
    username: string;
    role: UserRole;
    sub_id: '284f427d-ea08-457b-9e67-73ac38a4479a';
}

export interface Comment {
    comment: string;
    user: string;
    date: number;
}

export enum Action {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE = 'DELETE'
}

export enum Result {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}

export enum ResourceGroupType {
    User = 'User',
    Organization = 'Organization',
    Asset = 'Asset',
    PAGE = 'PAGE',
    RAIL = 'RAIL',
    WIDGET = 'WIDGET',
    PropertyConfig = 'Property Config',
    EvolveConfig = 'Evolve Config',
    Property = 'Property',
    PropertyGroup = 'Property Group',
    GeneralSection = 'General Section'
}

export interface ActivityLogFilterOptions {
    organization_ids: string[];
    property_group_ids: string[];
    property_ids: string[];
    user_ids: string[];
    resources: string[];
    actions: string[];
}
