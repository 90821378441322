import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ThreeDotIcon } from '../../assets/svg/threeDotsHoriz.svg';
import { ReactComponent as Trashcan } from '../../assets/svg/trashBin.svg';
import { ReactComponent as CopyIcon } from '../../assets/svg/copy.svg';
import IconStylizer from '../../components/iconStylizer';
import { useUrlParams } from '../../hooks/useParam';
import { AccessDetails } from '../../redux/element/model';
import { checkIfElementIsLive } from '../../redux/elementsList/action';
import { ElementCategory, ElementsData } from '../../redux/elementsList/model';
import {
    selectElementDeleteAllowed,
    selectRailDuplicationAllowed,
    selectWidgetDuplicationAllowed,
    selectPageDuplicationAllowed
} from '../../redux/userOperations/reducer';
import { pxToRem } from '../../utils/stylesUtils';
import DuplicateElementModal from './duplicateElementModal';

const ElementActions = (props: {
    accessDetails: AccessDetails | undefined;
    element: ElementsData;
    handleOpenModal: (id: string, type: string) => void;
}) => {
    const { accessDetails, element, handleOpenModal } = props;
    const dispatch = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { property } = useUrlParams();
    const isDeleteElementAllowed = useSelector(selectElementDeleteAllowed);
    const isPageDuplicationAllowed = useSelector(selectPageDuplicationAllowed);
    const isRailDuplicationAllowed = useSelector(selectRailDuplicationAllowed);
    const isWidgetDuplicationAllowed = useSelector(
        selectWidgetDuplicationAllowed
    );

    const isDuplicateElementAllowed = (() => {
        switch (element.type) {
            case ElementCategory.PAGE:
                return isPageDuplicationAllowed;
            case ElementCategory.RAIL:
                return isRailDuplicationAllowed;
            case ElementCategory.WIDGET:
                return isWidgetDuplicationAllowed;
            default:
                return false;
        }
    })();

    function handleDelete(data: ElementsData) {
        setIsMenuOpen(false);
        // TODO: Need to remove.
        dispatch(checkIfElementIsLive(data.id, data.type, property));
        handleOpenModal(data.id, data.type);
    }

    const onDuplicateClick = () => {
        setIsMenuOpen(false);
        setOpenDuplicateModal(true);
    };

    const elementActionMenus = (() => {
        const menuOptions: React.ReactElement[] = [];

        if (isDuplicateElementAllowed && accessDetails?.Add)
            menuOptions.push(
                <MenuItem onClick={onDuplicateClick} sx={{ color: '#525261' }}>
                    <IconStylizer
                        height={pxToRem(24)}
                        width={pxToRem(24)}
                        color={'#525261'}
                        sx={{
                            marginRight: pxToRem(10),
                            display: 'flex'
                        }}
                    >
                        <CopyIcon />
                    </IconStylizer>
                    Duplicate
                </MenuItem>
            );

        if (isDeleteElementAllowed && accessDetails?.Delete)
            menuOptions.push(
                <MenuItem
                    onClick={() => {
                        handleDelete(element);
                    }}
                    sx={{ color: '#931621' }}
                >
                    <IconStylizer
                        height={pxToRem(24)}
                        width={pxToRem(24)}
                        color={'#931621'}
                        sx={{
                            marginRight: pxToRem(10),
                            display: 'flex'
                        }}
                    >
                        <Trashcan />
                    </IconStylizer>
                    Delete
                </MenuItem>
            );

        return menuOptions;
    })();

    return (
        <>
            {!isEmpty(elementActionMenus) && (
                <Box style={{ display: 'flex', flex: 1 }}>
                    <IconButton
                        ref={buttonRef}
                        id="actions-button"
                        aria-haspopup="true"
                        onClick={() => setIsMenuOpen(true)}
                    >
                        <IconStylizer
                            height={pxToRem(24)}
                            width={pxToRem(24)}
                            color="#000000"
                        >
                            <ThreeDotIcon />
                        </IconStylizer>
                    </IconButton>
                    <Menu
                        id={'action-menu'}
                        anchorEl={buttonRef.current}
                        open={Boolean(isMenuOpen)}
                        onClose={() => setIsMenuOpen(false)}
                        MenuListProps={{
                            'aria-labelledby': 'actions-button'
                        }}
                        sx={{
                            '&#action-menu .MuiPaper-rounded': {
                                boxShadow: 'none',
                                outline: `1px solid #000000`,
                                borderRadius: pxToRem(7)
                            }
                        }}
                    >
                        {elementActionMenus.map((elementAction, index) => (
                            <Box key={index}>{elementAction}</Box>
                        ))}
                    </Menu>
                </Box>
            )}
            {openDuplicateModal && (
                <DuplicateElementModal
                    elementType={element.type}
                    setShowModal={setOpenDuplicateModal}
                    elementName={element.name}
                    elementId={element.id}
                />
            )}
        </>
    );
};

export default ElementActions;
