import { AxiosResponse, AxiosPromise } from 'axios';
import { API_URL } from '../../api/apiConstants';
import { insertPropertyIdToPath } from '../../api/apiUtils';
import axios from '../../api/axios';
import {
    ContentField,
    ElementCreateUpdateRequestBody,
    WidgetSkeleton
} from './model';

export function fetchWidgetSkeleton(widgetTypeId: string, propertyId: string) {
    return axios.get(
        insertPropertyIdToPath(API_URL.widgetSkeleton, propertyId) +
            widgetTypeId
    );
}

export function createElementHelper(
    body: ElementCreateUpdateRequestBody,
    propertyId: string
) {
    return axios.post(
        insertPropertyIdToPath(API_URL.createElement, propertyId),
        body
    );
}

export function saveElementHelper(
    body: ElementCreateUpdateRequestBody,
    propertyId: string
) {
    return axios.post(
        insertPropertyIdToPath(API_URL.saveElement, propertyId),
        body
    );
}

export function fetchElement(elementId: string, propertyId: string) {
    return axios.get(
        `${insertPropertyIdToPath(API_URL.getElement, propertyId)}/${elementId}`
    );
}

// TODO: Not being used.
export function fetchElementList(elementIdsList: string[]) {
    const elements: Promise<AxiosResponse<WidgetSkeleton[]>>[] = [];
    elementIdsList.forEach((id: string) => {
        elements.push(axios.get(`${API_URL.getElement}/${id}`));
    });
    return Promise.all(elements);
}

// TODO: Not being used.
export async function fetchWidgetDropdown(
    filteredWidgetType: ContentField[],
    contentFieldKey: string,
    propertyId: string
) {
    const requestArr: AxiosPromise[] = [];
    filteredWidgetType?.forEach((val: ContentField, i: number) => {
        const body = {
            content_field_id: Object(filteredWidgetType[i])[contentFieldKey],
            allowed_type_ids: filteredWidgetType[i].allowed_type_id,
            association_id: propertyId
        };
        requestArr.push(
            axios.post(
                insertPropertyIdToPath(API_URL.widgetDropdown, propertyId),
                body
            )
        );
    });

    return Promise.all(requestArr);
}

export function getS3FolderSize(propertyId: string) {
    return axios.get(
        insertPropertyIdToPath(API_URL.s3ElementFolderSize, propertyId)
    );
}
