import {
    PlatformSection,
    PropertyFieldValueData
} from '../propertyValue/model';

export enum ZoneType {
    Default = 'Default',
    Standard = 'Standard',
    Event = 'Event'
}

export enum VisibilityType {
    Public = 'Public',
    Private = 'Private'
}

export interface Zone {
    id: string;
    property_id: string;
    description: string;
    visibility_type: VisibilityType;
    platforms?: PlatformSection;
    type: ZoneType;
    rooms_attached: AttachedRoomInfo[];
    name: string;
}

export interface AttachedRoomInfo {
    device_id: string;
    room_no: string;
    property_id: string;
    zone_id: string;
}

export interface DefaultZone extends Zone {
    type: ZoneType.Default;
}
export interface StandardZone extends Zone {
    type: ZoneType.Standard;
}

export interface EventZone extends Zone {
    type: ZoneType.Event;
    start_time: number;
    end_time: number;
}
export interface ZonesFieldValues {
    default: DefaultZone;
    standard: StandardZone[];
    event: EventZone[];
}

interface StandardZoneAttachment {
    id: string;
    name: string;
}

interface EventZoneAttachment {
    id: string;
    name: string;
    start_time: number;
    end_time: number;
}

export interface Room {
    id: string;
    room_no: string;
    device_hardware_id: string;
    zones_attached: {
        standard_zone?: StandardZoneAttachment;
        event_zone?: EventZoneAttachment[];
    };
}

export interface ZoneFields {
    [key: string]: PropertyFieldValueData[];
}
