import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionPayload } from '../types';
import { ApiResponse } from './../../api/apiInterfaces';
import {
    createAssetsResponse,
    CREATE_ASSETS,
    deleteAssetResponse,
    DELETE_ASSET,
    editAssetResponse,
    EDIT_ASSET,
    getAssetListsResponse,
    GET_ASSETS
} from './action';
import { CreateAssetsRequest, DeleteAsset, EditAsset } from './actionPayload';
import { deleteAsset, editAsset, fetchAllAssets, uploadAsset } from './helpers';
import { Asset } from './model';

// Get All Assets
function* getAllAssetWorker(data: ActionPayload<string>) {
    try {
        const res: ApiResponse<Asset[]> = yield call(
            fetchAllAssets,
            data.payload
        );
        yield put(getAssetListsResponse(false, '', res.response_body));
    } catch (error: any) {
        yield put(getAssetListsResponse(true, error.message, []));
    }
}

// Delete Assets
function* deleteAssetSaga(data: ActionPayload<DeleteAsset>) {
    try {
        const res: ApiResponse<String> = yield call(
            deleteAsset,
            data.payload.id,
            data.payload.propertyId
        );
        yield put(deleteAssetResponse(false, res.message));
    } catch (error: any) {
        console.log('catch', error);
        yield put(deleteAssetResponse(true, error.message));
    }
}

//Edit Assets
function* updatAssetName(data: ActionPayload<EditAsset>) {
    try {
        const res: ApiResponse<Asset> = yield call(
            editAsset,
            data.payload.asset_id,
            data.payload.updated_Name,
            data.payload.owner_id
        );

        yield put(editAssetResponse(false, res.message));
    } catch (error: any) {
        console.log('catch', error);
        yield put(editAssetResponse(true, error.message));
    }
}

function* createAssetsWorker(data: ActionPayload<CreateAssetsRequest>) {
    try {
        const {
            owner_id,
            owner_type,
            cancelUploadController,
            files,
            onUploadProgress
        } = data.payload;

        const createAssetsPromise = files.map((file, index) =>
            call(uploadAsset, {
                files: [file],
                owner_id,
                owner_type,
                cancelUploadController,
                onUploadProgress
            })
        );
        // @ts-ignore
        const apiResponse: ApiResponse<Asset>[] = yield all(
            createAssetsPromise
        );

        const totalCount = apiResponse.length;
        const successItems = apiResponse.filter(
            (responseObj: ApiResponse<Asset>) => responseObj.code === 200
        );
        const responseBody = successItems.map(
            (responseObj: ApiResponse<Asset>) => responseObj.response_body
        );

        if (successItems.length === 0) {
            yield put(
                createAssetsResponse(
                    true,
                    totalCount === 1
                        ? 'Asset Upload Failed'
                        : `0/${totalCount} Assets were Uploaded`,
                    undefined
                )
            );
        } else {
            yield put(
                createAssetsResponse(
                    false,
                    totalCount === 1
                        ? 'Uploaded the Asset Successfully'
                        : `${successItems.length}/${totalCount} Assets were Successfully Uploaded`,
                    responseBody
                )
            );
        }
    } catch (e: any) {
        console.log(e);
        yield put(createAssetsResponse(true, e.message, undefined));
    }
}

export function* watchAssetSagas() {
    yield all([
        takeLatest(GET_ASSETS, getAllAssetWorker),
        takeLatest(CREATE_ASSETS, createAssetsWorker),
        takeLatest(EDIT_ASSET, updatAssetName),
        takeLatest(DELETE_ASSET, deleteAssetSaga)
    ]);
}
