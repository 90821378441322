const generateUniqueName = (list: string[], name: string) => {
    const regex = /(.*?)(\((\d+)\))?$/;
    const match = name.match(regex);
    const baseName = match ? match[1].trim() : name;
    let counter = match ? (match[3] ? parseInt(match[3], 10) : 0) : 0;
    let newName = name;
    while (list.includes(newName)) {
        counter++;
        newName = `${baseName}(${counter})`;
    }
    return newName;
};

export default generateUniqueName;
