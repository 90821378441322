import {
    ElementCategory,
    ElementToElementAttachment,
    ElementToDeviceAttachment
} from '../elementsList/model';

export interface ElementObject {
    [key: string]: WidgetSkeleton;
}

export interface WidgetObject {
    [key: string]: WidgetDropDownResponse;
}

export enum FieldType {
    Text = 'TEXT',
    Number = 'NUMBER',
    Boolean = 'BOOLEAN',
    Media = 'MEDIA',
    MultiWidgetType = 'MULTIWIDGETTYPE',
    SectionHeader = 'SECTION_HEADER',
    Json = 'JSON'
}

export enum MediaType {
    Video = 'Video',
    Image = 'Image',
    Image_Video = 'Image/Video'
}

export interface MediaConfigType {
    allowedFileTypes?: string[];
    fileUploadInfoMessage?: string;
    maxFileSize?: number;
    mediaType?: MediaType;
}

export enum ConfigurationType {
    WelcomeHeader1 = 'welcomeHeader_1',
    WelcomeHeader2 = 'welcomeHeader_2',
    Rewards = 'rewards',
    Wifi = 'wifiDetails'
}

export interface Rewards {
    enabled: boolean;
    currentRewardMember: RewardsSubFields;
    notARewardMember: RewardsSubFields;
    rewardsInformationNotAvailable: RewardsSubFields;
}

export interface RewardsSubFields {
    rewardMessage: string;
    buttonText: string;
    rewardsUrl: string;
}

export interface HeaderVariants {
    greeting: string;
    message: string;
    subHeadline?: string;
}

export interface AccessDetails {
    View: boolean;
    Edit: boolean;
    Add: boolean;
    Delete: boolean;
    SortRail: boolean;
    SortWidget: boolean;
    PageSelection: boolean;
}

export interface WifiDetail {
    wifiMessage: {
        enabled: boolean;
        message: string;
    };
    networkCredentials: {
        enabled: boolean;
        networkName: string;
        networkPassword: string;
    };
}
export interface ContentField {
    blob_url: string;
    id: string;
    name: string;
    description: string;
    key: string;
    values:
        | string
        | number
        | boolean
        | string[]
        | Rewards
        | HeaderVariants
        | WifiDetail;
    is_required: boolean;
    type: FieldType;
    modified_date: Date;
    configuration: {
        textType: any; //FIXME: remove any
        useAsTitle?: boolean;
        charLimit?: number;
        rowLength: number;
        mediaType?: MediaType;
        type?: ConfigurationType;
        allowedFileTypes?: string[];
        fileUploadInfoMessage?: string;
        maxFileSize?: number;
    };
    access_details?: AccessDetails;
    allowed_type_id: string[];
    children?: WidgetDropDownResponse[];
    content_field_id: string;
    order: number;
}

export enum TextType {
    JSON = 'JSON',
    URL = 'URL'
}

export interface WidgetSkeleton {
    short_description: string;
    id: string;
    name: string;
    description: string;
    alias: string;
    type: ElementCategory;
    associated_to: string;
    association_id: string;
    status: string;
    content_fields: ContentField[];
    widget_type_id: string;
    widget_type_name: string;
    preview_url: string;
    icon_url: string;
    access_details?: AccessDetails;
    is_live: boolean;
    can_instant_publish: boolean;
    attached_to: ElementToElementAttachment | ElementToDeviceAttachment | null;
}

export interface WidgetSkeletonPayload {
    widgetTypeId: string;
    propertyId: string;
}

export enum RailAlias {
    HeroCarousel = 'hero-carousel',
    Carousel_728x90 = 'carousel--728x90px',
    WidgetRail_300x166 = 'swimlane--widgets',
    WidgetRail_350x284 = 'swimlane--widgets-large'
}

export enum PageAlias {
    GenericPage = 'generic-page',
    HotelInfoPage = 'page--hotel-information',
    AppsPage = 'page--apps',
    HomePage = 'page--home',
    MyStayPage = 'page--my-stay',
    WatchPage = 'page--watch'
}

export enum WidgetAlias {
    WidgetMoreInfo = 'widget--more-info',
    WidgetNotification = 'widget--notification',
    WidgetStaticAd = 'widget--static-ad',
    WidgetMoreInfoVideo = 'widget--more-info-video',
    WidgetDeepLink = 'widget--deep-link',
    WidgetSports = 'widget--sports',
    WidgetWeather = 'widget--weather',
    WidgetLiveChannel = 'widget--live-channel'
}

export enum WidgetTypeName {
    WidgetMoreInfo = 'Widget - More Info',
    WidgetNotification = 'Widget - Notification'
}

export enum ContentFieldsKey {
    AdHeroImage = 'adHeroImage',
    HeroImage = 'heroImage',
    Icon = 'icon',
    Image = 'image',
    Headline = 'headline',
    SubHeading = 'subHeading',
    BodyText = 'bodyText',
    Link = 'link',
    LinkText = 'linkText',
    PosterImage = 'posterImage',
    WidgetContents = 'widgetContents',
    ContentContainers = 'contentContainers',
    BackgroundImage = 'backgroundImage',
    VideoBackground = 'videoBackground',
    ChannelId = 'channelId',
    Rewards = 'rewardsMessages',
    WelcomeMessage1 = 'welcomeMessages_1',
    WelcomeMessage2 = 'welcomeMessages_2',
    JSON = 'json',
    Header = 'header',
    Background = 'background'
}

export interface ContentValues {
    content_field_id: string;
    id?: string;
    values:
        | string
        | number
        | boolean
        | string[]
        | Rewards
        | HeaderVariants
        | WifiDetail;
}

export interface ElementCreateUpdateRequestBody {
    associated_to: string;
    association_id: string;
    content_values: ContentValues[];
    content_fields?: ContentValues[];
    id: string;
    name: string;
    status: string;
    widget_type_id?: string;
}

export interface WidgetDropDownResponse {
    icon_url?: string;
    id: string;
    name: string;
    widget_type_id: string;
    widget_type_name: string;
}

export interface ElementsWithWidgetTypeIdRes {
    content_field_id: string;
    elements_with_widgetType_id: WidgetDropDownResponse[];
}

export interface GenericObj {
    [key: string]: any;
}
