import { Portal } from '@mui/material';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import React, { createContext, useContext, useState } from 'react';

interface SnackbarHandler {
    showSnackbar: (
        message: string,
        severity?: AlertColor,
        duration?: number
    ) => void;

    hideSnackbar: () => void;
}

//@ts-ignore
const SnackbarContext = createContext<SnackbarHandler>();

export const useSnackbar = () => useContext(SnackbarContext);
interface SnackbarProviderProps {
    children: React.ReactNode;
}

export const SnackbarProvider = (
    props: SnackbarProviderProps
): React.ReactElement => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] = useState<AlertColor>('success');

    const snackbarHandler: SnackbarHandler = {
        showSnackbar: function (
            message: string,
            severity: AlertColor = 'success',
            duration: number = 2000
        ): void {
            setMessage(message);
            setSeverity(severity);
            setDuration(duration);
            setOpen(true);
        },

        hideSnackbar: function (): void {
            setOpen(false);
        }
    };

    const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        snackbarHandler.hideSnackbar();
    };

    return (
        <SnackbarContext.Provider value={snackbarHandler}>
            {props.children}
            <Portal>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    autoHideDuration={duration}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Slide}
                >
                    <Alert
                        variant="filled"
                        onClose={handleClose}
                        severity={severity}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Portal>
        </SnackbarContext.Provider>
    );
};
