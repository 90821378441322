import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';

import {
    ASSETS_OPERATION_LOADER_RESET,
    CREATE_ASSETS,
    CREATE_ASSETS_RESPONSE,
    DELETE_ASSET,
    DELETE_ASSET_RESPONSE,
    EDIT_ASSET,
    EDIT_ASSET_RESPONSE,
    GET_ASSETS,
    GET_ASSETS_RESPONSE,
    LOADER_RESET
} from './action';
import { CreateAssetsRequest, EditAsset } from './actionPayload';
import { Assets, initialAssetsState } from './state';
import { Asset } from './model';
import { orderBy } from 'lodash';

const reducer = (state: Assets = initialAssetsState, action: any): Assets => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: Assets = initialAssetsState,
    action: Action
): Assets => {
    switch (action.type) {
        case GET_ASSETS: {
            return {
                ...state,
                loader: LoadingStatus.INITIATED
            };
        }
        case LOADER_RESET: {
            return {
                ...state,
                message: '',
                loader: LoadingStatus.NONE
            };
        }
        case ASSETS_OPERATION_LOADER_RESET: {
            return {
                ...state,
                message: '',
                assetOperationLoader: LoadingStatus.NONE
            };
        }
        default: {
            return state;
        }
    }
};

const actionWithPayloadReducer = (
    state: Assets = initialAssetsState,
    action: ActionPayload<string | EditAsset | CreateAssetsRequest>
): Assets => {
    switch (action.type) {
        case CREATE_ASSETS: {
            return {
                ...state,
                assetOperationLoader: LoadingStatus.INITIATED
            };
        }
        case DELETE_ASSET: {
            return {
                ...state,
                assetOperationLoader: LoadingStatus.INITIATED
            };
        }
        case EDIT_ASSET: {
            return {
                ...state,
                assetOperationLoader: LoadingStatus.INITIATED
            };
        }
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: Assets = initialAssetsState,
    action: ActionPayloadOrError<Asset | void | Asset[]>
): Assets => {
    switch (action.type) {
        case GET_ASSETS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    loader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while getting the assets.'
                };
            }
            return {
                ...state,
                loader: LoadingStatus.DONE,
                assets: action.payload as Asset[]
            };
        }

        case CREATE_ASSETS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    assetOperationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while creating the asset.'
                };
            }
            return {
                ...state,
                assetOperationLoader: LoadingStatus.DONE,
                assets: [...(action.payload as Asset[]), ...state.assets],
                message: action.message ?? 'Asset created successfully.'
            };
        }

        case DELETE_ASSET_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    assetOperationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while deleting the asset.'
                };
            }

            return {
                ...state,
                assetOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Asset deleted successfully.'
            };
        }

        case EDIT_ASSET_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    assetOperationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while updating the asset.'
                };
            }

            return {
                ...state,
                assetOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Asset updated successfully.'
            };
        }

        default: {
            return state;
        }
    }
};

export const selectAssets = (state: coreRootState): Asset[] => {
    return orderBy(state.assets.assets, 'created_at', ['desc']);
};

export const selectAssetsLoader = (state: coreRootState): LoadingStatus => {
    return state.assets.loader;
};

export const selectMessage = (state: coreRootState): string => {
    return state.assets.message;
};

export const selectAssetOperationLoadingState = (
    state: coreRootState
): LoadingStatus => {
    return state.assets.assetOperationLoader;
};

export const existingAssetsNames = (state: coreRootState): string[] => {
    const assets = state.assets.assets;
    return assets.map((individual_asset) => individual_asset.name);
};

export default reducer;
