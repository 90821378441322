import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import cmpTexts from '../../../locale/en';

const ContainerGrid = styled(Grid)(() => ({
    width: '89.6%',
    position: 'sticky',
    top: 66,
    zIndex: 200,
    marginLeft: '3.8%',
    marginTop: 2
}));

const CustomPaper = styled(Paper)(() => ({
    padding: 24
}));

const Container = styled('div')(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'red'
}));

function MandatoryFieldAlert() {
    return (
        <ContainerGrid
            xs={12}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <CustomPaper elevation={4}>
                <Container>{cmpTexts.SaveBarTexts.description}</Container>
            </CustomPaper>
        </ContainerGrid>
    );
}

export default MandatoryFieldAlert;
