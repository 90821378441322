import styled from '@emotion/styled';
import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';

export enum CheckBoxVariation {
    Default = 'default',
    SectionToggle = 'section-toggle'
}

const SectionToggle = styled('div')(() => ({
    display: 'flex',
    order: 1,
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: `-${pxToRem(100)}`,
    alignItems: 'center'
}));

interface Props {
    checked: boolean;
    handleChange: (value: boolean) => void;
    label: string | undefined;
    variation?: CheckBoxVariation;
    disabled?: boolean;
}

const Variation = (props: {
    variation: CheckBoxVariation;
    children: React.ReactNode;
}) => {
    const { children } = props;
    switch (props.variation) {
        case CheckBoxVariation.SectionToggle:
            return <SectionToggle>{children}</SectionToggle>;
        default:
            return <>{children}</>;
    }
};

const PropertySectionCheckBox = (props: Props) => {
    const {
        checked,
        handleChange,
        label,
        variation = CheckBoxVariation.Default,
        disabled = false
    } = props;

    return (
        <Variation variation={variation}>
            <FormControlLabel
                className="checkBox"
                sx={{ color: '#171725' }}
                control={
                    <Checkbox
                        disabled={disabled}
                        checked={checked}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                }
                label={label}
            />
        </Variation>
    );
};

export default PropertySectionCheckBox;
