import React from 'react';
import CMPTexts from '../../locale/en';
import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { selectMaintenanceBackgroumdImage } from '../../redux/maintenance/reducer';
import { useSelector } from 'react-redux';

const Container = styled('div')(() => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f6f6f6',
    flexGrow: 1
}));

function UnderMaintenance() {
    const imageUrl = useSelector(selectMaintenanceBackgroumdImage);
    return (
        <Container style={{ minHeight: '50vh' }}>
            <Grid
                container
                style={{
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <Grid item>
                            <img
                                style={{ maxHeight: '40vh' }}
                                src={imageUrl}
                                alt="CMP is under Maintenance"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                fontWeight="bold"
                            >
                                {CMPTexts.underMaintenance.title}
                            </Typography>
                            <Typography
                                variant="body1"
                                paragraph
                                style={{ whiteSpace: 'pre-line' }}
                            >
                                {CMPTexts.underMaintenance.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default UnderMaintenance;
