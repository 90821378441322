import { styled } from '@mui/material/styles';
import { FormikProps } from 'formik';
import React from 'react';
import { useLocation } from 'react-router';
import PreviewImageSection from '../../components/previewImageSection';
import { WidgetSkeleton, WidgetAlias } from '../../redux/element/model';
import { ElementCategory } from '../../redux/elementsList/model';
import { pxToRem } from '../../utils/stylesUtils';
import { description } from '../../utils/widgetDescription';

interface RightContainerProps {
    formik: FormikProps<WidgetSkeleton>;
    previewImage: string;
}

const ImageBackground = styled('img')(() => ({
    height: pxToRem(250),
    width: '100%'
}));

const DescriptionContainer = styled('div')(() => ({
    marginTop: 0,
    display: 'inline-block',
    flex: 1
}));

const HeadingText = styled('h2')(() => ({
    fontSize: '1.04rem',
    fontWeight: 'normal'
}));

const DescriptionText = styled('h2')(() => ({
    fontSize: '1.04rem',
    fontWeight: 500
}));

function ElementContainerRight(props: RightContainerProps) {
    const location = useLocation();

    const routerState = location?.state;

    const { formik, previewImage } = props;
    return (
        <>
            {routerState &&
                routerState?.widget?.type !== ElementCategory.RAIL &&
                routerState?.widget?.type !== ElementCategory.PAGE && (
                    <DescriptionContainer>
                        <HeadingText>
                            {location.pathname.split('/').pop() === 'create'
                                ? `Sample ${formik?.values?.alias}`
                                : routerState?.widget?.widget_type_name
                                ? `${routerState?.widget?.widget_type_name}`
                                : ''}
                        </HeadingText>
                        {formik?.values?.type === ElementCategory.WIDGET &&
                        (formik?.values?.alias === WidgetAlias.WidgetMoreInfo ||
                            formik?.values?.alias ===
                                WidgetAlias.WidgetNotification ||
                            formik?.values?.alias ===
                                WidgetAlias.WidgetStaticAd ||
                            formik?.values?.alias ===
                                WidgetAlias.WidgetMoreInfoVideo) ? (
                            <PreviewImageSection
                                formik={formik}
                                widgetType={formik?.values?.alias}
                            />
                        ) : (
                            formik?.values?.type === ElementCategory.WIDGET &&
                            previewImage && (
                                <ImageBackground src={previewImage} />
                            )
                        )}
                        <DescriptionText>
                            {formik?.values?.description
                                ? formik?.values?.description
                                : description(formik?.values?.alias)}
                        </DescriptionText>
                    </DescriptionContainer>
                )}
        </>
    );
}

export default ElementContainerRight;
