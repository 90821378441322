import React from 'react';
import { Select, SelectProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)(({ theme }) => ({
    '&.MuiOutlinedInput-root.MuiInputBase-formControl.MuiSelect-root .MuiOutlinedInput-notchedOutline':
        {
            borderColor: 'black'
        },
    '&.MuiOutlinedInput-root.MuiInputBase-formControl.MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline':
        { borderColor: theme.palette.primary.main }
}));

const CustomSelect = (props: SelectProps) => {
    return <StyledSelect {...props} />;
};

export default CustomSelect;
