import { isEmpty, isNil } from 'lodash';
import * as Yup from 'yup';
import {
    ConfigurationType,
    Rewards,
    TextType,
    HeaderVariants,
    WifiDetail
} from '../redux/element/model';
import { urlRegex } from './regexUtil';

export const ConfigureElementSchema = Yup.object().shape({
    name: Yup.string().trim().required('*Required'),
    content_fields: Yup.array().of(
        Yup.object()
            .shape({})
            .test('isRequired', '*Required', (data: any) => {
                if (
                    (data.type === 'TEXT' || data.type === 'MEDIA') &&
                    data.is_required === true &&
                    isEmpty(data.values?.toString().trim())
                ) {
                    return false;
                } else if (
                    data.type === 'NUMBER' &&
                    data.is_required === true &&
                    (isEmpty(data.values?.toString().trim()) ||
                        isNaN(data.values))
                ) {
                    return false;
                } else if (
                    data.type === 'JSON' &&
                    data.configuration?.type === ConfigurationType.Wifi &&
                    (isEmpty(data.values) ||
                        !(
                            data.values?.networkCredentials?.enabled ||
                            data.values?.wifiMessage?.enabled
                        ))
                ) {
                    return false;
                }
                return true;
            })
            .test('URL Format', '*Incorrect URL', (data: any) => {
                if (
                    data.type === 'TEXT' &&
                    data.configuration?.textType === TextType.URL &&
                    data.values
                ) {
                    return urlRegex.test(data.values);
                }
                return true;
            })
            .test('JSON', '*Invalid JSON', (data: any) => {
                if (
                    data.type === 'TEXT' &&
                    data.configuration?.textType === TextType.JSON
                ) {
                    try {
                        JSON.parse(data.values);
                        return true;
                    } catch (err) {
                        return false;
                    }
                }
                return true;
            })
    )
});

export const RewardsGeneratorSchema = Yup.object().shape({
    currentRewardMember: Yup.object().shape({
        rewardMessage: Yup.string()
            .required('*Required')
            .trim()
            .max(1000, 'Should be less than 1000'),
        buttonText: Yup.string()
            .required('*Required')
            .trim()
            .max(13, 'Should be less than 13'),
        rewardsUrl: Yup.string()
            .required('*Required')
            .trim()
            .test('URL Format', '*Incorrect URL', (url: any) => {
                return urlRegex.test(url);
            })
    }),
    notARewardMember: Yup.object().shape({
        rewardMessage: Yup.string()
            .required('*Required')
            .trim()
            .max(1000, 'Should be less than 1000'),
        buttonText: Yup.string()
            .required('*Required')
            .trim()
            .max(13, 'Should be less than 13'),
        rewardsUrl: Yup.string()
            .required('*Required')
            .trim()
            .test('URL Format', '*Incorrect URL', (url: any) => {
                return urlRegex.test(url);
            })
    }),
    rewardsInformationNotAvailable: Yup.object().shape({
        rewardMessage: Yup.string()
            .required('*Required')
            .trim()
            .max(1000, 'Should be less than 1000'),
        buttonText: Yup.string()
            .required('*Required')
            .trim()
            .max(13, 'Should be less than 13'),
        rewardsUrl: Yup.string()
            .required('*Required')
            .trim()
            .test('URL Format', '*Incorrect URL', (url: any) => {
                return urlRegex.test(url);
            })
    })
});

export const WifiGeneratorSchema = Yup.object().shape({
    wifiMessage: Yup.object().shape({
        enabled: Yup.boolean(),
        message: Yup.string().when('enabled', {
            is: true,
            then: Yup.string()
                .required('*Required')
                .trim()
                .max(1000, 'Should be less than 1000')
        })
    }),
    networkCredentials: Yup.object().shape({
        enabled: Yup.boolean(),
        networkName: Yup.string()
            .trim()
            .when('enabled', {
                is: true,
                then: Yup.string()
                    .required('*Required')
                    .trim()
                    .min(3, 'Should be more than 3')
            }),
        networkPassword: Yup.string()
            .trim()
            .when('enabled', {
                is: true,
                then: Yup.string()
                    .required('*Required')
                    .trim()
                    .min(5, 'Should be more than 5')
            })
    })
});

const defaultCharacterLimit = 35;
export const getCharacterLimit = (
    characterLimit = defaultCharacterLimit,
    fieldValue:
        | string
        | number
        | boolean
        | string[]
        | Rewards
        | HeaderVariants
        | WifiDetail
) => {
    if (!isNil(fieldValue) && !isEmpty(fieldValue.toString())) {
        return fieldValue.toString()?.trimStart().length + '/' + characterLimit;
    }
    return '0/' + characterLimit;
};
