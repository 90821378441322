import { API_URL } from '../../api/apiConstants';
import { insertPropertyIdToPath } from '../../api/apiUtils';
import axios from '../../api/axios';
import { ElementCategory } from './model';

export function fetchAllElementsHelper(propertyId: string) {
    return axios.get(insertPropertyIdToPath(API_URL.elementList, propertyId));
}

// TODO: Not being used. What to do?
export function fetchWidgetTypeList(
    category: ElementCategory,
    propertyId: string
) {
    return axios.get(
        `${insertPropertyIdToPath(
            API_URL.widgetTypesList,
            propertyId
        )}?type=${category}`
    );
}

export function fetchPage(propertyId: string, type: string) {
    return axios.get(
        `${insertPropertyIdToPath(API_URL.pageList, propertyId)}?type=${type}`
    );
}

export function fetchElementListByType(propertyId: string, type: string) {
    return axios.get(
        `${insertPropertyIdToPath(
            API_URL.fetchElementListByType,
            propertyId
        )}?type=${type}`
    );
}

export function fetchAllWidgetTypes(propertyId: string) {
    return axios.get(
        insertPropertyIdToPath(API_URL.fetchAllWidgetTypes, propertyId)
    );
}

export function deleteElement(
    propertyId: string,
    elementId: string,
    type: string
) {
    return axios.delete(
        `${insertPropertyIdToPath(
            API_URL.deleteElement,
            propertyId
        )}/${elementId}?type=${type}`
    );
}

export function duplicateElementHelper(
    propertyId: string,
    oldElementId: string,
    newElementName: string
) {
    const body = {
        old_element_id: oldElementId,
        new_element_name: newElementName
    };

    return axios.post(
        `${insertPropertyIdToPath(API_URL.duplicateElement, propertyId)}`,
        body
    );
}

export function isElementLive(
    elementId: string,
    type: string,
    propertyId: string
) {
    const body = {
        property_id: propertyId,
        element_type: type
    };

    return axios.post(
        `${insertPropertyIdToPath(
            API_URL.elementLive,
            propertyId
        )}/${elementId}/isPartOfLivePage`,
        body
    );
}

export function fetchPropertyAssignedToManager(propertyManagerIds: string[]) {}
