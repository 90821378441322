import { LoadingStatus } from '../common/state';
import { ElementCreateUpdateRequestBody, WidgetSkeleton } from './model';

export interface Element {
    data: WidgetSkeleton;
    status: LoadingStatus;
    error: string;
    imageLoader: LoadingStatus;
    createElementResponse: ElementCreateUpdateRequestBody;
    saveElementResponse: ElementCreateUpdateRequestBody;
    createElementStatus: LoadingStatus;
    saveElementStatus: LoadingStatus;
    pageBackgroundMessage: string;
}

export const emptyCreateAndSaveElementResponse: ElementCreateUpdateRequestBody =
    {
        associated_to: 'PROPERTY',
        association_id: 'string',
        content_values: [],
        id: '',
        name: '',
        status: '',
        widget_type_id: ''
    };

export const initialElementState: Element = {
    data: {
        short_description: '',
        id: '',
        name: '',
        description: '',
        alias: '',
        //@ts-ignore
        type: '',
        associated_to: '',
        association_id: '',
        status: '',
        content_fields: [],
        preview_url: '',
        widget_type_name: ''
    },
    status: LoadingStatus.NONE,
    error: '',
    imageLoader: LoadingStatus.NONE,
    createElementResponse: emptyCreateAndSaveElementResponse,
    saveElementResponse: emptyCreateAndSaveElementResponse,
    createElementStatus: LoadingStatus.NONE,
    saveElementStatus: LoadingStatus.NONE,
    pageData: {},
    railData: {},
    widgetData: {},
    railDropDown: {},
    pageBackgroundMessage: ''
};
