import { LoadingStatus } from '../common/state';

export type MaintenanceState = {
    loadingStatus: LoadingStatus;
    enabled: boolean;
    image_url: string;
    message: string;
};

export const initialMaintenanceState: MaintenanceState = {
    loadingStatus: LoadingStatus.NONE,
    enabled: false,
    image_url: '',
    message: ''
};
