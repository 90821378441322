import { Grid } from '@mui/material';
import { head, isEmpty, join, last } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import TimePicker from '../../../../components/timePicker';
import DatePicker from './datePicker';

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'HH:mm:ss';

const DateAndTimePicker = ({
    required,
    label,
    epochDate,
    setEpochDate,
    error,
    ...rest
}: {
    required: boolean;
    label: string;
    epochDate: number | undefined;
    error: string;
    minimumDate?: Date;
    maximumDate?: Date;
    setEpochDate: (epochDate: number) => void;
}) => {
    const [dateAndTime, setDateAndTime] = useState<string[]>(['', '']);

    useEffect(() => {
        if (epochDate) {
            const [date, time] = moment(epochDate)
                .format(`${DATE_FORMAT};${TIME_FORMAT}`)
                .split(';');
            setDateAndTime([date, time]);
        }
    }, [epochDate]);

    const setDate = (date: moment.Moment) => {
        const tempDate = moment(date).format(DATE_FORMAT);
        setDateAndTime((prevDateAndTime: string[]) => [
            tempDate,
            last(prevDateAndTime) as string
        ]);
    };

    const setTime = (time: string) => {
        setDateAndTime((prevDateAndTime: string[]) => [
            head(prevDateAndTime) as string,
            time
        ]);
    };

    useEffect(() => {
        if (!(isEmpty(head(dateAndTime)) || isEmpty(last(dateAndTime)))) {
            const formattedDate = join(
                [head(dateAndTime), ';', last(dateAndTime)],
                ''
            );
            const newEpochDate =
                moment(formattedDate, `${DATE_FORMAT};${TIME_FORMAT}`).unix() *
                1000;

            if (newEpochDate !== epochDate) {
                setEpochDate(newEpochDate);
            }
        }
    }, [head(dateAndTime), last(dateAndTime)]);

    return (
        <>
            <Grid item xs={8}>
                <DatePicker
                    error={error}
                    required
                    label={label}
                    date={head(dateAndTime)}
                    setDate={setDate}
                    {...rest}
                />
            </Grid>
            <Grid item xs={4}>
                <TimePicker
                    value={last(dateAndTime) ?? ''}
                    onChange={setTime}
                    required={required}
                />
            </Grid>
        </>
    );
};

export default DateAndTimePicker;
