import { store } from '../redux/store';
import { userLogin } from '../redux/login/action';
import { UserLoginPayloadType } from '../redux/login/model';

const LOGIN_BROADCAST_CHANNEL = 'LOGIN_BROADCAST_CHANNEL';

const LoginBroadcastChannel = (() => {
    let bc: BroadcastChannel = new BroadcastChannel(LOGIN_BROADCAST_CHANNEL);
    console.log('*** Login Broadcast initialized');

    function init() {
        bc = new BroadcastChannel(LOGIN_BROADCAST_CHANNEL);
        console.log('*** Login channel init');
    }

    function login(payload: UserLoginPayloadType) {
        bc.postMessage(payload);
    }

    bc.onmessage = (event: MessageEvent) => {
        console.log('*** login event', event);
        const loginStatus = store.getState().login.status;
        if (loginStatus !== 'LOGGING_IN' && loginStatus !== 'SUCCESS') {
            store.dispatch(userLogin(event.data));
        }
    };

    return {
        triggerLogin(payload: UserLoginPayloadType) {
            console.log('*** trigger Login');
            const loginStatus = store.getState().login.status;
            if (loginStatus !== 'LOGGING_IN' && loginStatus !== 'SUCCESS') {
                store.dispatch(userLogin(payload));
                login(payload);
            }
        },
        initLoginChannel() {
            init();
        },
        closeLoginChannel() {
            bc.close();
            console.log('*** Login channel close');
        }
    };
})();

export default LoginBroadcastChannel;
