import { Box, Divider } from '@mui/material';
import styled from '@mui/styles/styled';
import { useFormik } from 'formik';
import { get, isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropertyDetailsNavigationPrompt from './propertyDetailsNavigationPrompt';
import { DisableableBox } from '../../../components/disableableBox';
import FullScreenCircularLoader from '../../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../../components/snackbar';
import StickySaveBar from '../../../components/stickySaveBar';
import StyledPaper from '../../../components/styledPaper';
import { useUrlParams } from '../../../hooks/useParam';
import { LoadingStatus } from '../../../redux/common/state';
import {
    getEvolve2Fields,
    resetEvolve2Loader,
    resetEvolve2OperationLoader,
    updateEvolve2Config
} from '../../../redux/evolve2/action';
import {
    selectEvolve2Data,
    selectEvolve2Loader,
    selectEvolve2Message,
    selectEvolve2OperationLoader
} from '../../../redux/evolve2/reducer';
import { getPropertyDetails } from '../../../redux/propertylist/action';
import {
    selectCurrentPropertyDetails,
    selectPropertyLoader,
    selectPropertyOperationLoader
} from '../../../redux/propertylist/reducer';
import {
    PropertyField,
    PropertyFieldValue
} from '../../../redux/propertyValue/model';
import { propertyFieldsValidationSchema } from '../../../utils/propertiesUtil';
import { pxToRem } from '../../../utils/stylesUtils';
import usePropertyFieldUpdatePayloadCreator from '../hooks/usePropertyFieldPayloadCreator';
import PropertySectionCheckBox from './components/propertySectionCheckBox';
import PropertySectionComplex from './components/propertySectionComplex';
import { PropertyFieldSectionTitle } from './styles';

const evolve2FieldValidation = Yup.object().shape({
    fields: propertyFieldsValidationSchema
});

const Header = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between'
}));

const Evolve2 = () => {
    const { showSnackbar } = useSnackbar();
    const [showSaveBar, setShowSaveBar] = useState(false);
    const evolve2Fields = useSelector(selectEvolve2Data);
    const { property } = useUrlParams();
    const propertyDetailsStatus = useSelector(selectPropertyOperationLoader);
    const propertyLoader = useSelector(selectPropertyLoader);

    const evolve2FieldsStatus = useSelector(selectEvolve2Loader);

    const evolve2OperationLoader = useSelector(selectEvolve2OperationLoader);
    const message = useSelector(selectEvolve2Message);
    const evolve2EnabledStatus = useSelector(
        selectCurrentPropertyDetails
    ).evolve2_enabled;
    const [evolve2Enabled, setEvolve2Enabled] = useState(evolve2EnabledStatus);
    const dispatch = useDispatch();
    const propertyFieldPayloadCreator = usePropertyFieldUpdatePayloadCreator();

    useEffect(() => {
        dispatch(getEvolve2Fields(property));
        dispatch(getPropertyDetails(property));
    }, []);

    const onEvolve2StatusChange = (value: boolean) => {
        setEvolve2Enabled(value);
        setShowSaveBar(true);
        if (value) formik.validateForm();
    };

    useEffect(() => {
        if (evolve2FieldsStatus === LoadingStatus.DONE) {
            dispatch(resetEvolve2Loader());
        } else if (evolve2FieldsStatus === LoadingStatus.FAILED) {
            showSnackbar(message, 'error');
            dispatch(resetEvolve2Loader());
        }
    }, [evolve2FieldsStatus]);

    useEffect(() => {
        if (evolve2OperationLoader === LoadingStatus.DONE) {
            showSnackbar(message);
            dispatch(getEvolve2Fields(property));
            dispatch(getPropertyDetails(property));
            dispatch(resetEvolve2OperationLoader());
        } else if (evolve2OperationLoader === LoadingStatus.FAILED) {
            showSnackbar(message, 'error');
            dispatch(resetEvolve2OperationLoader());
        }
        setShowSaveBar(false);
    }, [evolve2OperationLoader]);

    const onFieldUpdate = (
        sectionIndex: number,
        childIndex: number,
        value: PropertyFieldValue,
        isManualUpdate: boolean
    ) => {
        const propertyFieldToUpdate = `fields[${sectionIndex}].children[${childIndex}].value`;
        const propertyFieldId = get(
            formik.values,
            `fields[${sectionIndex}].children[${childIndex}].id`,
            ''
        );
        formik.setFieldValue(propertyFieldToUpdate, value);
        propertyFieldPayloadCreator.updatePropertyFieldPayload(
            propertyFieldId,
            value,
            isManualUpdate
        );
    };

    const getPropertyFieldError = (
        sectionIndex: number,
        childIndex: number
    ): string => {
        const errorMessage = get(
            formik.errors,
            `fields[${sectionIndex}].children[${childIndex}].value`,
            ''
        );
        return errorMessage;
    };

    const handleCancel = () => {
        setShowSaveBar(false);
        setEvolve2Enabled(evolve2EnabledStatus);
        formik.resetForm();
        propertyFieldPayloadCreator.clearPayload();
    };

    const handleSubmit = () => {
        dispatch(
            updateEvolve2Config({
                evolve2_enabled: evolve2Enabled,
                property_id: property,
                fields: propertyFieldPayloadCreator.payload
            })
        );

        propertyFieldPayloadCreator.clearPayload();
    };

    const formik = useFormik<{ fields: PropertyField[] | [] }>({
        initialValues: isEmpty(evolve2Fields)
            ? { fields: [] }
            : { fields: evolve2Fields },
        validationSchema: evolve2FieldValidation,
        enableReinitialize: true,
        onSubmit: () => {}
    });

    useEffect(() => {
        setShowSaveBar(formik.dirty || evolve2Enabled !== evolve2EnabledStatus);
    }, [formik.dirty, evolve2Enabled, evolve2EnabledStatus]);

    return (
        <>
            {(evolve2FieldsStatus === LoadingStatus.INITIATED ||
                evolve2OperationLoader === LoadingStatus.INITIATED ||
                propertyLoader === LoadingStatus.INITIATED ||
                propertyDetailsStatus === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            <Box
                sx={{
                    backgroundColor: '#f6f6f6',
                    padding: `${pxToRem(24)} ${pxToRem(44)} ${pxToRem(48)}`
                }}
            >
                <Header>
                    <PropertyFieldSectionTitle>
                        EVOLVE M Provisioning
                    </PropertyFieldSectionTitle>
                    <PropertySectionCheckBox
                        checked={evolve2Enabled}
                        handleChange={onEvolve2StatusChange}
                        label="Enable EVOLVE M Provisioning"
                    />
                </Header>
                {!isEmpty(formik.values?.fields) && (
                    <StyledPaper>
                        <DisableableBox disabled={!evolve2Enabled}>
                            {formik.values.fields.map(
                                (section: PropertyField, index) => {
                                    const canView =
                                        section.access_details?.View;
                                    if (!canView) return null;

                                    return (
                                        <Fragment key={section.id}>
                                            <Box
                                                sx={{
                                                    marginTop: pxToRem(32),
                                                    marginBottom: pxToRem(20)
                                                }}
                                            >
                                                <PropertySectionComplex
                                                    section={section}
                                                    allSectionsFields={
                                                        formik.values.fields
                                                    }
                                                    getPropertyFieldError={(
                                                        childIndex
                                                    ) =>
                                                        getPropertyFieldError(
                                                            index,
                                                            childIndex
                                                        )
                                                    }
                                                    onFieldUpdate={(
                                                        childIndex,
                                                        value,
                                                        isManualUpdate
                                                    ) =>
                                                        onFieldUpdate(
                                                            index,
                                                            childIndex,
                                                            value,
                                                            isManualUpdate
                                                        )
                                                    }
                                                    manuallyUpdatedFieldIds={
                                                        propertyFieldPayloadCreator.manuallyUpdatedFieldIds
                                                    }
                                                />
                                            </Box>
                                            {index !==
                                                formik.values.fields.length -
                                                    1 && (
                                                <Divider
                                                    sx={{
                                                        marginTop: pxToRem(12),
                                                        marginBottom: pxToRem(8)
                                                    }}
                                                />
                                            )}
                                        </Fragment>
                                    );
                                }
                            )}
                        </DisableableBox>
                    </StyledPaper>
                )}
            </Box>
            <PropertyDetailsNavigationPrompt
                shouldBlockNavigation={showSaveBar}
            />
            {showSaveBar && (
                <StickySaveBar
                    handleSave={handleSubmit}
                    handleCancel={handleCancel}
                    enableSaveButton={!formik.errors?.fields}
                />
            )}
        </>
    );
};

export default Evolve2;
