import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { pxToRem, StylesDictionary } from '../../../utils/stylesUtils';

export const PropertyFieldStyles: StylesDictionary = {
    fieldDisabled: {
        pointerEvents: 'none',
        opacity: 0.5
    }
};

export const PropertySectionPageContainer = styled(Box)(() => ({
    width: '100%',
    backgroundColor: '#f6f6f6',
    padding: `${pxToRem(24)} ${pxToRem(48)} ${pxToRem(48)}`
}));

export const PropertyFieldSectionTitle = styled(Typography)(() => ({
    color: 'black',
    marginBottom: pxToRem(20),
    fontSize: '1.6rem',
    fontWeight: 500,
    textAlign: 'start'
}));

export const PropertyFieldSubSectionTitle = styled(Typography)(() => ({
    fontWeight: 700,
    color: '#171725',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(22)
}));
