import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { UserOperationResponse, OperationPayload } from './actionPayload';
import { Operations } from './model';

export const GET_OPERATIONS = 'GET_OPERATIONS';
export const OPERATIONS_RESPONSE = 'OPERATIONS_RESPONSE';
export const PURGE_OPERATIONS = 'PURGE_OPERATIONS';

export const purgeOperations = (): Action => {
    return {
        type: PURGE_OPERATIONS
    };
};

export const getOperations = (
    role: string
): ActionPayload<OperationPayload> => {
    return {
        type: GET_OPERATIONS,
        payload: { role }
    };
};

export const userOperationsResponse = (
    error: boolean,
    operations?: Operations
): ActionPayloadOrError<UserOperationResponse> => {
    return {
        type: OPERATIONS_RESPONSE,
        payload: { operations },
        error
    };
};
