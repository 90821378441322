import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionProps, AccordionSummaryProps, styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#292938',
    color: '#fff',
    fontWeight: 'bold',
    flexDirection: 'row-reverse',
    overflow: 'auto',
    padding: '0px !important',
    '& .MuiTypography-root': {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: `${pxToRem(18)}`
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: '#fff',
        transform: 'rotate(90deg)',
        margin: 10
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(270deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    },
    '& .css-1uh3lx7-MuiAccordionDetails-root': {
        padding: '0px !important'
    }
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    borderTop: `${pxToRem(1)} solid rgba(0, 0, 0, .125)`,
    padding: 0
}));
