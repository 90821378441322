import { Edit } from '@mui/icons-material';
import {
    Box,
    Card,
    Grid,
    MenuItem,
    SelectChangeEvent,
    styled,
    Typography
} from '@mui/material';
// import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../../components/button';
import { DisableableBox } from '../../../../components/disableableBox';
import Select from '../../../../components/select';
import Tooltip from '../../../../components/tooltip';
import cmpTexts from '../../../../locale/en';
import { ElementListResponse } from '../../../../redux/elementsList/model';
import { selectPageList } from '../../../../redux/elementsList/reducer';
import { PropertyField } from '../../../../redux/propertyValue/model';
import { evaluateEditabilityCondition } from '../../../../utils/propertiesUtil';
import { pxToRem } from '../../../../utils/stylesUtils';
import LiveToggle from '../../propertyDetails/components/liveToggle';
import PropertySectionCheckBox from '../../propertyDetails/components/propertySectionCheckBox';
import { PropertyFieldSubSectionTitle } from '../../propertyDetails/styles';
import PreviewButton from '../../preview/previewButton';
import WarningIcon from '@mui/icons-material/ReportProblem';

interface PlatformPageCardProps {
    platform: string;
    pageField: PropertyField;
    siblingPages: PropertyField[];
    onChildFieldUpdate: (childIndex: number, value: any) => void;
    propertyId: string;
    showWarningIcon: boolean;
}

export enum TileTypes {
    SELECTABLE_PAGE = 'SELECTABLE_PAGE',
    PAGE = 'PAGE',
    TV_GUIDE_PAGE = 'TV_GUIDE_PAGE'
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const NameBadge = styled(Box)(() => ({
    cursor: 'pointer',
    color: '#F5F5F6',
    background: '#525261',
    marginRight: pxToRem(10),
    padding: pxToRem(6),
    borderRadius: '50%',
    width: pxToRem(30),
    height: pxToRem(30),
    fontWeight: 'bold',
    textAlign: 'center'
}));

const PlatformPageCard = (props: PlatformPageCardProps) => {
    const {
        platform,
        pageField,
        onChildFieldUpdate,
        propertyId,
        siblingPages,
        showWarningIcon
    } = props;

    const [selectedPage, setSelectedPage] = useState<ElementListResponse>();
    const pageList = useSelector(selectPageList);
    const tileType = pageField.configuration?.tileType;

    if (pageField.children === null) pageField.children = [];

    const pageElementFiledIndex = pageField.children.findIndex(
        (p: PropertyField) => p.key === 'pageElement'
    );
    const pageVisibilityFieldIndex = pageField.children.findIndex(
        (p: PropertyField) => p.key === 'isPageEnabled'
    );

    const pageElementConfig: PropertyField | null =
        pageElementFiledIndex !== -1
            ? pageField.children[pageElementFiledIndex]
            : null;

    const pageVisibilityConfig: PropertyField | null =
        pageVisibilityFieldIndex !== -1
            ? pageField.children[pageVisibilityFieldIndex]
            : null;

    const location = useLocation();
    const navigate = useNavigate();

    const CheckBoxWithLabel = (pf: PropertyField, disableCheckBox: boolean) => {
        const canEdit = pf?.access_details?.Edit ?? false;
        return (
            <DisableableBox
                disabled={
                    (disableCheckBox && isPageSelectionDisabled) || !canEdit
                }
            >
                <PropertySectionCheckBox
                    checked={Boolean(pf?.value)}
                    handleChange={handleCheckbox(pf?.key as string)}
                    label={pf?.name}
                />
            </DisableableBox>
        );
    };

    const autoScrollIndex = pageField.children?.findIndex(
        (i) => i.key === 'enableAutoScroll'
    );
    const miniGuideIndex = pageField.children?.findIndex(
        (i) => i.key === 'enableMiniGuide'
    );

    const getPlatformPageCard = (tileType: string) => {
        switch (tileType) {
            case TileTypes.SELECTABLE_PAGE:
                return (
                    <>
                        {renderPageSelectDropdown && (
                            <Grid item xs={12}>
                                {canChangePage ? (
                                    <Select
                                        value={pageElementConfig?.value}
                                        onChange={(e) =>
                                            handlePageChange(
                                                e as SelectChangeEvent
                                            )
                                        }
                                        MenuProps={menuProps}
                                        sx={{
                                            width: '100%',
                                            height: pxToRem(40),
                                            border: '1px solid black',
                                            fieldset: {
                                                border: 'none'
                                            }
                                        }}
                                        disabled={isPageSelectionDisabled}
                                    >
                                        {filteredPageList?.map(
                                            (val: ElementListResponse) => {
                                                return (
                                                    <MenuItem
                                                        key={val.id}
                                                        value={val.id}
                                                    >
                                                        {val.name}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                ) : (
                                    <Typography variant={'body1'} fontSize={18}>
                                        {
                                            filteredPageList.find(
                                                (fp) =>
                                                    fp.id ===
                                                    pageElementConfig?.value
                                            )?.name
                                        }
                                    </Typography>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                variant={'caption'}
                                fontWeight={'bold'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minHeight: pxToRem(40)
                                }}
                            >
                                {selectedPage?.modified_by && (
                                    <Tooltip message={selectedPage.modified_by}>
                                        <NameBadge>
                                            {getNameShortForm(
                                                selectedPage.modified_by
                                            )}
                                        </NameBadge>
                                    </Tooltip>
                                )}
                                {`Page last edited ${moment(
                                    selectedPage?.modified_date
                                ).calendar({
                                    sameElse: `M/D/YY [at] h:mm A`
                                })}`}
                            </Typography>
                        </Grid>
                        {selectedPage && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    padding: pxToRem(16),
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    flex: 1,
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button
                                    startIcon={<Edit />}
                                    buttonVariant={ButtonVariation.CONTAINED}
                                    onClick={handlePageLinkClick}
                                    buttonSize={ButtonSize.SMALL}
                                    sx={{
                                        height: pxToRem(40),
                                        width: pxToRem(100)
                                    }}
                                >
                                    {cmpTexts.propertyDetails.edit}
                                </Button>

                                <PreviewButton
                                    pageId={selectedPage.id}
                                    propertyId={propertyId}
                                    platform={platform}
                                    label={cmpTexts.preview.preview}
                                />
                            </Box>
                        )}
                    </>
                );
            case TileTypes.TV_GUIDE_PAGE:
                return (
                    <>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    paddingTop: pxToRem(28),
                                    paddingLeft: pxToRem(28)
                                }}
                            >
                                {autoScrollIndex &&
                                    pageField?.children?.[autoScrollIndex] &&
                                    CheckBoxWithLabel(
                                        pageField?.children?.[autoScrollIndex],
                                        true
                                    )}
                            </Box>
                        </Grid>
                        <Box>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    display: 'flex',
                                    width: '100%',
                                    height: '36%',
                                    background: '#e0e0e0',
                                    paddingLeft: pxToRem(46),
                                    alignItems: 'center'
                                }}
                            >
                                {miniGuideIndex &&
                                    pageField?.children?.[miniGuideIndex] &&
                                    CheckBoxWithLabel(
                                        pageField?.children?.[miniGuideIndex],
                                        false
                                    )}
                            </Box>
                        </Box>
                    </>
                );
            case TileTypes.PAGE:
            default:
                return (
                    <>
                        <Grid item xs={12}>
                            <Typography variant={'body1'} fontSize={18}>
                                {pageField.description}
                            </Typography>
                        </Grid>
                    </>
                );
        }
    };

    useEffect(() => {
        setSelectedPage(
            pageList?.find(
                (p: ElementListResponse) => p.id === pageElementConfig?.value
            )
        );
    }, [pageList, pageElementConfig]);

    const handlePageLinkClick = () => {
        if (!selectedPage) return;

        navigate(`${location.pathname}/elements/${selectedPage.id}/canvas`);
    };

    const handlePageChange = (e: SelectChangeEvent) => {
        if (pageElementFiledIndex !== -1)
            onChildFieldUpdate(pageElementFiledIndex, e.target.value);
    };

    const handlePageVisibilityChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (pageVisibilityFieldIndex === -1) return;

        if (!e.target.checked && tileType === TileTypes.TV_GUIDE_PAGE) {
            const keyIndex = pageField?.children?.findIndex(
                (p: PropertyField) => p.key === 'enableAutoScroll'
            );
            if (keyIndex !== undefined && keyIndex > -1)
                onChildFieldUpdate(keyIndex, false);
        }
        onChildFieldUpdate(pageVisibilityFieldIndex, e.target.checked);
    };

    const handleCheckbox = (key: string) => (value: boolean) => {
        const keyIndex = pageField.children?.findIndex(
            (p: PropertyField) => p.key === key
        );

        if (keyIndex !== undefined || keyIndex === -1)
            onChildFieldUpdate(keyIndex, value);
    };

    const isPageSelectionDisabled =
        pageVisibilityConfig != null && !pageVisibilityConfig.value;

    const pageFilter =
        pageField?.children?.find((child) => child.key === 'pageType')?.value ??
        false;

    const filteredPageList = pageList.filter(
        (page) => page.widget_type_id === pageFilter
    );

    const renderPageSelectDropdown =
        (pageField?.children?.findIndex(
            (child) => child.key === 'pageElement'
        ) ?? -1) > -1;

    const canChangePage = pageField.access_details?.PageSelection;

    const getNameShortForm = (name: string) => {
        let shortForm = '';
        name.trim()
            .split(' ')
            .filter(Boolean)
            .slice(0, 2)
            .forEach((i) => (shortForm += i[0]));

        return shortForm.toUpperCase();
    };

    const renderPageVisibilityToggle = (
        pageVisibilityConfig: PropertyField
    ): React.ReactElement => {
        const [canEdit, message] = evaluateEditabilityCondition(
            pageVisibilityConfig,
            siblingPages
        );

        return (
            <DisableableBox disabled={!canEdit} disableReason={message}>
                <LiveToggle
                    handleChange={handlePageVisibilityChange}
                    checked={Boolean(pageVisibilityConfig.value)}
                    label={pageVisibilityConfig.value ? 'LIVE' : 'OFF'}
                />
            </DisableableBox>
        );
    };

    if (!tileType) return <></>;

    return (
        <Card
            sx={{
                margin: pxToRem(12),
                padding: `${pxToRem(16)} ${pxToRem(20)}`,
                background: '#eee',
                height: pxToRem(230),
                borderRadius: pxToRem(14),
                position: 'relative',
                boxShadow: 'none',
                width: '100%'
            }}
        >
            <Grid container spacing={2}>
                <Box
                    sx={{
                        display: 'flex',
                        padding: pxToRem(16),
                        paddingRight: 0,
                        paddingBottom: 0,
                        flex: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flex: '1',
                            alignItems: 'center'
                        }}
                    >
                        <PropertyFieldSubSectionTitle>
                            {pageField.name}
                        </PropertyFieldSubSectionTitle>
                        {showWarningIcon && (
                            <WarningIcon
                                sx={{ fill: '#EAB700', marginLeft: pxToRem(6) }}
                                fontSize={'small'}
                            />
                        )}
                    </Box>
                    {pageVisibilityConfig != null &&
                        renderPageVisibilityToggle(pageVisibilityConfig)}
                </Box>
                {getPlatformPageCard(tileType)}
            </Grid>
        </Card>
    );
};

export default PlatformPageCard;
