import { Visibility, VisibilityOff } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Grid, InputAdornment, Paper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariation } from '../../components/button';
import CMPBackground from '../../components/cmpBackground';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import TextField from '../../components/textField';
import useQuery from '../../hooks/useQuery';
import CMPTexts from '../../locale/en';
import {
    forgotPasswordSubmit,
    setForgotPasswordSubmitStatus,
    setLoginStatus,
    userLogout
} from '../../redux/login/action';
import {
    ForgotPasswordSubmitStatus,
    LoginStatus
} from '../../redux/login/model';
import {
    selectError,
    selectForgotPasswordSubmitStatus
} from '../../redux/login/reducer';
import { changePasswordValidationSchema } from '../../utils/loginUtils';

const LoginContainer = styled('div')(() => ({
    backgroundColor: 'rgb(23,23,37)',
    height: '100%',
    width: '100%'
}));

const CustomPaper = styled(Paper)(() => ({
    width: '30vw',
    height: 'auto',
    marginTop: '8vh'
}));

const BackToLogin = styled(Button)(() => ({
    color: 'black',
    textAlign: 'left',
    textTransform: 'none',
    fontSize: 12,
    justifyItems: 'flex-start',
    marginTop: 10
}));

const PaperTitleBox = styled(Box)(() => ({
    textAlign: 'left',
    fontSize: 28
}));

const CustomTextField = styled(TextField)(() => ({
    marginTop: 20,
    width: '25vw'
}));

const CustomButton = styled(Button)(() => ({
    marginTop: 25,
    backgroundColor: 'rgb(255,163,0)',
    width: '35%',
    fontSize: 14,
    marginBottom: 30,
    p: 1,
    color: 'rgb(245,245,246)'
}));

const PasswordConditionBox = styled(Box)(() => ({
    marginTop: 10,
    textAlign: 'left',
    fontSize: 12
}));

const DoneIconBox = styled(Box)(() => ({
    textAlign: 'left',
    fontSize: 11
}));

const DoneIconComponent = styled(DoneIcon)(() => ({
    fontSize: 12,
    verticalAlign: 'middle'
}));

const ChangePassword = () => {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const query = useQuery();
    const email: string | null = query.get('email');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const forgotPasswordStatus = useSelector(selectForgotPasswordSubmitStatus);
    const changePasswordError = useSelector(selectError);

    useEffect(() => {
        dispatch(userLogout());
        dispatch(setLoginStatus(LoginStatus.NONE));
    }, []);

    const loginRedirect = () => {
        setTimeout(() => {
            navigate('/');
        }, 1500);
    };

    useEffect(() => {
        if (
            forgotPasswordStatus ===
            ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_DONE
        ) {
            formik.handleReset('e');
            showSnackbar(CMPTexts.changePassword.passwordResetSuccess);
            dispatch(setForgotPasswordSubmitStatus());
            console.log('Reset Password Successfully');
            loginRedirect();
        } else if (
            forgotPasswordStatus ===
            ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_FAILED
        ) {
            formik.handleReset('e');
            showSnackbar(changePasswordError, 'error');
            dispatch(setForgotPasswordSubmitStatus());
            console.error('Could not reset Password - ', changePasswordError);
        }
    }, [forgotPasswordStatus]);

    const formik = useFormik({
        initialValues: {
            email: email ?? '',
            securityCode: '',
            password: '',
            repeatPassword: ''
        },
        validationSchema: changePasswordValidationSchema,
        onReset: () => {},
        onSubmit: () => {}
    });

    const handleClick = () => {
        console.log('Redirecting to Login...');
        navigate('/'); //to redirect to login page
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (Object.keys(formik.errors).length === 0) {
            dispatch(
                forgotPasswordSubmit(
                    formik.values.email,
                    formik.values.securityCode,
                    formik.values.password
                )
            );
            console.log(`Resetting Password for ${formik.values.email}...`);
        } else {
            showSnackbar(CMPTexts.changePassword.passwordResetError, 'error');
            console.log('Form is not filled correctly');
        }
    };

    return (
        <LoginContainer>
            {forgotPasswordStatus ===
                ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_INITIATED && (
                <FullScreenCircularLoader />
            )}
            <CMPBackground>
                <CustomPaper elevation={3}>
                    <form onSubmit={handleSubmit}>
                        <Grid>
                            <Grid item xs={5} container justifyContent="center">
                                <BackToLogin
                                    buttonVariant={ButtonVariation.TEXT}
                                    onClick={handleClick}
                                >
                                    <KeyboardBackspaceIcon fontSize="small" />
                                    {CMPTexts.changePassword.backToLogin}
                                </BackToLogin>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <PaperTitleBox>
                                    {CMPTexts.changePassword.paperTitle}
                                </PaperTitleBox>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <CustomTextField
                                    disabled={Boolean(email)}
                                    size="small"
                                    id="outlined-basic"
                                    label={
                                        CMPTexts.changePassword.textFieldEmail
                                    }
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('email')}
                                    error={Boolean(formik.errors.email)}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <CustomTextField
                                    size="small"
                                    id="outlined-basic"
                                    label={
                                        CMPTexts.changePassword
                                            .textFieldSecurityCode
                                    }
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('securityCode')}
                                    error={Boolean(formik.errors.securityCode)}
                                    helperText={formik.errors.securityCode}
                                />
                            </Grid>

                            <Grid container justifyContent="flex-start">
                                <Grid item xs={1}></Grid>
                                <Grid item xs={9}>
                                    <CustomTextField
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        size="small"
                                        id="outlined-basic"
                                        label={
                                            CMPTexts.changePassword
                                                .textFieldNewPassword
                                        }
                                        variant="outlined"
                                        fullWidth
                                        {...formik.getFieldProps('password')}
                                        error={Boolean(formik.errors.password)}
                                        helperText={formik.errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowPassword(
                                                                !showPassword
                                                            )
                                                        }
                                                    >
                                                        {!showPassword ? (
                                                            <Visibility fontSize="small" />
                                                        ) : (
                                                            <VisibilityOff fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={1}></Grid>
                                <Grid item xs={9}>
                                    <CustomTextField
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        size="small"
                                        id="outlined-basic"
                                        label={
                                            CMPTexts.changePassword
                                                .textFieldConfirmPassword
                                        }
                                        variant="outlined"
                                        fullWidth
                                        {...formik.getFieldProps(
                                            'repeatPassword'
                                        )}
                                        error={Boolean(
                                            formik.errors.repeatPassword
                                        )}
                                        helperText={
                                            formik.errors.repeatPassword
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setShowRepeatPassword(
                                                                !showRepeatPassword
                                                            )
                                                        }
                                                    >
                                                        {!showRepeatPassword ? (
                                                            <Visibility fontSize="small" />
                                                        ) : (
                                                            <VisibilityOff fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <PasswordConditionBox>
                                    {
                                        CMPTexts.changePassword
                                            .passwordConditionsTitle
                                    }
                                </PasswordConditionBox>
                                <DoneIconBox>
                                    <DoneIconComponent />
                                    {
                                        CMPTexts.changePassword
                                            .passwordConditions[0]
                                    }
                                </DoneIconBox>
                                <DoneIconBox>
                                    <DoneIconComponent />
                                    {
                                        CMPTexts.changePassword
                                            .passwordConditions[1]
                                    }
                                </DoneIconBox>
                                <DoneIconBox>
                                    <DoneIconComponent />
                                    {
                                        CMPTexts.changePassword
                                            .passwordConditions[2]
                                    }
                                </DoneIconBox>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent="center"
                            >
                                <CustomButton
                                    type="submit"
                                    buttonVariant={ButtonVariation.CONTAINED}
                                    disabled={!(formik.isValid && formik.dirty)}
                                >
                                    {CMPTexts.changePassword.button}
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </form>
                </CustomPaper>
            </CMPBackground>
        </LoginContainer>
    );
};

export default ChangePassword;
