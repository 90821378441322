import React from 'react';
import { Box, Typography } from '@mui/material';
import { pxToRem } from '../../../../utils/stylesUtils';

interface PropertySectionHorizontalOptionSelectorProps {
    value: any;
    options: any[];
    description?: string;
    onSelectOption?: (value: any) => void;
}

const PropertySectionHorizontalOptionSelector = (
    props: PropertySectionHorizontalOptionSelectorProps
): React.ReactElement => {
    const { value, options, onSelectOption, description } = props;

    return (
        <>
            {description && (
                <Typography
                    sx={{
                        color: '#000010',
                        fontWeight: 500,
                        fontSize: pxToRem(18),
                        lineHeight: pxToRem(24),
                        marginBottom: pxToRem(12)
                    }}
                >
                    {description}
                </Typography>
            )}
            <Box
                sx={{
                    display: 'flex',
                    border: '1px solid #525261',
                    borderRadius: pxToRem(4),
                    width: 'fit-content',
                    overflow: 'hidden'
                }}
            >
                {options.map((o, i) => {
                    return (
                        <Box
                            sx={{
                                height: pxToRem(32),
                                minWidth: pxToRem(72),
                                padding: `0 ${pxToRem(8)}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRight:
                                    i < options.length - 1
                                        ? '1px solid #525261'
                                        : '',
                                cursor: 'pointer',
                                background: o === value ? '#525261' : '#F5F5F6'
                            }}
                            key={o}
                            onClick={() => onSelectOption?.(o)}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    height: 'fit-content',
                                    color: o === value ? '#fff' : '#171725'
                                }}
                            >
                                {o}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};

export default PropertySectionHorizontalOptionSelector;
