import { AccessDetails, ContentField } from '../element/model';

export interface ElementLiveDetails {
    isElementLive?: boolean;
    isElementAtached?: boolean;
    error?: string;
}

export enum ElementCategory {
    PAGE = 'PAGE',
    RAIL = 'RAIL',
    WIDGET = 'WIDGET'
}

export interface Platform {
    name: string;
    pageElementId: string;
    isPageAttachedToPlatform: boolean;
    canAssignPage: boolean;
}

export interface ZoneData {
    id: string;
    name: string;
    platform: Platform[];
}

export interface ZoneAttachment {
    default: ZoneData;
    standard: ZoneData[] | null;
    event: ZoneData[] | null;
}
export enum AttachmentTypes {
    ELEMENT_TO_DEVICE = 'element_to_device',
    ELEMENT_TO_ELEMENT = 'element_to_element'
}

export interface ElementToElementAttachment {
    type: AttachmentTypes.ELEMENT_TO_ELEMENT;
    element_to_element: {
        element_ids: string[];
    };
}
export interface ElementToDeviceAttachment {
    type: AttachmentTypes.ELEMENT_TO_DEVICE;
    element_to_device: ZoneAttachment;
}

export interface ElementsData {
    id: string;
    name: string;
    description: string;
    content_fields: ContentField[];
    alias: string;
    type: ElementCategory;
    modified_date: Date;
    modified_by: string;
    status: string;
    associated_to: string;
    association_id: string;
    widget_type_id: string;
    access_details?: AccessDetails;
    widget_type_name: string;
    preview_url: string;
    icon_url: string;
    attached_to: ElementToElementAttachment | ElementToDeviceAttachment | null;
    is_live: boolean;
    can_instant_publish: boolean;
}
export interface ElementsResponse {
    element_category_name: string;
    helper_text: string;
    elements: ElementsData[];
    display_name: string;
}

export interface WidgetType {
    alias: string;
    id: string;
    name: string;
    short_description: string;
}

export interface ElementListResponse {
    id: string;
    modified_date: Date;
    modified_by: string;
    name: string;
    status: string;
    type: string;
    widget_type_id: string;
    widget_type_name: string;
}
