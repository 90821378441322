import { styled } from '@mui/material/styles';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { includes, isArray } from 'lodash';
import {
    // WidgetListContainer,
    WidgetContainerdiv,
    HeroCarouselLightbulbIconDiv
    //HeroCarouselIconUrl
} from './pageGenerator';
import { pxToRem } from '../../utils/stylesUtils';
import { HeroCarousalProps } from './elementsInterface';
import './carousal.css';

export const HeroContainer = styled('div')(() => ({
    height: 200,
    width: '100%'
}));

export const WidgetListContainer = styled('div')(() => ({
    height: 'auto',
    marginTop: 5,
    backgroundColor: 'rgba(23,23,37,0.16)',
    border: `${pxToRem(2)} solid rgba(23,23,37,0.26)`,
    borderRadius: 2,
    marginRight: 30,
    marginLeft: 30
}));

export const WidgetNamediv = styled('div')(() => ({
    flex: 19,
    marginTop: 42,
    marginLeft: 16,
    whiteSpace: 'nowrap'
}));

export const HeroCarouselLinkPageItemList = styled('div')(() => ({
    color: '#171726',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 22,
    textAlign: 'left'
}));

export const HeroCarouselItemPageList = styled('div')(() => ({
    color: '#181818',
    fontSize: 16,
    fontWeight: 400,
    width: 180,
    marginTop: 4,
    textAlign: 'left'
}));

export const HeroCarouselIconUrl = styled('img')(() => ({
    //color: 'rgba(23,23,37,0.54)',
    width: 90,
    height: 115
}));

export default function HeroCarousal(props: HeroCarousalProps) {
    const { railDataArray, widgets, itemDataWidgetTypeName, allowedTypeIds } =
        props;
    console.log('itemDataWidgetTypeName ', itemDataWidgetTypeName);

    if (!(isArray(railDataArray) && railDataArray.length > 0)) {
        return null;
    }

    return (
        <HeroContainer>
            <Carousel>
                {/* @ts-ignore */}
                {railDataArray?.map((widgetId: string, index: number) => {
                    const widget = widgets.find((w) => w.id === widgetId);
                    if (
                        widget &&
                        includes(allowedTypeIds, widget.widget_type_id)
                    ) {
                        return (
                            <WidgetListContainer key={index + ''}>
                                <WidgetContainerdiv>
                                    <HeroCarouselLightbulbIconDiv>
                                        <HeroCarouselIconUrl
                                            src={widget.icon_url}
                                        />
                                    </HeroCarouselLightbulbIconDiv>
                                    <WidgetNamediv>
                                        <HeroCarouselLinkPageItemList>
                                            {widget.name}
                                        </HeroCarouselLinkPageItemList>

                                        <HeroCarouselItemPageList>
                                            {widget.widget_type_name}
                                        </HeroCarouselItemPageList>
                                    </WidgetNamediv>
                                </WidgetContainerdiv>
                            </WidgetListContainer>
                        );
                    }
                    return null;
                })}
            </Carousel>
        </HeroContainer>
    );
}
