import { LoadingStatus } from '../common/state';
import { Room, ZonesFieldValues } from './model';

export interface ZonesState {
    zones: ZonesFieldValues;
    rooms: Room[];
    message: string;
    zoneOperationLoader: LoadingStatus;
    loader: LoadingStatus;
}

export const initialZonesState: ZonesState = {
    zones: {
        //@ts-ignore
        default: {},
        standard: [],
        event: []
    },
    rooms: [],
    message: '',
    loader: LoadingStatus.NONE,
    zoneOperationLoader: LoadingStatus.NONE
};
