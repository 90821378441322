import { LoadingStatus } from '../common/state';
import { PropertyField } from '../propertyValue/model';

export type Evolve2State = {
    fields: PropertyField[];
    loader: LoadingStatus;
    operationLoader: LoadingStatus;
    message: string;
};

export const initialEvolveStateValue: Evolve2State = {
    fields: [],
    loader: LoadingStatus.NONE,
    operationLoader: LoadingStatus.NONE,
    message: ''
};
