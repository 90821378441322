import {
    BreadCrumb,
    ForgotPasswordStatus,
    ForgotPasswordSubmitStatus,
    LoginResponse,
    LoginType
} from './model';

export type LoginState = {
    loadingStatus: boolean;
    status: LoginType;
    isAuthenticated: boolean;
    forgotPasswordStatus: ForgotPasswordStatus;
    forgotPasswordSubmitStatus: ForgotPasswordSubmitStatus;
    error: string;
    details: LoginResponse;
    breadCrumb: BreadCrumb[];
    newCreatedElement: { id: string; type: string };
};

export const initialLoginState: LoginState = {
    loadingStatus: false,
    status: 'NOT_INITIATED',
    isAuthenticated: false,
    forgotPasswordStatus: ForgotPasswordStatus.FORGOT_PASSWORD_NOT_INITIATED,
    forgotPasswordSubmitStatus:
        ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_NOT_INITIATED,
    error: '',
    details: {
        attributes: {
            'custom:role': '',
            email: '',
            email_verified: false,
            name: '',
            sub: ''
        },
        signInUserSession: {
            accessToken: {
                jwtToken: '',
                payload: {}
            },
            idToken: {
                jwtToken: '',
                payload: {}
            },
            refreshToken: {
                jwtToken: '',
                payload: {}
            }
        },
        userDataKey: '',
        username: '',
        challengeName: ''
    },
    breadCrumb: [],
    newCreatedElement: { id: '', type: '' }
};
