import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { AddCommentPayload, FetchActivityLogs } from './actionPayload';

export function fetchActivityLogsHelper(body: FetchActivityLogs) {
    return axios.post(API_URL.fetchActivityLogs, body);
}

export function addCommentHelper(body: AddCommentPayload) {
    return axios.post(API_URL.addComment, body);
}

export function fetchActivityLogFiltersHelper() {
    return axios.get(API_URL.getActivityLogFilters);
}
