import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionPayload } from '../types';
import { ApiResponse } from './../../api/apiInterfaces';
import { GET_OPERATIONS, userOperationsResponse } from './action';
import { OperationPayload } from './actionPayload';
import { fetchUserOperations } from './helper';
import { OperationRes } from './model';

function* userOperationWorker(data: ActionPayload<OperationPayload>) {
    try {
        const { role } = data.payload;
        const userOperationRes: ApiResponse<OperationRes> = yield call(
            fetchUserOperations,
            role
        );
        yield put(
            userOperationsResponse(
                false,
                userOperationRes.response_body.modules
            )
        );
    } catch (error) {
        yield put(userOperationsResponse(true));
    }
}

// export all worker Sagas
export function* watchUserOperations() {
    yield all([takeLatest(GET_OPERATIONS, userOperationWorker)]);
}
