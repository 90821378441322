import { initialPropertyGroupState } from './propertylist/state';
import { initialPropertyValueState } from './propertyValue/state';
import { initialLoginState } from './login/state';
import { initialUserOperationState } from './userOperations/state';
import { initialElementsListState } from './elementsList/state';
import { initialElementState } from './element/state';
import { coreRootState } from './store.types';
import { initialOrganizationState } from './organizations/state';
import { initialPreviewState } from './preview/state';
import { initialAssetsState } from './assets/state';
import { initialUsersState } from './users/state';
import { initialSectionValue } from './generalSection/state';
import { initialMaintenanceState } from './maintenance/state';
import { initialEvolveStateValue } from './evolve2/state';
import { initialUserActivityState } from './activityMonitoring/state';
import { initialZonesState } from './zones/state';

export const initialCoreRootState: coreRootState = {
    propertyList: initialPropertyGroupState,
    propertyFieldValue: initialPropertyValueState,
    login: initialLoginState,
    userOperation: initialUserOperationState,
    elementsList: initialElementsListState,
    element: initialElementState,
    evolve2: initialEvolveStateValue,
    organizations: initialOrganizationState,
    preview: initialPreviewState,
    assets: initialAssetsState,
    users: initialUsersState,
    generalSection: initialSectionValue,
    maintenance: initialMaintenanceState,
    userActivity: initialUserActivityState,
    zones: initialZonesState
};
