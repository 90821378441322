import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import Tooltip from '../../../../components/tooltip';
import { pxToRem } from '../../../../utils/stylesUtils';

interface Props {
    tooltipMessage: string | undefined;
    label: string;
    tooltipPresent: boolean;
}

const PropertySectionToolTips = (props: Props) => {
    const { label, tooltipPresent, tooltipMessage } = props;
    return (
        <>
            {label}

            {tooltipPresent && (
                <Tooltip message={tooltipMessage ?? ''}>
                    <HelpIcon
                        sx={{
                            cursor: 'pointer',
                            marginLeft: pxToRem(10),
                            pointerEvents: 'auto'
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Tooltip>
            )}
        </>
    );
};

export default PropertySectionToolTips;
