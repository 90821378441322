import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    GET_FIELD_CONFIG_VALUES,
    GET_FIELD_CONFIG_VALUES_RESPONSE,
    RESET_PROPERTY_VALUE_OPERATION_LOADER,
    RESET_PROPERTY_VALUE_LOADER,
    RESET_GET_FIELD_CONFIG_VALUES_RESPONSE,
    UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE,
    UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE_RESPONSE,
    SAVE_QUEUED_ACTION,
    REMOVE_QUEUED_ACTIONS,
    HIDE_GENERIC_COMMENT_MODAL,
    SHOW_GENERIC_COMMENT_MODAL,
    UPDATE_COMMENTS
} from './action';
import { initialPropertyValueState, PropertyValue } from './state';

import {
    emptyPropertyFieldValues,
    PropertyField,
    PropertyFieldValues,
    UpdatePropertyFieldValuesBody
} from './model';
import { orderBy } from 'lodash';

const reducer = (
    state: PropertyValue = initialPropertyValueState,
    action: any
): PropertyValue => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: PropertyValue = initialPropertyValueState,
    action: Action
): PropertyValue => {
    switch (action.type) {
        case RESET_GET_FIELD_CONFIG_VALUES_RESPONSE:
            return {
                ...state,
                fieldConfigValues: emptyPropertyFieldValues,
                propertyValueOperationLoader: LoadingStatus.NONE
            };

        case RESET_PROPERTY_VALUE_LOADER:
            return {
                ...state,
                propertyValueLoader: LoadingStatus.NONE
            };

        case RESET_PROPERTY_VALUE_OPERATION_LOADER:
            return {
                ...state,
                propertyValueOperationLoader: LoadingStatus.NONE
            };

        case SHOW_GENERIC_COMMENT_MODAL:
            return {
                ...state,
                showGenericCommentModal: true,
                comment: ''
            };

        case HIDE_GENERIC_COMMENT_MODAL:
            return {
                ...state,
                showGenericCommentModal: false
            };
        case REMOVE_QUEUED_ACTIONS:
            return {
                ...state,
                queuedActions: []
            };
        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state: PropertyValue = initialPropertyValueState,
    action: ActionPayload<string | UpdatePropertyFieldValuesBody>
): PropertyValue => {
    switch (action.type) {
        case GET_FIELD_CONFIG_VALUES: {
            return {
                ...state,
                propertyValueLoader: LoadingStatus.INITIATED
            };
        }
        case UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE:
            return {
                ...state,
                propertyValueOperationLoader: LoadingStatus.INITIATED
            };
        case SAVE_QUEUED_ACTION: {
            const queuedActions = [...state.queuedActions];
            return {
                ...state,
                queuedActions: [...queuedActions, action.payload]
            };
        }

        case UPDATE_COMMENTS:
            return {
                ...state,
                comment: action.payload as string
            };

        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: PropertyValue = initialPropertyValueState,
    action: ActionPayloadOrError<PropertyFieldValues | string>
): PropertyValue => {
    switch (action.type) {
        case GET_FIELD_CONFIG_VALUES_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload as string,
                    propertyValueLoader: LoadingStatus.FAILED
                };
            }

            return {
                ...state,
                fieldConfigValues: action.payload as PropertyFieldValues,
                propertyValueLoader: LoadingStatus.DONE
            };
        }
        case UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyValueOperationLoader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                propertyValueOperationLoader: LoadingStatus.DONE
            };
        }
        default:
            return state;
    }
};

export const selectPropertyField = (
    state: coreRootState
): PropertyFieldValues => {
    return state.propertyFieldValue.fieldConfigValues;
};

export const selectPropertyThemeAndAssets = (
    state: coreRootState
): PropertyField[] | undefined => {
    return orderBy(
        state.propertyFieldValue.fieldConfigValues.property.themes,
        'order'
    );
};

export const selectPropertyConfig = (state: coreRootState): PropertyField[] => {
    const propertyConfigs = orderBy(
        state.propertyFieldValue.fieldConfigValues.property.default,
        'order'
    );
    propertyConfigs.forEach((item) => {
        item.children = orderBy(item.children, 'order');
    });
    return propertyConfigs;
};

export const selectPropertyValueLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.propertyFieldValue.propertyValueLoader;
};

export const selectPropertyValueOperationLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.propertyFieldValue.propertyValueOperationLoader;
};

export const selectPropertyLogoAssetId = (state: coreRootState): string => {
    const assetField =
        state.propertyFieldValue.fieldConfigValues.property.themes?.find(
            (pf) => pf.key === 'assets'
        );
    const logoField = assetField?.children?.find((pf) => pf.key === 'logo');

    return logoField?.value?.toString() ?? '';
};

export const selectShowGenericCommentModal = (
    state: coreRootState
): boolean => {
    return state.propertyFieldValue.showGenericCommentModal;
};

export const selectQueuedActions = (state: coreRootState): any => {
    return state.propertyFieldValue.queuedActions;
};

export const selectGenericComment = (state: coreRootState): string => {
    return state.propertyFieldValue.comment;
};

export default reducer;
