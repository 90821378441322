import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { WidgetSkeleton } from '../element/model';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    CHECK_IF_ELEMENT_IS_LIVE,
    CHECK_IF_ELEMENT_IS_LIVE_RESPONSE,
    DELETE_ELEMENT,
    DELETE_ELEMENT_RESPONSE,
    DUPLICATE_ELEMENT,
    DUPLICATE_ELEMENT_RESPONSE,
    FECTH_ELEMENT_LIST_BY_TYPE,
    FECTH_ELEMENT_LIST_BY_TYPE_RESPONSE,
    FETCH_ALL_WDIGET_TYPES,
    FETCH_ALL_WDIGET_TYPES_RESPONSE,
    FETCH_ELEMENTS,
    FETCH_ELEMENTS_RESPONSE,
    PAGE_LIST_RESPONSE,
    PURGE_ELEMENT_LIVE,
    RESET_LOADING_STATUS,
    RESET_ELEMENT_OPERATION_LOADER
} from './action';
import {
    ElementOperationResponse,
    IsElementLive,
    IsElementLiveResponse,
    PropertyIdPayload
} from './actionPayload';
import {
    ElementCategory,
    ElementListResponse,
    ElementsData,
    ElementsResponse
} from './model';
import { ElementsListState, initialElementsListState } from './state';
const reducer = (
    state: ElementsListState = initialElementsListState,
    action: any
): ElementsListState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: ElementsListState = initialElementsListState,
    action: Action
): ElementsListState => {
    switch (action.type) {
        case RESET_LOADING_STATUS: {
            return {
                ...state,
                status: LoadingStatus.NONE
            };
        }
        case RESET_ELEMENT_OPERATION_LOADER: {
            return {
                ...state,
                elementOperationLoader: LoadingStatus.NONE,
                message: ''
            };
        }
        case PURGE_ELEMENT_LIVE:
            return {
                ...state,
                openDeleteElementModal: undefined,
                elementLiveDetails: {
                    isElementLive: state?.elementLiveDetails?.isElementLive,
                    error: undefined
                }
            };
        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state: ElementsListState = initialElementsListState,
    action: ActionPayload<
        PropertyIdPayload | ElementListResponse[] | IsElementLive
    >
): ElementsListState => {
    switch (action.type) {
        case DELETE_ELEMENT:
        case DUPLICATE_ELEMENT:
            return {
                ...state,
                elementOperationLoader: LoadingStatus.INITIATED
            };
        case FETCH_ELEMENTS:
        case FECTH_ELEMENT_LIST_BY_TYPE:
        case CHECK_IF_ELEMENT_IS_LIVE:
            return {
                ...state,
                status: LoadingStatus.INITIATED
            };
        case FETCH_ALL_WDIGET_TYPES:
            return {
                ...state,
                widgetTypeListLoadingStatus: LoadingStatus.INITIATED
            };
        case FECTH_ELEMENT_LIST_BY_TYPE_RESPONSE: {
            const data = action.payload as ElementListResponse[];
            const { type } = data[0];
            const { elements } = state;
            const updatedElements = elements.map((element) => {
                if (element.elements[0].type === type) {
                    return { ...element, elements: data };
                }
                return element;
            });
            return {
                ...state,
                elements: updatedElements as ElementsResponse[]
            };
        }
        case PAGE_LIST_RESPONSE:
            return {
                ...state,
                pageList: action.payload as ElementListResponse[]
            };

        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: ElementsListState = initialElementsListState,
    action: ActionPayloadOrError<
        | IsElementLiveResponse
        | ElementsResponse[]
        | WidgetSkeleton[]
        | string
        | ElementOperationResponse
    >
): ElementsListState => {
    switch (action.type) {
        case DUPLICATE_ELEMENT_RESPONSE:
        case DELETE_ELEMENT_RESPONSE: {
            const data = action.payload as ElementOperationResponse;
            if (action?.error) {
                return {
                    ...state,
                    elementOperationLoader: LoadingStatus.DONE,
                    message: data.message
                };
            }
            return {
                ...state,
                elementOperationLoader: LoadingStatus.FAILED,
                message: data.message
            };
        }
        case CHECK_IF_ELEMENT_IS_LIVE_RESPONSE: {
            if (action?.error) {
                return {
                    ...state,
                    status: LoadingStatus.DONE,
                    elementLiveDetails: {
                        error: action.error
                    }
                };
            }

            if (action.payload !== undefined) {
                const { data } = action.payload as IsElementLiveResponse;
                if (data !== undefined)
                    return {
                        ...state,
                        status: LoadingStatus.DONE,
                        openDeleteElementModal: true,
                        elementLiveDetails: {
                            isElementLive: data.is_part_of_live_page,
                            isElementAtached: data.is_attached_to_page
                        }
                    };
            }
            return state;
        }
        case FETCH_ELEMENTS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    status: LoadingStatus.FAILED,
                    error: action.error
                };
            }
            return {
                ...state,
                status: LoadingStatus.DONE,
                elements: action.payload as ElementsResponse[]
            };
        }

        case FETCH_ALL_WDIGET_TYPES_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    widgetTypeListLoadingStatus: LoadingStatus.FAILED,
                    error: action.error
                };
            }
            return {
                ...state,
                widgetTypeListLoadingStatus: LoadingStatus.DONE,
                allWidgetTypes: action.payload as WidgetSkeleton[]
            };
        }
        default:
            return state;
    }
};

export const selectElements = (state: coreRootState): ElementsResponse[] => {
    return state.elementsList.elements;
};

export const selectPages = (state: coreRootState): ElementsData[] => {
    // @ts-ignore
    return (
        state.elementsList.elements.find(
            (el) => el.element_category_name === ElementCategory.PAGE
        )?.elements ?? []
    );
};

export const selectRails = (state: coreRootState): ElementsData[] => {
    return (
        state.elementsList.elements.find(
            (el) => el.element_category_name === ElementCategory.RAIL
        )?.elements ?? []
    );
};

export const selectWidgets = (state: coreRootState): ElementsData[] => {
    return (
        state.elementsList.elements.find(
            (el) => el.element_category_name === ElementCategory.WIDGET
        )?.elements ?? []
    );
};

export const selectElementsError = (state: coreRootState): string => {
    return state.elementsList.error;
};

export const selectElementsLoadingStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.elementsList.status;
};

export const selectWidgetTypeListLoadingStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.elementsList.widgetTypeListLoadingStatus;
};

export const selectAllWidgetTypes = (state: coreRootState): WidgetSkeleton[] =>
    state.elementsList.allWidgetTypes;

export const selectPageList = (state: coreRootState): ElementListResponse[] => {
    return state.elementsList.pageList;
};

export const selectElementOperationLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.elementsList.elementOperationLoader;
};

export const selectMessage = (state: coreRootState): string => {
    return state.elementsList.message;
};
export const selectIsElementLive = (state: coreRootState): boolean => {
    return state.elementsList.elementLiveDetails?.isElementLive ?? false;
};

export const selectIsElementAttached = (state: coreRootState): boolean => {
    return state.elementsList.elementLiveDetails?.isElementAtached ?? false;
};

export const selectElementLiveError = (
    state: coreRootState
): string | undefined => {
    return state?.elementsList.elementLiveDetails?.error;
};

export const selectOpenElementDeleteModal = (
    state: coreRootState
): boolean | undefined => {
    return state.elementsList.openDeleteElementModal;
};

export default reducer;
