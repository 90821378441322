const baseURL = process.env.REACT_APP_API_BASE_URL;
const userBaseURL = process.env.REACT_APP_USER_API_BASE_URL;
const propertyBaseURL = process.env.REACT_APP_PROPERTY_API_BASE_URL;
const elementBaseURL = process.env.REACT_APP_ELEMENT_API_BASE_URL;
const previewBaseURL = process.env.REACT_APP_PREVIEW_BASE_URL;
const onStreamAPIBaseURL = process.env.REACT_APP_ONSTREAM_API_BASE_URL;

const API_URL = {
    // ==================== Need to cleanup the below endpoints and their usages ============/
    entryType: baseURL + '/widgetTypes',
    entries: baseURL + '/entriesList',
    propertyList: baseURL + '/propertyListing',
    propertyFieldValue: baseURL + '/propertyFieldValue',
    propertyAssetValue: baseURL + '/propertyAssetValues',
    slectedContentEntry: baseURL + '/PFSelectedContentEntry',
    selectEntryDropDown: baseURL + '/PFSelectEntryDropdown',
    entriesContentEntry: baseURL + '/entriesContentEntry',
    individualEntrySection: baseURL + '/individualEntrySections',
    content: baseURL + '/content',
    propertyAsset: baseURL + '/propertyAsset',
    propertyField: baseURL + '/propertyField',
    getProperties: baseURL + '/propertyDropDown',
    // ==================== Need to cleanup the Above endpoints and their usages ============/

    // Users API
    users: userBaseURL + '/users',
    createUser: userBaseURL + '/users/create',
    updateUser: userBaseURL + '/users/update',
    userOperation: userBaseURL + '/users/operations',
    deleteUser: userBaseURL + '/users',
    childUsers: userBaseURL + '/users/child',
    getOrganizations: userBaseURL + '/organization',
    addOrganization: userBaseURL + '/organization/create',
    deleteOrganization: userBaseURL + '/organizations/',
    editOrganization: userBaseURL + '/organization/update',
    getUsersUnderOrganization: userBaseURL + '/usersOrganization/',
    updateUserStatus: userBaseURL + '/users/edit/status',

    // user activity monitoring
    getActivityLogFilters: userBaseURL + '/activityLogs/getUniqueFilterValues',
    addComment: userBaseURL + '/activity/addComment',
    fetchActivityLogs: userBaseURL + '/activityLogs',

    // Property API
    associateUserToProperty: propertyBaseURL + '/propertyUsers/associate', // users page edit property Manager
    getPropertyAssociatedToUser:
        propertyBaseURL + '/properties/associatedToUsers',
    addGroup: propertyBaseURL + '/propertyGroups/create', //create property group
    editPropertyGroup: propertyBaseURL + '/propertyGroup/update',
    addProperty: propertyBaseURL + '/property/create', // create property
    assignProperty: propertyBaseURL + '/propertyUsers/associate', //add property manager to property
    registerProperty: propertyBaseURL + '/property', //add smartbox id and chassis no to property
    propertyDetails: `${propertyBaseURL}/property`, // To fetch smartbox id and chassis number to property
    fieldConfigValues: propertyBaseURL + '/property', //show property field's values
    fieldUpdate: propertyBaseURL + '/property/', //add property values in values table
    multiFieldUpdate: propertyBaseURL + '/property', //add property values in values table
    deleteProperty: propertyBaseURL + '/properties', // To delete a property
    deletePropertyGroup: propertyBaseURL + '/propertyGroups',
    createProperty: propertyBaseURL + '/properties',
    getPropertyTypes: propertyBaseURL + '/propertyTypes',
    moveProperty: propertyBaseURL + '/property/',
    duplicateProperty: propertyBaseURL + '/property/duplicate',

    // General Section
    getGeneralSection: propertyBaseURL + '/platform/generalSection',
    updateGeneralSection: propertyBaseURL + '/platform/generalSection/update',

    // Template API
    elementList: elementBaseURL + '/{{propertyId}}/elements', // To list the element for a particular property
    widgetSkeleton: elementBaseURL + '/{{propertyId}}/widget_type/',
    fetchAllWidgetTypes: elementBaseURL + '/{{propertyId}}/widget_types',
    //element service apis constants
    widgetTypesList: elementBaseURL + '/{{propertyId}}/widget_type', //get widget Types list for add element modal drop down
    getElement: elementBaseURL + '/{{propertyId}}/elements', //get element after save
    createElement: elementBaseURL + '/{{propertyId}}/v2/elements/create', //to create new element
    saveElement: elementBaseURL + '/{{propertyId}}/v2/elements/update', //to save element
    widgetDropdown: elementBaseURL + '/{{propertyId}}/elements/widgetTypeIds', // To get the list of widget to populate dropdown in element screen
    pageList: elementBaseURL + '/{{propertyId}}/elementsWidgetTypes', //fetchPageList to populate dropdown values in individual property screen
    fetchElementListByType:
        elementBaseURL + '/{{propertyId}}/elementsWidgetTypes/',
    deleteElement: elementBaseURL + '/{{propertyId}}/elements',
    elementLive: elementBaseURL + '/{{propertyId}}/elements',
    s3ElementFolderSize: elementBaseURL + '/{{propertyId}}/assets/size',
    createPreview: elementBaseURL + '/{{propertyId}}/elements/createPreview',
    deletePreview: elementBaseURL + '/{{propertyId}}/elements/preview',
    duplicateElement: elementBaseURL + '/{{propertyId}}/element/duplicate',

    // preivew
    previewUrl: previewBaseURL,
    // maintenance
    getMaintenance: userBaseURL + '/maintenance',
    updateMaintenance: userBaseURL + '/maintenance/update',
    // assets
    listAssets: elementBaseURL + '/{{propertyId}}/assets',
    uploadAsset: elementBaseURL + '/{{propertyId}}/assets/create',
    editAsset: elementBaseURL + '/{{propertyId}}/assets/update',
    //evolve2
    evolve2: propertyBaseURL + '/property',

    // onstream (channel logo, etc)
    getChannelLogo: onStreamAPIBaseURL + '/metadata/channel-logo',

    //zones
    zonesBaseUrl: propertyBaseURL + '/property/'
};

export { API_URL };
