import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from '../../../api/apiConstants';
import CMPLogo from '../../../components/cmpLogo';
import FullScreenCircularLoader from '../../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../../components/snackbar';
import cmpTexts from '../../../locale/en';
import { LoadingStatus } from '../../../redux/common/state';
import {
    createPreview,
    deletePreview,
    resetPreviewData
} from '../../../redux/preview/action';
import {
    CreatePreviewPayload,
    PageConfigDump
} from '../../../redux/preview/model';
import {
    selectPreviewId,
    selectPreviewLoader
} from '../../../redux/preview/reducer';
import { pxToRem } from '../../../utils/stylesUtils';
import { Platform } from './platforms';

interface PreviewDialogProp {
    isOpen: boolean;
    onExitClick: () => void;
    pageId?: string;
    propertyId: string;
    platform: Platform;
    pageConfigDump?: PageConfigDump;
}

const PreviewIframe = styled('iframe')(() => ({
    flexGrow: 1,
    border: 'none',
    marginTop: pxToRem(64)
}));

const PagePreviewDialog = (props: PreviewDialogProp) => {
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbar();
    const previewLoadingStatus = useSelector(selectPreviewLoader);
    const [iframeLoadState, setIFrameLoadState] = useState(
        LoadingStatus.INITIATED
    );
    const previewId = useSelector(selectPreviewId);

    useEffect(() => {
        if (props.isOpen) onOpenPreviewDialog();
        else onClosePreviewDialog();
    }, [props.isOpen]);

    useEffect(() => {
        return () => {
            dispatch(resetPreviewData());
        };
    }, []);

    useEffect(() => {
        if (previewLoadingStatus === LoadingStatus.FAILED) {
            showSnackbar(cmpTexts.preview.error, 'error');
            props.onExitClick();
        }
    }, [previewLoadingStatus]);

    const onOpenPreviewDialog = () => {
        setIFrameLoadState(LoadingStatus.INITIATED);
        const createPreviewPayload: CreatePreviewPayload = {
            propertyId: props.propertyId,
            platform: props.platform.name,
            pageId: props.pageId,
            pageConfigDump: props.pageConfigDump
        };
        dispatch(createPreview(createPreviewPayload));
    };

    const onClosePreviewDialog = () => {
        setIFrameLoadState(LoadingStatus.NONE);
        dispatch(deletePreview(previewId, props.propertyId));
    };

    return (
        <Dialog fullScreen open={props.isOpen}>
            <AppBar
                position="fixed"
                color="secondary"
                sx={{ height: pxToRem(64) }}
            >
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <CMPLogo />
                    </Box>
                    <Button
                        onClick={props.onExitClick}
                        variant="text"
                        sx={{
                            textTransform: 'none',
                            color: '#ffffff'
                        }}
                        startIcon={<ExitToAppIcon />}
                    >
                        <Typography fontWeight="bold" variant="body2">
                            {cmpTexts.preview.exit}
                        </Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            {(previewLoadingStatus === LoadingStatus.INITIATED ||
                iframeLoadState === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            {previewId && (
                <PreviewIframe
                    onLoad={() => setIFrameLoadState(LoadingStatus.DONE)}
                    src={`${API_URL.previewUrl}?previewId=${previewId}`}
                />
            )}
        </Dialog>
    );
};

export default PagePreviewDialog;
