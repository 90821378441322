import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';

export function fetchOrganizationsList() {
    return axios.get(API_URL.getOrganizations);
}

export function fetchUsersUnderOrganizations(orgId: string, role: string) {
    return axios.get(
        API_URL.getUsersUnderOrganization + `?orgId=${orgId}&userRole=${role}`
    );
}

export function addOrganization(name: string) {
    const body = { id: '', name: name };
    return axios.post(API_URL.addOrganization, body);
}

export function editOrganization(id: string, name: string) {
    const body = { id, name };
    return axios.post(API_URL.editOrganization, body);
}

export function deleteOrganization(id: string) {
    return axios.delete(`${API_URL.deleteOrganization}${id}`);
}

export function fetchPropertiesAssociatedToUsers(user_ids: string[]) {
    const body = user_ids;
    return axios.post(API_URL.getPropertyAssociatedToUser, body);
}
