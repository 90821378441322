import { includes, isEmpty } from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getOrganizationsResponse } from '../organizations/action';
import {
    fetchOrganizationsList,
    fetchPropertiesAssociatedToUsers
} from '../organizations/helper';
import { Organization, PropertyUserAssociation } from '../organizations/model';
import { ActionPayload } from '../types';
import { ApiResponse } from './../../api/apiInterfaces';
import {
    checkUserExistenceResponse,
    CHECK_USER_EXISTS,
    createUserResponse,
    CREATE_USER,
    deleteUserResponse,
    DELETE_USER,
    getAllUsersResponse,
    GET_ALL_USERS,
    updateUsersResponse,
    UPDATE_USER
} from './action';
import {
    CreateUserData,
    DeleteUserData,
    UpdateUserData
} from './actionPayload';
import {
    associatePropertiesToUser,
    checkUserExistence,
    createUser,
    deleteUser,
    fetchAllUsers,
    updateUser
} from './helper';
import { UserDetails, UserExistsData, UserRole } from './model';

/***************************************************************************************
 *
 *                              Create users saga
 ***************************************************************************************/

function* createUserWorker(data: ActionPayload<CreateUserData>) {
    try {
        const createUserRes: ApiResponse<any> = yield call(
            createUser,
            data.payload
        );

        const properties = data.payload.propertyIds;

        if (!isEmpty(properties)) {
            yield call(
                associatePropertiesToUser,
                properties,
                createUserRes.response_body.sub_id
            );
        }

        yield put(createUserResponse(false, createUserRes.message));
    } catch (error: any) {
        yield put(createUserResponse(true, error.message));
    }
}

/***************************************************************************************
 *
 *                              Edit users saga
 ***************************************************************************************/
function* getUpdateUsersWorker(data: ActionPayload<UpdateUserData>) {
    try {
        let updateUserMessage = '';
        if (data.payload.shouldUpdateName) {
            const updateUserResp: ApiResponse<any> = yield call(
                updateUser,
                data.payload
            );
            updateUserMessage = updateUserResp.message;
        }

        if (!isEmpty(data.payload.propertyIds)) {
            const associatePropMessage: ApiResponse<any> = yield call(
                associatePropertiesToUser,
                data.payload.propertyIds,
                data.payload.userId
            );
            updateUserMessage = associatePropMessage.message;
        }

        yield put(updateUsersResponse(false, updateUserMessage));
    } catch (err: any) {
        yield put(updateUsersResponse(true, err.message));
    }
}
/***************************************************************************************
 *
 *                              Delete users saga
 ***************************************************************************************/

function* deleteUserWorker(data: ActionPayload<DeleteUserData>) {
    try {
        const { role, userId } = data.payload;
        const deleteUserRes: ApiResponse<string> = yield call(
            deleteUser,
            role,
            userId
        );
        yield put(deleteUserResponse(false, deleteUserRes.message));
    } catch (error: any) {
        yield put(deleteUserResponse(true, error.message));
    }
}

/***************************************************************************************
 *
 *                              GET ALL USER WORKER
 ***************************************************************************************/

function* getAllUsersWorker() {
    try {
        const allUsersYield = call(fetchAllUsers);
        const allOrgYield = call(fetchOrganizationsList);

        const usersResp: ApiResponse<UserDetails[]> = yield allUsersYield;
        const orgResp: ApiResponse<Organization[]> = yield allOrgYield;

        yield put(
            getOrganizationsResponse(
                false,
                orgResp.message,
                orgResp.response_body
            )
        );

        const orgIdToNameMap: Map<string, string> = new Map();
        orgResp.response_body.forEach((o) => orgIdToNameMap.set(o.id, o.name));

        let users: UserDetails[] = usersResp.response_body.map((u) => {
            return {
                ...u,
                id: u.sub_id,
                name: `${u.first_name} ${u.last_name}`,
                organizationName: orgIdToNameMap.get(u.organization_id) ?? ''
            };
        });
        const propertyAssignmentRoles = [
            UserRole.Integrator,
            UserRole.PropertyManager,
            UserRole.Consultant
        ];

        const usersIdsHavingPropertyAssociations = users
            .filter((u) => includes(propertyAssignmentRoles, u.role))
            .map((u) => u.sub_id);

        const propertyListResp: ApiResponse<PropertyUserAssociation[]> =
            yield call(
                fetchPropertiesAssociatedToUsers,
                usersIdsHavingPropertyAssociations
            );

        users = users.map((user) => {
            if (includes(propertyAssignmentRoles, user.role)) {
                user.property_ids =
                    propertyListResp.response_body
                        .find((p) => p.user_id === user.sub_id)
                        ?.properties.map((p) => p.id) ?? [];
            }
            return user;
        });

        yield put(getAllUsersResponse(users, false, usersResp.message));
    } catch (err: any) {
        getAllUsersResponse([], true, err.message);
    }
}

/***************************************************************************************
 *
 *                              CHECK USER EXISTS
 ***************************************************************************************/

function* checkUserExistsWorker(data: ActionPayload<string>) {
    try {
        // TODO Will update to proper type once the PR from Arko on refactor is merged to avoid conflicts
        const userExistsResponse: ApiResponse<any> = yield call(
            checkUserExistence,
            data.payload
        );

        const userExistsData: UserExistsData = {
            userId: userExistsResponse.response_body.sub_id,
            email: data.payload,
            exists: userExistsResponse.response_body.user_exist,
            firstName: userExistsResponse.response_body.first_name,
            lastName: userExistsResponse.response_body.last_name,
            role: userExistsResponse.response_body.role
        };

        yield put(
            checkUserExistenceResponse(
                false,
                userExistsResponse.message,
                userExistsData
            )
        );
    } catch (err: any) {
        yield put(checkUserExistenceResponse(true, err.message, undefined));
    }
}

export function* watchUsersSagas() {
    yield all([
        takeLatest(GET_ALL_USERS, getAllUsersWorker),
        takeLatest(CREATE_USER, createUserWorker),
        takeEvery(DELETE_USER, deleteUserWorker),
        takeLatest(UPDATE_USER, getUpdateUsersWorker),
        takeLatest(CHECK_USER_EXISTS, checkUserExistsWorker)
    ]);
}
