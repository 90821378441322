import { UnfoldMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Box, IconButton, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../components/confirmationModal';
import { StyledDataGrid } from '../../components/dataGrid';
import DownloadAssetButton from '../../components/downloadAssetButton';
import Tooltip from '../../components/tooltip';
import cmpTexts from '../../locale/en';
import { deleteAsset, getAssetLists } from '../../redux/assets/action';
import { Asset, AssetHeaders } from '../../redux/assets/model';
import { selectAssets } from '../../redux/assets/reducer';
import { dataSizeConverter } from '../../utils/sizeConverter';
import { pxToRem } from '../../utils/stylesUtils';
import EditAssetModal from './editAssetsModal';
import ViewAssetModal from './viewAssetModal';
import VideoAssetThumbnail from './videoAssetThumbail';

const styles: { [key: string]: {} } = {
    hide: { visibility: 'hidden' },
    iconColor: { color: '#000' },
    disabled: { color: 'gray' }
};

const Table = styled('div')(() => ({
    display: 'flex',
    height: '65vh',
    width: '95%'
}));

const TableContainer = styled('div')(() => ({
    flexGrow: 1,
    height: '100%',
    '& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle': {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(19),
        fontWeight: '700'
    },
    '& .MuiDataGrid-iconButtonContainer .MuiDataGrid-sortIcon': {
        opacity: '100% !important',
        visibility: 'visible',
        color: 'black'
    },
    '& .MuiDataGrid-root  .MuiDataGrid-cell:focus,& .MuiDataGrid-root  .MuiDataGrid-columnHeader:focus-within,& .MuiDataGrid-root  .MuiDataGrid-cell:focus-within':
        {
            outline: 'none'
        }
}));

const ImageAssetThumbNail = styled('img')(() => ({
    backgroundColor: '#eeeeee',
    padding: pxToRem(8),
    width: pxToRem(100),
    height: pxToRem(55),
    borderRadius: '8%',
    border: `${pxToRem(0.5)} solid #000000`
}));

const NoRowAvailable = styled('div')(() => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(22)
}));
interface Props {
    property: string;
}

const AssetsTable = (props: Props) => {
    const dispatch = useDispatch();
    const assets = useSelector(selectAssets);
    const { property } = props;
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAssetViewModalOpen, setAssetViewModalOpen] = useState(false);
    const [isAssetAssociationsModalOpen, setIsAssetIsAssociationsModalOpen] =
        useState(false);
    const [selecedAssetData, setSelectedAssetData] = useState<
        Asset | undefined
    >(undefined);
    const [deleteModalAssetId, setDeleteModalAssetId] = useState('');

    useEffect(() => {
        if (property) {
            dispatch(getAssetLists(property));
        }
    }, [property]);

    const handleDeleteModalOpen = (assetId: string) => () => {
        setDeleteModalAssetId(assetId);
    };

    const handleCloseDeleteModal = () => setDeleteModalAssetId('');

    const deleteModalOnclick = () => {
        dispatch(deleteAsset(deleteModalAssetId, property));
        setDeleteModalAssetId('');
    };

    const assetThumbNailColumn = (
        params: GridRenderCellParams<any, Asset, any>
    ) => {
        return (
            <Box
                sx={{ display: 'flex', cursor: 'pointer' }}
                onClick={() => {
                    setSelectedAssetData(params.row);
                    setAssetViewModalOpen(true);
                }}
            >
                {params.value &&
                    params.value.length > 0 &&
                    (params.row.type === 'IMAGE' ? (
                        <ImageAssetThumbNail src={params.value} alt="Image" />
                    ) : (
                        <VideoAssetThumbnail src={`${params.row.url}#t=1`} />
                    ))}
            </Box>
        );
    };

    const assetNameColumn = (params: GridRenderCellParams<any, Asset, any>) => {
        return (
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    cursor: 'pointer',
                    color: '#0092B8',
                    textDecoration: 'underline',
                    textUnderlineOffset: pxToRem(3)
                }}
                onClick={() => {
                    setIsEditModalOpen(true);
                    setSelectedAssetData(params.row);
                }}
            >
                {params.value}
            </div>
        );
    };

    const fileExtensionColumn = (
        params: GridRenderCellParams<any, Asset, any>
    ) => {
        return (
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {params.row.type === 'IMAGE' ? (
                    <InsertPhotoIcon />
                ) : (
                    <VideocamIcon />
                )}
                <div
                    style={{
                        padding: `${pxToRem(3)} 0 0 ${pxToRem(10)}`
                    }}
                >
                    {params.value}
                </div>
            </div>
        );
    };

    const assetSizeColumn = (params: GridRenderCellParams<any, Asset, any>) => {
        const values = params?.value ? dataSizeConverter(params?.value) : '0 B';
        return (
            <div
                className="d-flex justify-content-between align-items-center"
                style={{ pointerEvents: 'none' }}
            >
                {values}
            </div>
        );
    };

    const associationsColumn = (
        params: GridRenderCellParams<any, Asset, any>
    ) => {
        return (
            <Link
                className="d-flex justify-content-between align-items-center"
                color="rgb(0, 146, 184)"
                style={
                    params?.value?.length > 0
                        ? { cursor: 'pointer' }
                        : {
                              pointerEvents: 'none',
                              textDecoration: 'none',
                              color: 'black'
                          }
                }
                onClick={() => {
                    if (params?.value?.length > 0) {
                        setIsAssetIsAssociationsModalOpen(true);
                        setSelectedAssetData(params.row);
                    }
                }}
            >
                {params?.value?.length ? params?.value?.length : 0}
            </Link>
        );
    };

    const downloadDeleteColumn = (
        params: GridRenderCellParams<any, Asset, any>
    ) => {
        return (
            <Box
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: 'pointer' }}
            >
                <DownloadAssetButton
                    assetUrl={params.row.url}
                    fileName={params.row.name}
                    extension={params.row.file_extension}
                />
                {params?.row?.associations?.length > 0 && (
                    <IconButton>
                        <DeleteIcon sx={styles.disabled} />
                    </IconButton>
                )}
                {!(params?.row?.associations?.length > 0) && (
                    <Tooltip message={cmpTexts.assets.DeleteButtonTooltip}>
                        <IconButton
                            onClick={handleDeleteModalOpen(params.row.id)}
                        >
                            <DeleteIcon sx={styles.iconColor} />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
        );
    };

    const noAssetsPresent = () => {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
                <NoRowAvailable>
                    {cmpTexts.assets.noAssetAvailable}
                </NoRowAvailable>
            </Stack>
        );
    };

    return (
        <Table>
            <TableContainer>
                <StyledDataGrid
                    rowHeight={64}
                    autoPageSize
                    rows={assets}
                    columns={[
                        {
                            // asset image/video display column
                            field: 'url',
                            headerName: '',
                            width: 140,
                            sortable: false,
                            renderCell: (params) => assetThumbNailColumn(params)
                        },
                        {
                            // asset name|edit asset modal column
                            field: AssetHeaders.NAME,
                            headerName:
                                cmpTexts.assets.assetsTable[AssetHeaders.NAME],
                            width: 140,
                            flex: 1,
                            renderCell: (params) => assetNameColumn(params)
                        },
                        {
                            // file type for the asset ie png, jpg, mp4 etc
                            field: AssetHeaders.FILE_EXTENSION,
                            headerName:
                                cmpTexts.assets.assetsTable[
                                    AssetHeaders.FILE_EXTENSION
                                ],
                            flex: 1,
                            renderCell: (params) => fileExtensionColumn(params)
                        },
                        {
                            // size of asset can be in terms of B,KB, MB,GB
                            field: AssetHeaders.SIZE,
                            headerName:
                                cmpTexts.assets.assetsTable[AssetHeaders.SIZE],
                            flex: 1,
                            width: 140,
                            renderCell: (params) => assetSizeColumn(params)
                        },
                        {
                            // for no of places assets are attached to  can also be used to click and open a modal which contains the places the assets are attatched to
                            field: AssetHeaders.ASSOCIATIONS,
                            headerName:
                                cmpTexts.assets.assetsTable[
                                    AssetHeaders.ASSOCIATIONS
                                ],
                            flex: 1,
                            sortComparator: (v1, v2) => {
                                if (
                                    v1 instanceof Array &&
                                    v2 instanceof Array
                                ) {
                                    return v1?.length - v2?.length;
                                }
                                return 0;
                            },
                            renderCell: (params) => associationsColumn(params)
                        },
                        {
                            // for download and delete assets
                            field: 'actions',
                            headerName: '',
                            sortable: false,
                            width: 140,
                            renderCell: (params) => downloadDeleteColumn(params)
                        }
                    ]}
                    components={{
                        ColumnUnsortedIcon: UnfoldMore,
                        ColumnSortedAscendingIcon: UnfoldMore,
                        ColumnSortedDescendingIcon: UnfoldMore,
                        NoRowsOverlay: () => noAssetsPresent()
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }]
                        }
                    }}
                    disableSelectionOnClick
                    disableColumnMenu
                />
                {isEditModalOpen && selecedAssetData && (
                    <EditAssetModal
                        heading={cmpTexts.assets.editAssetModal}
                        handleClose={setIsEditModalOpen}
                        asset={selecedAssetData}
                        property_id={property}
                        isEdit={true}
                    />
                )}
                {isAssetAssociationsModalOpen && selecedAssetData && (
                    <EditAssetModal
                        heading={cmpTexts.assets.associationsModal}
                        handleClose={setIsAssetIsAssociationsModalOpen}
                        asset={selecedAssetData}
                        property_id={property}
                    />
                )}

                {deleteModalAssetId && (
                    <ConfirmationModal
                        title={cmpTexts.assets.delteAssetModal.title}
                        description={
                            cmpTexts.assets.delteAssetModal.description
                        }
                        onPositiveButtonClick={deleteModalOnclick}
                        onClose={handleCloseDeleteModal}
                        positiveButtonText={
                            cmpTexts.assets.delteAssetModal.yesButtonText
                        }
                        negativeButtonText={
                            cmpTexts.assets.delteAssetModal.noButtonText
                        }
                    />
                )}

                {isAssetViewModalOpen && selecedAssetData && (
                    <ViewAssetModal
                        asset={selecedAssetData}
                        onClose={() => setAssetViewModalOpen(false)}
                    />
                )}
            </TableContainer>
        </Table>
    );
};

export default AssetsTable;
