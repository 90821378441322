import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pxToRem } from '../utils/stylesUtils';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';

const TIME_FORMAT = 'HH:mm:ss';

interface TimePickerProps {
    value: string;
    onChange: (value: string) => void;
    required?: boolean;
    ampm?: boolean;
}

const TimePicker = (props: TimePickerProps): React.ReactElement => {
    const { value, onChange, required, ampm = true } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
                components={['TimeField']}
                sx={{
                    marginTop: pxToRem(-8)
                }}
            >
                <TimeField
                    sx={{
                        fieldset: {
                            border: '1px solid #000010'
                        },
                        '&.MuiTextField-root': {
                            minWidth: 0
                        },
                        label: {
                            '&::after': {
                                content: required ? '"*"' : '""'
                            }
                        },
                        width: '100%'
                    }}
                    value={dayjs(value, TIME_FORMAT)}
                    onChange={(val) => {
                        if (val?.isValid()) onChange(val.format(TIME_FORMAT));
                    }}
                    label="Time"
                    format={ampm ? 'hh:mm A' : 'HH:mm'}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default TimePicker;
