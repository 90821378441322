import React from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps, SxProps } from '@mui/material';
import { pxToRem } from '../utils/stylesUtils';

interface IconWrapperProps extends BoxProps {
    height: string;
    width: string;
    color: string;
    backgroundColor?: string;
}

interface IconStylizerProps {
    children: React.ReactNode;
    height?: string;
    width?: string;
    color: string;
    backgroundColor?: string | undefined;
    sx?: SxProps;
}

/* FYI for new Icons or if icon is not working as intended 

    - icons  requires a few classes  so that it works as intended when we are changing its background or its color
    - To control the icon color we have the following classes used
         1.   icon-color-fill
         2.   icon-color-stroke
    - To control the icon background we have the following classes
         1. icon-background
         2. icon-background-fill
         3. icon-background-stroke
         
    - the decision to use these type of classes was come up as there are primarily 2 ways an icon color is painted  , those are ether via  fill other is stroke
    - To determine what goes where we can simply use an online tool called svg viewer (https://www.svgviewer.dev/) and 
      by inspecting we can see which element is responsible for changing which which places

    eg here we  have a logo for right chevron
        <svg  width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Arrow">
                <g mask="url(#mask0_1124_2125)">
                    <path id="arrow" d="M4.53489 1.37106L10.1641 7.00023L4.53489 12.6294L3.70365 11.7981L8.51615 7.00023L3.70365 2.20231L4.53489 1.37106Z" fill="white"/>
                </g>
            </g>
        </svg>
        to change the background we are adding icon-background to svg  and to path we are adding  icon-color-fill because path uses fill to paint 
       ( note: that only elements which have stroke or fill paints the icon)
       so here is how it will look
        <svg class="icon-background"   width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Arrow">
                <g mask="url(#mask0_1124_2125)">
                        <path class="icon-color-fill" fill="white" id="arrow" d="M4.53489 1.37106L10.1641 7.00023L4.53489 12.6294L3.70365 11.7981L8.51615 7.00023L3.70365 2.20231L4.53489 1.37106Z" />
                </g>
            </g>
        </svg>

    - another example 
        <svg class="icon-background" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <path class="icon-color-stroke"  stroke="white" d="M19 11V18C19 18.5304 18.7893 19.0391 18.4142 19.4142C18.0391 19.7893 17.5304 20 17 20H3C2.46957 20 1.96086 19.7893 1.58579 19.4142C1.21071 19.0391 1 18.5304 1 18V4C1 3.46957 1.21071 2.96086 1.58579 2.58579C1.96086 2.21071 2.46957 2 3 2H10M14 4H20M17 1V7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="icon-color-stroke"  stroke="white" d="M7 10C8.10457 10 9 9.10457 9 8C9 6.89543 8.10457 6 7 6C5.89543 6 5 6.89543 5 8C5 9.10457 5.89543 10 7 10Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path class="icon-color-stroke"  stroke="white" d="M19 13.9997L15.914 10.9137C15.5389 10.5388 15.0303 10.3281 14.5 10.3281C13.9697 10.3281 13.4611 10.5388 13.086 10.9137L4 19.9997" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    -another complex example 
     here we are using for LG logo 
    <svg class='icon-background' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle class="icon-color-fill " cx="12" cy="12" r="9" fill="white"/> 
        <path class="icon-background-fill" fill="#525261" d="M12.001 3.00208C10.8283 2.9763 9.66237 3.18997 8.5716 3.63058C7.48083 4.07118 6.48716 4.72984 5.64888 
        5.56793C4.8106 6.40601 4.14458 7.40666 3.68989 8.51116C3.23519 9.61567 3.00098 10.8018 3.00098 12C3.00098 13.1982 3.23519 14.3843 3.68989 
        15.4888C4.14458 16.5933 4.8106 17.594 5.64888 18.4321C6.48716 19.2702 7.48083 19.9288 8.5716 20.3694C9.66237 20.81 10.8283 21.0237 
        12.001 20.9979C13.1737 21.0237 14.3396 20.81 15.4304 20.3694C16.5211 19.9288 17.5148 19.2702 18.3531 18.4321C19.1914 17.594 19.8574 
        16.5933 20.3121 15.4888C20.7668 14.3843 21.001 13.1982 21.001 12C21.001 10.8018 20.7668 9.61567 20.3121 8.51116C19.8574 7.40666 19.1914 6.40601 
        18.3531 5.56793C17.5148 4.72984 16.5211 4.07118 15.4304 3.63058C14.3396 3.18997 13.1737 2.9763 12.001 3.00208ZM14.1352 
        15.2369V15.9477H11.6398V8.05234H12.3601V15.2369H14.1352ZM19.4974 11.6623V12C19.4985 12.9938 19.3044 13.9777 18.9266 
        14.8936C18.5488 15.8094 17.9949 16.6384 17.2978 17.3316C15.8794 18.7427 13.9778 19.5323 11.9979 19.5323C10.018 19.5323 8.11639 18.7427 6.69801 
        17.3316C6.00255 16.6375 5.45019 15.8081 5.07348 14.8924C4.69677 13.9767 4.50334 12.9933 4.50459 12C4.52291 9.98537 5.32257 8.06039 6.72823 6.64715C8.13388 
        5.23391 10.0308 4.44776 12.003 4.46109C12.0887 4.46109 12.2662 4.46109 12.3601 4.47151V5.17392H12.003C10.2058 5.16629 8.47859 5.88479 7.1979 7.17277C6.56592 
        7.80072 6.06357 8.55172 5.72055 9.38138C5.37753 10.211 5.2008 11.1025 5.2008 12.0031C5.2008 12.9037 5.37753 13.7952 5.72055 14.6249C6.06357 15.4545 6.56592 16.2055 
        7.1979 16.8335C8.12051 17.7603 9.28209 18.4 10.5471 18.678C11.8122 18.956 13.1285 18.8608 14.3426 18.4034C15.5567 17.9461 16.6184 17.1456 17.404 16.0952C18.1895 1
        5.0448 18.6664 13.7879 18.7791 12.4711C18.7811 12.435 18.7811 12.3988 18.7791 12.3627H14.1434V11.6686H19.4974V11.6623ZM10.2136 9.12784C10.2168 9.34535 10.1567 
        9.55893 10.0407 9.74141C9.92477 9.92388 9.7583 10.067 9.5625 10.1525C9.3667 10.2381 9.15041 10.2622 8.94116 10.2218C8.7319 10.1814 8.53915 10.0783 8.38742
         9.92566C8.23568 9.77302 8.13183 9.57772 8.08908 9.3646C8.04633 9.15149 8.06661 8.93021 8.14734 8.7289C8.22807 8.5276 8.3656 8.35538 8.54243 8.23416C8.71926 8.11294 8.92739 8.0482 
        9.14035 8.04817C9.28022 8.04707 9.41893 8.07413 9.54857 8.12779C9.6782 8.18146 9.79622 8.26068 9.89588 8.36093C9.99554 8.46119 10.0749 8.58052 10.1294
         8.71211C10.1839 8.84369 10.2125 8.98496 10.2136 9.12784Z" />
   </svg>

      
*/

const IconWrapper = styled(Box, {
    shouldForwardProp: (prop: string) =>
        !['height', 'width', 'color', 'backgroundColor'].includes(prop)
})<IconWrapperProps>((props) => ({
    '&.icon-stylizer svg': { height: props.height, width: props.width },
    '&.icon-stylizer ': {
        '& svg .icon-color-fill': {
            fill: props.color
        },
        '& svg .icon-color-stroke': {
            stroke: props.color
        },
        '.icon-background': { background: props.backgroundColor },
        '.icon-background-fill': { fill: props.backgroundColor },
        '.icon-background-stroke': { stroke: props.backgroundColor }
    }
}));

const IconStylizer = (props: IconStylizerProps) => {
    const {
        children,
        backgroundColor,
        height = pxToRem(24),
        width = pxToRem(24),
        color,
        sx
    } = props;
    return (
        <IconWrapper
            height={height}
            width={width}
            className="icon-stylizer"
            backgroundColor={backgroundColor}
            color={color}
            sx={{ ...sx }}
        >
            {children}
        </IconWrapper>
    );
};

export default IconStylizer;
