import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { CreateUserData, UpdateUserData } from './actionPayload';

export function fetchAllUsers() {
    return axios.get(API_URL.users);
}

export function checkUserExistence(emailId: string) {
    return axios.get(`${API_URL.users}/${emailId}/checkExistence`);
}

export function createUser(data: CreateUserData) {
    const body = {
        email: data.email,
        username: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        role: data.role,
        organization_id: data.organizationId
    };
    return axios.post(API_URL.createUser, body);
}

export function updateUser(data: UpdateUserData) {
    const body = {
        user_id: data.userId,
        first_name: data.firstName,
        last_name: data.lastName
    };

    return axios.post(API_URL.updateUser, body);
}

export function deleteUser(role: string, userId: string) {
    return axios.delete(API_URL.deleteUser + `/${userId}`, {
        headers: {
            role
        }
    });
}

export function associatePropertiesToUser(
    propertyIds: string[],
    userId: string
) {
    const body = {
        property_ids: propertyIds,
        user_id: userId
    };
    return axios.post(API_URL.assignProperty, body);
}
