import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { pxToRem } from '../utils/stylesUtils';

export const CloseIconComponent = styled(CloseIcon, {
    shouldForwardProp: (prop: string) =>
        !['mode', 'roundedBorder'].includes(prop)
})<{ mode?: 'dark' | 'light'; roundedBorder?: boolean }>(
    ({ mode = 'light', roundedBorder: roudedBorder = false }) => ({
        color: mode === 'light' ? '#525261' : '#fff',
        height: pxToRem(20),
        width: pxToRem(20),
        cursor: 'pointer',
        position: 'absolute',
        borderRadius: roudedBorder ? '50%' : 0,
        backgroundColor: roudedBorder
            ? mode === 'light'
                ? '#fff'
                : '#525261'
            : 'transparent',
        top: pxToRem(24),
        right: pxToRem(24)
    })
);
