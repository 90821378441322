import './css/react-calendar.css';

import DateRangePicker, { OnSelectCallbackParam } from 'react-daterange-picker';
import React from 'react';
import { DateRange, MomentRange } from 'moment-range';
import moment from 'moment';

interface calendarProps {
    selectionType: 'single' | 'range';
    maximumDate?: Date;
    minimumDate?: Date;
    value:
        | (MomentRange & typeof moment)
        | DateRange
        | moment.Moment
        | undefined;
    onSelectDate?: (date: moment.Moment) => void;
    onSelectDateRange?: (value: OnSelectCallbackParam) => void;
}

const CalendarPickerComponent = (props: calendarProps) => {
    const {
        selectionType,
        maximumDate,
        minimumDate,
        value,
        onSelectDate,
        onSelectDateRange
    } = props;

    let onSelect;
    if (onSelectDate) {
        onSelect = onSelectDate;
    } else {
        onSelect = onSelectDateRange;
    }

    return (
        //@ts-ignore
        <DateRangePicker
            singleDateRange
            selectionType={selectionType}
            maximumDate={maximumDate}
            minimumDate={minimumDate}
            value={value}
            onSelect={onSelect}
        />
    );
};

export default CalendarPickerComponent;
