import React, { ErrorInfo } from 'react';
import ErrorBoundaryFallback from './errorBoundaryFallback';

export default class ErrorBoundary extends React.Component<
    {},
    { hasError: boolean }
> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        console.log('Error Boundary - error ', error);
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log('Error Boundary - ', error, ' error Info', errorInfo);
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorBoundaryFallback />;
        }
        return this.props.children;
    }
}
