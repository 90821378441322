import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useClearCache } from 'react-clear-cache';
import ClearBin from '../../assets/svg/clearBin.svg';
import Button, { ButtonVariation } from '../../components/button';
import CMPTexts from '../../locale/en';
import { pxToRem } from '../../utils/stylesUtils';

const Container = styled('div')(() => ({
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f6f6f6',
    minHeight: '100vh'
}));

const ContainerGrid = styled(Grid)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
}));

const ImageContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    height: '100vh',
    justifyContent: 'flex-end',
    alignItems: 'center'
}));

const RightContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'flex-start',
    marginTop: '30vh',
    alignItems: 'flex-start'
}));

const TitleText = styled(Typography)(() => ({
    fontSize: '1.8rem'
}));

const DescriptionText = styled(Typography)(() => ({
    textAlign: 'start',
    fontSize: '0.85rem',
    lineHeight: `${pxToRem(17)}`,
    marginTop: '1vh',
    marginBottom: '3vh'
}));

export default function ErrorBoundaryFallback() {
    const { emptyCacheStorage } = useClearCache();

    const onClearCacheClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        emptyCacheStorage();
        localStorage.clear();
        window.location.reload();
    };
    return (
        <Container>
            <ImageContainer>
                <img src={ClearBin} alt="Error" />
            </ImageContainer>
            <RightContainer>
                <Grid>
                    <ContainerGrid item xs={10}>
                        <TitleText variant="h4" gutterBottom fontWeight="bold">
                            {CMPTexts.errorBoundaryFallback.title}
                        </TitleText>
                        <DescriptionText gutterBottom>
                            {CMPTexts.errorBoundaryFallback.description}
                        </DescriptionText>
                        <Button
                            buttonVariant={ButtonVariation.CONTAINED}
                            onClick={onClearCacheClick}
                        >
                            {CMPTexts.errorBoundaryFallback.buttonText}
                        </Button>
                    </ContainerGrid>
                </Grid>
            </RightContainer>
        </Container>
    );
}
