/* eslint-disable no-useless-escape */
export const emailRegex =
    /^(?![.-])[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

export const urlRegex =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const nameRegex = /^[a-zA-Z0-9\s]+$/;

export const colorRgex = /^#([0-9A-F]{3}){1,2}$/;

export const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2B50}\u{2B06}\u{2934}\u{2935}\u{2B07}\u{2B50}\u{3030}\u{3297}\u{3299}\u{1F004}-\u{1F0CF}\u{1F170}-\u{1F171}\u{1F17E}-\u{1F17F}\u{1F18E}\u{1F191}-\u{1F19A}\u{1F201}-\u{1F251}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F773}\u{1F780}-\u{1F7D4}\u{1F7D8}-\u{1F7E1}\u{1F7E5}-\u{1F7E6}\u{1F600}-\u{1F64F}\u{1F645}-\u{1F64F}\u{1F600}-\u{1F64F}\u{1F600}-\u{1F64F}]/gu;

export const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
