import { ActionPayloadOrError } from './types';

export enum ActionTypes {
    Action = 'Action',
    ActionPayload = 'ActionPayload',
    ActionWithPayloadOrError = 'ActionWithPayloadOrError'
}

export const getActionType = (action: ActionPayloadOrError<any>) => {
    if (
        !('payload' in action) &&
        !('error' in action) &&
        !('message' in action)
    ) {
        return ActionTypes.Action;
    } else if ('payload' in action && !('error' in action)) {
        return ActionTypes.ActionPayload;
    } else return ActionTypes.ActionWithPayloadOrError;
};
