import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { CreateAssetsRequest, DeleteAsset, EditAsset } from './actionPayload';
import { Asset } from './model';
export const GET_ASSETS = 'GET_ASSETS';
export const GET_ASSETS_RESPONSE = 'GET_ASSETS_RESPONSE';
export const LOADER_RESET = 'LOADER_RESET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSET_RESPONSE = 'DELETE_ASSET_RESPONSE';
export const EDIT_ASSET = 'EDIT_ASSET';
export const EDIT_ASSET_RESPONSE = 'EDIT_ASSET_RESPONSE';
export const ASSETS_OPERATION_LOADER_RESET = 'ASSETS_OPERATION_LOADER_RESET';
export const CREATE_ASSETS = 'CREATE_ASSETS';
export const CREATE_ASSETS_RESPONSE = 'CREATE_ASSETS_RESPONSE';
// export const RESET_CREATE_STATE = 'RESET_CREATE_STATE';

/* FOR ASSETS OF A GIVEN PROPERTY */
// Get Assets
export const getAssetLists = (data: string): ActionPayload<string> => {
    return {
        type: GET_ASSETS,
        payload: data
    };
};

export const getAssetListsResponse = (
    error: boolean,
    message: string,
    assets: Asset[]
): ActionPayloadOrError<Asset[]> => {
    return {
        type: GET_ASSETS_RESPONSE,
        payload: assets,
        message,
        error
    };
};
// reset Loader
export const resetLoader = (): Action => {
    return {
        type: LOADER_RESET
    };
};

/* FOR DELETING OF A GIVEN PROPERTY */
// Delete Asset
export const deleteAsset = (
    id: string,
    property: string
): ActionPayload<DeleteAsset> => {
    return { type: DELETE_ASSET, payload: { id, propertyId: property } };
};

// delete Asset response
export const deleteAssetResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: DELETE_ASSET_RESPONSE,
        message,
        error
    };
};

// reset loader for crud operations
export const assetOperationLoaderReset = (): Action => {
    return {
        type: ASSETS_OPERATION_LOADER_RESET
    };
};

/* FOR EDITING ASSET NAME OF A GIVEN ASSET */
// Edit Asset
export const editAsset = (
    asset_id: string,
    updated_Name: string,
    owner_id: string
): ActionPayload<EditAsset> => {
    return {
        type: EDIT_ASSET,
        payload: {
            asset_id,
            updated_Name,
            owner_id
        }
    };
};

// Edit Asset Response
export const editAssetResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: EDIT_ASSET_RESPONSE,
        message,
        error
    };
};

export const createAssets = (
    data: CreateAssetsRequest
): ActionPayload<CreateAssetsRequest> => {
    return {
        type: CREATE_ASSETS,
        payload: data
    };
};

export const createAssetsResponse = (
    error: boolean,
    message: string,
    data?: Asset[]
): ActionPayloadOrError<Asset[]> => {
    return {
        type: CREATE_ASSETS_RESPONSE,
        payload: data,
        message,
        error
    };
};
