import { orderBy } from 'lodash';
import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    CREATE_ELEMENT,
    CREATE_ELEMENT_RESPONSE,
    FETCH_ELEMENT_SKELETON,
    FETCH_ELEMENT_SKELETON_RESPONSE,
    PURGE_ELEMENT,
    RESET_CREATE_AND_SAVE_ELEMENT_RESPONSE,
    RESET_LOADING_STATUS,
    SAVE_ALL_ELEMENTS_RESPONSE,
    SAVE_ELEMENT,
    SAVE_ELEMENTS,
    SAVE_ELEMENT_RESPONSE,
    SET_ELEMENT_STATUS,
    SET_PAGE_BACKGROUND_MESSAGE
} from './action';
import { CreateAndSaveElement, FetchWidgetSkeleton } from './actionPayload';
import {
    ElementCreateUpdateRequestBody,
    ElementObject,
    WidgetObject,
    WidgetSkeleton
} from './model';
import {
    Element,
    emptyCreateAndSaveElementResponse,
    initialElementState
} from './state';

const reducer = (
    state: Element = initialElementState,
    action: any
): Element => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionWithPayloadOrErrorReducer = (
    state: Element = initialElementState,
    action: ActionPayloadOrError<
        string | ElementCreateUpdateRequestBody | WidgetSkeleton | undefined
    >
): Element => {
    switch (action.type) {
        case CREATE_ELEMENT_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload as string,
                    createElementStatus: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                createElementResponse:
                    action.payload as ElementCreateUpdateRequestBody,
                createElementStatus: LoadingStatus.DONE
            };
        }
        case SAVE_ELEMENT_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload as string,
                    saveElementStatus: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                saveElementResponse:
                    action.payload as ElementCreateUpdateRequestBody,
                saveElementStatus: LoadingStatus.DONE
            };
        }

        case SAVE_ALL_ELEMENTS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.message as string,
                    saveElementStatus: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                saveElementStatus: LoadingStatus.DONE
            };
        }

        case FETCH_ELEMENT_SKELETON_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    status: LoadingStatus.FAILED,
                    error: action.error
                };
            }
            const data = action.payload as WidgetSkeleton;
            return {
                ...state,
                status: LoadingStatus.DONE,
                data: {
                    ...data,
                    content_fields: orderBy(data.content_fields, 'order', [
                        'asc'
                    ])
                }
            };
        }

        default:
            return state;
    }
};

const actionReducer = (
    state: Element = initialElementState,
    action: Action
): Element => {
    switch (action.type) {
        case RESET_LOADING_STATUS:
            return {
                ...state,
                status: LoadingStatus.NONE
            };

        case PURGE_ELEMENT:
            return initialElementState;

        case RESET_CREATE_AND_SAVE_ELEMENT_RESPONSE:
            return {
                ...state,
                createElementResponse: emptyCreateAndSaveElementResponse,
                saveElementResponse: emptyCreateAndSaveElementResponse
            };

        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state: Element = initialElementState,
    action: ActionPayload<
        | string
        | CreateAndSaveElement
        | WidgetObject
        | ElementObject
        | FetchWidgetSkeleton
    >
): Element => {
    switch (action.type) {
        case SET_PAGE_BACKGROUND_MESSAGE:
            return {
                ...state,
                pageBackgroundMessage: action.payload as string
            };

        case SAVE_ELEMENT:
        case SAVE_ELEMENTS: {
            return {
                ...state,
                saveElementStatus: LoadingStatus.INITIATED
            };
        }

        case CREATE_ELEMENT: {
            return {
                ...state,
                createElementStatus: LoadingStatus.INITIATED
            };
        }

        case SET_ELEMENT_STATUS: {
            if (action.payload === 'createElementStatus') {
                return {
                    ...state,
                    createElementStatus: LoadingStatus.NONE
                };
            } else if (action.payload === 'saveElementStatus') {
                return {
                    ...state,
                    saveElementStatus: LoadingStatus.NONE
                };
            }
            return {
                ...state,
                status: LoadingStatus.NONE
            };
        }

        case FETCH_ELEMENT_SKELETON:
            return {
                ...state,
                status: LoadingStatus.INITIATED
            };
        default:
            return state;
    }
};

export const selectElementId = (state: coreRootState): string => {
    return state.element.createElementResponse.id;
};

export const selectElementData = (state: coreRootState): WidgetSkeleton => {
    return state.element.data;
};

export const selectElementLoader = (state: coreRootState): LoadingStatus => {
    return state.element.status;
};

export const selectElementsError = (state: coreRootState): string => {
    return state.element.error;
};

export const selectImageLoader = (state: coreRootState): LoadingStatus => {
    return state.element.imageLoader;
};

export const selectCreateElementStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.element.createElementStatus;
};

export const selectSaveElementStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.element.saveElementStatus;
};

export const selectElementName = (state: coreRootState): string => {
    return state.element.data?.name;
};

export const selectCreateElementResponse = (
    state: coreRootState
): ElementCreateUpdateRequestBody => {
    return state.element.createElementResponse;
};

export const selectSaveElementResponse = (
    state: coreRootState
): ElementCreateUpdateRequestBody => {
    return state.element.saveElementResponse;
};

export const selectElement = (state: coreRootState): Element => {
    return state.element;
};

export const selectPageBackgroundMessage = (state: coreRootState): string => {
    return state.element.pageBackgroundMessage;
};

export default reducer;
