const ApiResponseCode = {
    /**
     * OK
     */
    200: 200,
    /**
     * Created
     */
    201: 201,
    /**
     * Delete Successfully
     */
    204: 204,
    /**
     * Bad Request
     */
    400: 400,
    /**
     * Not Found
     */
    404: 404,
    /**
     * Internal Server Error
     */
    500: 500,
    /**
     * Child user doesn't exist
     */
    3001: 3001,

    /**
     * Refresh token expired
     */
    REFRESH_TOKEN_EXPIRED: 'NotAuthorizedException',

    /**
     * When user logged out
     */
    NO_CURRENT_USER: 'No current user'
};

export default ApiResponseCode;
