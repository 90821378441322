import { all, call, put, takeLatest } from 'redux-saga/effects';
import cmpTexts from '../../locale/en';
import { ActionPayload } from '../types';
import { ApiResponse } from './../../api/apiInterfaces';
import {
    addOrganizationResponse,
    ADD_ORGANIZATION,
    deleteOrganizationResponse,
    DELETE_ORGANIZATION,
    editOrganizationResponse,
    EDIT_ORGANIZATION,
    getOrganizationsResponse,
    GET_ALL_ORGANIZATION_LIST
} from './action';
import {
    AddOrganization,
    DeleteOrganization,
    EditOrganization
} from './actionPayload';
import {
    addOrganization,
    deleteOrganization,
    editOrganization,
    fetchOrganizationsList
} from './helper';
import { AddOrganizationResponse, Organization } from './model';

//========================================================================
//            add organization
//========================================================================

function* addOrganizationWorker(data: ActionPayload<AddOrganization>) {
    try {
        const res: ApiResponse<AddOrganizationResponse> = yield call(
            addOrganization,
            data.payload.name
        );

        yield put(addOrganizationResponse(false, res.message));
    } catch (err) {
        const e: any = err;
        yield put(
            addOrganizationResponse(
                true,
                e.message || cmpTexts.common.genericError
            )
        );
    }
}

//=====================================================================
//             get All Organization Workers
//=====================================================================

function* getAllOrganizationWorker() {
    try {
        const allOrganizationList: ApiResponse<Organization[]> = yield call(
            fetchOrganizationsList
        );

        yield put(
            getOrganizationsResponse(
                false,
                allOrganizationList.message,
                allOrganizationList.response_body
            )
        );
    } catch (err: any) {
        console.log('getAllOrganizationWorker', err);
        getOrganizationsResponse(true, err.message);
    }
}

function* getEditOrganizationWorker(data: ActionPayload<EditOrganization>) {
    try {
        const { id, name } = data.payload;
        const res: ApiResponse<EditOrganization> = yield call(
            editOrganization,
            id,
            name
        );
        yield put(editOrganizationResponse(false, res.message));
    } catch (err: any) {
        yield put(
            editOrganizationResponse(
                true,
                err.message || cmpTexts.common.genericError
            )
        );
    }
}

function* getDeleteOrganizationWorker(data: ActionPayload<DeleteOrganization>) {
    try {
        const { id } = data.payload;
        const res: ApiResponse<DeleteOrganization> = yield call(
            deleteOrganization,
            id
        );
        yield put(deleteOrganizationResponse(false, res.message));
    } catch (err: any) {
        yield put(
            deleteOrganizationResponse(
                true,
                err.message || cmpTexts.common.genericError
            )
        );
    }
}

export function* watchOrganizationSagas() {
    yield all([
        takeLatest(ADD_ORGANIZATION, addOrganizationWorker),
        takeLatest(GET_ALL_ORGANIZATION_LIST, getAllOrganizationWorker),
        takeLatest(EDIT_ORGANIZATION, getEditOrganizationWorker),
        takeLatest(DELETE_ORGANIZATION, getDeleteOrganizationWorker)
    ]);
}
