import React from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import { Grid, Paper } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import TextField from '../../components/textField';
import Button, { ButtonVariation } from '../../components/button';
import { registerProperty } from '../../redux/propertylist/action';
import { SmartBoxDetailsProps } from './propertiesInterface';
import { SmartBoxFormValidationSchema } from '../../utils/propertiesUtil';
import cmpTexts from '../../locale/en';

const SmartBoxDetailsForm = (props: SmartBoxDetailsProps) => {
    const { propertyId } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            swatchId: ''
        },
        validationSchema: SmartBoxFormValidationSchema,
        onReset: () => {},
        onSubmit: () => {}
    });

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        dispatch(registerProperty('', formik.values.swatchId, propertyId));
    }

    return (
        <form onSubmit={handleSubmit}>
            <Paper elevation={3}>
                <BoxContainer>
                    <StyledErrorOutlineIcon fontSize="small" />
                    {cmpTexts.smartBoxDetailsFormText.alertMessage}
                </BoxContainer>
                <GridSection container sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={4}>
                        <TextField
                            size="small"
                            required
                            id="outlined-basic"
                            name="swatchId"
                            maxLength={25}
                            label={
                                cmpTexts.smartBoxDetailsFormText.textField[0]
                            }
                            variant="outlined"
                            fullWidth
                            value={formik.values.swatchId}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.swatchId)}
                            helperText={
                                formik.errors.swatchId
                                    ? formik.errors.swatchId
                                    : ' '
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={3}
                        sx={{ display: 'flex', justifyContent: 'end' }}
                    >
                        <Button
                            type="submit"
                            buttonVariant={ButtonVariation.CONTAINED}
                            disabled={!(formik.isValid && formik.dirty)}
                        >
                            {cmpTexts.smartBoxDetailsFormText.button}
                        </Button>
                    </Grid>
                </GridSection>
            </Paper>
        </form>
    );
};

export default SmartBoxDetailsForm;

const BoxContainer = styled(Box)(() => ({
    fontSize: '0.96rem',
    backgroundColor: '#CE4C00',
    color: '#F5F5F6',
    padding: 10,
    textAlign: 'left',
    display: 'flex',
    flex: 1,
    alignItems: 'center'
}));

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)(() => ({
    verticalAlign: 'middle',
    marginRight: '1vw'
}));

const GridSection = styled(Grid)(() => ({
    padding: 30
}));
