import { Typography } from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';

interface PropertySectionInfoTextProps {
    description: string;
    size: 'small' | 'normal';
}

const PropertySectionInfoText = (props: PropertySectionInfoTextProps) => {
    const { description, size = 'normal' } = props;
    return (
        <Typography
            sx={{
                color: '#171725',
                padding: `${pxToRem(12)} 0 0`,
                fontWeight: '500',
                fontSize: size === 'small' ? pxToRem(14) : pxToRem(18),
                lineHeight: pxToRem(20)
            }}
        >
            {description}
        </Typography>
    );
};

export default PropertySectionInfoText;
