import { store } from '../redux/store';
import { userLogout } from '../redux/login/action';
import LoginBroadcastChannel from './loginBroadcastChannel';

const LOGOUT_BROADCAST_CHANNEL = 'LOGOUT_BROADCAST_CHANNEL';

const LogoutBroadcastChannel = (() => {
    let bc: BroadcastChannel = new BroadcastChannel(LOGOUT_BROADCAST_CHANNEL);
    console.log('*** Logout Broadcast initialized');

    function init() {
        bc = new BroadcastChannel(LOGOUT_BROADCAST_CHANNEL);
        console.log('*** Logout channel init');
    }

    function logout() {
        bc.postMessage('Logout from all tabs');
    }

    bc.onmessage = (event: MessageEvent) => {
        console.log('*** logout event', event);
        store.dispatch(userLogout());
    };

    return {
        triggerLogout() {
            console.log('*** trigger Logout');
            LoginBroadcastChannel.closeLoginChannel();
            store.dispatch(userLogout());
            logout();
        },
        initLogoutChannel() {
            init();
        },
        closeLogoutChannel() {
            bc.close();
            console.log('*** Logout channel close');
        }
    };
})();

export default LogoutBroadcastChannel;
