export enum LoadingStatus {
    INITIATED = 'INITIATED',
    NONE = 'NONE',
    FAILED = 'FAILED',
    DONE = 'DONE'
}

export enum ApiResponseState {
    NONE = 'NONE',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}
