import { LoadingStatus } from '../common/state';
import { PropertyField } from '../propertyValue/model';

export type GeneralSectionState = {
    fields: PropertyField[];
    loader: LoadingStatus;
    operationLoader: LoadingStatus;
    message: string;
};

export const initialSectionValue: GeneralSectionState = {
    fields: [],
    loader: LoadingStatus.NONE,
    operationLoader: LoadingStatus.NONE,
    message: ''
};
