import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CustomButton, {
    ButtonSize,
    ButtonVariation
} from '../../components/button';
import TextField from '../../components/textField';
import cmpTexts from '../../locale/en';
import { ContentField, Rewards } from '../../redux/element/model';
import {
    getCharacterLimit,
    RewardsGeneratorSchema
} from '../../utils/configureElementUtil';
import { pxToRem, StylesDictionary } from '../../utils/stylesUtils';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';

interface UiContents {
    subHeader: string;
    primaryText: {
        placeholder: string;
    };
    buttonInputText: {
        placeholder: string;
    };
    key: string;
}

const RewardMessageInputTextField = styled(TextField)(() => ({
    color: '#AAAAAA'
}));

const RewardsButtonContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));

const RewardsButton = styled(Button)(() => ({
    color: '#050505',
    fontSize: pxToRem(16),
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: pxToRem(17),
    borderRadius: pxToRem(2.2),
    backgroundColor: 'rgba(23, 23, 37, 0.25)',
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.5)',
    padding: `${pxToRem(14)} ${pxToRem(52)}`
}));

const ModalHeader = styled(CMPDialogTitle)(() => ({
    color: '#171725',
    fontSize: pxToRem(32),
    letterSpacing: 0,
    lineHeight: pxToRem(35),
    padding: 0,
    display: 'flex',
    gap: pxToRem(22)
}));

export const StyledInputAdornment = styled(InputAdornment)(() => ({
    padding: 10,
    '&.css-1mlg37t-MuiTypography-root': {
        color: 'blue'
    }
}));

const SubHeader = styled(Typography)(() => ({
    color: '#050505',
    fontSize: pxToRem(22),
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: pxToRem(24),
    marginBottom: pxToRem(12)
}));

const Section = styled('div')(() => ({
    marginBottom: pxToRem(29)
}));

const styles: StylesDictionary = {
    checkboxLabel: {
        color: '#181818',
        fontSize: pxToRem(18),
        letterSpacing: 0,
        lineHeight: pxToRem(20)
    }
};

const emptyRewardsMessages: Rewards = {
    enabled: false,
    currentRewardMember: {
        rewardMessage: '',
        buttonText: '',
        rewardsUrl: ''
    },
    notARewardMember: {
        rewardMessage: '',
        buttonText: '',
        rewardsUrl: ''
    },
    rewardsInformationNotAvailable: {
        rewardMessage: '',
        buttonText: '',
        rewardsUrl: ''
    }
};

function RewardsGenerator(props: {
    item: ContentField;
    setIsEdited: (isEdited: boolean) => void;
    onRewardsChange: (rewardsObj: Rewards) => void;
}) {
    const [openModal, setOpenModal] = useState(false);
    const [isLocalEdited, setIsLocalEdited] = useState(false);
    const { item, setIsEdited, onRewardsChange } = props;

    const initialRewardsMessages = item.values;
    const formik = useFormik({
        initialValues: initialRewardsMessages
            ? (initialRewardsMessages as Rewards)
            : emptyRewardsMessages,
        validationSchema: RewardsGeneratorSchema,
        onSubmit: () => {}
    });

    const UIContents: UiContents[] = [
        {
            subHeader: cmpTexts.rewardsGenerator.currentRewardsMessageSubHeader,
            primaryText: {
                placeholder: cmpTexts.rewardsGenerator.currentRewardsMessage
            },
            buttonInputText: {
                placeholder: cmpTexts.rewardsGenerator.currentRewardsButtonText
            },
            key: 'currentRewardMember'
        },
        {
            subHeader: cmpTexts.rewardsGenerator.notARewardSubHeader,
            primaryText: {
                placeholder: cmpTexts.rewardsGenerator.notARewardMessage
            },
            buttonInputText: {
                placeholder: cmpTexts.rewardsGenerator.notARewardButtonText
            },
            key: 'notARewardMember'
        },
        {
            subHeader: cmpTexts.rewardsGenerator.rewardNASubHeader,
            primaryText: {
                placeholder: cmpTexts.rewardsGenerator.rewardNAMessage
            },
            buttonInputText: {
                placeholder: cmpTexts.rewardsGenerator.rewardNAButtonText
            },
            key: 'rewardsInformationNotAvailable'
        }
    ];

    useEffect(() => {
        onRewardsChange(formik.values);
    }, []);

    const onSaveClick = () => {
        onRewardsChange(formik.values);
        setIsEdited(true);
        setIsLocalEdited(false);
        setOpenModal(false);
    };
    return (
        <>
            <RewardsButtonContainer>
                <RewardsButton onClick={() => setOpenModal(true)}>
                    {cmpTexts.rewardsGenerator.rewardsButtonText}
                </RewardsButton>
            </RewardsButtonContainer>
            {openModal && (
                <CMPDialog onClose={() => setOpenModal(false)} maxWidth="md">
                    <ModalHeader>
                        {cmpTexts.rewardsGenerator.modalTitle}

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.enabled}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            setIsLocalEdited(true);
                                        }}
                                        name="enabled"
                                    />
                                }
                                label={
                                    <Typography sx={styles.checkboxLabel}>
                                        Enable
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </ModalHeader>

                    <CMPDialogContent
                        sx={{
                            '&.MuiDialogContent-root': {
                                padding: 0
                            }
                        }}
                    >
                        {UIContents.map((item: UiContents, index) => (
                            <Section key={index}>
                                <SubHeader>{item.subHeader}</SubHeader>
                                <Grid
                                    item
                                    container
                                    rowSpacing={2}
                                    spacing={2}
                                    xs={12}
                                >
                                    <Grid item xs={12}>
                                        <RewardMessageInputTextField
                                            disabled={!formik.values.enabled}
                                            id="outlined-basic"
                                            name={`${item.key}.rewardMessage`}
                                            label="Rewards Message"
                                            variant="outlined"
                                            value={
                                                formik.values[
                                                    item.key as keyof Rewards
                                                    // @ts-ignore
                                                ].rewardMessage
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                formik.handleChange(e);
                                                setIsLocalEdited(true);
                                            }}
                                            placeholder={
                                                item.primaryText.placeholder
                                            }
                                            fullWidth
                                            multiline
                                            rows={6}
                                            error={Boolean(
                                                // @ts-ignore
                                                formik?.errors?.[item.key]
                                                    ?.rewardMessage &&
                                                    formik.values.enabled
                                            )}
                                            helperText={
                                                formik.values.enabled
                                                    ? // @ts-ignore
                                                      formik?.errors?.[item.key]
                                                          ?.rewardMessage
                                                    : ''
                                            }
                                            maxLength={1000}
                                            InputProps={{
                                                endAdornment: (
                                                    <StyledInputAdornment
                                                        position="end"
                                                        sx={{
                                                            top: pxToRem(-69),
                                                            position: 'relative'
                                                        }}
                                                    >
                                                        {getCharacterLimit(
                                                            1000,
                                                            formik.values[
                                                                item.key as keyof Rewards
                                                                // @ts-ignore
                                                            ].rewardMessage
                                                        )}
                                                    </StyledInputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RewardMessageInputTextField
                                            maxLength={13}
                                            placeholder={
                                                item.buttonInputText.placeholder
                                            }
                                            name={`${item.key}.buttonText`}
                                            label="Button Text"
                                            variant="outlined"
                                            disabled={!formik.values.enabled}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                formik.handleChange(e);
                                                setIsLocalEdited(true);
                                            }}
                                            error={Boolean(
                                                // @ts-ignore
                                                formik?.errors?.[item.key]
                                                    ?.buttonText &&
                                                    formik.values.enabled
                                            )}
                                            helperText={
                                                formik.values.enabled
                                                    ? // @ts-ignore
                                                      formik?.errors?.[item.key]
                                                          ?.buttonText
                                                    : ''
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            value={
                                                formik.values[
                                                    item.key as keyof Rewards
                                                    // @ts-ignore
                                                ].buttonText
                                            }
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <StyledInputAdornment position="end">
                                                        {getCharacterLimit(
                                                            13,
                                                            formik.values[
                                                                item.key as keyof Rewards
                                                                // @ts-ignore
                                                            ].buttonText
                                                        )}
                                                    </StyledInputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RewardMessageInputTextField
                                            name={`${item.key}.rewardsUrl`}
                                            label="Rewards Url"
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                formik.handleChange(e);
                                                setIsLocalEdited(true);
                                            }}
                                            error={Boolean(
                                                // @ts-ignore
                                                formik?.errors?.[item.key]
                                                    ?.rewardsUrl &&
                                                    formik.values.enabled
                                            )}
                                            helperText={
                                                formik.values.enabled
                                                    ? // @ts-ignore
                                                      formik?.errors?.[item.key]
                                                          ?.rewardsUrl ||
                                                      cmpTexts.rewardsGenerator
                                                          .rewardsUrlHelperText
                                                    : ''
                                            }
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            disabled={!formik.values.enabled}
                                            value={
                                                formik.values[
                                                    item.key as keyof Rewards
                                                    // @ts-ignore
                                                ].rewardsUrl
                                            }
                                            fullWidth
                                            maxLength={10000}
                                        />
                                    </Grid>
                                </Grid>
                            </Section>
                        ))}
                    </CMPDialogContent>

                    <CMPDialogActions>
                        <CustomButton
                            onClick={() => setOpenModal(false)}
                            buttonVariant={ButtonVariation.OUTLINED_BLACK}
                            buttonSize={ButtonSize.LARGE}
                            sx={{ fontWeight: 600 }}
                        >
                            Back
                        </CustomButton>
                        <CustomButton
                            onClick={onSaveClick}
                            buttonVariant={ButtonVariation.CONTAINED}
                            disabled={!(isLocalEdited && formik.isValid)}
                        >
                            Save
                        </CustomButton>
                    </CMPDialogActions>
                </CMPDialog>
            )}
        </>
    );
}

export default RewardsGenerator;
