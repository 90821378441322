import { Action } from 'redux';
import { ApiResponse } from '../../api/apiInterfaces';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    ADD_PROPERTY,
    ADD_PROPERTY_GROUP,
    ADD_PROPERTY_GROUP_RESPONSE,
    ADD_PROPERTY_RESPONSE,
    DELETE_PROPERTY,
    DELETE_PROPERTY_RESPONSE,
    DELETE_PROPERTY_GROUP,
    DELETE_PROPERTY_GROUP_RESPONSE,
    EXPANDED_PROPERTY_GROUP_ID,
    GET_PROPERTY_DETAILS,
    PROPERTY_DETAILS_RESPONSE,
    PROPERTY_LIST,
    PROPERTY_LIST_RESPONSE,
    PROPERTY_TYPES,
    PROPERTY_TYPES_RESPONSE,
    PURGE_PROPERTY_DETAILS,
    PURGE_PROPERTY_LIST,
    REGISTER_PROPERTY,
    REGISTER_PROPERTY_RESPONSE,
    RESET_PROPERTY_LOADER,
    RESET_PROPERTY_OPERATIONS_LOADER,
    SEARCH_PROPERTY_GROUP_OR_PROPERTY,
    UPDATE_PROPERTY_NAME_IN_PROPERTY_LIST,
    DUPLICATE_PROPERTY,
    DUPLICATE_PROPERTY_RESPONSE,
    MOVE_PROPERTY,
    MOVE_PROPERTY_RESPONSE,
    EDIT_PROPERTY_GROUP,
    EDIT_PROPERTY_GROUP_RESPONSE,
    PURGE_REDIRECT_TO_PROPERTY_ID
} from './action';
import {
    AddGroupResponse,
    AddPropertyResponse,
    GetPropertyListResponse,
    PropertiesUnderPropertyGroupRes,
    PropertyTypesResponse
} from './actionPayload';
import {
    emptyPropertyDetails,
    PropertyDetails,
    PropertyGroupInterface,
    PropertyType
} from './model';
import { initialPropertyGroupState, PropertyList } from './state';

const reducer = (
    state: PropertyList = initialPropertyGroupState,
    action: any
): PropertyList => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: PropertyList = initialPropertyGroupState,
    action: Action
): PropertyList => {
    switch (action.type) {
        case RESET_PROPERTY_LOADER: {
            return {
                ...state,
                propertyLoader: LoadingStatus.NONE,
                message: ''
            };
        }

        case RESET_PROPERTY_OPERATIONS_LOADER: {
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.NONE,
                message: ''
            };
        }

        case PROPERTY_LIST:
        case PROPERTY_TYPES:
            return {
                ...state,
                propertyLoader: LoadingStatus.INITIATED
            };

        case PURGE_PROPERTY_DETAILS: {
            return {
                ...state,
                currentPropertyDetails: undefined
            };
        }

        case PURGE_PROPERTY_LIST: {
            return initialPropertyGroupState;
        }

        case PURGE_REDIRECT_TO_PROPERTY_ID:
            return {
                ...state,
                redirectToPropertyId: ''
            };

        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state: PropertyList = initialPropertyGroupState,
    action: ActionPayload<any>
): PropertyList => {
    switch (action.type) {
        case GET_PROPERTY_DETAILS:
            return {
                ...state,
                propertyLoader: LoadingStatus.INITIATED
            };

        case ADD_PROPERTY_GROUP:
        case DELETE_PROPERTY_GROUP:
        case EDIT_PROPERTY_GROUP:
        case ADD_PROPERTY:
        case DELETE_PROPERTY:
        case REGISTER_PROPERTY:
        case MOVE_PROPERTY:
        case DUPLICATE_PROPERTY:
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.INITIATED
            };

        case SEARCH_PROPERTY_GROUP_OR_PROPERTY: {
            const { searchKeyword } = action.payload;

            return {
                ...state,
                searchKeyword
            };
        }

        case EXPANDED_PROPERTY_GROUP_ID: {
            return {
                ...state,
                expandedPropertyGroupIds:
                    action.payload.expandedPropertyGroupIds
            };
        }

        case UPDATE_PROPERTY_NAME_IN_PROPERTY_LIST: {
            const { propertyGroupId, propertyId, propertyName } =
                action.payload;
            const propertyList = [...state.values];
            let filteredPropertyList = propertyList.filter(
                (val) => val.property_group_id === propertyGroupId
            );
            const newPropertyList = filteredPropertyList[0].properties.map(
                (val) => {
                    if (val.id === propertyId) {
                        return { ...val, name: propertyName };
                    }
                    return val;
                }
            );
            filteredPropertyList = propertyList.map((val) => {
                if (val.property_group_id === propertyGroupId) {
                    return { ...val, property: newPropertyList };
                }
                return { ...val };
            });
            return { ...state, values: filteredPropertyList };
        }

        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: PropertyList = initialPropertyGroupState,
    action: ActionPayloadOrError<
        | PropertyTypesResponse
        | PropertyDetails
        | ApiResponse<string>
        | string
        | AddGroupResponse
        | AddPropertyResponse
        | GetPropertyListResponse
    >
): PropertyList => {
    switch (action.type) {
        case DELETE_PROPERTY_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error occurred while deleting the property'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property deleted successfully'
            };
        }

        case DELETE_PROPERTY_GROUP_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error occurred while deleting the property group'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property group deleted successfully'
            };
        }

        case EDIT_PROPERTY_GROUP_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error occurred while updating the property group'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property group updated successfully'
            };
        }

        case REGISTER_PROPERTY_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error occurred while updating the property'
                };
            }

            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property updated successfully'
            };
        }

        case MOVE_PROPERTY_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error occurred while moving the property'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property moved successfully'
            };
        }

        case DUPLICATE_PROPERTY_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error occurred while duplicating the property'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                redirectToPropertyId: (
                    (action.payload as AddPropertyResponse)
                        .propertyGroupResponse as PropertiesUnderPropertyGroupRes
                ).id,
                message: action.message ?? 'Property duplicated successfully'
            };
        }

        case PROPERTY_LIST_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyLoader: LoadingStatus.DONE,
                    message:
                        action.message ??
                        'Error while getting the property list'
                };
            }

            return {
                ...state,
                propertyLoader: LoadingStatus.DONE,
                values: (action.payload as GetPropertyListResponse)
                    .propertyList as PropertyGroupInterface[]
            };
        }

        case ADD_PROPERTY_GROUP_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ?? 'Error while adding property group.'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property group added successfully.'
            };
        }

        case ADD_PROPERTY_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ?? 'Error while adding the property.'
                };
            }
            return {
                ...state,
                propertyOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Property added successfully.',
                redirectToPropertyId: (
                    (action.payload as AddPropertyResponse)
                        .propertyGroupResponse as PropertiesUnderPropertyGroupRes
                ).id
            };
        }

        case PROPERTY_DETAILS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    message: action.payload as string,
                    propertyLoader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                currentPropertyDetails: action.payload as PropertyDetails,
                propertyLoader: LoadingStatus.DONE
            };
        }

        case PROPERTY_TYPES_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    propertyLoader: LoadingStatus.FAILED,
                    message: 'Error occurred while loading the property details'
                };
            }

            return {
                ...state,
                propertyLoader: LoadingStatus.DONE,
                propertyTypes: (action.payload as PropertyTypesResponse)
                    .propertyTypes as PropertyType[]
            };
        }

        default:
            return state;
    }
};

export const selectPropertyList = (
    state: coreRootState
): PropertyGroupInterface[] => {
    return state.organizations.filteredOrganization
        ? state.propertyList.values.filter(
              (pg) => pg.org_id === state.organizations.filteredOrganization
          )
        : state.propertyList.values;
};

export const selectUnfilteredPropertyList = (
    state: coreRootState
): PropertyGroupInterface[] => {
    return state.propertyList.values;
};

export const selectPropertyMessage = (state: coreRootState): string => {
    return state.propertyList.message;
};

export const selectCurrentPropertyDetails = (
    state: coreRootState
): PropertyDetails => {
    return state.propertyList.currentPropertyDetails ?? emptyPropertyDetails;
};

export const selectPropertyLoader = (state: coreRootState): LoadingStatus => {
    return state.propertyList.propertyLoader;
};

export const selectPropertyOperationLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.propertyList.propertyOperationLoader;
};

export const selectSearchKeyWord = (state: coreRootState): string => {
    return state.propertyList.searchKeyword;
};

export const selectExpandedPropertyGroupIds = (
    state: coreRootState
): string[] => {
    return state.propertyList.expandedPropertyGroupIds;
};

export const selectPropertyTypes = (state: coreRootState): PropertyType[] => {
    return state.propertyList.propertyTypes;
};

export const selectRedirectToPropertyId = (state: coreRootState): string => {
    return state.propertyList.redirectToPropertyId;
};

export default reducer;
