import * as React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { pxToRem } from '../utils/stylesUtils';

const TimeDisplay = (props: { time: number }) => {
    const { time } = props;
    const dateAndTime = moment(time).format('MMMM D, YYYY;hh:mm A').split(';');

    return (
        <Box>
            <Typography>{dateAndTime[0]}</Typography>
            <Typography
                sx={{
                    fontSize: pxToRem(14),
                    lineHeight: pxToRem(19),
                    color: '#525261'
                }}
            >
                {dateAndTime[1]}
            </Typography>
        </Box>
    );
};

export default TimeDisplay;
