import React, { useState } from 'react';
import { isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { Box, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import CMPDialog, {
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';
import { StylesDictionary, pxToRem } from '../../utils/stylesUtils';
import {
    ElementCategory,
    Platform,
    ZoneData,
    AttachmentTypes,
    ElementToElementAttachment,
    ElementToDeviceAttachment,
    ZoneAttachment
} from '../../redux/elementsList/model';
import { GetElementHyperlink } from './elementsTable';
import { ZoneType } from '../../redux/zones/model';
import { selectElements } from '../../redux/elementsList/reducer';

interface ElementToElement {
    attachedTo: string[];
    typeOfWidgetsAttached: ElementCategory.PAGE | ElementCategory.RAIL;
    typeOfAttachment: AttachmentTypes.ELEMENT_TO_ELEMENT;
}

interface ElementToDevice {
    typeOfAttachment: AttachmentTypes.ELEMENT_TO_DEVICE;
    attachedTo: ZoneAttachment;
    selectedTab?: ZoneType;
}

type ElementsVariant = ElementToElement | ElementToDevice;

const RenderGridComponent = (props: { name: string; gridWidth: number }) => {
    const { name, gridWidth } = props;
    return (
        <Grid
            item
            md={gridWidth}
            lg={gridWidth}
            xs={gridWidth}
            sx={{ paddingBottom: pxToRem(16) }}
        >
            {name}
        </Grid>
    );
};

const RenderPlatforms = (props: {
    platforms: Platform[];
    gridWidth: number;
}) => {
    const { platforms, gridWidth } = props;
    return (
        <>
            {platforms.map(
                (p) =>
                    p.isPageAttachedToPlatform && (
                        <RenderGridComponent
                            key={p.pageElementId}
                            name={p.name}
                            gridWidth={gridWidth}
                        />
                    )
            )}
        </>
    );
};

const ElementAttachmentDetails: React.FC<ElementsVariant> = (
    props: ElementsVariant
) => {
    const elementsByCategory = useSelector(selectElements);

    if (props.typeOfAttachment === AttachmentTypes.ELEMENT_TO_ELEMENT) {
        const { attachedTo, typeOfWidgetsAttached } = props as ElementToElement;
        const elementData = elementsByCategory.find(
            (element) => element.element_category_name === typeOfWidgetsAttached
        );

        const getAttachedElement = (elementId: string) => {
            const elementAttached = elementData?.elements.find(
                (e) => e.id === elementId
            );

            if (elementAttached) {
                return (
                    <Grid container sx={{ paddingBottom: pxToRem(16) }}>
                        <Grid item md={6} lg={6} xs={6}>
                            <GetElementHyperlink element={elementAttached} />
                        </Grid>
                        <Grid item md={6} lg={6} xs={6}>
                            {elementAttached.widget_type_name}
                        </Grid>
                    </Grid>
                );
            }
        };

        return (
            <>
                <Grid container>
                    <Grid item md={6} lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom fontWeight={800}>
                            {typeOfWidgetsAttached === ElementCategory.PAGE
                                ? 'Page Name'
                                : 'Rail Name'}
                        </Typography>
                    </Grid>
                    <Grid item md={6} lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom fontWeight={800}>
                            {typeOfWidgetsAttached === ElementCategory.PAGE
                                ? 'Page Type'
                                : 'Rail Type'}
                        </Typography>
                    </Grid>
                </Grid>
                {attachedTo.map((elementId) => (
                    <React.Fragment key={elementId}>
                        {getAttachedElement(elementId)}
                    </React.Fragment>
                ))}
            </>
        );
    } else {
        const { attachedTo, selectedTab } = props as ElementToDevice;

        return (
            <>
                {selectedTab === ZoneType.Default ? (
                    <Box>
                        <Typography variant="h6" gutterBottom fontWeight={800}>
                            Device Types
                        </Typography>
                        <Grid container>
                            {attachedTo.default?.platform && (
                                <RenderPlatforms
                                    gridWidth={4}
                                    platforms={attachedTo.default?.platform}
                                />
                            )}
                        </Grid>
                    </Box>
                ) : (
                    <Box>
                        <Grid container>
                            <Grid item md={6} lg={6} xs={6}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    fontWeight={800}
                                >
                                    {selectedTab === ZoneType.Standard
                                        ? 'Zone Name'
                                        : 'Event Name'}
                                </Typography>
                            </Grid>
                            <Grid item md={6} lg={6} xs={6}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    fontWeight={800}
                                >
                                    Device Types
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            {attachedTo[
                                selectedTab === ZoneType.Event
                                    ? 'event'
                                    : 'standard'
                            ]?.map((zone: ZoneData) => (
                                <>
                                    <RenderGridComponent
                                        name={zone.name}
                                        gridWidth={6}
                                    />
                                    <Grid container md={6} lg={6} xs={6}>
                                        <RenderPlatforms
                                            platforms={zone.platform}
                                            gridWidth={6}
                                        />
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Box>
                )}
            </>
        );
    }
};

const ElementAttachmentsModal = (props: {
    handleClose: (show: any) => void;
    name: string;
    attachedTo: ElementToDeviceAttachment | ElementToElementAttachment;
    typeOfWidgetsAttached: ElementCategory;
}) => {
    const { handleClose, name, attachedTo, typeOfWidgetsAttached } = props;
    const [selectedTab, setSelectedTab] = useState(ZoneType.Default);

    const styles: StylesDictionary = {
        TabsOverlay: {
            borderBottom: 1,
            borderColor: 'divider',
            marginBottom: pxToRem(20),
            padding: pxToRem(0),
            '& .MuiTabs-flexContainer': {
                gap: '15%',
                '& .MuiButtonBase-root': {
                    fontSize: pxToRem(16),
                    fontWeight: 600,
                    lineHeight: pxToRem(16),
                    padding: `${pxToRem(12)} 0 ${pxToRem(6)}`,
                    '&.Mui-selected': {
                        color: '#171725'
                    }
                }
            },
            '& .MuiTabs-indicator': {
                height: pxToRem(3)
            },
            '& .MuiTab-root': {
                textTransform: 'capitalize',
                color: '#171725'
            }
        }
    };

    return (
        <CMPDialog
            onClose={() => handleClose(null)}
            maxWidth="sm"
            PaperProps={{
                style: {
                    background: '#F5F5F6'
                }
            }}
        >
            <CMPDialogTitle
                sx={{
                    fontSize: pxToRem(32),
                    fontWeight: 800,
                    lineHeight: pxToRem(32),
                    letterSpacing: pxToRem(-0.2)
                }}
            >
                {name} Attached To
            </CMPDialogTitle>
            {attachedTo.type === AttachmentTypes.ELEMENT_TO_DEVICE && (
                <Tabs
                    value={selectedTab}
                    sx={styles.TabsOverlay}
                    onChange={(_, val: ZoneType) => setSelectedTab(val)}
                >
                    <Tab label="Default" value={ZoneType.Default} />
                    <Tab label="Zone" value={ZoneType.Standard} />
                    <Tab label="Events" value={ZoneType.Event} />
                </Tabs>
            )}
            <CMPDialogContent
                sx={{
                    '&.MuiDialogContent-root': {
                        padding: `${pxToRem(15)}  ${pxToRem(30)}`
                    },
                    border: '0.5px solid #000010',
                    borderRadius: pxToRem(12),
                    flexGrow: 1,
                    overflowY: 'auto',
                    height: pxToRem(282),
                    flexDirection: 'column',
                    display: 'flex'
                }}
            >
                {attachedTo.type === AttachmentTypes.ELEMENT_TO_ELEMENT ? (
                    <ElementAttachmentDetails
                        attachedTo={attachedTo.element_to_element.element_ids}
                        typeOfWidgetsAttached={
                            typeOfWidgetsAttached === ElementCategory.RAIL
                                ? ElementCategory.PAGE
                                : ElementCategory.RAIL
                        }
                        typeOfAttachment={attachedTo.type}
                    />
                ) : (
                    <ElementAttachmentDetails
                        attachedTo={attachedTo.element_to_device}
                        selectedTab={selectedTab}
                        typeOfAttachment={attachedTo.type}
                    />
                )}
            </CMPDialogContent>
        </CMPDialog>
    );
};

const ElementAttachments = (props: {
    name: string;
    attachedTo: ElementToElementAttachment | ElementToDeviceAttachment;
    typeOfWidgetsAttached: ElementCategory;
}) => {
    const { name, attachedTo, typeOfWidgetsAttached } = props;
    const [openModal, setOpenModal] = useState(false);

    const getNoOfAttachments = () => {
        switch (attachedTo.type) {
            case AttachmentTypes.ELEMENT_TO_ELEMENT:
                return attachedTo.element_to_element.element_ids.length;

            case AttachmentTypes.ELEMENT_TO_DEVICE: {
                return Object.values(attachedTo.element_to_device)
                    .flatMap((obj) => (!isNil(obj) ? obj : []))
                    .flatMap((e) => e.platform)
                    .filter((e) => e.isPageAttachedToPlatform).length;
            }
        }
    };

    const noOfAttachments = getNoOfAttachments();

    return (
        <>
            <Link
                color="#0092B8"
                style={
                    noOfAttachments > 0
                        ? {
                              cursor: 'pointer'
                          }
                        : {
                              pointerEvents: 'none',
                              textDecoration: 'none',
                              color: '#000'
                          }
                }
                onClick={() => setOpenModal(true)}
            >
                {noOfAttachments}
            </Link>

            {openModal && (
                <ElementAttachmentsModal
                    handleClose={() => setOpenModal(false)}
                    name={name}
                    attachedTo={attachedTo}
                    typeOfWidgetsAttached={typeOfWidgetsAttached}
                />
            )}
        </>
    );
};
export default ElementAttachments;
