import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';

const pxToRem = (px: number) => {
    return (px / 16).toString() + 'rem';
};

export type StylesDictionary = {
    [Key: string]: SxProps;
};

export type StylesDictionaryCssProps = {
    [Key: string]: CSSProperties;
};

export { pxToRem };
