import { Box, styled, Typography } from '@mui/material';
import { FileUploadOutlined } from '@mui/icons-material';
import { includes, isEmpty } from 'lodash';
import React from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import { dataSizeConverter } from '../utils/sizeConverter';
import { pxToRem } from '../utils/stylesUtils';

export const DEFAULT_ALLOWED_IMAGE_FILE_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/tiff',
    'image/png'
];
export const DEFAULT_ALLOWED_VIDEO_FILE_TYPES = [
    'video/quicktime',
    'video/mp4',
    'video/webm',
    'video/x-matroska',
    'video/avi',
    'video/x-m4v'
];
export const DEFAULT_ALLOWED_IMAGE_AND_VIDEO_FILE_TYPES = [
    ...DEFAULT_ALLOWED_IMAGE_FILE_TYPES,
    ...DEFAULT_ALLOWED_VIDEO_FILE_TYPES
];

export const DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE = 'jpg, jpeg, tiff or png';
export const DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_VIDEO =
    'mkv, mov, mp4, avi, m4v or webm';
export const DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE_AND_VIDEO =
    'jpg, jpeg, tiff, png, mkv, mov, mp4, avi, m4v or webm';

const FileDropBox = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'isDarkMode'
})<{ isDarkMode: boolean }>(({ isDarkMode }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    '& .MuiDropzoneArea-root': {
        width: pxToRem(336),
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none'
    },
    '& .MuiDropzoneArea-active': {
        background: isDarkMode
            ? 'rgba(196, 196, 202, 1)'
            : 'rgba(246, 245, 246)'
    },
    '& .MuiDropzoneArea-icon': {
        display: 'none'
    },
    border: '1px dashed gray',
    borderRadius: pxToRem(8)
}));

const DropZoneUI = (props: {
    isDarkMode: boolean;
    infoMessage: string;
    shouldShowVideoRestrictionInfo?: boolean;
}): React.ReactElement => (
    <Box
        sx={{
            justifySelf: 'center',
            color: props.isDarkMode ? '#fff' : '#000010'
        }}
    >
        <FileUploadOutlined sx={{ width: pxToRem(40), height: pxToRem(40) }} />
        <Typography fontWeight={800} fontSize={pxToRem(20)}>
            Drop your assets or{' '}
            <Typography
                sx={{
                    display: 'inline',
                    color: '#F01446',
                    textDecoration: 'underline'
                }}
                fontWeight={800}
                fontSize={pxToRem(20)}
            >
                browse
            </Typography>
        </Typography>
        <Typography
            fontWeight={500}
            fontSize={pxToRem(16)}
            color={props.isDarkMode ? '#C4C4CA' : '#888'}
            padding={pxToRem(8)}
        >
            {props.infoMessage}
        </Typography>
        {props.shouldShowVideoRestrictionInfo && (
            <Typography
                fontSize={pxToRem(14)}
                color={props.isDarkMode ? '#C4C4CA' : '#888'}
                padding={pxToRem(8)}
            >
                {'Video max resolution and size is 4K and 200MB'}
            </Typography>
        )}
    </Box>
);

interface FileDropZoneProps {
    onFileChange: (files: File[]) => void;
    onDropRejected: (message: string) => void;
    allowedFileTypes: string[];
    fileUploadInfoMessage: string;
    maxFileSize?: number;
    isDarkMode: boolean;
    filesLimit: number;
}

const FileDropZone = (props: FileDropZoneProps): React.ReactElement => {
    const {
        onFileChange,
        onDropRejected,
        allowedFileTypes,
        fileUploadInfoMessage,
        maxFileSize = 200000000,
        isDarkMode,
        filesLimit
    } = props;

    return (
        <FileDropBox isDarkMode={isDarkMode}>
            <DropzoneArea
                filesLimit={filesLimit}
                showAlerts={false}
                showPreviewsInDropzone={false}
                showFileNames={false}
                //@ts-ignore
                Icon={null}
                //@ts-ignore
                dropzoneText={
                    <DropZoneUI
                        isDarkMode={isDarkMode}
                        infoMessage={fileUploadInfoMessage}
                        shouldShowVideoRestrictionInfo={allowedFileTypes.some(
                            (ft) => ft.startsWith('video')
                        )}
                    />
                }
                maxFileSize={maxFileSize}
                acceptedFiles={allowedFileTypes}
                onChange={onFileChange}
                onDropRejected={(files) => {
                    let message = '';
                    const unsupportedFileNames: string[] = [];
                    const sizeExceededFileNames: string[] = [];

                    files.forEach((file) => {
                        if (file.size > maxFileSize) {
                            sizeExceededFileNames.push(file.name);
                        } else if (!includes(allowedFileTypes, file.type)) {
                            unsupportedFileNames.push(file.name);
                        }
                    });

                    if (!isEmpty(unsupportedFileNames)) {
                        const isSingleFile = unsupportedFileNames.length === 1;
                        message += `${
                            isSingleFile ? 'File' : 'Files'
                        } ${unsupportedFileNames.join(', ')} ${
                            isSingleFile ? 'is' : 'are'
                        } not supported.`;
                    }

                    if (!isEmpty(sizeExceededFileNames)) {
                        const isSingleFile = sizeExceededFileNames.length === 1;
                        message += `${
                            isSingleFile ? 'File' : 'Files'
                        } ${sizeExceededFileNames.join(', ')} ${
                            isSingleFile ? 'exceeds' : 'exceed'
                        } the file size limit of ${dataSizeConverter(
                            maxFileSize
                        )}.`;
                    }

                    if (isEmpty(message)) {
                        message =
                            'The file that you have selected does not match CMP Asset requirements.';
                    }

                    onDropRejected(message);
                }}
            />
        </FileDropBox>
    );
};

export default FileDropZone;
