import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../../components/button';
import {
    AttachedRoomInfo,
    EventZone,
    StandardZone,
    ZoneType
} from '../../../../redux/zones/model';
import { pxToRem } from '../../../../utils/stylesUtils';
import { Mode, RequiredText } from '../manageZones';
import { zoneValidationSchema } from '../zoneUtils';
import ZoneRoomSelectorSection from './zoneRoomSelectorSection';
import { CreateUpdateZone } from '../../../../redux/zones/actionPayload';
import { useDispatch } from 'react-redux';
import { editZone } from '../../../../redux/zones/action';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../../../components/cmpDialog';

const ViewRoomsModal = (props: {
    setIsModalOpen: (isAddCommentModalOpen: boolean) => void;
    actionType: Mode;
    zone: StandardZone | EventZone;
}) => {
    const { setIsModalOpen, zone } = props;
    const dispatch = useDispatch();
    const [deviceIds, setDeviceIds] = useState<string[]>([]);
    const [isRoomOverlapping, setIsRoomOverlapping] = useState(false);
    const [isRoomsEdited, setIsRoomsEdited] = useState(false);

    const formik = useFormik({
        initialValues: zone,
        validationSchema: zoneValidationSchema([]),
        onSubmit: () => {}
    });

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const onSaveClick = () => {
        const createPayload: CreateUpdateZone = {
            rooms_attached: deviceIds,
            propertyId: formik.values.property_id,
            type: formik.values.type,
            visibility_type: formik.values.visibility_type,
            description: formik.values.description,
            name: formik.values.name,
            zoneId: formik.values.id
        };

        if (zone.type === ZoneType.Standard) {
            dispatch(editZone(createPayload));
        } else {
            createPayload.start_time = (formik.values as EventZone).start_time;
            createPayload.end_time = (formik.values as EventZone).end_time;
            dispatch(editZone(createPayload));
        }
    };

    return (
        <CMPDialog onClose={handleClose}>
            <CMPDialogTitle sx={{ fontSize: pxToRem(28), fontWeight: 800 }}>
                View Rooms
            </CMPDialogTitle>
            <CMPDialogContent>
                <Typography
                    sx={{
                        fontSize: pxToRem(20),
                        fontWeight: 800,
                        lineHeight: pxToRem(24),
                        letterSpacing: 0,
                        marginBottom: pxToRem(4)
                    }}
                >
                    Zone Name
                </Typography>

                <Typography
                    sx={{ fontSize: pxToRem(16), lineHeight: pxToRem(21) }}
                >
                    {formik.values.name}
                </Typography>

                <Typography
                    sx={{
                        fontSize: pxToRem(18),
                        fontWeight: 500,
                        lineHeight: pxToRem(24),
                        letterSpacing: 0,
                        marginTop: pxToRem(16)
                    }}
                >
                    Select Rooms*
                </Typography>

                <ZoneRoomSelectorSection
                    isEditMode={true}
                    zone={formik.values}
                    onSelectRooms={(isEdited, hasError, deviceIds) => {
                        setIsRoomOverlapping(hasError);
                        setDeviceIds(deviceIds);
                        setIsRoomsEdited(isEdited);
                    }}
                />

                <RequiredText>*Required Field</RequiredText>
            </CMPDialogContent>
            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.OUTLINED_BLACK}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => {
                        onSaveClick();
                        setIsModalOpen(false);
                    }}
                    disabled={
                        !formik.isValid || isRoomOverlapping || !isRoomsEdited
                    }
                >
                    Save
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

const ViewRooms = (props: {
    rooms: AttachedRoomInfo[];
    zone: EventZone | StandardZone;
}) => {
    const { rooms, zone } = props;
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);

    return (
        <>
            <Typography
                style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    color: '#0092B8',
                    textDecoration: 'underline',
                    userSelect: 'none'
                }}
                onClick={() => setIsViewModalOpen(true)}
            >
                {rooms.length}
            </Typography>
            {isViewModalOpen && (
                <ViewRoomsModal
                    setIsModalOpen={setIsViewModalOpen}
                    actionType={Mode.Edit}
                    zone={zone}
                />
            )}
        </>
    );
};

export default ViewRooms;
