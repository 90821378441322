import { FileUploadOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { ReactComponent as DcoumentIcon } from '../../../../assets/svg/document_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/trashBin.svg';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../../components/button';
import CMPDialog, { CMPDialogTitle } from '../../../../components/cmpDialog';
import FileDropZone from '../../../../components/fileDropZone';
import IconStylizer from '../../../../components/iconStylizer';
import { useSnackbar } from '../../../../components/snackbar';
import Tooltip from '../../../../components/tooltip';
import { FileAsStringValue } from '../../../../redux/propertyValue/model';
import { readFileAsString } from '../../../../utils/fileUtils';
import { pxToRem } from '../../../../utils/stylesUtils';

interface FileAsStringUploadModalProps {
    title: string;
    allowedFileTypes: string[];
    fileUploadInfoMessage: string;
    maxFileSize?: number;
    onClose: () => void;
    onReadFileContent?: (fineName: string, content: string) => void;
}

const FileAsStringUploadModal = (
    props: FileAsStringUploadModalProps
): React.ReactElement => {
    const { showSnackbar } = useSnackbar();

    const onFileSelected = async (files: File[]) => {
        if (isEmpty(files)) return;

        try {
            const file = files[0];
            const fileContent = await readFileAsString(file);
            props.onReadFileContent?.(file.name, fileContent);
        } catch (err: any) {
            showSnackbar(err.message, 'error');
        }
    };

    return (
        <CMPDialog onClose={props.onClose} maxWidth="sm">
            <CMPDialogTitle
                sx={{
                    fontSize: pxToRem(28),
                    fontWeight: 800,
                    color: '#000010'
                }}
            >
                {props.title}
            </CMPDialogTitle>
            <Box
                sx={{
                    display: 'flex',
                    height: pxToRem(270),
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                }}
            >
                <FileDropZone
                    onFileChange={onFileSelected}
                    onDropRejected={(message) => showSnackbar(message, 'error')}
                    allowedFileTypes={props.allowedFileTypes}
                    fileUploadInfoMessage={props.fileUploadInfoMessage}
                    isDarkMode={false}
                    filesLimit={1}
                    maxFileSize={props.maxFileSize}
                />
            </Box>
        </CMPDialog>
    );
};

interface PropertySectionFileAsStringProps {
    value: FileAsStringValue | null;
    allowedFileTypes: string[];
    fileUploadInfoMessage: string;
    maxFileSize?: number;
    onUpdateValue?: (value: FileAsStringValue | null) => void;
}

const PropertySectionFileAsString = (
    props: PropertySectionFileAsStringProps
): React.ReactElement => {
    const {
        value,
        allowedFileTypes,
        fileUploadInfoMessage,
        maxFileSize,
        onUpdateValue
    } = props;
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);

    return (
        <>
            {value === null ? (
                <>
                    <Button
                        buttonVariant={ButtonVariation.OUTLINED_BLACK}
                        buttonSize={ButtonSize.MEDIUM}
                        onClick={() => setShowFileUploadModal(true)}
                        endIcon={<FileUploadOutlined />}
                    >
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize'
                            }}
                        >
                            Upload
                        </Typography>
                    </Button>
                    {showFileUploadModal && (
                        <FileAsStringUploadModal
                            onClose={() => setShowFileUploadModal(false)}
                            title={'Upload files'}
                            allowedFileTypes={allowedFileTypes}
                            fileUploadInfoMessage={fileUploadInfoMessage}
                            maxFileSize={maxFileSize}
                            onReadFileContent={(name, content) =>
                                onUpdateValue?.({ name, content })
                            }
                        />
                    )}
                </>
            ) : (
                <Box sx={{ width: pxToRem(200), position: 'relative' }}>
                    <Tooltip message={value.name}>
                        <Box
                            sx={{
                                background: '#aaaaaa33',
                                height: pxToRem(120),
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                borderRadius: pxToRem(4)
                            }}
                        >
                            <DcoumentIcon />
                        </Box>
                    </Tooltip>
                    <IconStylizer
                        color={'#000'}
                        sx={{
                            position: 'absolute',
                            top: pxToRem(8),
                            right: pxToRem(8),
                            cursor: 'pointer'
                        }}
                    >
                        <DeleteIcon onClick={() => onUpdateValue?.(null)} />
                    </IconStylizer>
                    <Typography
                        sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            marginTop: pxToRem(4)
                        }}
                    >
                        {value.name}
                    </Typography>
                </Box>
            )}
        </>
    );
};

export default PropertySectionFileAsString;
