import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';
import Tooltip from './tooltip';

interface DisableableBoxProps {
    sx?: SxProps<Theme>;
    disabled: boolean;
    disableReason?: string;
    children: React.ReactNode;
    changeOpacity?: boolean;
}

export const DisableableBox = (
    props: DisableableBoxProps
): React.ReactElement => {
    const {
        children,
        disabled,
        sx = {},
        changeOpacity = true,
        disableReason
    } = props;

    const disableableContent = (
        <Box>
            <Box
                sx={
                    disabled
                        ? {
                              ...sx,
                              pointerEvents: 'none',
                              opacity: changeOpacity ? 0.5 : 1.0,
                              '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl':
                                  { pointerEvents: 'none' },
                              cursor: 'not-allowed'
                          }
                        : sx
                }
            >
                {children}
            </Box>
        </Box>
    );

    return disabled && disableReason ? (
        <Tooltip message={disableReason}>{disableableContent}</Tooltip>
    ) : (
        disableableContent
    );
};
