import { styled } from '@mui/material/styles';
import { FormikProps } from 'formik';
import React from 'react';
import { WidgetSkeleton } from '../../redux/element/model';
import { ElementCategory } from '../../redux/elementsList/model';
import { pxToRem } from '../../utils/stylesUtils';
import ElementFormGenerator from './elementFormGenerator';
import PageGenerator from './pageGenerator';
import RailGenerator from './railGenerator';

interface LeftContainerProps {
    formik: FormikProps<WidgetSkeleton>;
    setIsEdited: (edited: boolean) => void;
    isEdited: boolean;
    setShowAddElement: (showAddElement: boolean) => void;
    setShowModal: (showModal: boolean) => void;
    setRedirectParams?: (params: { link: string; state: {} }) => void;
}

const ElementGeneratorContainer = styled('div')(() => ({
    marginTop: pxToRem(10)
}));

const ElementContainerLeftSub = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
}));

function ElementContainerLeft(props: LeftContainerProps) {
    const {
        formik,
        setIsEdited,
        isEdited,
        setShowAddElement,
        setShowModal,
        setRedirectParams
    } = props;
    return (
        <ElementContainerLeftSub>
            {formik.values.type.toUpperCase() === ElementCategory.WIDGET && (
                <ElementGeneratorContainer>
                    <ElementFormGenerator
                        formik={formik}
                        setIsEdited={setIsEdited}
                    />
                </ElementGeneratorContainer>
            )}

            {formik.values.type === ElementCategory.RAIL && (
                <ElementGeneratorContainer>
                    <RailGenerator
                        formik={formik}
                        setIsEdited={setIsEdited}
                        isEdited={isEdited}
                        setShowAddElement={setShowAddElement}
                        setRedirectParams={setRedirectParams}
                        setShowWarningModal={setShowModal}
                    />
                </ElementGeneratorContainer>
            )}

            {formik.values.type === ElementCategory.PAGE && (
                <ElementGeneratorContainer>
                    <PageGenerator
                        formik={formik}
                        setIsEdited={setIsEdited}
                        isEdited={isEdited}
                        setShowAddElement={setShowAddElement}
                        setRedirectParams={setRedirectParams}
                        setShowWarningModal={setShowModal}
                    />
                </ElementGeneratorContainer>
            )}
        </ElementContainerLeftSub>
    );
}

export default ElementContainerLeft;
