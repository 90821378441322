import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { ActionPayload } from '../types';
import { UpdatePropertyFieldValuesBody } from './model';

export function fetchFieldConfigValues(data: ActionPayload<string>) {
    return axios.get(
        API_URL.fieldConfigValues + `/${data.payload}/fieldsAndValues`
    );
}

export function updateMultiFieldvalue(body: UpdatePropertyFieldValuesBody) {
    console.log('updatePropertyMultiFieldValue ', body.fields);
    const payload = {
        fields: body.fields,
        modified_at: Date.now()
    };

    return axios.post(
        API_URL.multiFieldUpdate + `/${body.property_id}/fields`,
        payload
    );
}
