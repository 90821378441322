import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { GetGeneralSectionResult } from './actionPayload';
import { GeneralSectionRequestBody } from './model';
import { PropertyField } from '../propertyValue/model';

export const GET_GENERAL_SECTION = 'GET_GENERAL_SECTION';
export const GET_GENERAL_SECTION_RESULTS = 'GET_GENERAL_SECTION_RESULTS';
export const UPDATE_GENERAL_SECTION = 'UPDATE_GENERAL_SECTION';
export const UPDATE_GENERAL_SECTION_RESULTS = 'UPDATE_GENERAL_SECTION_RESULTS';
export const RESET_GENERAL_SECTION_LOADER = 'RESET_GENERAL_SECTION_LOADER';
export const RESET_GENERAL_SECTION_OPERATION_LOADER =
    'RESET_GENERAL_SECTION_OPERATION_LOADER';

export const getGeneralSection = (): Action => {
    return { type: GET_GENERAL_SECTION };
};

export const getGeneralSectionResults = (
    error: boolean,
    message: string,
    data?: PropertyField[]
): ActionPayloadOrError<GetGeneralSectionResult> => {
    return {
        type: GET_GENERAL_SECTION_RESULTS,
        payload: { data },
        error,
        message
    };
};

export const updateGeneralSection = (
    data: GeneralSectionRequestBody[]
): ActionPayload<GeneralSectionRequestBody[]> => {
    return { type: UPDATE_GENERAL_SECTION, payload: data };
};

export const updateGeneralSectionResults = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: UPDATE_GENERAL_SECTION_RESULTS,
        message,
        error
    };
};

export const resetGeneralSectionLoader = (): Action => {
    return {
        type: RESET_GENERAL_SECTION_LOADER
    };
};

export const resetGeneralSectionOperationLoader = (): Action => {
    return {
        type: RESET_GENERAL_SECTION_OPERATION_LOADER
    };
};
