import { cloneDeep } from 'lodash';
import { Asset } from '../../../redux/assets/model';
import {
    ContentField,
    FieldType,
    RailAlias,
    WidgetSkeleton
} from '../../../redux/element/model';
import { PageConfigDump } from '../../../redux/preview/model';
import { CanvasData } from '../../canvas/canvasProvider';
import { WidgetData } from '../../canvas/dataAdapter/models/baseWidgetData';
import { RailDataWithHeader } from '../../canvas/dataAdapter/models/railDataModels';
import widgetsDataAdapter from '../../canvas/dataAdapter/widgetsDataAdapter';

export const getPagePreviewDump = (
    pageData: WidgetSkeleton,
    assets: Asset[]
): PageConfigDump => {
    const previewElementData = cloneDeep(pageData);
    // replace the media type content fields asset id values with urls
    previewElementData.content_fields = previewElementData.content_fields.map(
        (cf: ContentField) => {
            if (cf.type === FieldType.Media && cf.values) {
                cf.values =
                    assets.find((a: Asset) => a.id === cf.values)?.url ?? '';
            }
            return cf;
        }
    );

    return { isPartialDump: true, data: previewElementData };
};

export const getCanvasPagePreviewDump = (
    canvasData: CanvasData
): PageConfigDump => {
    const pageData = canvasData.editor.currentEditingPage;
    if (pageData === undefined) return { isPartialDump: false, data: null };

    const allRailsAndWidgets: WidgetData[] = [
        ...canvasData.widgets.getAll(),
        ...canvasData.rails.getAll()
    ];
    const allAssets: Asset[] = [
        ...canvasData.assets.getImageAssets(),
        ...canvasData.assets.getVideoAssets()
    ];

    return {
        isPartialDump: false,
        data: buildElementStructure(pageData, allRailsAndWidgets, allAssets)
    };
};

const buildElementStructure = (
    rootElement: WidgetData,
    allRailsAndWidgets: WidgetData[],
    assets: Asset[]
): { [key: string]: any } => {
    const widgetSkeletonData =
        widgetsDataAdapter.transformToWidgetSkeletonData(rootElement);
    if (widgetSkeletonData === null) return {};

    const meta = {
        id: widgetSkeletonData.id,
        typeid: widgetSkeletonData.widget_type_id,
        typeAlias: widgetSkeletonData.alias
    };

    const elementStructure: { [key: string]: any } = {
        _meta: meta,
        title: getElementTitle(rootElement)
    };

    widgetSkeletonData.content_fields.forEach((cf) => {
        switch (cf.type) {
            case FieldType.Text: {
                elementStructure[cf.key] = cf.values ? String(cf.values) : '';
                break;
            }

            case FieldType.Media: {
                const assetId = String(cf.values);
                const asset = assets.find((a) => a.id === assetId);
                elementStructure[cf.key] = asset?.url ?? '';
                break;
            }

            case FieldType.Boolean: {
                elementStructure[cf.key] = Boolean(cf.values);
                break;
            }

            case FieldType.Number: {
                elementStructure[cf.key] = cf.values ? Number(cf.values) : '';
                break;
            }

            case FieldType.Json: {
                elementStructure[cf.key] = cf.values;
                break;
            }

            case FieldType.MultiWidgetType: {
                const elementIds = cf.values as string[];
                const elementStructureArr: { [key: string]: any }[] = [];
                elementIds.forEach((eid) => {
                    const element = allRailsAndWidgets.find(
                        (e) => e.id === eid
                    );

                    if (element !== undefined) {
                        const elementStructure = buildElementStructure(
                            element,
                            allRailsAndWidgets,
                            assets
                        );
                        elementStructureArr.push(elementStructure);
                    }
                });
                elementStructure[cf.key] = elementStructureArr;
                break;
            }
        }
    });

    return elementStructure;
};

const getElementTitle = (widgetData: WidgetData): string => {
    if (
        widgetData.alias === RailAlias.WidgetRail_350x284 ||
        widgetData.alias === RailAlias.WidgetRail_300x166
    ) {
        const railData = widgetData as RailDataWithHeader;
        return railData.header.values.toString();
    }
    return widgetData.name;
};
