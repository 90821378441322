import {
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    Switch,
    TextField as MuiTextField,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../../components/textField';
import { useUrlParams } from '../../hooks/useParam';
import { getAssetLists } from '../../redux/assets/action';
import { selectAssets } from '../../redux/assets/reducer';
import {
    ContentField,
    FieldType,
    MediaType,
    TextType,
    WidgetSkeleton
} from '../../redux/element/model';
import { getCharacterLimit } from '../../utils/configureElementUtil';
import { pxToRem } from '../../utils/stylesUtils';
import { filterAssetsByType } from '../assets/assets';
import MediaAssetInputField from './mediaAssetInputField';
const defaultCharacterLimit = 35;

export const DescriptionTextRight = styled(Typography)(() => ({
    fontSize: '0.88rem',
    fontStyle: 'italic',
    letterSpacing: 0,
    color: '#171725',
    textAlign: 'right'
}));

export const StyledInputAdornment = styled(InputAdornment)(() => ({
    padding: 10
}));

export const MuiStyledInputAdornment = styled(InputAdornment)(() => ({
    padding: 10,
    '&.MuiInputAdornment-positionEnd .MuiTypography-root': {
        color: '#F8F8F8'
    }
}));

export const StyledTextField = styled(TextField)(() => ({
    marginTop: 16,
    marginBottom: 4
}));

export const MuiStyledTextField = styled(MuiTextField)(({ theme }) => {
    return {
        marginTop: 16,
        marginBottom: 4,
        '&.MuiTextField-root': {
            label: {
                color: '#F8F8F8'
            },
            textarea: {
                color: '#F8F8F8'
            }
        },
        '& .MuiOutlinedInput-root': {
            textarea: {
                color: '#F8F8F8'
            },
            '&:hover fieldset': {
                borderColor: '#F8F8F8'
            },
            '&.Mui-error': {
                '& fieldset': {
                    borderColor: '#F8F8F8',
                    color: '#F8F8F8'
                },
                '&:hover fieldset': {
                    borderColor: '#F8F8F8',
                    color: '#F8F8F8'
                }
            },
            '& fieldset': {
                borderColor: '#F8F8F8'
            }
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
                borderColor: '#F8F8F8'
            },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#F8F8F8'
        },

        '& .MuiInputLabel-outlined': {
            color: '#F8F8F8'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F8F8F8'
        },
        '& label.Mui-focused': {
            color: '#F8F8F8'
        }
    };
});

const SectionHeader = styled('div')(() => ({
    fontSize: pxToRem(19),
    fontWeight: 'bold'
}));

export interface GenerateFormProps {
    formik: FormikProps<WidgetSkeleton>;
    fieldData: ContentField;
    index: number;
    setIsEdited: (edited: boolean) => void;
}

function GenerateForm({
    fieldData,
    index,
    formik,
    setIsEdited
}: GenerateFormProps) {
    const assets = useSelector(selectAssets);
    if (!fieldData.access_details?.View) return null;
    if (
        fieldData.type === FieldType.Text ||
        fieldData.type === FieldType.Number
    ) {
        return (
            <>
                <StyledTextField
                    key={index}
                    name={`content_fields[${index}].values`}
                    value={
                        fieldData.type === FieldType.Text
                            ? formik.values.content_fields[index].values
                                  ?.toString()
                                  .trimStart() || ''
                            : formik.values.content_fields[index].values || ''
                    }
                    label={
                        fieldData.is_required
                            ? `${fieldData.name}*`
                            : fieldData.name
                    }
                    variant="outlined"
                    disabled={!fieldData.access_details?.Edit}
                    onChange={
                        fieldData.type === FieldType.Number // maxLength doesnt work on type<number> field
                            ? (e: React.ChangeEvent<HTMLInputElement>) => {
                                  e.preventDefault();
                                  setIsEdited(true);
                                  formik.setFieldValue(
                                      `content_fields[${index}].values`,
                                      parseInt(
                                          e.target.value.replace(/[^0-9]/g, '')
                                      )
                                  );
                              }
                            : (e: React.SyntheticEvent) => {
                                  setIsEdited(true);
                                  formik.handleChange(e);
                              }
                    }
                    onBlur={formik.handleBlur}
                    multiline={Boolean(
                        fieldData?.configuration?.useAsTitle ||
                            fieldData?.configuration?.rowLength
                    )}
                    rows={
                        fieldData?.configuration?.useAsTitle
                            ? 4
                            : fieldData?.configuration?.rowLength
                            ? fieldData?.configuration?.rowLength
                            : 0
                    }
                    fullWidth
                    helperText={
                        formik?.errors?.content_fields &&
                        formik?.errors?.content_fields[index]
                    }
                    error={Boolean(
                        formik?.errors?.content_fields &&
                            formik?.errors?.content_fields[index]
                    )}
                    maxLength={
                        fieldData?.configuration?.textType === TextType.JSON
                            ? Number.MAX_SAFE_INTEGER
                            : fieldData?.configuration?.charLimit
                            ? fieldData?.configuration?.charLimit
                            : defaultCharacterLimit
                    }
                    InputProps={{
                        endAdornment: (
                            <>
                                {fieldData?.configuration?.textType !==
                                    TextType.JSON && (
                                    <StyledInputAdornment position="end">
                                        {getCharacterLimit(
                                            fieldData?.configuration?.charLimit,
                                            formik.values.content_fields[index]
                                                .values
                                        )}
                                    </StyledInputAdornment>
                                )}
                            </>
                        )
                    }}
                />
                <DescriptionTextRight>
                    {fieldData.description}
                </DescriptionTextRight>
            </>
        );
    } else if (fieldData.type === FieldType.Boolean) {
        return (
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            name={`content_fields[${index}].values`}
                            checked={Boolean(
                                formik.values.content_fields[index].values
                            )}
                            disabled={!fieldData.access_details?.Edit}
                            onChange={(e: React.SyntheticEvent) => {
                                setIsEdited(true);
                                formik.handleChange(e);
                            }}
                        />
                    }
                    label={fieldData.name}
                />
            </FormGroup>
        );
    } else if (fieldData.type === FieldType.Media) {
        const fileMediaType =
            fieldData.configuration.mediaType ?? MediaType.Image;
        return (
            <MediaAssetInputField
                name={fieldData.name}
                description={fieldData.description}
                isRequired={fieldData.is_required}
                initialAssetId={fieldData.values as string}
                onMediaAssetSelected={(assetId: string) => {
                    formik.setFieldValue(
                        `content_fields[${index}].values`,
                        assetId
                    );
                    setIsEdited(true);
                }}
                disabled={!fieldData.access_details?.Edit}
                assets={filterAssetsByType(assets, fileMediaType)}
                mediaConfigs={fieldData.configuration}
            />
        );
    } else if (fieldData.type === FieldType.SectionHeader) {
        return <SectionHeader>{fieldData.name}</SectionHeader>;
    }
    return null;
}

export interface ElementGeneratorProps {
    formik: FormikProps<WidgetSkeleton>;
    setIsEdited: (edited: boolean) => void;
}

export default function DynamicForm(props: ElementGeneratorProps) {
    const { formik, setIsEdited } = props;
    const { property } = useUrlParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAssetLists(property));
    }, []);

    return (
        <>
            {formik.values.content_fields.map(
                (val: ContentField, i: number) => {
                    return (
                        <Grid item xs={12} key={i + ''}>
                            <GenerateForm
                                key={i + ''}
                                fieldData={val}
                                index={i}
                                formik={formik}
                                setIsEdited={setIsEdited}
                            />
                        </Grid>
                    );
                }
            )}
        </>
    );
}
