import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionPayload } from '../types';
import { ApiResponse } from '../../api/apiInterfaces';

import {
    GET_GENERAL_SECTION,
    UPDATE_GENERAL_SECTION,
    getGeneralSectionResults,
    updateGeneralSectionResults
} from './action';
import { generalSection, updateGeneralSection } from './helper';
import { GeneralSectionRequestBody } from './model';
import { PropertyField } from '../propertyValue/model';

/*  GENERAL SECTION FETCH     */
function* generalSectionFetchWorker() {
    try {
        const generalSectionResult: ApiResponse<{
            default: PropertyField[];
        }> = yield call(generalSection);

        yield put(
            getGeneralSectionResults(
                false,
                '',
                generalSectionResult.response_body.default
            )
        );
    } catch (error: any) {
        yield put(getGeneralSectionResults(true, error.message));
    }
}

/*  GENERAL SECTION UPDATE     */
function* updateGeneralSectionWorker(
    data: ActionPayload<GeneralSectionRequestBody[]>
) {
    try {
        const updateGeneralSectionResult: ApiResponse<{}> = yield call(
            updateGeneralSection,
            data.payload
        );
        yield put(
            updateGeneralSectionResults(
                false,
                updateGeneralSectionResult.message
            )
        );
    } catch (error: any) {
        yield put(updateGeneralSectionResults(true, error.message));
    }
}

export function* watchGeneralSectionSagas() {
    yield all([
        takeLatest(GET_GENERAL_SECTION, generalSectionFetchWorker),
        takeLatest(UPDATE_GENERAL_SECTION, updateGeneralSectionWorker)
    ]);
}
