import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { ReactComponent as InfoIcon } from '../assets/svg/info.svg';
import { pxToRem } from '../utils/stylesUtils';
import IconStylizer from './iconStylizer';

const CMPErrorHelperText = ({ text }: { text: string | undefined }) => {
    if (isEmpty(text)) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: pxToRem(5),
                marginTop: pxToRem(8)
            }}
        >
            <IconStylizer
                color="#931621"
                height={pxToRem(15)}
                width={pxToRem(16)}
                sx={{ display: 'flex' }}
            >
                <InfoIcon />
            </IconStylizer>

            <Typography
                sx={{
                    lineHeight: pxToRem(17),
                    fontSize: pxToRem(12),
                    color: '#931621'
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default CMPErrorHelperText;
