import {
    GET_GENERAL_SECTION,
    GET_GENERAL_SECTION_RESULTS,
    RESET_GENERAL_SECTION_LOADER,
    UPDATE_GENERAL_SECTION,
    UPDATE_GENERAL_SECTION_RESULTS,
    RESET_GENERAL_SECTION_OPERATION_LOADER
} from './action';

import { GeneralSectionState, initialSectionValue } from './state';
import { GeneralSectionRequestBody } from './model';

import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import { Action } from 'redux';
import { GetGeneralSectionResult } from './actionPayload';
import { orderBy } from 'lodash';
import { PropertyField } from '../propertyValue/model';

const reducer = (
    state: GeneralSectionState = initialSectionValue,
    action: any
): GeneralSectionState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialSectionValue,
    action: Action
): GeneralSectionState => {
    switch (action.type) {
        case GET_GENERAL_SECTION:
            return {
                ...state,
                loader: LoadingStatus.INITIATED
            };

        case RESET_GENERAL_SECTION_LOADER:
            return {
                ...state,
                loader: LoadingStatus.NONE
            };

        case RESET_GENERAL_SECTION_OPERATION_LOADER:
            return {
                ...state,
                operationLoader: LoadingStatus.NONE
            };

        default: {
            return state;
        }
    }
};

const actionWithPayloadReducer = (
    state = initialSectionValue,
    action: ActionPayload<GeneralSectionRequestBody[]>
): GeneralSectionState => {
    switch (action.type) {
        case UPDATE_GENERAL_SECTION:
            return {
                ...state,
                operationLoader: LoadingStatus.INITIATED
            };
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state = initialSectionValue,
    action: ActionPayloadOrError<GetGeneralSectionResult>
): GeneralSectionState => {
    switch (action.type) {
        case GET_GENERAL_SECTION_RESULTS:
            if (action.error) {
                return {
                    ...state,
                    loader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while fetching data'
                };
            }
            if (action.payload !== undefined)
                return {
                    ...state,
                    loader: LoadingStatus.DONE,
                    message: '',
                    fields: action.payload.data as PropertyField[]
                };
            return { ...state, loader: LoadingStatus.DONE, message: '' };
        case UPDATE_GENERAL_SECTION_RESULTS:
            if (action.error) {
                return {
                    ...state,
                    operationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while updating the data'
                };
            }
            return {
                ...state,
                operationLoader: LoadingStatus.DONE,
                message: action.message ?? 'updated successfully'
            };
        default: {
            return state;
        }
    }
};

export const selectGeneralSectionData = (
    state: coreRootState
): PropertyField[] => orderBy(state.generalSection.fields, 'order');

export const selectGeneralSectionLoader = (
    state: coreRootState
): LoadingStatus => state.generalSection.loader;
export const selectGeneralSectionOperationLoader = (
    state: coreRootState
): LoadingStatus => state.generalSection.operationLoader;

export const selectGeneralSectionMessage = (state: coreRootState): string =>
    state.generalSection.message;

export default reducer;
