/**
 * Disable page scroll
 */
export function disableScroll() {
    document.body.style.overflow = 'hidden';
}

/**
 * Enable page scroll
 */
export function enableScroll() {
    document.body.style.overflow = 'auto';
}

/**
 * Enables horizontal scrolls using Ref
 */
export const sideScroll = (
    setBackarrowButton: (showBackarrowButton: boolean) => void,
    element: HTMLDivElement,
    distance: number
) => {
    setBackarrowButton(true);
    element.scrollLeft += distance;
};
