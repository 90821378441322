import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';

import {
    GET_EVOLVE2_FIELDS,
    GET_EVOLVE2_FIELDS_RESPONSE,
    RESET_EVOLVE2_LOADER,
    UPDATE_EVOLVE2_FIELDS,
    UPDATE_EVOLVE2_FIELDS_RESULTS,
    RESET_EVOLVE2_OPERATION_LOADER
} from './action';

import { Evolve2State, initialEvolveStateValue } from './state';

import { GetEvolve2FieldResult } from './actionPayload';
import { PropertyField } from '../propertyValue/model';
import { orderBy } from 'lodash';

const reducer = (
    state: Evolve2State = initialEvolveStateValue,
    action: any
): Evolve2State => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialEvolveStateValue,
    action: Action
): Evolve2State => {
    switch (action.type) {
        case RESET_EVOLVE2_LOADER:
            return {
                ...state,
                loader: LoadingStatus.NONE
            };

        case RESET_EVOLVE2_OPERATION_LOADER:
            return {
                ...state,
                operationLoader: LoadingStatus.NONE
            };
        default: {
            return state;
        }
    }
};

const actionWithPayloadReducer = (
    state = initialEvolveStateValue,
    action: ActionPayload<[]>
): Evolve2State => {
    switch (action.type) {
        case GET_EVOLVE2_FIELDS:
            return {
                ...state,
                loader: LoadingStatus.INITIATED
            };
        case UPDATE_EVOLVE2_FIELDS:
            return {
                ...state,
                operationLoader: LoadingStatus.INITIATED
            };

        default:
            return state;
    }
};
const actionWithPayloadOrErrorReducer = (
    state = initialEvolveStateValue,
    action: ActionPayloadOrError<GetEvolve2FieldResult>
): Evolve2State => {
    switch (action.type) {
        case GET_EVOLVE2_FIELDS_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    loader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while fetching data'
                };
            }
            if (action.payload !== undefined)
                return {
                    ...state,
                    loader: LoadingStatus.DONE,
                    message: '',
                    fields: action.payload.data as PropertyField[]
                };
            return { ...state, loader: LoadingStatus.DONE, message: '' };
        case UPDATE_EVOLVE2_FIELDS_RESULTS:
            if (action.error) {
                return {
                    ...state,
                    operationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while updating the data'
                };
            }
            return {
                ...state,
                operationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Updated Evolve2 config successfully'
            };
        default: {
            return state;
        }
    }
};

export const selectEvolve2Data = (state: coreRootState): PropertyField[] => {
    const evolve2Fields = orderBy(state.evolve2.fields, 'order');
    evolve2Fields.forEach((item) => {
        item.children = orderBy(item.children, 'order');
    });
    return evolve2Fields;
};

export const selectEvolve2Loader = (state: coreRootState): LoadingStatus => {
    return state.evolve2.loader;
};

export const selectEvolve2OperationLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.evolve2.operationLoader;
};

export const selectEvolve2Message = (state: coreRootState): string => {
    return state.evolve2.message;
};

export default reducer;
