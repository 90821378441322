import * as React from 'react';
import NavigationPrompt from 'react-router-prompt';
import { Typography } from '@mui/material';
import Button, {
    ButtonVariation,
    ButtonSize
} from '../../../components/button';
import CMPDialog, {
    CMPDialogTitle,
    CMPDialogContent,
    CMPDialogActions
} from '../../../components/cmpDialog';
import cmpTexts from '../../../locale/en';
import { pxToRem } from '../../../utils/stylesUtils';

interface PropertyDetailsNavigationPromptProps {
    shouldBlockNavigation: boolean;
}

const PropertyDetailsNavigationPrompt = ({
    shouldBlockNavigation
}: PropertyDetailsNavigationPromptProps) => (
    <NavigationPrompt when={shouldBlockNavigation}>
        {({ onConfirm, onCancel }: { onConfirm: any; onCancel: any }) => {
            if (shouldBlockNavigation) {
                return (
                    <CMPDialog maxWidth="sm" onClose={onCancel}>
                        <CMPDialogTitle
                            sx={{
                                fontSize: pxToRem(28),
                                lineHeight: pxToRem(32),
                                fontWeight: 800
                            }}
                        >
                            {cmpTexts.PropertyNavigationModalTexts.modalHeading}
                        </CMPDialogTitle>
                        <CMPDialogContent
                            sx={{
                                fontSize: pxToRem(18),
                                lineHeight: pxToRem(24)
                            }}
                        >
                            <Typography sx={{ marginBottom: pxToRem(16) }}>
                                {
                                    cmpTexts.PropertyNavigationModalTexts
                                        .bodyText1
                                }
                            </Typography>
                            <Typography>
                                {
                                    cmpTexts.PropertyNavigationModalTexts
                                        .bodyText2
                                }
                            </Typography>
                        </CMPDialogContent>
                        <CMPDialogActions
                            sx={{
                                justifyContent: 'center',
                                gap: pxToRem(16)
                            }}
                        >
                            <Button
                                buttonVariant={ButtonVariation.OUTLINED}
                                buttonSize={ButtonSize.LARGE}
                                sx={{
                                    color: '#000010',
                                    borderColor: '#000010',
                                    fontWeight: 600,
                                    textTransform: 'capitalize'
                                }}
                                onClick={onConfirm}
                            >
                                {
                                    cmpTexts.PropertyNavigationModalTexts
                                        .button[0]
                                }
                            </Button>
                            <Button
                                sx={{ textTransform: 'capitalize' }}
                                buttonVariant={ButtonVariation.CONTAINED}
                                onClick={onCancel}
                            >
                                {
                                    cmpTexts.PropertyNavigationModalTexts
                                        .button[1]
                                }
                            </Button>
                        </CMPDialogActions>
                    </CMPDialog>
                );
            }
        }}
    </NavigationPrompt>
);

export default PropertyDetailsNavigationPrompt;
