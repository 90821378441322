import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { AddCommentPayload, FetchActivityLogs } from './actionPayload';
import { ActivityLogFilters } from './model';
import { ActivityLog } from './state';

export const RESET_ACTIVITY_LOGS_OPERATION_LOADER =
    'RESET_ACTIVITY_LOGS_OPERATION_LOADER';
export const FETCH_ACTIVITY_LOGS = 'FETCH_ACTIVITY_LOGS';
export const FETCH_ACTIVITY_LOGS_RESPONSE = 'FETCH_ACTIVITY_LOGS_RESPONSE';
export const ADD_COMMENT_TO_ACTIVITY_LOG = 'ADD_COMMENT_TO_ACTIVITY_LOG';
export const ADD_COMMENT_RESPONSE = 'ADD_COMMENT_RESPONSE';
export const FETCH_ACTIVITY_LOGS_FILTERS = 'GET_ACTIVITY_LOGS_FILTERS';
export const FETCH_ACTIVITY_LOGS_FILTERS_RESPONSE =
    'FETCH_ACTIVITY_LOGS_FILTERS_RESPONSE';

export const resetActivityLogsOperationLoader = (): Action => {
    return {
        type: RESET_ACTIVITY_LOGS_OPERATION_LOADER
    };
};

export const fetchActivityLogFilters = (): Action => {
    return {
        type: FETCH_ACTIVITY_LOGS_FILTERS
    };
};

export const fetchActivityLogs = (
    payload: FetchActivityLogs
): ActionPayload<FetchActivityLogs> => {
    return {
        type: FETCH_ACTIVITY_LOGS,
        payload
    };
};

export const fetchActivityLogsResponse = (
    payload: ActivityLog[] | undefined,
    error: boolean,
    message: string
): ActionPayloadOrError<ActivityLog[]> => {
    return {
        type: FETCH_ACTIVITY_LOGS_RESPONSE,
        payload,
        message,
        error
    };
};

export const fetchActivityLogsFiltersResponse = (
    payload: ActivityLogFilters | undefined,
    error: boolean,
    message: string
): ActionPayloadOrError<ActivityLogFilters> => {
    return {
        type: FETCH_ACTIVITY_LOGS_FILTERS_RESPONSE,
        payload,
        message,
        error
    };
};

export const addCommentToActivityLog = (
    payload: AddCommentPayload
): ActionPayload<AddCommentPayload> => {
    return {
        type: ADD_COMMENT_TO_ACTIVITY_LOG,
        payload
    };
};

export const addCommentResponse = (
    payload: ActivityLog[] | undefined,
    error: boolean,
    message: string
): ActionPayloadOrError<ActivityLog[]> => {
    return {
        type: ADD_COMMENT_RESPONSE,
        payload,
        message,
        error
    };
};
