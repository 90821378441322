import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import ConfirmationModal from '../../components/confirmationModal';
import cmpTexts from '../../locale/en';
import { LoadingStatus } from '../../redux/common/state';
import { updateMaintenanceStatus } from '../../redux/maintenance/action';
import {
    selectMaintenanceLoadingStatus,
    selectMaintenanceStatus
} from '../../redux/maintenance/reducer';
import { pxToRem } from '../../utils/stylesUtils';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)'
        }
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200
        })
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,.35)'
                : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box'
    }
}));

export default function MaintenanceModeSwitch() {
    const dispatch = useDispatch();
    const isUnderMaintenance = useSelector(selectMaintenanceStatus);
    const maintenanceLoadingStatus = useSelector(
        selectMaintenanceLoadingStatus
    );
    const [openConfirmationModal, setOpenConfirmationModal] =
        useState<boolean>(false);

    const handleCloseModal = () => {
        setOpenConfirmationModal(false);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOpenConfirmationModal(true);
    };

    const yesClick = () => {
        dispatch(updateMaintenanceStatus(!isUnderMaintenance));
        handleCloseModal();
    };

    return (
        <>
            {maintenanceLoadingStatus === LoadingStatus.INITIATED && (
                <FullScreenCircularLoader />
            )}

            <Box
                sx={{
                    background: '#e34958',
                    height: pxToRem(64),
                    width: pxToRem(340),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <FormGroup>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography fontWeight="bold" marginRight={pxToRem(8)}>
                            CMP Maintenance Mode
                        </Typography>
                        <Typography>Off</Typography>
                        <AntSwitch
                            checked={isUnderMaintenance}
                            onChange={handleRadioChange}
                            inputProps={{ 'aria-label': 'ant design' }}
                        />
                        <Typography>On</Typography>
                    </Stack>
                </FormGroup>

                {openConfirmationModal && (
                    <ConfirmationModal
                        title={cmpTexts.confirmMaintenanceModal?.title}
                        description={
                            cmpTexts.confirmMaintenanceModal?.description
                        }
                        onClose={handleCloseModal}
                        onPositiveButtonClick={yesClick}
                        positiveButtonText={
                            cmpTexts.confirmMaintenanceModal?.button[0]
                        }
                        negativeButtonText={
                            cmpTexts.confirmMaintenanceModal?.button[1]
                        }
                    />
                )}
            </Box>
        </>
    );
}
