import { Button, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CMPTexts from '../../locale/en';
import CMPBackground from '../../components/cmpBackground';

const LoginContainer = styled('div')(() => ({
    backgroundColor: 'rgb(23,23,37)',
    height: '100vh',
    width: '100%'
}));

const CustomPaper = styled(Paper)(() => ({
    width: '30vw',
    marginTop: '30vh'
}));

const PaperHeadingBox = styled(Box)(() => ({
    fontSize: 28,
    textAlign: 'left',
    color: 'rgb(23,23,37)',
    marginTop: 20
}));

const PaperBodyBox = styled(Box)(() => ({
    fontSize: 12,
    textAlign: 'left',
    color: 'rgb(23,23,37)',
    marginTop: 20
}));

const CustomButton = styled(Button)(() => ({
    color: 'rgb(245,245,246)',
    fontSize: 14,
    marginTop: 25,
    marginBottom: 30,
    p: 1
}));

const EmailVerificationSubmit = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            handleRedirect();
        }, 3000);
    });

    const handleRedirect = () => {
        console.log('Redirecting to Login...');
        navigate('/');
    };

    return (
        <LoginContainer>
            <CMPBackground>
                <CustomPaper elevation={3}>
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <PaperHeadingBox>
                                {CMPTexts.emaiVerificationSubmit.paperHeading}
                            </PaperHeadingBox>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <PaperBodyBox>
                                {CMPTexts.emaiVerificationSubmit.paperBody}
                            </PaperBodyBox>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <CustomButton
                            variant="contained"
                            onClick={handleRedirect}
                        >
                            {CMPTexts.emaiVerificationSubmit.button}
                        </CustomButton>
                    </Grid>
                </CustomPaper>
            </CMPBackground>
        </LoginContainer>
    );
};

export default EmailVerificationSubmit;
