export const readFileAsString = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const content = reader.result as string;
                // Check for binary characters
                for (let i = 0; i < content.length; i++) {
                    const charCode = content.charCodeAt(i);
                    if (
                        charCode === 0 ||
                        (charCode < 32 &&
                            charCode !== 9 &&
                            charCode !== 10 &&
                            charCode !== 13)
                    ) {
                        reject(
                            new Error(
                                'Invalid file contents. Please upload a valid file.'
                            )
                        );
                        return;
                    }
                }
                resolve(content);
            } else {
                reject(
                    new Error(
                        'Error reading file content. Please upload a valid file.'
                    )
                );
            }
        };

        reader.onerror = () => {
            reject(
                new Error(
                    'Error reading file content. Please upload a valid file.'
                )
            );
        };

        reader.readAsText(file);
    });
};
