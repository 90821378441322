import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CMPTexts from '../locale/en';
import LoginBackgroundImage from '../assets/svg/loginBackgroundImage.svg';
import { pxToRem } from '../utils/stylesUtils';

const CMPBackground = (props: { children: React.ReactNode }) => {
    const { children } = props;

    return (
        <GridContainer container sx={{ backgroundColor: 'rgb(23,23,37)' }}>
            <ImageBackground>
                <DivContainer>
                    <LeftSideTextGrid>
                        <LeftSideTexth1>
                            {CMPTexts.login.textOnLeft}
                        </LeftSideTexth1>
                    </LeftSideTextGrid>
                    <ChildrenGrid>{children}</ChildrenGrid>
                </DivContainer>
            </ImageBackground>
        </GridContainer>
    );
};

export default CMPBackground;

const ImageBackground = styled('div')(() => ({
    height: '100%',
    width: '75%',
    backgroundImage: `url(${LoginBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'Right',
    backgroundSize: 'cover'
}));

const GridContainer = styled(Grid)(() => ({
    width: '100%',
    height: '100vh'
}));

const DivContainer = styled('div')(() => ({
    display: 'flex'
}));

const LeftSideTextGrid = styled('div')(() => ({
    flex: 5,
    marginLeft: '5vw',
    marginRight: '1vw',
    marginTop: '14vh',
    lineHeight: '2.5em',
    textAlign: 'justify',
    textJustify: 'inter-word',
    wordWrap: 'break-word'
}));

const ChildrenGrid = styled('div')(() => ({
    flex: 1,
    width: '4vw'
}));

const LeftSideTexth1 = styled('h1')(() => ({
    color: '#F5F5F6',
    fontWeight: 600,
    fontSize: `${pxToRem(33)}`,
    fontStyle: 'Proxima Nova Light'
}));
