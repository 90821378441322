import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    FETCH_MAINTENANCE_STATUS,
    FETCH_MAINTENANCE_STATUS_RESPONSE,
    LOADER_RESET,
    UPDATE_MAINTENANCE_STATUS,
    UPDATE_MAINTENANCE_STATUS_RESPONSE
} from './action';
import { MaintenanceStatus } from './actionPayload';
import { initialMaintenanceState, MaintenanceState } from './state';

const reducer = (
    state: MaintenanceState = initialMaintenanceState,
    action: any
): MaintenanceState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: MaintenanceState,
    action: Action
): MaintenanceState => {
    switch (action.type) {
        case FETCH_MAINTENANCE_STATUS:
            return {
                ...state,
                loadingStatus: LoadingStatus.INITIATED
            };

        case LOADER_RESET: {
            return {
                ...state,
                message: '',
                loadingStatus: LoadingStatus.NONE
            };
        }

        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state: MaintenanceState,
    action: ActionPayload<MaintenanceStatus>
): MaintenanceState => {
    switch (action.type) {
        case UPDATE_MAINTENANCE_STATUS:
            return {
                ...state,
                loadingStatus: LoadingStatus.INITIATED
            };
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: MaintenanceState = initialMaintenanceState,
    action: ActionPayloadOrError<MaintenanceState>
): MaintenanceState => {
    switch (action.type) {
        case UPDATE_MAINTENANCE_STATUS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    message: action.message as string,
                    loadingStatus: LoadingStatus.FAILED
                };
            }

            return {
                ...state,
                message: action.message as string,
                loadingStatus: LoadingStatus.DONE
            };
        }

        case FETCH_MAINTENANCE_STATUS_RESPONSE:
            return {
                ...state,
                enabled: action.payload?.enabled as boolean,
                image_url: action.payload?.image_url ?? '',
                loadingStatus: LoadingStatus.DONE
            };

        default:
            return state;
    }
};

export const selectMaintenanceStatus = (state: coreRootState): boolean => {
    return state.maintenance.enabled;
};

export const selectMaintenanceBackgroumdImage = (
    state: coreRootState
): string => {
    return state.maintenance.image_url;
};

export const selectMaintenanceLoadingStatus = (
    state: coreRootState
): string => {
    return state.maintenance.loadingStatus;
};

export const selectMaintenanceMessage = (state: coreRootState): string => {
    return state.maintenance.message;
};
export default reducer;
