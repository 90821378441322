import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import SearchTextField from '../../../components/searchTextField';
import { Zone, ZoneType } from '../../../redux/zones/model';
import { shallowSearch } from '../../../utils/searchUtil';
import { pxToRem } from '../../../utils/stylesUtils';
import { PlatformSpecificWarningMessage } from './components/platformPanel';
import EventsTable from './eventsTable';
import ZonesTable from './zonesTable';

export interface ZoneSpecificWarningMessages {
    [zoneId: string]: PlatformSpecificWarningMessage;
}
export interface ZoneAndEventCommonProps {
    propertyId: string;
    setShouldResetForm: (shouldResetForm: boolean) => void;
    shouldResetForm: boolean;
    updateSubmitPayload: (
        zId: string,
        pId: string,
        key: string,
        value: any,
        isManualUpdate: boolean
    ) => void;
    setShowSaveBar: (showSaveBar: boolean) => void;
    expandedRows: string[];
    setExpandedRows: (expandedRows: string[]) => void;
    autoAffectedFieldIds: string[];
    warningMessages?: ZoneSpecificWarningMessages;
    setWarningMessage?: (
        zoneId: string,
        platform: string,
        message: string
    ) => void;
}

interface ZoneAndEventTabProps extends ZoneAndEventCommonProps {
    zoneData: Zone[];
    selectedTab: ZoneType;
}

const ZonesAndEventsTab = (props: ZoneAndEventTabProps) => {
    const {
        zoneData,
        selectedTab,
        propertyId,
        setShouldResetForm,
        shouldResetForm,
        updateSubmitPayload,
        setShowSaveBar,
        expandedRows,
        setExpandedRows,
        autoAffectedFieldIds
    } = props;

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);

    const zonesSortedByNames = orderBy(zoneData, 'name', 'asc');
    const filteredZones = searchText
        ? shallowSearch(zonesSortedByNames, searchText)
        : zonesSortedByNames;

    useEffect(() => {
        setPage(0);
    }, [filteredZones]);

    const tableProps = {
        filteredItems: filteredZones,
        page,
        setPage,
        updateSubmitPayload,
        propertyId,
        setShouldResetForm,
        shouldResetForm,
        setShowSaveBar,
        expandedRows,
        setExpandedRows,
        autoAffectedFieldIds
    };

    return (
        <>
            <SearchTextField
                variant="standard"
                initialSearchValue=""
                sx={{
                    width: '30%',
                    marginBottom: pxToRem(32)
                }}
                placeholder="Search"
                handleOnChange={setSearchText}
            />
            {selectedTab === ZoneType.Standard ? (
                <ZonesTable {...tableProps} />
            ) : (
                <EventsTable {...tableProps} />
            )}
        </>
    );
};

export default ZonesAndEventsTab;
