import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { saveAs } from 'file-saver';
import React from 'react';
import cmpTexts from '../locale/en';
import { useSnackbar } from './snackbar';
import Tooltip from './tooltip';

interface DownloadAssetButtonProps {
    fileName: string;
    assetUrl: string;
    extension: string;
}

const DownloadAssetButton = (
    props: DownloadAssetButtonProps
): React.ReactElement => {
    const { showSnackbar, hideSnackbar } = useSnackbar();

    const downloadAsset = async (e: any) => {
        const assetName = `${props.fileName}.${props.extension.toLowerCase()}`;
        e.stopPropagation();
        showSnackbar(cmpTexts.assets.downloadStartInfoMessage, 'success', 4000);
        try {
            const response = await axios.get<BlobPart>(props.assetUrl, {
                responseType: 'blob'
            });
            const blobData = new Blob([response.data]);
            hideSnackbar();
            saveAs(blobData, assetName);
        } catch (error) {
            showSnackbar(cmpTexts.assets.downloadError, 'error');
        }
    };

    return (
        <Tooltip message={cmpTexts.assets.DownloadButtonToolTip}>
            <IconButton onClick={downloadAsset}>
                <DownloadIcon sx={{ color: '#000' }} />
            </IconButton>
        </Tooltip>
    );
};

export default DownloadAssetButton;
