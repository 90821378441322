import { includes } from 'lodash';
import * as Yup from 'yup';
import { UserRole } from '../redux/users/model';
import { emailRegex } from '../utils/regexUtil';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
        .required('*Required')
        .email('*Invalid Email Address')
        .matches(emailRegex, 'Enter a valid email address'),
    password: Yup.string().required('*Please Enter your password')
});

export const forgotPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
        .required('*Required')
        .email('*Invalid Email Address')
        .matches(emailRegex, 'Enter a valid email address')
});

export const emailVerificationValidationSchema = Yup.object().shape({
    tempPassword: Yup.string().required('*Required'),
    password: Yup.string()
        .required('*Please Enter your password')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            '*Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('*Required')
});

export const changePasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid Email Address')
        .required('Required')
        .matches(emailRegex, 'Enter a valid email address'),
    securityCode: Yup.string().required('Required'),
    password: Yup.string()
        .required('Please Enter your password')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
    repeatPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required')
});

export function isSuperRole(role: UserRole): boolean {
    return includes([UserRole.SuperAdmin, UserRole.SuperUser], role);
}
