export interface User {
    sub_id: string;
    name: string;
    role: string;
    email: string;
    orgId: string;
    userName: string;
    IntegratorId: string;
    status: string;
    isActionAllowed: boolean;
}

export enum UserRole {
    SuperAdmin = 'SuperAdmin',
    SuperUser = 'SuperUser',
    AdminIntegrator = 'AdminIntegrator',
    Integrator = 'Integrator',
    PropertyManager = 'PropertyManager',
    Consultant = 'Consultant'
}

export interface UserExistsData {
    email: string;
    userId: string;
    exists: boolean;
    firstName: string;
    lastName: string;
    role: UserRole;
}
export interface UserDetails {
    id: string;
    property_ids: string[];
    organization_id: string;
    organizationName: string;
    email: string;
    name: string;
    first_name: string;
    last_name: string;
    username: string;
    role: UserRole;
    sub_id: string;
    status: string;
}
