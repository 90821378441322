import { orderBy } from 'lodash';
import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    ADD_ORGANIZATION,
    ADD_ORGANIZATION_RESPONSE,
    DELETE_ORGANIZATION,
    DELETE_ORGANIZATION_RESPONSE,
    EDIT_ORGANIZATION,
    EDIT_ORGANIZATION_RESPONSE,
    GET_ALL_ORGANIZATION_LIST,
    GET_ORGANIZATIONS_RESPONSE,
    ORGANIZATION_OPERATION_LOADER,
    PURGE_ORGANIZATION,
    SET_FILTERED_ORGANIZATION,
    SET_LOADER
} from './action';
import {
    AddOrganization,
    DeleteOrganization,
    EditOrganization,
    GetOrganizationsResponse
} from './actionPayload';
import { Organization } from './model';
import { initialOrganizationState, OrganizationsState } from './state';

const reducer = (
    state: OrganizationsState = initialOrganizationState,
    action: any
): OrganizationsState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialOrganizationState,
    action: Action
): OrganizationsState => {
    switch (action.type) {
        case GET_ALL_ORGANIZATION_LIST: {
            return {
                ...state,
                loader: LoadingStatus.INITIATED
            };
        }
        case SET_LOADER: {
            return {
                ...state,
                loader: LoadingStatus.NONE
            };
        }
        case ORGANIZATION_OPERATION_LOADER: {
            return {
                ...state,
                organizationOperationLoader: LoadingStatus.NONE
            };
        }
        case PURGE_ORGANIZATION:
            return initialOrganizationState;

        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state = initialOrganizationState,
    action: ActionPayload<
        EditOrganization | DeleteOrganization | string | AddOrganization
    >
): OrganizationsState => {
    switch (action.type) {
        case EDIT_ORGANIZATION:
        case DELETE_ORGANIZATION:
        case ADD_ORGANIZATION: {
            return {
                ...state,
                organizationOperationLoader: LoadingStatus.INITIATED
            };
        }
        case SET_FILTERED_ORGANIZATION:
            return {
                ...state,
                filteredOrganization: action.payload as string
            };
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state = initialOrganizationState,
    action: ActionPayloadOrError<GetOrganizationsResponse>
): OrganizationsState => {
    switch (action.type) {
        case GET_ORGANIZATIONS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    message:
                        action.message ??
                        'Error while getting the organizations',
                    loader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                organizations: orderBy(
                    (action.payload as GetOrganizationsResponse)
                        .organizations as Organization[],
                    [(org) => org.name.toLowerCase()],
                    ['asc']
                ),
                loader: LoadingStatus.DONE
            };
        }

        case ADD_ORGANIZATION_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    organizationOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ?? 'Error while adding the organiztion.'
                };
            }
            return {
                ...state,
                organizationOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Organization added successfully.'
            };
        }

        case EDIT_ORGANIZATION_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    organizationOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error while updating the organization.'
                };
            }
            return {
                ...state,
                organizationOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Organization updated successfully.'
            };
        }

        case DELETE_ORGANIZATION_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    organizationOperationLoader: LoadingStatus.FAILED,
                    message:
                        action.message ??
                        'Error while deleting the organization.'
                };
            }
            return {
                ...state,
                organizationOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Organization deleted successfully'
            };
        }

        default: {
            return state;
        }
    }
};

export const selectOrganizationsLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.organizations.loader;
};

export const selectOrganizationOperationLoader = (
    state: coreRootState
): LoadingStatus => {
    return state.organizations.organizationOperationLoader;
};

export const selectMessage = (state: coreRootState): string => {
    return state.organizations.message;
};

export const selectOrganizationList = (
    state: coreRootState
): Organization[] => {
    return state.organizations.organizations;
};

export const selectFilteredOrganization = (state: coreRootState): string =>
    state.organizations.filteredOrganization;

export default reducer;
