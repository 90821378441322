import axios from '../../api/axios';
import { API_URL } from '../../api/apiConstants';
import { GeneralSectionRequestBody } from './model';

export function generalSection() {
    return axios.get(API_URL.getGeneralSection);
}

export function updateGeneralSection(data: GeneralSectionRequestBody[]) {
    return axios.post(API_URL.updateGeneralSection, data);
}
