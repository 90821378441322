import { Box, FormHelperText, Popover, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { ReactComponent as Calendar } from '../../../../assets/svg/calendar.svg';
import DatePickerCalendar from '../../../../components/datePickerCalendar';
import { pxToRem } from '../../../../utils/stylesUtils';

interface DatePickerProps {
    required?: boolean;
    label: string;
    date: string | undefined;
    setDate: (date: moment.Moment) => void;
    error: string;
    minimumDate?: Date;
    maximumDate?: Date;
}

const DatePicker = (props: DatePickerProps) => {
    const { required = false, label, date, setDate, error, ...rest } = props;
    const [showCalendar, setShowCalendar] = useState(false);
    const popoverAnchor = useRef<HTMLElement>();

    const dateToDisplay = isEmpty(date) ? 'MM / DD / YYYY' : date;

    const formattedDate = isEmpty(date) ? undefined : moment(date);

    return (
        <>
            <Box
                sx={{
                    border: isEmpty(error)
                        ? '1px solid black'
                        : '1px solid #d32f2f',
                    borderRadius: pxToRem(7),
                    height: pxToRem(54),
                    padding: `${pxToRem(2)} ${pxToRem(15)} ${pxToRem(6)}`,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    },
                    '&.selected': {
                        backgroundColor: 'rgba(240, 20, 70, 0.08)',
                        '&:hover': {
                            backgroundColor: 'rgba(240, 20, 70, 0.12)'
                        }
                    },
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    setShowCalendar(true);
                }}
                ref={popoverAnchor}
            >
                <Box>
                    <Typography
                        sx={{
                            color: '#525261',
                            fontWeight: 500,
                            fontSize: pxToRem(12),
                            '&::after': {
                                content: required ? '"*"' : '""'
                            }
                        }}
                    >
                        {label}
                    </Typography>
                    <Typography sx={{ color: '#525261' }}>
                        {dateToDisplay}
                    </Typography>
                </Box>
                <Calendar
                    style={{
                        marginLeft: pxToRem(20)
                    }}
                />
            </Box>

            {!isEmpty(error) && (
                <FormHelperText
                    sx={{ marginLeft: pxToRem(14) }}
                    error
                    required
                    id="calendar-helper-text"
                >
                    {error}
                </FormHelperText>
            )}

            {showCalendar && (
                <Popover
                    open={true}
                    onClose={() => setShowCalendar(false)}
                    anchorEl={popoverAnchor.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    sx={{
                        minWidth: pxToRem(280),
                        marginTop: pxToRem(4),
                        border: '1px solid black'
                    }}
                >
                    <DatePickerCalendar
                        value={formattedDate}
                        onSelectDate={(val: moment.Moment) => {
                            setDate(val);
                            setShowCalendar(false);
                        }}
                        selectionType="single"
                        {...rest}
                    />
                </Popover>
            )}
        </>
    );
};

export default DatePicker;
