import { Delete, Upload } from '@mui/icons-material';
import {
    Box,
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { ButtonVariation } from '../../../../components/button';
import {
    DEFAULT_ALLOWED_IMAGE_FILE_TYPES,
    DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE
} from '../../../../components/fileDropZone';
import SearchTextField from '../../../../components/searchTextField';
import { useSnackbar } from '../../../../components/snackbar';
import Tooltip from '../../../../components/tooltip';
import { Asset } from '../../../../redux/assets/model';
import {
    selectAssetOperationLoadingState,
    selectMessage
} from '../../../../redux/assets/reducer';
import { LoadingStatus } from '../../../../redux/common/state';
import { MediaConfigType } from '../../../../redux/element/model';
import { search } from '../../../../utils/searchUtil';
import { dataSizeConverter } from '../../../../utils/sizeConverter';
import { pxToRem } from '../../../../utils/stylesUtils';
import UploadAssetModal from '../../../assets/uploadAssetModal';

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: pxToRem(20),
    justifyContent: 'left',
    flexWrap: 'wrap',
    flexGrow: 'unset'
}));

const LogoContainer = styled(Box)(() => ({
    width: pxToRem(200),
    height: pxToRem(100),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: pxToRem(4),
    background: 'rgba(170, 170, 170, 0.20)',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: pxToRem(0.4),
    position: 'relative',
    padding: `${pxToRem(10)} ${pxToRem(10)}`,
    marginLeft: pxToRem(10)
}));

const SelectedLogoName = styled(Typography)(() => ({
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    color: '#171725',
    textAlign: 'center',
    marginTop: pxToRem(5),
    width: pxToRem(200),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const MenuItemContainer = styled(Box)(() => ({
    textAlign: 'center',
    width: '94%'
}));

const MenuProps = {
    autoFocus: false,
    PaperProps: {
        style: {
            maxHeight: 576,
            width: pxToRem(520)
        }
    }
};

const StyledDeleteIcon = styled(Delete)(() => ({
    position: 'absolute',
    right: 3,
    top: 3,
    fontSize: pxToRem(18),
    color: '#171725',
    cursor: 'pointer'
}));

const ImageContainer = styled(Box)(() => ({
    width: pxToRem(200),
    height: pxToRem(120),
    padding: `${pxToRem(2)} ${pxToRem(2)}`,
    borderRadius: pxToRem(20),
    background: 'rgba(170, 170, 170, 0.20)',
    display: 'flex',
    justifyContent: 'center'
}));

const Image = styled('img')(() => ({
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: pxToRem(20)
}));

const AssetName = styled(Typography)(() => ({
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    color: '#171725',
    marginTop: pxToRem(5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

interface PropertyAssetSelectorProps {
    assets: Asset[];
    onChange: (assetId: string) => void;
    selectedAssetId: string;
    assetSelectionAllowed?: boolean;
    mediaConfigs?: MediaConfigType;
}

const PropertyAssetSelector = (props: PropertyAssetSelectorProps) => {
    const {
        assets,
        onChange,
        selectedAssetId,
        assetSelectionAllowed = false,
        mediaConfigs
    } = props;
    const { showSnackbar } = useSnackbar();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const selectedAsset = assets.find((asset) => asset.id === selectedAssetId);
    const displayedAssets = searchText
        ? assets.filter((asset) => search(asset.name, searchText))
        : assets;
    const message = useSelector(selectMessage);
    const uploadLoader = useSelector(selectAssetOperationLoadingState);

    useEffect(() => {
        if (uploadLoader === LoadingStatus.DONE) {
            showSnackbar(message);
        }
    }, [uploadLoader]);

    return (
        <Container>
            <Box>
                <LogoContainer>
                    {isEmpty(selectedAsset) ? (
                        <Typography sx={{ fontSize: pxToRem(12) }}>
                            No Logo Uploaded
                        </Typography>
                    ) : (
                        <>
                            <Tooltip
                                message={dataSizeConverter(
                                    selectedAsset?.size as number
                                )}
                            >
                                <img
                                    src={selectedAsset?.url}
                                    alt={selectedAsset?.name}
                                    style={{
                                        width: pxToRem(150),
                                        height: pxToRem(80),
                                        borderRadius: pxToRem(4)
                                    }}
                                />
                            </Tooltip>

                            {assetSelectionAllowed && (
                                <StyledDeleteIcon
                                    onClick={() => onChange('')}
                                />
                            )}
                        </>
                    )}
                </LogoContainer>
                {!isEmpty(selectedAsset) && (
                    <SelectedLogoName>{`${
                        selectedAsset?.name
                    }.${selectedAsset?.file_extension.toLowerCase()}`}</SelectedLogoName>
                )}
            </Box>
            <FormControl>
                <InputLabel shrink={false} id="select-label">
                    Select Logo
                </InputLabel>
                <Select
                    value=""
                    labelId="select-label"
                    onChange={(e) => {
                        onChange(e.target.value as string);
                    }}
                    onClose={() => setSearchText('')}
                    MenuProps={MenuProps}
                    sx={{ width: pxToRem(520) }}
                    disabled={!assetSelectionAllowed}
                >
                    <ListSubheader>
                        <SearchTextField
                            sx={{
                                paddingTop: pxToRem(12),
                                paddingBottom: pxToRem(14)
                            }}
                            placeholder="Search Assets"
                            fullWidth
                            autoFocus
                            handleOnChange={setSearchText}
                            onKeyDown={(e: any) => {
                                if (e.key !== 'Escape') {
                                    e.stopPropagation();
                                }
                            }}
                            initialSearchValue={searchText}
                        />
                    </ListSubheader>
                    {displayedAssets.map((asset) => {
                        return (
                            <MenuItem
                                key={asset.id}
                                value={asset.id}
                                sx={{
                                    display: 'inline-flex',
                                    width: '50%',
                                    marginBottom: pxToRem(10),
                                    justifyContent: 'center',
                                    ':hover': {
                                        backgroundColor: 'transparent'
                                    }
                                }}
                            >
                                <MenuItemContainer>
                                    <Tooltip
                                        message={dataSizeConverter(
                                            asset?.size as number
                                        )}
                                    >
                                        <ImageContainer>
                                            <Image
                                                alt={asset.name}
                                                src={asset.url}
                                            />
                                        </ImageContainer>
                                    </Tooltip>
                                    <AssetName>{`${
                                        asset.name
                                    }.${asset.file_extension.toLowerCase()}`}</AssetName>
                                </MenuItemContainer>
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Button
                buttonVariant={ButtonVariation.CONTAINED}
                startIcon={<Upload />}
                onClick={() => setShowUploadModal(true)}
                disabled={!assetSelectionAllowed}
                sx={{ lineHeight: 1 }}
            >
                Upload Asset
            </Button>
            {showUploadModal && (
                <UploadAssetModal
                    setIsModalOpen={setShowUploadModal}
                    onAssetUploaded={(asset: Asset) => {
                        onChange(asset.id);
                    }}
                    allowedFileTypes={
                        mediaConfigs?.allowedFileTypes ??
                        DEFAULT_ALLOWED_IMAGE_FILE_TYPES
                    }
                    assetUploadInfoMessage={
                        mediaConfigs?.fileUploadInfoMessage ??
                        DEFAULT_ASSET_UPLOAD_INFO_MESSAGE_IMAGE
                    }
                />
            )}
        </Container>
    );
};

export default PropertyAssetSelector;
