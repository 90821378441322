import { Parser } from 'expr-eval';
import { isEmpty } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import { PropertyGroupInterface } from '../redux/propertylist/model';
import {
    Condition,
    PropertyField,
    PropertyFieldValueType
} from '../redux/propertyValue/model';
import { colorRgex } from './regexUtil';

const parser = new Parser();

export const filterPropertyNames = (
    orgId: string,
    propertylist: PropertyGroupInterface[]
) => {
    return propertylist
        .filter((p) => p.org_id === orgId)
        .flatMap((p) => p.properties)
        .map((p) => p.name);
};

export const SmartBoxFormValidationSchema = Yup.object().shape({
    swatchId: Yup.string().trim().required('*Required')
    // serialNumber: Yup.string().required('*Required')
});

export const AddPropertyValidationSchema = Yup.object().shape({
    property: Yup.string()
        .required('*Required')
        .trim()
        .min(3, 'Should be atleast 3 characters')
        .test(
            'unique-name',
            'Property with this name already exists',
            function (value) {
                const { filteredPropertyNames } = this.parent;
                return !filteredPropertyNames.includes(value);
            }
        ),
    propertyDescription: Yup.string().required('*Required').trim(),
    zip_code: Yup.string().required('*Required').min(5, 'Must be 5 digits'),
    propertyTypeId: Yup.string().required('*Required')
});

export const EditPropertyValidationSchema = Yup.object().shape({
    property: Yup.string()
        .required('*Required')
        .trim()
        .min(3, 'Should be atleast 3 characters')
        .test(
            'unique-name',
            'Property with this name already exists',
            function (value) {
                const { filteredPropertyNames } = this.parent;
                return !filteredPropertyNames.includes(value);
            }
        ),
    propertyDescription: Yup.string().required('*Required').trim()
});

export const AddPropertyGroupForSuperUserValidationSchema = Yup.object().shape({
    groupName: Yup.string()
        .required('*Required')
        .trim()
        .min(3, 'Should be atleast 3 characters'),
    organizationId: Yup.string().required('*Required')
});

export const AddPropertyGroupValidationSchema = Yup.object().shape({
    groupName: Yup.string()
        .required('*Required')
        .trim()
        .min(3, 'Should be atleast 3 characters')
});

export const getLastModifiedString = (
    modifiedBy: string,
    modifiedAt: Date
): string => {
    const calendarDate = moment(modifiedAt).calendar();
    let preposition = '';
    if (calendarDate.includes('Last') || calendarDate.includes('/')) {
        preposition = 'on';
    }
    return `Modified by ${modifiedBy} ${preposition} ${calendarDate}`;
};

const propertyComplexFieldValidationSchema = (
    allSectionsFields: PropertyField[]
) =>
    Yup.object()
        .shape({
            value: Yup.mixed()
                .when('value_type', {
                    is: PropertyFieldValueType.String,
                    then: Yup.string().when(
                        'configuration.maxLength',
                        (maxValue = 200) =>
                            Yup.string()
                                .nullable(true)
                                .max(maxValue, `Must be less than ${maxValue}`)
                    )
                })
                .when('value_type', {
                    is: PropertyFieldValueType.Integer,
                    then: Yup.number().when(
                        ['configuration.maxValue', 'configuration.minValue'],
                        (maxValue = Number.MAX_SAFE_INTEGER, minValue = 0) =>
                            Yup.number()
                                .transform((value) =>
                                    Number.isNaN(value) ? null : value
                                )
                                .nullable()
                                .max(maxValue, `Must be less than ${maxValue}`)
                                .min(
                                    minValue,
                                    `Must be greater than ${minValue}`
                                )
                    )
                })
                .when('value_type', {
                    is: PropertyFieldValueType.Color,
                    then: Yup.string()
                        .required('*Required')
                        .matches(colorRgex, 'Invalid Color Format')
                })
        })

        .test('Required Field Validation', (data: any, ctx: any) => {
            const propertyField = data as PropertyField;
            const isRequired = evaluateRequiredCondition(
                propertyField,
                allSectionsFields
            );

            if (!isRequired) return true;

            let isValueEmpty = false;

            switch (propertyField.value_type) {
                case PropertyFieldValueType.ConsentCheckbox:
                    isValueEmpty = propertyField.value !== true;
                    break;

                case PropertyFieldValueType.Integer:
                    isValueEmpty = isEmpty(propertyField.value?.toString());
                    break;

                default:
                    isValueEmpty = isEmpty(propertyField.value);
            }

            if (isValueEmpty)
                return ctx.createError({
                    path: `${ctx.path}.value`,
                    message: '*Required'
                });

            return true;
        });

const faqValidationSchema = Yup.object()
    .nullable(true)
    .shape({
        faq: Yup.array().of(
            Yup.object().shape({
                question: Yup.string(),
                answer: Yup.string()
            })
        )
    });

const propertyComplexSectionValidationSchema = (
    allSectionsFields: PropertyField[]
) =>
    Yup.object().shape({
        children: Yup.array().test(
            'Validate Complex Field Children',
            (data, context) => {
                try {
                    Yup.array(
                        propertyComplexFieldValidationSchema(allSectionsFields)
                    ).validateSync(data, { abortEarly: false });
                    return true;
                } catch (error: any) {
                    return parseValidationError(context, error);
                }
            }
        )
    });

export const propertyFieldsValidationSchema = Yup.array().of(
    Yup.object().test('Validate Property Fields', (data, context) => {
        switch (data.value_type) {
            case PropertyFieldValueType.FAQ:
                return faqValidationSchema.isValidSync(data);
            case PropertyFieldValueType.Complex: {
                try {
                    propertyComplexSectionValidationSchema(
                        context.parent
                    ).validateSync(data, {
                        abortEarly: false
                    });
                    return true;
                } catch (error) {
                    return parseValidationError(context, error);
                }
            }
            default:
                return true;
        }
    })
);

const parseValidationError = (
    context: any,
    error: any
): Yup.ValidationError => {
    const validationError = error as Yup.ValidationError;
    validationError.inner.forEach((e) => {
        e.path = `${context.path}${e.path}`;
    });

    return validationError;
};

const addQuoteIfString = (value: any): any => {
    if (typeof value === 'string') return `"${value}"`;
    return value;
};

const evaluateCondition = (
    fieldConfigs: PropertyField[],
    condition: Condition
): boolean => {
    let dependentConfigField = undefined;
    let configSearchSet = fieldConfigs;

    const keys = condition.key.split('.');

    for (let i = 0; i < keys.length; i++) {
        dependentConfigField = configSearchSet.find((f) => f.key === keys[i]);

        // If we cant find the config for a given key, return false
        if (!dependentConfigField) return false;

        // if the current key is parent key and the config doesn't have children, return false
        if (i < keys.length - 1 && isEmpty(dependentConfigField.children))
            return false;

        // if the current key is child(leaf node) key and the config doesn't have value, return false
        if (
            i === keys.length - 1 &&
            (dependentConfigField.value === undefined ||
                dependentConfigField.value === null)
        )
            return false;

        // update the config search set
        configSearchSet = dependentConfigField.children ?? [];
    }

    try {
        return parser
            .parse(
                `${addQuoteIfString(dependentConfigField?.value)} ${
                    condition.operator
                } ${addQuoteIfString(condition.value)}`
            )
            .evaluate();
    } catch (e) {
        return false;
    }
};

export const evaluateVisibilityCondition = (
    childField: PropertyField,
    allSectionsFields: PropertyField[] | null
): boolean => {
    const visibleConditions =
        childField.configuration?.conditions?.visibleConditions;
    const canView = childField.access_details?.View;

    if (!canView) return false;

    const matchedViewCondition = visibleConditions?.find((ec) =>
        ec.conditions?.every((c) =>
            evaluateCondition(allSectionsFields ?? [], c)
        )
    );

    return matchedViewCondition !== undefined
        ? matchedViewCondition.isVisible
        : true;
};

export const evaluateEditabilityCondition = (
    childField: PropertyField,
    allSectionsFields: PropertyField[] | null
): [boolean, string | undefined] => {
    const editableConditions =
        childField.configuration?.conditions?.editableConditions;
    const canEdit = childField.access_details?.Edit;

    if (!canEdit) return [false, ''];

    const matchedEditableCondition = editableConditions?.find((ec) =>
        ec.conditions?.every((c) =>
            evaluateCondition(allSectionsFields ?? [], c)
        )
    );

    return matchedEditableCondition !== undefined
        ? [
              matchedEditableCondition.isEditable,
              matchedEditableCondition.message
          ]
        : [true, ''];
};

export const evaluateRequiredCondition = (
    childField: PropertyField,
    allSectionsFields: PropertyField[] | null
): boolean => {
    const requiredConditions =
        childField.configuration?.conditions?.requiredConditions;
    const isRequired = childField.configuration?.isRequired;

    if (isRequired) return true;

    const matchedRequiredCondition = requiredConditions?.find((rc) =>
        rc.conditions?.every((c) =>
            evaluateCondition(allSectionsFields ?? [], c)
        )
    );

    return matchedRequiredCondition !== undefined
        ? matchedRequiredCondition.isRequired
        : false;
};

export const evaluateValueCondition = (
    childField: PropertyField,
    allSectionsFields: PropertyField[] | null
): any | null => {
    const valueConditions =
        childField.configuration?.conditions?.valueConditions;

    const matchedValueCondition = valueConditions?.find((vc) =>
        vc.conditions?.every((c) =>
            evaluateCondition(allSectionsFields ?? [], c)
        )
    );

    if (matchedValueCondition === undefined) return null;

    return matchedValueCondition.value;
};

export const evaluateMessagingCondition = (
    childField: PropertyField,
    allSectionsFields: PropertyField[] | null
): string | null => {
    const messagingConditions =
        childField.configuration?.conditions?.messagingConditions;

    const matchedMessagingCondition = messagingConditions?.find((vc) =>
        vc.conditions?.every((c) =>
            evaluateCondition(allSectionsFields ?? [], c)
        )
    );

    return matchedMessagingCondition?.message ?? null;
};
