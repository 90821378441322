import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    CREATE_PREVIEW,
    CREATE_PREVIEW_RESPONSE,
    DELETE_PREVIEW,
    RESET_PREVIEW_DATA
} from './action';
import { CreatePreviewResponsePayload } from './actionPayload';
import { initialPreviewState, PreviewState } from './state';
import { CreatePreviewPayload } from './model';
import { Action } from 'redux';

const reducer = (
    state: PreviewState = initialPreviewState,
    action: any
): PreviewState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialPreviewState,
    action: Action
): PreviewState => {
    switch (action.type) {
        case RESET_PREVIEW_DATA:
            return {
                ...state,
                previewData: { id: '' }
            };
        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state = initialPreviewState,
    action: ActionPayload<CreatePreviewPayload | string>
): PreviewState => {
    switch (action.type) {
        case CREATE_PREVIEW:
            return {
                ...state,
                previewLoader: LoadingStatus.INITIATED
            };

        case DELETE_PREVIEW:
            return {
                ...state,
                previewLoader: LoadingStatus.NONE,
                previewData: { id: '' }
            };
        default: {
            return state;
        }
    }
};

const actionWithPayloadOrErrorReducer = (
    state = initialPreviewState,
    action: ActionPayloadOrError<CreatePreviewResponsePayload>
): PreviewState => {
    switch (action.type) {
        case CREATE_PREVIEW_RESPONSE:
            if (
                action.error ||
                !(action.payload as CreatePreviewResponsePayload).data
            )
                return {
                    ...state,
                    previewLoader: LoadingStatus.FAILED
                };
            if (action.payload && action.payload.data)
                return {
                    ...state,
                    previewLoader: LoadingStatus.DONE,
                    previewData: { id: action.payload?.data.id as string }
                };
            return state;
        default: {
            return state;
        }
    }
};

export const selectPreviewLoader = (state: coreRootState): LoadingStatus => {
    return state.preview.previewLoader;
};

export const selectPreviewId = (state: coreRootState): string => {
    return state.preview.previewData.id;
};

export default reducer;
