import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from 'react';
import { DisableableBox } from '../../components/disableableBox';
import { Asset } from '../../redux/assets/model';
import { MediaConfigType } from '../../redux/element/model';
import { pxToRem } from '../../utils/stylesUtils';
import AssetSelectionDropDown from './assetSelectionDropDown';

const CustomTypography = styled(Typography)(() => ({
    marginTop: '3vh',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginBottom: '0px !important',
    color: '#171725'
}));

export const DescriptionText = styled(Typography)(() => ({
    fontSize: pxToRem(14),
    letterSpacing: 0,
    lineHeight: pxToRem(16),
    color: '#171725'
}));

const Container = styled('div')({
    marginTop: '3vh',
    marginBottom: '3vh'
});

export const InputBox = styled('input')({
    display: 'none'
});

export interface MediaInputFieldProps {
    assets: Asset[];
    name: string;
    description: string;
    isRequired: boolean;
    disabled: boolean;
    initialAssetId: string;
    onMediaAssetSelected: (assetId: string) => void;
    mediaConfigs: MediaConfigType;
}

const MediaAssetInputField = (props: MediaInputFieldProps) => {
    const {
        assets,
        isRequired,
        disabled,
        name,
        description,
        initialAssetId,
        mediaConfigs,
        onMediaAssetSelected
    } = props;

    const [selectedAssetId, setSelectedAssetId] = useState(initialAssetId);
    const { allowedFileTypes, fileUploadInfoMessage, maxFileSize } =
        mediaConfigs;

    const onAssetSelected = (assetId: string) => {
        onMediaAssetSelected(assetId);
        setSelectedAssetId(assetId);
    };

    useEffect(() => {
        if (isEmpty(initialAssetId)) {
            setSelectedAssetId('');
        }
    }, [initialAssetId]);

    return (
        <Container>
            <CustomTypography variant="subtitle2" gutterBottom>
                {`${name} ${isRequired ? '*' : ''}`}
            </CustomTypography>
            <DescriptionText variant="subtitle2" gutterBottom>
                <Markdown>{description}</Markdown>
            </DescriptionText>
            <DisableableBox disabled={disabled}>
                <AssetSelectionDropDown
                    name={name}
                    selectedAssetId={selectedAssetId}
                    onAssetSelected={onAssetSelected}
                    assets={assets}
                    allowedFileTypes={allowedFileTypes}
                    assetUploadInfoMessage={fileUploadInfoMessage}
                    isRequired={isRequired}
                    maxFileSize={maxFileSize}
                />
            </DisableableBox>
        </Container>
    );
};

export default MediaAssetInputField;
