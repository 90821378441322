import { FormikProps } from 'formik';
import { includes } from 'lodash';
import React from 'react';
import { WidgetSkeleton } from '../../redux/element/model';
import ElementContainerLeft from './elementContainerLeft';
import ElementContainerRight from './elementContainerRight';

interface ElementContainerProps {
    formik: FormikProps<WidgetSkeleton>;
    setIsEdited: (edited: boolean) => void;
    isEdited: boolean;
    setShowAddElement: (showAddElement: boolean) => void;
    setShowModal: (showModal: boolean) => void;
    setRedirectParams?: (params: { link: string; state: {} }) => void;
    previewImage: string;
}

function ElementContainers(props: ElementContainerProps) {
    const { formik, previewImage } = props;
    return (
        <>
            {formik.values.content_fields &&
                formik.values.content_fields.length !== 0 && (
                    <ElementContainerLeft {...props} />
                )}
            {includes(
                ['widget--sports', 'widget--weather'],
                formik?.values?.alias
            ) && (
                <ElementContainerRight
                    formik={formik}
                    previewImage={previewImage}
                />
            )}
        </>
    );
}

export default ElementContainers;
