import { PropertyField } from '../propertyValue/model';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { GetEvolve2FieldResult } from './actionPayload';
import { Evolve2ConfigRequestBody } from './model';

export const GET_EVOLVE2_FIELDS = 'GET_EVOLVE2_FIELDS';
export const GET_EVOLVE2_FIELDS_RESPONSE = 'GET_EVOLVE2_FIELDS_RESPONSE';
export const RESET_EVOLVE2_LOADER = 'RESET_EVOLVE2_LOADER';
//update redux not yet written
export const UPDATE_EVOLVE2_FIELDS = 'UPDATE_EVOLVE2_FIELDS';
export const UPDATE_EVOLVE2_FIELDS_RESULTS = 'UPDATE_EVOLVE2_FIELDS_RESULTS';
export const RESET_EVOLVE2_OPERATION_LOADER = 'RESET_EVOLVE2_OPERATION_LOADER';

export const getEvolve2Fields = (propertyId: string): ActionPayload<string> => {
    return {
        type: GET_EVOLVE2_FIELDS,
        payload: propertyId
    };
};

export const updateEvolve2Config = (
    data: Evolve2ConfigRequestBody
): ActionPayload<Evolve2ConfigRequestBody> => {
    return {
        type: UPDATE_EVOLVE2_FIELDS,
        payload: data
    };
};

export const updateEvolve2ConfigResults = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: UPDATE_EVOLVE2_FIELDS_RESULTS,
        message,
        error
    };
};

export const putEvolve2ConfigResults = (
    error: boolean,
    message: string,
    data?: PropertyField[]
): ActionPayloadOrError<GetEvolve2FieldResult> => {
    return {
        type: GET_EVOLVE2_FIELDS_RESPONSE,
        payload: { data },
        error,
        message
    };
};

export const resetEvolve2Loader = () => {
    return { type: RESET_EVOLVE2_LOADER };
};

export const resetEvolve2OperationLoader = () => {
    return { type: RESET_EVOLVE2_OPERATION_LOADER };
};
