import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiResponse } from '../../api/apiInterfaces';
import ApiResponseCode from '../../api/apiResponseCode';
import { ApiResponseState } from '../common/state';
import { WidgetSkeleton } from '../element/model';
import { ActionPayload } from '../types';
import {
    checkIfElementLiveResponse,
    CHECK_IF_ELEMENT_IS_LIVE,
    deleteElementResponse,
    DELETE_ELEMENT,
    elementsResponse,
    FECTH_ELEMENT_LIST_BY_TYPE,
    fetchAllWidgetTypesResponse,
    fetchElementListByTypeResponse,
    FETCH_ALL_WDIGET_TYPES,
    FETCH_ELEMENTS,
    FETCH_PAGE_LIST,
    pageListResponse,
    DUPLICATE_ELEMENT,
    duplicateElementResponse
} from './action';
import {
    DeleteElementPayload,
    DuplicateElementPayload,
    FetchElementListByType,
    IsElementLive,
    PropertyIdPayload
} from './actionPayload';
import {
    deleteElement,
    duplicateElementHelper,
    fetchAllElementsHelper,
    fetchAllWidgetTypes,
    fetchElementListByType,
    fetchPage,
    isElementLive
} from './helper';
import cmpTexts from '../../locale/en';
import { ElementListResponse, ElementsResponse } from './model';

function* elementsWorker(data: ActionPayload<PropertyIdPayload>) {
    try {
        const { propertyId } = data.payload;
        const elements: ApiResponse<ElementsResponse[]> = yield call(
            fetchAllElementsHelper,
            propertyId
        );

        yield put(elementsResponse(elements?.response_body));
    } catch (err) {
        console.log('Error in elementsWorker', err);
        const e: any = err;
        const errorMessage: any = e.message;
        yield put(elementsResponse(undefined, errorMessage));
    }
}

/**
 * ==============================================================================
 *                              Fetch Page List
 * ==============================================================================
 */
function* pageListWorker(data: ActionPayload<FetchElementListByType>) {
    try {
        const { propertyId, type } = data.payload;
        const pageList: ApiResponse<ElementListResponse[]> = yield call(
            fetchPage,
            propertyId,
            type
        );
        if (pageList.code === ApiResponseCode['200']) {
            yield put(pageListResponse(pageList.response_body));
        }
    } catch (err) {
        console.log('PageList error', err);
    }
}

function* fetchElementListByTypeWorker(
    data: ActionPayload<FetchElementListByType>
) {
    try {
        const { propertyId, type } = data.payload;
        const widgetList: ApiResponse<ElementListResponse[]> = yield call(
            fetchElementListByType,
            propertyId,
            type
        );
        if (widgetList.code === ApiResponseCode['200']) {
            yield put(fetchElementListByTypeResponse(widgetList.response_body));
        }
        console.log('widgetList Response', widgetList);
    } catch (err) {
        console.log('widgetList error', err);
    }
}

function* fetchAllWidgetTypesWorker(data: ActionPayload<PropertyIdPayload>) {
    try {
        const { propertyId } = data.payload;
        const widgetList: ApiResponse<WidgetSkeleton[]> = yield call(
            fetchAllWidgetTypes,
            propertyId
        );

        yield put(fetchAllWidgetTypesResponse(false, widgetList.response_body));
    } catch (err) {
        yield put(fetchAllWidgetTypesResponse(true, undefined));
    }
}

function* deleteElementWorker(data: ActionPayload<DeleteElementPayload>) {
    try {
        const { propertyId, elementId, elementType } = data.payload;
        yield call(deleteElement, propertyId, elementId, elementType);

        yield put(
            deleteElementResponse(
                true,
                cmpTexts.deleteElementMessage.successDeleteMeassge
            )
        );
    } catch (err) {
        console.log('Error in deleteElementWorker ', err);
        yield put(
            deleteElementResponse(
                true,
                cmpTexts.deleteElementMessage.errorDeleteMessage
            )
        );
    }
}

function* duplicateElementWorker(data: ActionPayload<DuplicateElementPayload>) {
    try {
        const { propertyId, oldElementId, newElementName } = data.payload;
        yield call(
            duplicateElementHelper,
            propertyId,
            oldElementId,
            newElementName
        );

        yield put(
            duplicateElementResponse(
                true,
                `${data.payload.elementType} Successfully Duplicated`
            )
        );
    } catch (err) {
        const e: any = err;
        yield put(duplicateElementResponse(false, e.message));
    }
}

function* checkElementLiveWorker(data: ActionPayload<IsElementLive>) {
    try {
        const { elementId, elementType, propertyId } = data.payload;
        const checkElementLiveRes: ApiResponse<{
            is_attached_to_page: boolean;
            is_part_of_live_page: boolean;
        }> = yield call(isElementLive, elementId, elementType, propertyId);

        yield put(
            checkIfElementLiveResponse(
                ApiResponseState.SUCCESS,
                checkElementLiveRes.response_body
            )
        );
    } catch (err) {
        console.log('Error in checkElementLiveWorker', err);
        const e: any = err;
        const message = e.message;
        yield put(
            checkIfElementLiveResponse(
                ApiResponseState.FAILED,
                undefined,
                message ?? 'Error while fetcing live details'
            )
        );
    }
}

export function* watchElementsList() {
    yield all([
        takeLatest(FETCH_ELEMENTS, elementsWorker),
        takeLatest(FETCH_PAGE_LIST, pageListWorker),
        takeLatest(FECTH_ELEMENT_LIST_BY_TYPE, fetchElementListByTypeWorker),
        takeLatest(DELETE_ELEMENT, deleteElementWorker),
        takeLatest(CHECK_IF_ELEMENT_IS_LIVE, checkElementLiveWorker),
        takeLatest(FETCH_ALL_WDIGET_TYPES, fetchAllWidgetTypesWorker),
        takeLatest(DUPLICATE_ELEMENT, duplicateElementWorker)
    ]);
}
