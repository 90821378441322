import React from 'react';
import CustomTextField from '../../../../components/textField';
import { FormHelperText } from '@mui/material';
interface Props {
    id: string;
    maxLength: number;
    value: React.ReactNode | string | number;
    label: string;
    handleFieldChange: (e: any) => void;
    error: boolean;
    helperText: string;
    type: string;
    maxNumber?: number;
    minNumber?: number;
    description?: string;
    required?: boolean;
}

const PropertySectionTextField = (props: Props) => {
    const {
        id,
        maxLength,
        label,
        value,
        handleFieldChange,
        error,
        helperText,
        type = 'text',
        description,
        required
    } = props;

    return (
        <>
            <CustomTextField
                required={required}
                id={id}
                maxLength={maxLength}
                label={label}
                variant="outlined"
                value={value}
                onChange={handleFieldChange}
                error={error}
                helperText={helperText}
                type={type}
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
            <FormHelperText
                sx={{
                    '&.MuiFormHelperText-root': {
                        float: 'right'
                    }
                }}
            >
                {description}
            </FormHelperText>
        </>
    );
};

export default PropertySectionTextField;
