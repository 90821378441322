import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';
import { PropertyFieldValueData } from '../propertyValue/model';
import { CreateUpdateZone } from './actionPayload';

export function fetchAllZones(propertyId: string) {
    return axios.get(API_URL.zonesBaseUrl + `${propertyId}/zones/fieldValues`);
}

export function createZone(data: CreateUpdateZone, propertyId: string) {
    return axios.post(
        API_URL.zonesBaseUrl + `${propertyId}/zones/create`,
        data
    );
}

export function updateZone(
    data: CreateUpdateZone,
    propertyId: string,
    zoneId: string
) {
    return axios.post(
        API_URL.zonesBaseUrl + `${propertyId}/zones/${zoneId}/update`,
        data
    );
}

export function updateZoneFields(
    data: PropertyFieldValueData[],
    propertyId: string,
    zoneId: string
) {
    console.log(data, propertyId, zoneId);
    return axios.post(
        API_URL.zonesBaseUrl + `${propertyId}/zones/${zoneId}/fields`,
        { fields: data }
    );
}

export function deleteZone(propertyId: string, zoneId: string) {
    return axios.delete(API_URL.zonesBaseUrl + `${propertyId}/zones/${zoneId}`);
}

export function fetchAllRooms(propertyId: string) {
    return axios.get(API_URL.zonesBaseUrl + `${propertyId}/rooms`);
}
