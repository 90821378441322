import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import cmpTexts from '../../../../locale/en';
import { FAQ, PropertyField } from '../../../../redux/propertyValue/model';
import { pxToRem } from '../../../../utils/stylesUtils';
import Faq from '../../faq/faqSection';

interface Props {
    setShowSaveBar: (showSaveBar: boolean) => void;
    setHasError: (setHasError: boolean) => void;
    fieldData: PropertyField;
    showSaveBar: boolean;
    onFieldUpdate: (value: any) => void;
}

const HeadingStyleTypography = styled(Typography)(() => ({
    fontWeight: 'bold',
    fontSize: pxToRem(18),
    color: 'black'
}));

function PropertySectionFAQ({
    onFieldUpdate,
    setShowSaveBar,
    showSaveBar,
    setHasError,
    fieldData
}: Props) {
    const onFaqUpdated = (values: FAQ) => {
        onFieldUpdate(values);
    };

    const emptyFaq: FAQ = {
        faq: []
    };

    return (
        <Box>
            <HeadingStyleTypography variant="h6" gutterBottom>
                {fieldData?.name ?? cmpTexts.faqSection.heading}
            </HeadingStyleTypography>

            <Faq
                showDescription={fieldData.configuration?.showDescription}
                faqData={(fieldData.value as FAQ) ?? emptyFaq}
                setShowSaveBar={setShowSaveBar}
                showSaveBar={showSaveBar}
                description={
                    fieldData?.description ?? cmpTexts.faqSection.description
                }
                setHasError={setHasError}
                maxAllowedFAQs={fieldData?.configuration?.maxAllowedFAQs}
                onFaqUpdated={onFaqUpdated}
                deleteFAQModalDescription={
                    cmpTexts.faqSection.deleteModalDescriptionProperty
                }
                deleteFAQModalHeader={cmpTexts.faqSection.modalHeader}
                viewOnly={!fieldData.access_details?.Edit}
            />
        </Box>
    );
}

export default PropertySectionFAQ;
