import { useState } from 'react';
import {
    PropertyFieldValue,
    PropertyFieldValueData
} from '../../../redux/propertyValue/model';

const usePropertyFieldUpdatePayloadCreator = () => {
    const [propertyFieldsUpdatePayload, setPropertyFieldsUpdatePayload] =
        useState<PropertyFieldValueData[]>([]);
    const [
        manuallyUpdatedPropertyFieldIds,
        setManuallyUpdatedPropertyFieldIds
    ] = useState<string[]>([]);

    const updatePropertyFieldPayload = (
        property_field_id: string,
        values: PropertyFieldValue,
        isManualUpdate: boolean
    ) => {
        if (isManualUpdate)
            setManuallyUpdatedPropertyFieldIds((pfids) => [
                ...pfids,
                property_field_id
            ]);

        setPropertyFieldsUpdatePayload((preFieldsAndValues) => {
            let fieldsAndValues = [...preFieldsAndValues];

            const fieldToUpdateIndex = fieldsAndValues.findIndex(
                (ele) => ele.property_field_id === property_field_id
            );

            if (fieldToUpdateIndex > -1) {
                fieldsAndValues[fieldToUpdateIndex].values = values;
            } else {
                fieldsAndValues = [
                    ...fieldsAndValues,
                    { property_field_id, values }
                ];
            }

            return fieldsAndValues;
        });
    };

    const clearPayload = () => {
        setPropertyFieldsUpdatePayload([]);
        setManuallyUpdatedPropertyFieldIds([]);
    };

    return {
        payload: propertyFieldsUpdatePayload,
        manuallyUpdatedFieldIds: manuallyUpdatedPropertyFieldIds,
        updatePropertyFieldPayload,
        clearPayload
    };
};

export default usePropertyFieldUpdatePayloadCreator;
