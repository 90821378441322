export interface Asset {
    id: string;
    name: string;
    type: string;
    url: string;
    owner_type: string;
    owner_id: string;
    size: number;
    associations: {
        association_type: string;
        association_id: string;
        association_group: {
            id: string;
            type: string;
            name: string;
            resource_type: string;
        };
    }[];
    file_extension: string;
    created_at: number;
}

export enum AssetHeaders {
    URL = 'url',
    ID = 'id',
    TYPE = 'type',
    NAME = 'name',
    OWNER_TYPE = 'owner_type',
    OWNER_ID = 'owner_id',
    SIZE = 'size',
    ASSOCIATIONS = 'associations',
    FILE_EXTENSION = 'file_extension'
}

export enum OwnerType {
    PROPERTY = 'PROPERTY'
}
