import { emojiRegex } from './regexUtil';
import { v4 as uuidv4 } from 'uuid';

/**
 * @param str string to be converted to pascal case
 * @returns string in pascal case
 */
export function pascalCase(str: string) {
    return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
    });
}

export function formatString(str: string, ...args: string[]): string {
    let formatted = str;
    for (let i = 0; i < arguments.length; i++) {
        const regexp = new RegExp('\\{' + i + '\\}', 'gi');
        formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
}

export function removeEmojis(val: string): string {
    return val.replace(emojiRegex, '');
}

export function generateUUID(): string {
    return uuidv4();
}
