import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    CreatePreviewResponsePayload,
    DeletePreviewResponsePayload
} from './actionPayload';
import { CreatePreviewPayload, CreatePreviewResponse } from './model';

export const CREATE_PREVIEW = 'CREATE_PREVIEW';
export const CREATE_PREVIEW_RESPONSE = 'CREATE_PREVIEW_RESPONSE';
export const DELETE_PREVIEW = 'DELETE_PREVIEW';
export const RESET_PREVIEW_DATA = 'RESET_PREVIEW_DATA';

export const createPreview = (
    payload: CreatePreviewPayload
): ActionPayload<CreatePreviewPayload> => {
    return {
        type: CREATE_PREVIEW,
        payload
    };
};

export const resetPreviewData = (): Action => {
    return {
        type: RESET_PREVIEW_DATA
    };
};

export const deletePreview = (
    previewId: string,
    propertyId: string
): ActionPayload<DeletePreviewResponsePayload> => {
    return {
        type: DELETE_PREVIEW,
        payload: { previewId, propertyId }
    };
};

export const putCreatePreviewResponse = (
    error: boolean,
    data?: CreatePreviewResponse
): ActionPayloadOrError<CreatePreviewResponsePayload> => {
    return {
        type: CREATE_PREVIEW_RESPONSE,
        payload: {
            data
        },
        error
    };
};
