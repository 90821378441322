import { Box, FormHelperText, SxProps, TextFieldProps } from '@mui/material';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';
import TextField from './textField';

interface OutlinedTextFieldProps {
    labelStyle?: SxProps;
    maxLength?: number;
    rowHeight?: number;
    required?: boolean;
    mode?: 'dark' | 'light';
    helperTextProps?: {
        content: string | React.ReactNode;
        sx?: SxProps;
    };
}

const CMPOutlinedTextField = (
    props: TextFieldProps & OutlinedTextFieldProps
) => {
    const {
        label,
        name,
        error,
        placeholder,
        sx,
        fullWidth = false,
        labelStyle = {},
        rows = 1,
        InputProps,
        rowHeight = 56,
        disabled,
        required = false,
        mode = 'dark',
        helperTextProps = { content: '', sx: {} },
        ...rest
    } = props;

    let border;
    if (mode === 'dark') {
        border = `${pxToRem(1)} solid rgb(173,156,162)`;
        if (error) {
            border = `${pxToRem(1)} solid #931621`;
        } else if (disabled) {
            border = `${pxToRem(1)} solid rgba(255, 255, 255, 0.50)`;
        }
    } else {
        border = `${pxToRem(1)} solid #000010`;
        if (error) {
            border = `${pxToRem(1.5)} solid #931621`;
        } else if (disabled) {
            border = `${pxToRem(1)} solid #525261`;
        }
    }

    const styles = {
        borderRadius: pxToRem(8),
        border,
        background: mode === 'dark' ? 'rgba(0, 0, 16, 0.5)' : '#fff',
        label: {
            color: mode === 'dark' ? '#fff' : '#525261',
            fontWeight: 500,
            lineHeight: pxToRem(58),
            '&.MuiInputLabel-outlined': {
                ' &.Mui-disabled': {
                    color:
                        mode === 'dark'
                            ? 'rgba(255, 255, 255, 0.50)'
                            : '#525261'
                },
                '&.Mui-focused': {
                    color: mode === 'dark' ? '#fff' : '#525261'
                },
                '&.Mui-error': {
                    color: mode === 'dark' ? '#fff' : '#525261'
                }
            },
            '&::after': {
                content: required ? '"*"' : '""'
            },
            ...labelStyle
        },
        input: {
            lineHeight: pxToRem(24),
            height: pxToRem(32),
            paddingBottom: pxToRem(5)
        },
        textarea: {
            paddingTop: pxToRem(18),
            lineHeight: pxToRem(24),
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            scrollbarWidth: 'none'
        },
        fieldset: {
            border: '0 !important'
        },
        height: pxToRem((rows as number) * rowHeight),
        ...sx
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TextField
                label={label}
                name={name}
                disabled={disabled}
                sx={styles}
                multiline={(rows as number) > 1}
                rows={rows}
                placeholder={placeholder}
                fullWidth={fullWidth}
                InputLabelProps={{ shrink: true }}
                error={error}
                InputProps={{
                    style: {
                        color: '#fff',
                        fontSize: pxToRem(16),
                        fontWeight: 700,
                        ...InputProps?.style
                    },
                    ...InputProps
                }}
                {...rest}
            />

            <FormHelperText
                error={error}
                sx={{ margin: 0, ...helperTextProps.sx }}
            >
                {helperTextProps.content}
            </FormHelperText>
        </Box>
    );
};

export default CMPOutlinedTextField;
