import { LoadingStatus } from '../common/state';
import { Operations } from './model';

export interface UserOperationType {
    operations: Operations;
    operationLoader: LoadingStatus;
    isInitialState: boolean;
    // NOTE: we are not resetting loader to NONE until we logout. if its status is DONE only then we can access pages, if FAILED we go to access denied. INITIATED WILL HAVE CIRCULAR LOADER
}
export const initialUserOperationState: UserOperationType = {
    operationLoader: LoadingStatus.NONE,
    operations: {
        users: {
            is_module_accessible: false,
            user: {
                SuperAdminResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                },
                SuperUserResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                },
                AdminIntegratorResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                },
                IntegratorResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                },
                PropertyManagerResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                },
                ConsultantResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                }
            }
        },
        properties: {
            is_module_accessible: false,
            property: {
                PropertyResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false,
                    MoveProperty: false,
                    DuplicateProperty: false
                },
                Evolve2Resource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                },
                ZoneResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false,
                    AssociateRooms: false
                }
            },
            property_group: {
                PropertyGroupResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                }
            }
        },
        templates: {
            is_module_accessible: false,
            elements: {
                ElementResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                }
            },
            asset: {
                AssetResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                }
            }
        },
        organizations: {
            is_module_accessible: false,
            organisation: {
                OrganizationResource: {
                    View: false,
                    Edit: false,
                    Add: false,
                    Delete: false
                }
            }
        },
        platformAccess: {
            is_module_accessible: false,
            platform: {
                GeneralSectionResource: {
                    Add: false,
                    Delete: false,
                    Edit: false,
                    View: false
                }
            }
        },
        activityLogs: {
            activity: {
                ActivityLogsResource: {
                    Add: false,
                    Delete: false,
                    Edit: false,
                    View: false
                }
            },
            is_module_accessible: false
        }
    },
    isInitialState: true
};
