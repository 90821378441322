import { LoadingStatus } from '../common/state';
import { CreatePreviewResponse } from './model';

export interface PreviewState {
    previewLoader: LoadingStatus;
    error: string;
    previewData: CreatePreviewResponse;
}

export const initialPreviewState: PreviewState = {
    previewLoader: LoadingStatus.NONE,
    error: '',
    previewData: { id: '' }
};
