export interface CreateAssetsRequest {
    owner_type: string;
    owner_id: string;
    files: { file: File; id: string }[];
    onUploadProgress?: (
        percent: number,
        id: string,
        status: AssetStatus
    ) => void;
    cancelUploadController?: AbortController;
}

export interface EditAsset {
    asset_id: string;
    updated_Name: string;
    owner_id: string;
}

export interface DeleteAsset {
    id: string;
    propertyId: string;
}

export enum AssetStatus {
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    UPLOADING = 'UPLOADING',
    READY = 'READY'
}
