import axios from '../../api/axios';

import { API_URL } from '../../api/apiConstants';

export function fetchUserOperations(role: string) {
    const axiosConfig = {
        headers: {
            role
        },
        withCredentials: false
    };
    return axios.get(API_URL.userOperation, axiosConfig);
}
