import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionPayload } from '../types';
import { ApiResponse } from './../../api/apiInterfaces';
import {
    createZoneResponse,
    CREATE_ZONE,
    deleteZoneResponse,
    DELETE_ZONE,
    editZoneResponse,
    EDIT_ZONE,
    getAllRoomsResponse,
    getAllZones,
    getZonesResponse,
    GET_ALL_ROOMS,
    GET_ALL_ZONES,
    updateZoneFieldsResponse,
    UPDATE_ZONE_FIELDS
} from './action';
import {
    CreateUpdateZone,
    DeleteZone,
    UpdateZoneFieldsPayload
} from './actionPayload';
import {
    createZone,
    deleteZone,
    fetchAllRooms,
    fetchAllZones,
    updateZone,
    updateZoneFields
} from './helper';
import { Room, ZonesFieldValues } from './model';

function* getAllZonesWorker(data: ActionPayload<string>) {
    try {
        const res: ApiResponse<ZonesFieldValues> = yield call(
            fetchAllZones,
            data.payload
        );
        yield put(getZonesResponse(false, '', res.response_body));
    } catch (error: any) {
        yield put(getZonesResponse(true, error.message));
    }
}

function* createZoneWorker(data: ActionPayload<CreateUpdateZone>) {
    try {
        const { propertyId } = data.payload;
        const res: ApiResponse<string> = yield call(
            createZone,
            data.payload,
            propertyId
        );
        yield put(createZoneResponse(false, res.message));
        yield put(getAllZones(propertyId));
    } catch (error: any) {
        yield put(createZoneResponse(true, error.message));
    }
}

function* updateZoneWorker(data: ActionPayload<CreateUpdateZone>) {
    try {
        const { propertyId, zoneId } = data.payload;

        const res: ApiResponse<string> = yield call(
            updateZone,
            data.payload,
            propertyId,
            zoneId as string
        );
        yield put(editZoneResponse(false, res.message));
        yield put(getAllZones(propertyId));
    } catch (error: any) {
        yield put(editZoneResponse(true, error.message));
    }
}

function* updateZoneFieldsWorker(data: ActionPayload<UpdateZoneFieldsPayload>) {
    try {
        const fields = data.payload.fields;
        const zoneIds = Object.keys(fields);

        const zoneFieldsApiPromise = zoneIds.map((zId) =>
            call(updateZoneFields, fields[zId], data.payload.property_id, zId)
        );

        yield all(zoneFieldsApiPromise);
        yield put(
            updateZoneFieldsResponse(false, 'Zone Config Updated Successfully')
        );

        yield put(getAllZones(data.payload.property_id));
    } catch (error: any) {
        yield put(updateZoneFieldsResponse(true, error.message));
    }
}

function* deleteZoneWorker(data: ActionPayload<DeleteZone>) {
    try {
        const { propertyId, zoneId } = data.payload;
        const res: ApiResponse<string> = yield call(
            deleteZone,
            propertyId,
            zoneId
        );
        yield put(deleteZoneResponse(false, res.message));
        yield put(getAllZones(data.payload.propertyId));
    } catch (error: any) {
        yield put(deleteZoneResponse(true, error.message));
    }
}
// Get All Rooms
function* getAllRoomsWorker(data: ActionPayload<string>) {
    try {
        const res: ApiResponse<{ rooms: Room[] }> = yield call(
            fetchAllRooms,
            data.payload
        );
        yield put(getAllRoomsResponse(false, '', res.response_body.rooms));
    } catch (error: any) {
        yield put(getAllRoomsResponse(true, error.message));
    }
}

export function* watchZoneSagas() {
    yield all([
        takeLatest(GET_ALL_ZONES, getAllZonesWorker),
        takeLatest(GET_ALL_ROOMS, getAllRoomsWorker),
        takeLatest(CREATE_ZONE, createZoneWorker),
        takeLatest(EDIT_ZONE, updateZoneWorker),
        takeLatest(DELETE_ZONE, deleteZoneWorker),
        takeLatest(UPDATE_ZONE_FIELDS, updateZoneFieldsWorker)
    ]);
}
