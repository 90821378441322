import { LoadingStatus } from '../common/state';
import { UserRole } from '../users/model';
import {
    Action,
    ActivityLogFilters,
    emptyActivityLogFilters,
    Result,
    Comment,
    ResourceGroupType
} from './model';

export interface UserActivityState {
    loadingStatus: LoadingStatus;
    activityLogs: ActivityLog[];
    filters: ActivityLogFilters;
    error: string;
    message: string;
    operationLoadingStatus: LoadingStatus;
}

export interface ActivityLog {
    id: string;
    user_name: string;
    user_id: string;
    resource_name: string;
    time: number;
    action: Action;
    result: Result;
    description: string;
    comments: Comment[] | null;
    undo_available: boolean;
    property_id: string;
    property_group_id: string;
    organization_id: string;
    user_role: UserRole;
    resource_type: string;
    resource_group_type: ResourceGroupType;
    property_name: string;
    organization_name: string;
    property_group_name: string;
}

export const initialUserActivityState: UserActivityState = {
    loadingStatus: LoadingStatus.NONE,
    operationLoadingStatus: LoadingStatus.NONE,
    activityLogs: [],
    filters: emptyActivityLogFilters,
    error: '',
    message: ''
};
