import { Box, FormHelperText, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { ColorPicker, ColorValue } from 'mui-color';
import React from 'react';
import { DisableableBox } from '../../../components/disableableBox';
import CustomTextField from '../../../components/textField';
import { PropertyField } from '../../../redux/propertyValue/model';
import { pxToRem } from '../../../utils/stylesUtils';
import PropertySectionTooltip from './components/propertySectionTooltips';

interface Props {
    theme: PropertyField;
    onFieldUpdate: (index: number, value: any) => void;
    getPropertyFieldError: (index: number) => string;
}

const ColorRowDiv = styled('div')(() => ({
    display: 'flex',
    flex: 1
}));

const SectionHeader = styled(Typography)(() => ({
    color: '#171725',
    fontSize: pxToRem(22),
    fontWeight: 700,
    lineHeight: 'normal',
    marginRight: pxToRem(12)
}));

const ColorPickerComponent = styled(Box)(() => ({
    '& .MuiButtonBase-root.MuiButton-root': {
        width: pxToRem(200),
        marginRight: pxToRem(12),
        boxShadow: 'none',
        div: {
            minWidth: 'max-content',
            width: pxToRem(200),
            height: pxToRem(55)
        }
    },

    display: 'flex',
    alignItems: 'center'
}));

function PropertyThemeSection({
    theme,
    onFieldUpdate,
    getPropertyFieldError
}: Props) {
    const handleColorChange = (values: ColorValue, index: number) => {
        if (!Object(values)?.error) {
            onFieldUpdate(
                index,
                Object(values).css.backgroundColor.toUpperCase()
            );
        }
    };

    const handleTextChange = (value: string, index: number) => {
        onFieldUpdate(index, value);
    };

    return (
        <>
            <ColorRowDiv>
                {theme.children && theme?.children!.length > 0
                    ? theme?.children?.map((pf: PropertyField, i: number) => {
                          const canView = pf.access_details?.View;
                          const canEdit = pf.access_details?.Edit;
                          if (!canView) return null;

                          const error = getPropertyFieldError(i);

                          return (
                              <DisableableBox
                                  key={pf.id}
                                  disabled={!canEdit}
                                  changeOpacity={false}
                                  sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'end'
                                  }}
                              >
                                  <ColorPickerComponent>
                                      <SectionHeader>
                                          <PropertySectionTooltip
                                              tooltipMessage={
                                                  pf?.configuration?.toolTip
                                              }
                                              label={pf?.name}
                                              tooltipPresent
                                          />
                                      </SectionHeader>
                                      <ColorPicker
                                          value={String(pf.value)}
                                          onChange={(e) =>
                                              handleColorChange(e, i)
                                          }
                                          hideTextfield
                                          disableAlpha
                                      />
                                      <CustomTextField
                                          variant="outlined"
                                          InputProps={{
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      #
                                                  </InputAdornment>
                                              )
                                          }}
                                          value={
                                              (pf?.value as string)?.split(
                                                  '#'
                                              )[1]
                                          }
                                          maxLength={6}
                                          onChange={(e) =>
                                              handleTextChange(
                                                  `#${e.target.value}`.toUpperCase(),
                                                  i
                                              )
                                          }
                                          disabled={!canEdit}
                                      />
                                  </ColorPickerComponent>
                                  {Boolean(error) && (
                                      <FormHelperText
                                          sx={{ width: 'fit-content' }}
                                          error={Boolean(error)}
                                          required
                                      >
                                          {error}
                                      </FormHelperText>
                                  )}
                              </DisableableBox>
                          );
                      })
                    : null}
            </ColorRowDiv>
        </>
    );
}

export default PropertyThemeSection;
