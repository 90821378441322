import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAssets } from '../redux/assets/reducer';
import {
    ContentField,
    ContentFieldsKey,
    WidgetAlias
} from '../redux/element/model';
import { PreviewImageProps } from '../screens/elements/elementsInterface';
import { StylesDictionary } from '../utils/stylesUtils';

const styles: StylesDictionary = {
    widgetIconNotification: {
        marginTop: 20
    },
    textDecoration: {
        textDecoration: 'none'
    }
};

const PreviewImageSection = (props: PreviewImageProps) => {
    const { formik, widgetType } = props;
    const [backgroundUrl, setBackgroundUrl] = useState('');
    const [iconUrl, setIconUrl] = useState('');
    const heroImage = formik?.values?.content_fields?.find(
        (j: ContentField) =>
            j.key === ContentFieldsKey.AdHeroImage ||
            j.key === ContentFieldsKey.HeroImage
    );

    const icon = formik?.values?.content_fields?.find(
        (j: ContentField) => j.key === ContentFieldsKey.Icon
    );

    const posterImage = formik?.values?.content_fields?.find(
        (j: ContentField) => j.key === ContentFieldsKey.PosterImage
    );

    const headLine = formik?.values?.content_fields?.find(
        (j: ContentField) => j.key === ContentFieldsKey.Headline
    );

    const subHeading = formik?.values?.content_fields?.find(
        (j: ContentField) => j.key === ContentFieldsKey.SubHeading
    );

    const bodyText = formik?.values?.content_fields?.find(
        (j: ContentField) => j.key === ContentFieldsKey.BodyText
    );

    const assets = useSelector(selectAssets);

    useEffect(() => {
        assets.forEach((asset) => {
            if (asset.id === heroImage?.values) {
                setBackgroundUrl(get(asset, 'url', ''));
            } else if (asset.id === icon?.values) {
                setIconUrl(get(asset, 'url', ''));
            } else if (asset.id === posterImage?.values) {
                setBackgroundUrl(get(asset, 'url', ''));
            }
            if (isEmpty(heroImage?.values) && isEmpty(posterImage?.values)) {
                setBackgroundUrl('');
            }
            if (isEmpty(icon?.values)) {
                setIconUrl('');
            }
        });
    }, [heroImage, icon, assets, posterImage]);

    return widgetType !== WidgetAlias.WidgetNotification ? (
        <GridContainer
            style={{
                backgroundImage: `url(${backgroundUrl})`,
                display: 'flex',
                height: 241,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <MainConatiner>
                <>
                    <WidgetInputHeadline>
                        {headLine?.values}
                    </WidgetInputHeadline>

                    <BodyTextContainer>
                        <WidgetIconDiv>
                            <WidgetIconImage src={iconUrl} />
                        </WidgetIconDiv>

                        <TextDiv>
                            {subHeading?.values}
                            {'\n'}
                            {bodyText?.values}
                        </TextDiv>
                    </BodyTextContainer>

                    <ButtonConatiner>
                        <LearnMoreButton>Learn More</LearnMoreButton>
                    </ButtonConatiner>
                </>
            </MainConatiner>
        </GridContainer>
    ) : (
        <GridContainer>
            <WidgetNotificationIcon>
                <WidgetIconImage
                    src={icon?.values + ''}
                    style={styles.widgetIconNotification}
                />
            </WidgetNotificationIcon>
            <HeadingDdiv>
                <WidgetNotificationInputHeadline>
                    {headLine?.values}
                </WidgetNotificationInputHeadline>
                <BodyTextdiv>{bodyText?.values}</BodyTextdiv>
            </HeadingDdiv>
            <ButtonConatinerDiv>
                {icon?.values && (
                    <LearnMoreNotificationButton>
                        More Info
                    </LearnMoreNotificationButton>
                )}
            </ButtonConatinerDiv>
        </GridContainer>
    );
};

export default PreviewImageSection;

const BodyTextContainer = styled('div')(() => ({
    display: 'flex',
    marginBottom: 30,
    maxHeight: 100,
    overflow: 'hidden',
    marginTop: 5
}));

const WidgetIconDiv = styled('div')(() => ({
    flex: 1,
    marginLeft: 47
}));

const TextDiv = styled('div')(() => ({
    flex: 10,
    fontSize: '0.8rem',
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    paddingLeft: 5,
    color: '#ffffff'
}));

const ButtonConatiner = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    marginBottom: 30
}));

const WidgetNotificationIcon = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const HeadingDdiv = styled('div')(() => ({
    width: '50%',
    marginLeft: '25%'
}));

const BodyTextdiv = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
}));

const ButtonConatinerDiv = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const MainConatiner = styled('div')(() => ({
    color: '#000000',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'Right'
}));

const GridContainer = styled('div')(() => ({
    width: '100%',
    minWidth: 480,
    backgroundColor: '#f2f2f2',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: '#cce6ff',
    borderRadius: 3
}));

const WidgetInputHeadline = styled('div')(() => ({
    color: '#ffffff',
    marginLeft: 45,
    marginTop: 30,
    fontSize: '1.3rem',
    fontWeight: 'bold'
}));

const WidgetNotificationInputHeadline = styled('div')(() => ({
    textAlign: 'center',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const WidgetIconImage = styled('img')(() => ({
    width: 30,
    marginTop: 4
}));

const LearnMoreButton = styled(Button)(() => ({
    width: 110,
    fontSize: 10,
    fontWeight: 'bold',
    marginLeft: 45,
    backgroundColor: '#d9d9d9',
    borderColor: '#000000',
    borderRadius: 20,
    color: '#ffffff'
}));

const LearnMoreNotificationButton = styled(Button)(() => ({
    width: 110,
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 15,
    marginBottom: 40,
    backgroundColor: '#d9d9d9',
    borderColor: '#000000',
    borderRadius: 20,
    color: '#000000'
}));
