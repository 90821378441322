// @ts-nocheck
import { createTheme } from '@mui/material';

export const theme = createTheme({
    typography: {
        fontFamily: '"Proxima Nova", Helvetica, sans-serif'
    },
    palette: {
        primary: {
            //main: 'rgb(255,163,0)'
            main: '#F01446'
        },
        secondary: {
            main: '#171725',
            light: '#f5f5f6'
        },
        companyBlack: {
            main: '#171726'
        }
    }
});
