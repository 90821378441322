import { IconButton, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { pxToRem, StylesDictionary } from '../../utils/stylesUtils';
import { RailHeadingConatinerProps } from './elementsInterface';
import { ContentSmall, DeleteIconStyle } from './pageGenerator';

const styles: StylesDictionary = {
    customLinkStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#0098D1',
        textDecorationColor: '#0098D1',
        marginLeft: 0.8,
        marginTop: 2
    }
};

export const LinkItemList = styled('div')(() => ({
    display: 'flex',
    color: '#0092B8',
    fontSize: `${pxToRem(16)}`,
    fontWeight: 'bold',
    marginLeft: '0.6vw',
    marginTop: 6
}));

export const TitleRail = styled('div')(() => ({
    flex: 2
}));

export const WidgetTypeName = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
}));

export const DeleteButtonContainer = styled('div')(() => ({
    display: 'flex'
}));

export const DeleteIconDiv = styled('div')(() => ({
    marginTop: 8
}));

const RailHeadingConatiner = (props: RailHeadingConatinerProps) => {
    const {
        itemDataName,
        itemDataId,
        itemDataWidgetTypeName,
        showRemove,
        handleElementRedirectClick,
        handleRemoveItem
    } = props;

    return (
        <LinkItemList>
            <TitleRail>
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleElementRedirectClick}
                    underline="none"
                    sx={styles.customLinkStyle}
                >
                    {itemDataName}
                </Link>
            </TitleRail>
            {showRemove && (
                <DeleteButtonContainer>
                    <WidgetTypeName>
                        <ContentSmall variant="subtitle2" gutterBottom>
                            {itemDataWidgetTypeName}
                        </ContentSmall>
                    </WidgetTypeName>
                    <DeleteIconDiv>
                        <IconButton
                            aria-label="delete"
                            onClick={() => handleRemoveItem(itemDataId)}
                        >
                            <DeleteIconStyle />
                        </IconButton>
                    </DeleteIconDiv>
                </DeleteButtonContainer>
            )}
        </LinkItemList>
    );
};

export default RailHeadingConatiner;
