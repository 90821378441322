import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    ForgotPasswordFailed,
    ForgotPasswordSubmit,
    ForgotPasswordSubmitResponse,
    LoginFailed,
    SaveNewElement,
    SetNewPassword,
    UserAuthenticatedStatus,
    UserLogoutResponse
} from './actionPayload';
import {
    BreadCrumb,
    ForgotPasswordStatus,
    ForgotPasswordSubmitType,
    ForgotPasswordType,
    LoginResponse,
    LoginType,
    LogoutType,
    UserLoginPayloadType
} from './model';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_FORGOT_PASSWORD = 'SET_FORGOT_PASSWORD';
export const FORGOT_PASSWORD_RESPONSE_SUCCESS =
    'FORGOT_PASSWORD_RESPONSE_SUCCESS';
export const FORGOT_PASSWORD_RESPONSE_FAILURE =
    'FORGOT_PASSWORD_RESPONSE_FAILURE';
export const FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD_SUBMIT';
export const FORGOT_PASSWORD_SUBMIT_RESPONSE =
    'FORGOT_PASSWORD_SUBMIT_RESPONSE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_RESPONSE = 'USER_LOGOUT_RESPONSE';
export const PURGE_LOGIN_DATA = 'PURGE_LOGIN_DATA';
export const RETRIEVE_CURRENT_SESSION = 'RETRIEVE_CURRENT_SESSION';
export const AUTHENTICATED_STATUS = 'AUTHENTICATED_STATUS';
export const NEW_PASSWORD_RESPONSE = 'NEW_PASSWORD_RESPONSE';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const SET_FORGOT_PASSWORD_SUBMIT_STATUS =
    'SET_FORGOT_PASSWORD_SUBMIT_STATUS';
export const REPLACE_BREADCRUMB = 'REPLACE_BREADCRUMB';
export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const POP_BREADCRUMB = 'POP_BREADCRUMB';
export const SAVE_NEW_ELEMENT = 'SAVE_NEW_ELEMENT';

export const saveNewElement = (payload: {
    id: string;
    type: string;
}): ActionPayload<SaveNewElement> => {
    return {
        type: SAVE_NEW_ELEMENT,
        payload
    };
};

export const popBreadCrumb = (): Action => {
    return {
        type: POP_BREADCRUMB
    };
};

export const replaceBreadCrumb = (
    payload: BreadCrumb[]
): ActionPayload<BreadCrumb[]> => {
    return {
        type: REPLACE_BREADCRUMB,
        payload
    };
};

export const addBreadCrumb = (
    payload: BreadCrumb
): ActionPayload<BreadCrumb> => {
    return {
        type: ADD_BREADCRUMB,
        payload
    };
};

export const setForgotPasswordSubmitStatus = (): Action => {
    return {
        type: SET_FORGOT_PASSWORD_SUBMIT_STATUS
    };
};

export const setLoginStatus = (
    payload: LoginType
): ActionPayload<LoginType> => {
    return {
        type: SET_LOGIN_STATUS,
        payload: payload
    };
};

export const newPasswordResponse = (
    error: boolean,
    payload?: string
): ActionPayloadOrError<string | undefined> => {
    return {
        type: NEW_PASSWORD_RESPONSE,
        error: error,
        payload: payload
    };
};

export const userLogin = (
    payload: UserLoginPayloadType
): ActionPayload<UserLoginPayloadType> => {
    return {
        type: USER_LOGIN,
        payload
    };
};

export const userLoggingIn = (): Action => {
    return {
        type: USER_LOGGING_IN
    };
};

export const userLoginSuccess = (
    payload: LoginResponse
): ActionPayload<LoginResponse> => {
    return {
        type: USER_LOGIN_SUCCESS,
        payload
    };
};

export const userLoginFailure = (
    status: LoginType,
    error: string
): ActionPayloadOrError<LoginFailed> => {
    return {
        type: USER_LOGIN_FAILURE,
        payload: { status },
        error
    };
};

export const setNewPassword = (payload: {
    newPassword: string | undefined;
    loginDetails: LoginResponse;
}): ActionPayload<SetNewPassword> => {
    return {
        type: SET_NEW_PASSWORD,
        payload
    };
};

export const forgotPassword = (payload: string): ActionPayload<string> => {
    return {
        type: FORGOT_PASSWORD,
        payload
    };
};

export const forgotPasswordSubmit = (
    email: string,
    code: string,
    newPassword: string
): ActionPayload<ForgotPasswordSubmit> => {
    return {
        type: FORGOT_PASSWORD_SUBMIT,
        payload: { email, code, newPassword }
    };
};

export const userLogout = (): Action => {
    return {
        type: USER_LOGOUT
    };
};

export const purgeLoginData = (): Action => {
    return {
        type: PURGE_LOGIN_DATA
    };
};

export const userLogoutResponse = (
    status: LogoutType,
    error?: string
): ActionPayloadOrError<UserLogoutResponse> => {
    return {
        type: USER_LOGOUT_RESPONSE,
        payload: { status },
        error
    };
};

export const forgotPasswordResponseSuccess = (
    status: ForgotPasswordStatus
): ActionPayload<ForgotPasswordStatus> => {
    return {
        type: FORGOT_PASSWORD_RESPONSE_SUCCESS,
        payload: status
    };
};

export const forgotPasswordResponseFailure = (
    status: ForgotPasswordType,
    error: string
): ActionPayloadOrError<ForgotPasswordFailed> => {
    return {
        type: FORGOT_PASSWORD_RESPONSE_FAILURE,
        payload: { status },
        error
    };
};

export const setForgotPassword = (): Action => {
    return {
        type: SET_FORGOT_PASSWORD
    };
};

export const forgotPasswordSubmitResponse = (
    status: ForgotPasswordSubmitType,
    error?: string
): ActionPayloadOrError<ForgotPasswordSubmitResponse> => {
    return {
        type: FORGOT_PASSWORD_SUBMIT_RESPONSE,
        payload: { status },
        error
    };
};

export const retrieveCurrentSession = (): Action => {
    return {
        type: RETRIEVE_CURRENT_SESSION
    };
};

export const userAuthenticatedStatus = (
    status: boolean,
    error?: string
): ActionPayload<UserAuthenticatedStatus> => {
    return {
        type: AUTHENTICATED_STATUS,
        payload: { status }
    };
};
