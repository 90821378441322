import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import { useUrlParams } from '../../hooks/useParam';
import CMPTexts from '../../locale/en';
import { LoadingStatus } from '../../redux/common/state';
import { setElementSatus } from '../../redux/element/action';
import {
    selectCreateElementStatus,
    selectSaveElementStatus
} from '../../redux/element/reducer';
import {
    fetchElements,
    resetElimentListLoadingStatus,
    resetElementOperationLoader
} from '../../redux/elementsList/action';
import {
    selectElementOperationLoader,
    selectElements,
    selectElementsError,
    selectElementsLoadingStatus,
    selectMessage
} from '../../redux/elementsList/reducer';
import { replaceBreadCrumb } from '../../redux/login/action';
import { BreadCrumb } from '../../redux/login/model';
import { selectBreadCrumb } from '../../redux/login/reducer';
import { enableScroll } from '../../utils/scrollUtil';
import { pxToRem } from '../../utils/stylesUtils';
import ElementsAccordion from './elementsAccordion';

const ElementsContainer = styled('div')(() => ({
    flex: 1,
    overflow: 'auto',
    marginBottom: 50,
    marginLeft: pxToRem(43)
}));

const ManageElementsRow = styled(Typography)(() => ({
    fontSize: '1.6rem',
    fontWeight: 500,
    textAlign: 'start',
    marginTop: 20
}));

const DescriptionContainer = styled('div')(() => ({
    display: 'flex',
    marginBottom: '2vh',
    marginTop: 5
}));

const InfoOutlinedIconStyle = styled('div')(() => ({
    marginTop: 1
}));

const DescriptionText = styled('div')(() => ({
    marginLeft: 10,
    fontSize: '1.04rem',
    fontWeight: 500
}));
const Elements = () => {
    const { showSnackbar } = useSnackbar();
    const { property } = useUrlParams();
    const location = useLocation();
    const loader = useSelector(selectElementsLoadingStatus);
    const error = useSelector(selectElementsError);
    const elementsData = useSelector(selectElements);
    const dispatch = useDispatch();
    const createElementStatus = useSelector(selectCreateElementStatus);
    const saveElementStatus = useSelector(selectSaveElementStatus);
    const breadCrumb = useSelector(selectBreadCrumb);
    const elementOperationLoader = useSelector(selectElementOperationLoader);
    const messages = useSelector(selectMessage);

    useEffect(() => {
        console.log('fetching elements list...');
        dispatch(fetchElements(property));
        if (breadCrumb.length >= 2) {
            dispatch(replaceBreadCrumb(breadCrumb.slice(0, 2)));
        } else {
            //if user directly use url and land on this page
            const breadCrumb: BreadCrumb[] = [];

            breadCrumb.push({
                name: 'Properties',
                link: '/properties',
                state: {}
            });
            breadCrumb.push({
                name: 'Property',
                link: `/properties/${property}`,
                state: {}
            });
            dispatch(replaceBreadCrumb(breadCrumb));
        }
    }, []);

    useEffect(() => {
        enableScroll();
        if (loader === LoadingStatus.DONE) {
            console.log('successfully fetched elements list');
            if (
                createElementStatus === LoadingStatus.DONE ||
                saveElementStatus === LoadingStatus.DONE
            ) {
                if (location.state?.widgetType)
                    showSnackbar(
                        `${location.state?.widgetType} saved successfully. Please update the same in property details page`,
                        'success',
                        3000
                    );
                dispatch(setElementSatus('createElementStatus'));
                dispatch(setElementSatus('saveElementStatus'));
            }
        } else if (loader === LoadingStatus.FAILED) {
            dispatch(resetElimentListLoadingStatus());
            showSnackbar(error, 'error');
        }
    }, [loader]);

    useEffect(() => {
        if (elementOperationLoader === LoadingStatus.DONE) {
            showSnackbar(messages);
            dispatch(resetElementOperationLoader());
            dispatch(fetchElements(property));
        } else if (elementOperationLoader === LoadingStatus.FAILED) {
            showSnackbar(messages, 'error');
            dispatch(resetElementOperationLoader());
        }
    }, [elementOperationLoader, showSnackbar, dispatch, property]);

    return (
        <ElementsContainer>
            {(loader === LoadingStatus.INITIATED ||
                elementOperationLoader === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            <ManageElementsRow>
                {CMPTexts.configureElement.heading}
            </ManageElementsRow>
            <DescriptionContainer>
                <InfoOutlinedIconStyle>
                    <InfoOutlinedIcon />
                </InfoOutlinedIconStyle>
                <DescriptionText>
                    {CMPTexts.configureElement.pageDescription}
                </DescriptionText>
            </DescriptionContainer>
            <ElementsAccordion elementsData={elementsData} />
        </ElementsContainer>
    );
};

export default Elements;
