import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiResponse } from '../../api/apiInterfaces';
import { ActionPayload } from '../types';
import {
    addCommentResponse,
    ADD_COMMENT_TO_ACTIVITY_LOG,
    fetchActivityLogsFiltersResponse,
    fetchActivityLogsResponse,
    FETCH_ACTIVITY_LOGS,
    FETCH_ACTIVITY_LOGS_FILTERS
} from './action';
import { AddCommentPayload, FetchActivityLogs } from './actionPayload';
import {
    addCommentHelper,
    fetchActivityLogFiltersHelper,
    fetchActivityLogsHelper
} from './helper';
import { ActivityLogFilters } from './model';
import { selectActivityLogs } from './reducer';
import { ActivityLog } from './state';

function* fetchActivityLogsWorker(data: ActionPayload<FetchActivityLogs>) {
    try {
        const res: ApiResponse<ActivityLog[]> = yield call(
            fetchActivityLogsHelper,
            data.payload
        );
        yield put(
            fetchActivityLogsResponse(res.response_body, false, res.message)
        );
    } catch (e: any) {
        yield put(fetchActivityLogsResponse(undefined, true, e.message));
    }
}

function* fetchActivityLogsFiltersWorker() {
    try {
        const res: ApiResponse<ActivityLogFilters> = yield call(
            fetchActivityLogFiltersHelper
        );
        yield put(
            fetchActivityLogsFiltersResponse(
                res.response_body,
                false,
                res.message
            )
        );
    } catch (e: any) {
        yield put(fetchActivityLogsResponse(undefined, true, e.message));
    }
}

function* addCommentWorker(data: ActionPayload<AddCommentPayload>) {
    try {
        const res: ApiResponse<ActivityLog> = yield call(
            addCommentHelper,
            data.payload
        );
        const activityLogs: ActivityLog[] = yield select(selectActivityLogs);

        const index = activityLogs.findIndex(
            (log) => log.id === data.payload.id
        );
        activityLogs[index] = res.response_body;
        yield put(addCommentResponse(activityLogs, false, res.message));
    } catch (e: any) {
        yield put(
            addCommentResponse(
                undefined,
                true,
                'Failed to add comment to activity log'
            )
        );
    }
}

export function* watchUserActivitySagas() {
    yield all([
        takeLatest(FETCH_ACTIVITY_LOGS, fetchActivityLogsWorker),
        takeLatest(ADD_COMMENT_TO_ACTIVITY_LOG, addCommentWorker),
        takeLatest(FETCH_ACTIVITY_LOGS_FILTERS, fetchActivityLogsFiltersWorker)
    ]);
}
