import { ThemeProvider } from '@mui/material';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app';
import './index.css';
import { persistor, store } from './redux/store';
import ErrorBoundary from './screens/errorBoundary/errorBoundary';
import { theme } from './theme';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_USER_POOL_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
        authenticationFlowType:
            process.env.NODE_ENV === 'production'
                ? 'USER_SRP_AUTH'
                : 'USER_PASSWORD_AUTH'
    }
});

// Remove logs in production
if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
}

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <React.StrictMode>
                <PersistGate persistor={persistor}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </PersistGate>
            </React.StrictMode>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
