import moment from 'moment';
import * as Yup from 'yup';
import cmpTexts from '../../../locale/en';
import { ZoneType } from '../../../redux/zones/model';

export const zoneValidationSchema = (existingZoneNames: string[]) =>
    Yup.object().shape({
        name: Yup.string()
            .required('*Required')
            .notOneOf(existingZoneNames, cmpTexts.zones.nameAlreadyExists),
        visibility_type: Yup.string().required('*Required'),
        description: Yup.string().required('*Required'),
        rooms: Yup.array().of(Yup.string()),
        type: Yup.string().required(),
        start_time: Yup.number().when('type', {
            is: ZoneType.Event,
            then: Yup.number()
                .required('*Required')
                .max(
                    Yup.ref('end_time'),
                    'Start date/time must be before end date/time'
                )
        }),
        end_time: Yup.number().when('type', {
            is: ZoneType.Event,
            then: Yup.number()
                .required('*Required')
                .min(
                    Yup.ref('start_time'),
                    'End date/time must be after start date/time'
                )
                .min(
                    moment().unix() * 1000,
                    'End date/time must be in the future'
                )
        })
    });
