import { Box, styled } from '@mui/material';
import { pxToRem } from '../../utils/stylesUtils';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import React from 'react';

const VideoAssetThumbnailContainer = styled('video')(() => ({
    backgroundColor: '#eeeeee',
    width: pxToRem(100),
    height: pxToRem(55),
    borderRadius: pxToRem(5),
    border: `${pxToRem(0.5)} solid #000000`,
    objectFit: 'cover'
}));

const VideoAssetThumbnail = (props: { src: string }) => {
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    width: pxToRem(100),
                    borderRadius: pxToRem(5),
                    height: pxToRem(55),
                    background: '#0002',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white'
                }}
            >
                <PlayCircleOutlineIcon />
            </Box>
            <VideoAssetThumbnailContainer src={props.src} />
        </>
    );
};

export default VideoAssetThumbnail;
