import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    styled
} from '@mui/material';
import React from 'react';
import Select from '../../../../components/select';

interface Props {
    required: boolean;
    labelId: string;
    label: string | React.ReactNode;
    error: boolean;
    options: any[];
    value: any;
    helperText: string;
    onSelect: (option: string) => void;
}

const DropDownContainer = styled(FormControl)(({ theme }) => ({
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
        display: 'inline-flex'
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.secondary.main
        },
        '&.Mui-focused fieldset': { borderColor: theme.palette.primary.main }
    }
}));

const PropertySectionDropDown = (props: Props) => {
    const {
        labelId,
        label,
        error,
        required,
        options,
        value,
        helperText,
        onSelect
    } = props;
    return (
        <DropDownContainer fullWidth>
            <InputLabel id={`${labelId}-label`}>{label}</InputLabel>
            <Select
                required={required}
                labelId={`${labelId}-label`}
                name={labelId}
                label={label}
                value={value}
                onChange={(e) => onSelect(e.target.value as string)}
            >
                {options?.map((list) => {
                    return (
                        <MenuItem key={list} value={list}>
                            {list}
                        </MenuItem>
                    );
                })}
            </Select>
            <FormHelperText error={error}>{helperText}</FormHelperText>
        </DropDownContainer>
    );
};

export default PropertySectionDropDown;
