import React from 'react';
import CMPTexts from '../../locale/en';
import GenericErrorContainer from './genericErrorContainer';

export default function AccessDeniedPage() {
    return (
        <GenericErrorContainer
            title={CMPTexts.errorPage.title}
            subTitle={CMPTexts.errorPage.description}
        />
    );
}
