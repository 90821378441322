import { LoadingStatus } from '../common/state';
import { Asset } from './model';

// main state for assets
export interface Assets {
    assets: Asset[];
    loader: LoadingStatus;
    message: string;
    assetOperationLoader: LoadingStatus;
}

export const initialAssetsState: Assets = {
    assets: [],
    loader: LoadingStatus.NONE,
    message: '',
    assetOperationLoader: LoadingStatus.NONE
};
