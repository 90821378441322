import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import React from 'react';
import { removeEmojis } from '../utils/stringUtils';

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.secondary.main
        }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
        color: theme.palette.primary.main
    },
    '& .css-ifhv0-MuiInputBase-root-MuiOutlinedInput-root': {
        paddingRight: 0
    },
    '& .css-1i107b5-MuiInputBase-root-MuiOutlinedInput-root': {
        paddingRight: 0
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
        display: 'inline-flex'
    }
}));

const CustomTextField = (props: TextFieldProps & { maxLength?: number }) => {
    const { value, maxLength = 128 } = props;
    return (
        <StyledTextField
            {...props}
            onChange={(e) => {
                e.target.value = removeEmojis(e.target.value.trimStart());
                if (!isEmpty(value) || !isEmpty(e.target.value))
                    props.onChange?.(e);
            }}
            inputProps={{ maxLength: maxLength }}
        />
    );
};

export default CustomTextField;
