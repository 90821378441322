import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { isEmpty } from 'lodash';
import { ApiResponse } from '../../api/apiInterfaces';
import { ActionPayload } from '../types';
import {
    CREATE_PREVIEW,
    DELETE_PREVIEW,
    putCreatePreviewResponse
} from './action';
import { DeletePreviewResponsePayload } from './actionPayload';
import { createPreview, deletePreview } from './helper';
import {
    CreatePreviewPayload,
    CreatePreviewRequestBody,
    CreatePreviewResponse
} from './model';

function* createPreviewWorker(data: ActionPayload<CreatePreviewPayload>) {
    try {
        const payload = data.payload;
        const createPreviewRequest: CreatePreviewRequestBody = {
            property_id: payload.propertyId,
            platform: payload.platform,
            page_id: payload.pageId,
            preview_data: payload.pageConfigDump
        };

        const createPreviewResponse: ApiResponse<CreatePreviewResponse> =
            yield call(createPreview, createPreviewRequest, payload.propertyId);

        yield put(
            putCreatePreviewResponse(false, createPreviewResponse.response_body)
        );
    } catch (error: any) {
        yield put(putCreatePreviewResponse(true, error.message));
    }
}

function* deletePreviewWorker(
    data: ActionPayload<DeletePreviewResponsePayload>
) {
    const { previewId, propertyId } = data.payload;

    // for now we need not worry about delete preview api response.
    try {
        if (!isEmpty(previewId))
            yield call(deletePreview, previewId, propertyId);
    } catch (e) {
        console.log('Error deleting the preview.');
    }
}

export function* watchPreviewSaga() {
    yield all([takeLatest(CREATE_PREVIEW, createPreviewWorker)]);
    yield all([takeLatest(DELETE_PREVIEW, deletePreviewWorker)]);
}
