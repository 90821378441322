const unit = ['B', 'KB', 'MB', 'GB'];

export const dataSizeConverter: (
    sizeInBytes: number,
    type?: string,
    count?: number
) => string = (
    dataInBytes: number,
    type: string = unit[0],
    count: number = 0
): string => {
    if (dataInBytes >= 1000) {
        return dataSizeConverter(
            dataInBytes / 1000,
            unit[count + 1],
            count + 1
        );
    }
    return `${Math.floor(dataInBytes * 100) / 100} ${type}`;
};
