import { Action } from 'redux';
import { Room, ZonesFieldValues } from './model';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    CreateUpdateZone,
    DeleteZone,
    UpdateZoneFieldsPayload
} from './actionPayload';

export const GET_ALL_ZONES = 'GET_ALL_ZONES';
export const GET_ALL_ZONES_RESPONSE = 'GET_ALL_ZONES_RESPONSE';

export const RESET_ZONES_LOADER = 'RESET_ZONES_LOADER';
export const RESET_ZONES_OPERATION_LOADER = 'RESET_ZONES_OPERATION_LOADER';
export const RESET_ZONES_DATA = 'RESET_ZONES_DATA';

export const CREATE_ZONE = 'CREATE_ZONE';
export const CREATE_ZONE_RESPONSE = 'CREATE_ZONE_RESPONSE';

export const EDIT_ZONE = 'EDIT_ZONE';
export const EDIT_ZONE_RESPONSE = 'EDIT_ZONE_RESPONSE';

export const UPDATE_ZONE_FIELDS = 'UPDATE_ZONE_FIELDS';
export const UPDATE_ZONE_FIELDS_RESPONSE = 'UPDATE_ZONE_FIELDS_RESPONSE';

export const DELETE_ZONE = 'DELETE_ZONE';
export const DELETE_ZONE_RESPONSE = 'DELETE_ZONE_RESPONSE';

export const GET_ALL_ROOMS = 'GET_ALL_ROOMS';
export const GET_ALL_ROOMS_RESPONSE = 'GET_ALL_ROOMS_RESPONSE';
export const PURGE_ROOMS = 'PURGE_ROOMS';

/*-------------------------RESET------------------------*/
export const resetZonesLoader = (): Action => {
    return {
        type: RESET_ZONES_LOADER
    };
};

export const resetZonesOperationLoader = (): Action => {
    return {
        type: RESET_ZONES_OPERATION_LOADER
    };
};

export const resetZonesData = (): Action => {
    return {
        type: RESET_ZONES_DATA
    };
};

/*-------------------------GET-ALL-ZONES------------------------*/
export const getAllZones = (propertyId: string): ActionPayload<string> => {
    return {
        type: GET_ALL_ZONES,
        payload: propertyId
    };
};

export const getZonesResponse = (
    error: boolean,
    message: string,
    zones?: ZonesFieldValues
): ActionPayloadOrError<ZonesFieldValues> => {
    return {
        type: GET_ALL_ZONES_RESPONSE,
        payload: zones,
        message,
        error
    };
};

/*-------------------------CREATE ZONES------------------------*/
export const createZone = (
    data: CreateUpdateZone
): ActionPayload<CreateUpdateZone> => {
    return {
        type: CREATE_ZONE,
        payload: data
    };
};

export const createZoneResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: CREATE_ZONE_RESPONSE,
        message,
        error
    };
};

/*-------------------------EDIT ZONES------------------------*/
export const editZone = (
    data: CreateUpdateZone
): ActionPayload<CreateUpdateZone> => {
    return {
        type: EDIT_ZONE,
        payload: data
    };
};

export const editZoneResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: EDIT_ZONE_RESPONSE,
        message,
        error
    };
};

export const updateZoneFields = (
    data: UpdateZoneFieldsPayload
): ActionPayload<UpdateZoneFieldsPayload> => {
    return {
        type: UPDATE_ZONE_FIELDS,
        payload: data
    };
};

export const updateZoneFieldsResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: UPDATE_ZONE_FIELDS_RESPONSE,
        message,
        error
    };
};

/*-------------------------DELETE ZONE------------------------*/
export const deleteZone = (data: DeleteZone): ActionPayload<DeleteZone> => {
    return {
        type: DELETE_ZONE,
        payload: data
    };
};

export const deleteZoneResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: DELETE_ZONE_RESPONSE,
        message,
        error
    };
};

/*-------------------------GET ROOM------------------------*/
export const getAllRooms = (property_id: string): ActionPayload<string> => {
    return {
        type: GET_ALL_ROOMS,
        payload: property_id
    };
};

export const getAllRoomsResponse = (
    error: boolean,
    message: string,
    data?: Room[]
): ActionPayloadOrError<Room[]> => {
    return {
        type: GET_ALL_ROOMS_RESPONSE,
        payload: data,
        message,
        error
    };
};

export const purgeRooms = (): Action => {
    return {
        type: PURGE_ROOMS
    };
};
