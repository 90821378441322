import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { PropertyFieldValues, UpdatePropertyFieldValuesBody } from './model';
export const GET_FIELD_CONFIG_VALUES = 'GET_FIELD_CONFIG_VALUES';
export const GET_FIELD_CONFIG_VALUES_RESPONSE =
    'GET_FIELD_CONFIG_VALUES_RESPONSE';
export const UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE =
    'UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE';

export const UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE_RESPONSE =
    'UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE_RESPONSE';
export const RESET_PROPERTY_VALUE_LOADER = 'RESET_PROPERTY_VALUE_LOADER';
export const RESET_GET_FIELD_CONFIG_VALUES_RESPONSE =
    'RESET_GET_FIELD_CONFIG_VALUES_RESPONSE';
export const RESET_PROPERTY_VALUE_OPERATION_LOADER =
    'RESET_PROPERTY_VALUE_OPERATION_LOADER';

export const SHOW_GENERIC_COMMENT_MODAL = 'SHOW_GENERIC_COMMENT_MODAL';
export const HIDE_GENERIC_COMMENT_MODAL = 'HIDE_GENERIC_COMMENT_MODAL';
export const UPDATE_COMMENTS = 'UPDATE_COMMENTS';
export const SAVE_QUEUED_ACTION = 'SAVE_QUEUED_ACTION';
export const REMOVE_QUEUED_ACTIONS = 'REMOVE_QUEUED_ACTIONS';

export const getFieldConfigValues = (
    propertyId: string
): ActionPayload<string> => {
    return {
        type: GET_FIELD_CONFIG_VALUES,
        payload: propertyId
    };
};

export const fieldConfigValuesResponse = (
    payload: PropertyFieldValues,
    error: boolean
): ActionPayloadOrError<PropertyFieldValues> => {
    return {
        type: GET_FIELD_CONFIG_VALUES_RESPONSE,
        payload: payload,
        error: error
    };
};

export const updatePropertyMultipleFieldValue = (
    payload: UpdatePropertyFieldValuesBody
): ActionPayload<UpdatePropertyFieldValuesBody> => {
    return {
        type: UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE,
        payload
    };
};

export const updatePropertyMultipleFieldValueRes = (
    error?: string
): ActionPayloadOrError<string | undefined> => {
    return {
        type: UPDATE_PROPERTY_MULTIPLE_FIELD_VALUE_RESPONSE,
        error
    };
};

export const resetPropertyValueLoader = (): Action => {
    return {
        type: RESET_PROPERTY_VALUE_LOADER
    };
};

export const resetGetFieldConfigValuesResponse = (): Action => {
    return {
        type: RESET_GET_FIELD_CONFIG_VALUES_RESPONSE
    };
};

export const resetPropertyValueOperationLoader = (): Action => {
    return {
        type: RESET_PROPERTY_VALUE_OPERATION_LOADER
    };
};

export const showGenericCommentModal = (): Action => {
    return {
        type: SHOW_GENERIC_COMMENT_MODAL
    };
};

export const hideGenericCommentsModal = (): Action => {
    return {
        type: HIDE_GENERIC_COMMENT_MODAL
    };
};

export const updateComments = (payload: string): ActionPayload<string> => {
    return {
        type: UPDATE_COMMENTS,
        payload
    };
};

export const saveActionToQueue = (payload: any): any => {
    return {
        type: SAVE_QUEUED_ACTION,
        payload
    };
};

export const removeQueuedActions = (): any => {
    return {
        type: REMOVE_QUEUED_ACTIONS
    };
};
