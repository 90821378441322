import { LoadingStatus } from '../common/state';
import {
    Organization,
    PropertyManagers,
    PropertyUserAssociation
} from './model';

export interface OrganizationsState {
    propertyManagers: PropertyManagers[];
    loader: LoadingStatus;
    organizationOperationLoader: LoadingStatus;
    properties: PropertyUserAssociation[];
    organizations: Organization[];
    filteredOrganization: string;
    message: string;
}

export const initialOrganizationState: OrganizationsState = {
    propertyManagers: [],
    message: '',
    loader: LoadingStatus.NONE,
    organizationOperationLoader: LoadingStatus.NONE,
    properties: [],
    organizations: [],
    filteredOrganization: ''
};
