import * as Yup from 'yup';
import { UserRole } from '../redux/users/model';
import { emailRegex, nameRegex } from './regexUtil';

export const addUsersSchema = {};

export const userDataValidationSchema = (
    orgRequired: boolean,
    propertySelectionRequired: boolean
) => {
    return Yup.object().shape({
        organization: Yup.string().when([], {
            is: () => orgRequired,
            then: Yup.string().required('Required*')
        }),
        email: Yup.string()
            .trim()
            .matches(emailRegex, 'Enter a valid email address')
            .required('Required*'),
        firstName: Yup.string()
            .trim()
            .matches(nameRegex, 'Enter a valid First Name')
            .required('Required*'),
        lastName: Yup.string()
            .trim()
            .matches(nameRegex, 'Enter a valid Last Name')
            .required('Required*'),
        properties: Yup.array().when([], {
            is: () => propertySelectionRequired,
            then: Yup.array().min(1).required('Required*')
        })
    });
};

export const getRoleDisplayName = (role: UserRole): string => {
    switch (role) {
        case UserRole.SuperAdmin:
            return 'Super Admin';
        case UserRole.SuperUser:
            return 'Super User';
        case UserRole.AdminIntegrator:
            return 'Admin Partner';
        case UserRole.Integrator:
            return 'Partner';
        case UserRole.PropertyManager:
            return 'Property Manager';
        case UserRole.Consultant:
            return 'Consultant';
    }
};
