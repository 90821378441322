import { Action } from 'redux';
import { ApiResponseState } from '../common/state';
import { WidgetSkeleton } from '../element/model';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    DeleteElementPayload,
    DuplicateElementPayload,
    ElementOperationResponse,
    FetchElementListByType,
    IsElementLive,
    IsElementLiveResponse,
    PropertyIdPayload
} from './actionPayload';
import { ElementListResponse, ElementsResponse } from './model';

export const FETCH_ELEMENTS = 'FETCH_ELEMENTS';
export const FETCH_ELEMENTS_RESPONSE = 'FETCH_ELEMENTS_RESPONSE';
export const RESET_LOADING_STATUS = 'RESET_LOADING_STATUS';
export const FECTH_ELEMENT_LIST_BY_TYPE = 'FECTH_ELEMENT_LIST_BY_TYPE';
export const FECTH_ELEMENT_LIST_BY_TYPE_RESPONSE =
    'FECTH_ELEMENT_LIST_BY_TYPE_RESPONSE';
export const FETCH_PAGE_LIST = 'FETCH_PAGE_LIST';
export const PAGE_LIST_RESPONSE = 'PAGE_LIST_RESPONSE';
export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const DELETE_ELEMENT_RESPONSE = 'DELETE_ELEMENT_RESPONSE';
export const CHECK_IF_ELEMENT_IS_LIVE = 'CHECK_IF_ELEMENT_IS_LIVE';
export const CHECK_IF_ELEMENT_IS_LIVE_RESPONSE =
    'CHECK_IF_ELEMENT_IS_LIVE_RESPONSE';
export const PURGE_ELEMENT_LIVE = 'PURGE_ELEMENT_LIVE';
export const FETCH_ALL_WDIGET_TYPES = 'FETCH_ALL_WDIGET_TYPES';
export const FETCH_ALL_WDIGET_TYPES_RESPONSE =
    'FETCH_ALL_WDIGET_TYPES_RESPONSE';
export const DUPLICATE_ELEMENT = 'DUPLICATE_ELEMENT';
export const DUPLICATE_ELEMENT_RESPONSE = 'DUPLICATE_ELEMENT_RESPONSE';
export const RESET_ELEMENT_OPERATION_LOADER = 'RESET_ELEMENT_OPERATION_LOADER';

export const fetchAllWidgetTypes = (
    propertyId: string
): ActionPayload<PropertyIdPayload> => {
    return {
        type: FETCH_ALL_WDIGET_TYPES,
        payload: { propertyId }
    };
};

export const fetchAllWidgetTypesResponse = (
    error: boolean,
    payload?: WidgetSkeleton[]
): ActionPayloadOrError<WidgetSkeleton[]> => {
    return {
        type: FETCH_ALL_WDIGET_TYPES_RESPONSE,
        payload: payload,
        error: error
    };
};

export const fetchElements = (
    propertyId: string
): ActionPayload<PropertyIdPayload> => {
    return {
        type: FETCH_ELEMENTS,
        payload: { propertyId }
    };
};

export const elementsResponse = (
    payload: ElementsResponse[] | undefined,
    error?: string
): ActionPayloadOrError<ElementsResponse[] | undefined> => {
    return {
        type: FETCH_ELEMENTS_RESPONSE,
        payload,
        error
    };
};

export const resetElimentListLoadingStatus = (): Action => {
    return {
        type: RESET_LOADING_STATUS
    };
};

export const fetchPageList = (
    propertyId: string,
    type: string
): ActionPayload<FetchElementListByType> => {
    return {
        type: FETCH_PAGE_LIST,
        payload: { propertyId, type }
    };
};

export const fetchElementListByType = (
    propertyId: string,
    type: string
): ActionPayload<FetchElementListByType> => {
    return {
        type: FECTH_ELEMENT_LIST_BY_TYPE,
        payload: { propertyId, type }
    };
};

export const fetchElementListByTypeResponse = (
    payload: ElementListResponse[]
): ActionPayload<ElementListResponse[]> => {
    return {
        type: FECTH_ELEMENT_LIST_BY_TYPE_RESPONSE,
        payload
    };
};

export const pageListResponse = (
    payload: ElementListResponse[]
): ActionPayload<ElementListResponse[]> => {
    return {
        type: PAGE_LIST_RESPONSE,
        payload
    };
};

export const deleteElement = (
    elementId: string,
    elementType: string,
    propertyId: string
): ActionPayload<DeleteElementPayload> => {
    return {
        type: DELETE_ELEMENT,
        payload: { propertyId, elementId, elementType }
    };
};

export const deleteElementResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<ElementOperationResponse> => {
    return {
        type: DELETE_ELEMENT_RESPONSE,
        payload: { message },
        error
    };
};

export const duplicateElementResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<ElementOperationResponse> => {
    return {
        type: DUPLICATE_ELEMENT_RESPONSE,
        payload: { message },
        error
    };
};

export const duplicateElement = (
    propertyId: string,
    oldElementId: string,
    newElementName: string,
    elementType: string
): ActionPayload<DuplicateElementPayload> => {
    return {
        type: DUPLICATE_ELEMENT,
        payload: { propertyId, oldElementId, newElementName, elementType }
    };
};

export const checkIfElementIsLive = (
    elementId: string,
    elementType: string,
    propertyId: string
): ActionPayload<IsElementLive> => {
    return {
        type: CHECK_IF_ELEMENT_IS_LIVE,
        payload: { elementId, elementType, propertyId }
    };
};

export const checkIfElementLiveResponse = (
    status: ApiResponseState,
    data:
        | { is_attached_to_page: boolean; is_part_of_live_page: boolean }
        | undefined,
    error?: string
): ActionPayloadOrError<IsElementLiveResponse> => {
    return {
        type: CHECK_IF_ELEMENT_IS_LIVE_RESPONSE,
        payload: { status, data },
        error
    };
};

export const purgeElementLive = (): Action => {
    return {
        type: PURGE_ELEMENT_LIVE
    };
};

export const resetElementOperationLoader = (): Action => {
    return {
        type: RESET_ELEMENT_OPERATION_LOADER
    };
};
