import { all, fork } from 'redux-saga/effects';
import { watchAssetSagas } from './assets/saga';
import { watchElements } from './element/saga';
import { watchElementsList } from './elementsList/saga';
import { watchLoginSagas } from './login/saga';
import { watchOrganizationSagas } from './organizations/saga';
import { watchPreviewSaga } from './preview/saga';
import { getPropertyListWatcher } from './propertylist/saga';
import { getSelectEntryDropDownWatcher } from './propertyValue/saga';
import { watchUserOperations } from './userOperations/saga';
import { watchUsersSagas } from './users/saga';
import { watchGeneralSectionSagas } from './generalSection/saga';
import { watchMaintenanceSagas } from './maintenance/saga';
import { watchEvolve2Sagas } from './evolve2/saga';
import { watchUserActivitySagas } from './activityMonitoring/saga';
import { watchZoneSagas } from './zones/saga';

export default function* rootSaga() {
    yield all([
        fork(getPropertyListWatcher),

        fork(watchLoginSagas),
        fork(getSelectEntryDropDownWatcher),
        fork(watchUserOperations),
        fork(watchElementsList),
        fork(watchElements),
        fork(watchEvolve2Sagas),
        fork(watchOrganizationSagas),
        fork(watchPreviewSaga),
        fork(watchAssetSagas),
        fork(watchUsersSagas),
        fork(watchGeneralSectionSagas),
        fork(watchMaintenanceSagas),
        fork(watchUserActivitySagas),
        fork(watchZoneSagas)
    ]);
}
