export interface Platform {
    name: string;
    displayName: string;
}

export const Android: Platform = {
    name: 'android',
    displayName: 'Android'
};

export const Browser: Platform = {
    name: 'browser',
    displayName: 'Browser'
};

export const FireTV: Platform = {
    name: 'firetv',
    displayName: 'FireTV'
};

export const LG: Platform = {
    name: 'lg',
    displayName: 'LG'
};

export const Roku: Platform = {
    name: 'roku',
    displayName: 'Roku'
};

export const Platforms: Platform[] = [Android, Browser, FireTV, LG, Roku];

export const findPlatform = (name?: string): Platform | undefined => {
    return Platforms.find((p) => p.name === name?.toLowerCase());
};
