import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { get, isEmpty, isEqual, isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactComponent as AmazonLogo } from '../../../../assets/svg/amazonLogo.svg';
import { ReactComponent as AndroidLogo } from '../../../../assets/svg/androidLogo.svg';
import { ReactComponent as BrowserLogo } from '../../../../assets/svg/browserLogo.svg';
import { ReactComponent as LgLogo } from '../../../../assets/svg/lgLogo.svg';
import { ReactComponent as RokuLogo } from '../../../../assets/svg/RokuLogo.svg';
import IconStylizer from '../../../../components/iconStylizer';
import WarningMessage from '../../../../components/warningMessage';
import {
    PlatformSection,
    PropertyField,
    PropertyFieldValue
} from '../../../../redux/propertyValue/model';
import {
    evaluateMessagingCondition,
    evaluateValueCondition
} from '../../../../utils/propertiesUtil';
import { pxToRem } from '../../../../utils/stylesUtils';
import PlatformPageCard from './platformPageCard';

const PlatformTab = styled(IconButton)(() => ({
    width: pxToRem(86),
    height: pxToRem(68),
    borderRadius: pxToRem(7),
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(7),
    marginBottom: pxToRem(16),
    '& .MuiTypography-root': {
        color: '#FFFFFF'
    },
    '&.selected': {
        background: '#FFFFFF',
        '.MuiTypography-root': {
            color: '#F01446'
        }
    }
}));
export interface PlatformSpecificWarningMessage {
    [platform: string]: string;
}
interface PlatformPanelProps {
    platformSection: PlatformSection;
    propertyId: string;
    updateSubmitPayload: (
        zoneId: string,
        pfId: string,
        key: string,
        value: any,
        isManualUpdate: boolean
    ) => void;
    setShouldResetForm: (shouldResetForm: boolean) => void;
    shouldResetForm: boolean;
    setShowSaveBar: (showSaveBar: boolean) => void;
    zoneId: string;
    autoAffectedFieldIds: string[];
    platformSpecificWarningMessages?: PlatformSpecificWarningMessage;
    setWarningMessage?: (
        zoneId: string,
        platform: string,
        message: string
    ) => void;
}

const PlatformPanel = (props: PlatformPanelProps) => {
    const {
        platformSection,
        propertyId,
        setShowSaveBar,
        updateSubmitPayload,
        shouldResetForm,
        setShouldResetForm,
        zoneId,
        autoAffectedFieldIds,
        platformSpecificWarningMessages,
        setWarningMessage
    } = props;

    const platformNames = Object.keys(platformSection).filter(
        (name: string) => !isEmpty(platformSection[name])
    );

    const [selectedPlatform, setSelectedPlatform] = useState(platformNames[0]);
    const [manuallyUpdatedChildField, setManuallyUpdatedChildField] =
        useState<PropertyField>();

    const formik = useFormik({
        initialValues: platformSection,
        enableReinitialize: true,
        onSubmit: (values) => {}
    });

    const onPlatformClick = (val: string) => {
        setSelectedPlatform(val);
    };

    useEffect(() => {
        if (shouldResetForm) {
            formik.resetForm();
            setShouldResetForm(!shouldResetForm);
        }
    }, [shouldResetForm]);

    const onFieldUpdate = (key: string, value: any) => {
        formik.setFieldValue(key, value);
    };

    const getPlatformLogo = (platform: string) => {
        switch (platform) {
            case 'Android':
                return <AndroidLogo />;
            case 'FireTV':
                return <AmazonLogo />;
            case 'LG':
                return <LgLogo />;
            case 'Roku':
                return <RokuLogo />;
            default:
                return <BrowserLogo />;
        }
    };

    const platformPagesFieldIndex = platformSection[selectedPlatform].findIndex(
        (pf) => pf.key === 'pages'
    );

    const allPages =
        platformSection[selectedPlatform]?.[platformPagesFieldIndex]?.children;

    useEffect(() => {
        allPages?.forEach((pageField, pageIndex) => {
            pageField.children?.forEach((childField, childIndex) => {
                const evaluatedValue = evaluateValueCondition(
                    childField,
                    allPages
                );

                if (
                    !isNull(evaluatedValue) &&
                    !isEqual(evaluatedValue, childField.value)
                ) {
                    onPageChildFieldUpdate(
                        pageIndex,
                        pageField,
                        childIndex,
                        evaluatedValue,
                        false
                    );
                }
            });
        });
    }, [allPages]);

    useEffect(() => {
        if (manuallyUpdatedChildField) {
            const message =
                evaluateMessagingCondition(
                    manuallyUpdatedChildField,
                    allPages
                ) ?? '';

            setWarningMessage?.(zoneId, selectedPlatform, message);
            setManuallyUpdatedChildField(undefined);
        }
    }, [allPages, manuallyUpdatedChildField]);

    const onPageChildFieldUpdate = (
        pageIndex: number,
        pageField: PropertyField,
        childIndex: number,
        value: PropertyFieldValue,
        manual: boolean
    ) => {
        const fieldKey = `[${selectedPlatform}][${platformPagesFieldIndex}].children[${pageIndex}].children[${childIndex}]`;
        const fieldId = get(pageField.children, childIndex + '.id', '');
        onFieldUpdate(fieldKey, value);
        updateSubmitPayload(zoneId, fieldId, fieldKey, value, manual);
        setShowSaveBar(true);

        const childField = pageField.children?.[childIndex];
        if (manual && childField) setManuallyUpdatedChildField(childField);
    };

    return (
        <Box display="flex">
            <Box
                sx={{
                    background: '#525261',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: `${pxToRem(32)} ${pxToRem(8)}`,
                    alignItems: 'center',
                    borderRadius: `${pxToRem(30)} 0 0 ${pxToRem(30)}`
                }}
            >
                {platformNames.map((platformName) => (
                    <PlatformTab
                        key={platformName}
                        onClick={() => onPlatformClick(platformName)}
                        className={
                            selectedPlatform === platformName ? 'selected' : ''
                        }
                    >
                        <IconStylizer
                            color={
                                selectedPlatform === platformName
                                    ? '#F01446'
                                    : '#FFF'
                            }
                            backgroundColor={
                                selectedPlatform === platformName
                                    ? '#FFF'
                                    : '#525261'
                            }
                        >
                            {getPlatformLogo(platformName)}
                        </IconStylizer>
                        <Typography
                            sx={{
                                fontSize: pxToRem(14),
                                fontWeight: 500,
                                lineHeight: pxToRem(19),
                                letterSpacing: 0
                            }}
                        >
                            {platformName}
                        </Typography>
                    </PlatformTab>
                ))}
            </Box>
            <Grid
                container
                sx={{
                    padding: ` ${pxToRem(45)}  ${pxToRem(20)}`,
                    background: '#FFF',
                    borderRadius: `0 ${pxToRem(30)}  ${pxToRem(30)} 0`
                }}
            >
                {platformSpecificWarningMessages?.[selectedPlatform] &&
                    autoAffectedFieldIds.some((id) =>
                        id.startsWith(zoneId)
                    ) && (
                        <Grid item xs={12} sx={{ margin: pxToRem(12) }}>
                            <WarningMessage
                                message={
                                    platformSpecificWarningMessages[
                                        selectedPlatform
                                    ]
                                }
                            />
                        </Grid>
                    )}

                {platformPagesFieldIndex !== -1 &&
                    platformSection[selectedPlatform][
                        platformPagesFieldIndex
                    ]?.children?.map((pageField, pageIndex) => {
                        const canView = pageField.access_details?.View;
                        if (!canView) return null;

                        const zoneIdToFieldCombinations =
                            pageField.children?.map(
                                (c) => `${zoneId}:${c.id}`
                            ) ?? [];

                        const showWarningIcon = zoneIdToFieldCombinations.some(
                            (id) => autoAffectedFieldIds.includes(id)
                        );

                        return (
                            <Grid
                                item
                                xs={6}
                                lg={4}
                                xl={3}
                                key={pageField.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: 'fit-content'
                                }}
                            >
                                <PlatformPageCard
                                    platform={selectedPlatform}
                                    siblingPages={
                                        platformSection[selectedPlatform][
                                            platformPagesFieldIndex
                                        ].children ?? []
                                    }
                                    onChildFieldUpdate={(childIndex, value) =>
                                        onPageChildFieldUpdate(
                                            pageIndex,
                                            pageField,
                                            childIndex,
                                            value,
                                            true
                                        )
                                    }
                                    propertyId={propertyId}
                                    pageField={pageField}
                                    showWarningIcon={showWarningIcon}
                                />
                            </Grid>
                        );
                    })}
            </Grid>
        </Box>
    );
};

export default PlatformPanel;
