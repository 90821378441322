export interface UserSession {
    accessToken: {
        jwtToken: string;
        payload: { [key: string]: any };
    };
    idToken: {
        jwtToken: string;
        payload: { [key: string]: any };
    };
    refreshToken: {
        jwtToken: string;
        payload: { [key: string]: any };
    };
}
export interface LoginResponse {
    attributes: {
        'custom:role': string;
        'custom:organizationId'?: string;
        email: string;
        email_verified: boolean;
        name: string;
        sub: string;
    };
    signInUserSession: UserSession;
    userDataKey: string;
    username: string;
    challengeName?: string;
}

export type LoginType =
    | 'NOT_INITIATED'
    | 'LOGGING_IN'
    | 'SUCCESS'
    | 'NEW_PASSWORD_REQUIRED'
    | 'FAILED'
    | 'LOADING'
    | 'NEW_PASSWORD_SET_SUCCESSFULLY'
    | 'NONE'
    | 'NEW_PASSWORD_FAILED'
    | 'NOT_LOGGED_IN';

export type LogoutType = 'LOGGED_OUT' | 'LOGOUT_FAILED';

export type ForgotPasswordType =
    | 'FORGOT_PASSWORD_DONE'
    | 'FORGOT_PASSWORD_FAILED'
    | 'FORGOT_PASSWORD_NOT_INITIATED';

export type ForgotPasswordSubmitType =
    | 'FORGOT_PASSWORD_SUBMIT_DONE'
    | 'FORGOT_PASSWORD_SUBMIT_FAILED';

export enum LoginStatus {
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED',
    LOGGING_IN = 'LOGGING_IN',
    NEW_PASSWORD_SET_SUCCESSFULLY = 'NEW_PASSWORD_SET_SUCCESSFULLY',
    NOT_LOGGED_IN = 'NOT_LOGGED_IN',
    NONE = 'NONE'
}

export enum LogoutStatus {
    LOGGED_OUT = 'LOGGED_OUT',
    LOGOUT_FAILED = 'LOGOUT_FAILED'
}

export enum ForgotPasswordStatus {
    FORGOT_PASSWORD_DONE = 'FORGOT_PASSWORD_DONE',
    FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
    FORGOT_PASSWORD_NOT_INITIATED = 'FORGOT_PASSWORD_NOT_INITIATED'
}

export enum ForgotPasswordSubmitStatus {
    FORGOT_PASSWORD_SUBMIT_DONE = 'FORGOT_PASSWORD_SUBMIT_DONE',
    FORGOT_PASSWORD_SUBMIT_FAILED = 'FORGOT_PASSWORD_SUBMIT_FAILED',
    FORGOT_PASSWORD_SUBMIT_NOT_INITIATED = 'FORGOT_PASSWORD_SUBMIT_NOT_INITIATED',
    FORGOT_PASSWORD_SUBMIT_INITIATED = 'FORGOT_PASSWORD_SUBMIT_INITIATED'
}

export interface BreadCrumb {
    // id: string;
    name: string;
    link: string;
    state: {};
}

export interface UserLoginPayloadType {
    email: string;
    password: string;
    newPassword?: string | undefined;
}
