const cmpTexts = {
    common: {
        genericError: 'Something went wrong!'
    },
    login: {
        textOnLeft:
            'Welcome to the OnStream Content Management Portal. Your gateway to customizing OnStream for your property',
        paperHeading: 'Login',
        textFieldEmail: 'Email',
        textFieldPassword: 'Password',
        forgotPassword: 'Forgot Password?',
        login: 'LOGIN'
    },
    forgotPassword: {
        // textOnLeft:
        //     'Helping Users manage, modify and create properties with new digital experiences using secure, innovatie APIs',
        backToLogin: 'Back to login',
        paperHeading: 'Reset your Password',
        paperBody:
            'Enter your email address and a reset password link will be sent to you.',
        button: 'SEND CODE'
    },
    changePassword: {
        // textOnLeft:
        //     'Helping Users manage, modify and create properties with new digital experiences using secure, innovatie APIs',
        backToLogin: 'Back to login',
        paperTitle: 'Reset your Password',
        textFieldEmail: 'Email',
        textFieldSecurityCode: 'Security Code',
        textFieldNewPassword: 'New Password',
        textFieldConfirmPassword: 'Confirm New Password',
        passwordConditionsTitle: 'Password Conditions:',
        passwordConditions: [
            'At least 8 characters',
            'A mixture of both uppercase and lowercase letters',
            'A mixture of letters and numbers'
        ],
        button: 'SUBMIT',
        passwordResetSuccess:
            'Password has been set successfully. Redirecting To Login',
        passwordResetError:
            'Error occurred while resetting the password. Please try again after some time.'
    },
    emailVerification: {
        // textOnLeft:
        //     'Helping Users manage, modify and create properties with new digital experiences using secure, innovatie APIs',
        paperHeading: 'Welcome,',
        paperBody: 'Please enter your password twice to create your account!',
        textFieldTempPass: 'Enter Temporary Password',
        textFieldNewPass: 'Enter New Password',
        textFieldRepeatNewPass: 'Confirm New Password',
        passwordConditionsTitle: 'Password Conditions:',
        passwordConditions: [
            'At least 8 characters',
            'A mixture of both uppercase and lowercase letters',
            'A mixture of letters and numbers'
        ],
        button: 'SUBMIT'
    },
    emaiVerificationSubmit: {
        // textOnLeft:
        //     'Helping Users manage, modify and create properties with new digital experiences using secure, innovatie APIs',
        paperHeading: 'Account Created',
        paperBody:
            'You will be redirected to the Login page automatically where you can login with your new credentials.',
        button: 'RE-DIRECT TO LOGIN'
    },
    configureElement: {
        heading: 'Element Library',
        pageDescription:
            'Create and edit pages and page elements for your property',
        pageElementDescription: 'Create your page layout by adding rails.',
        railDescription:
            'Widgets appear in the order below. Add a new widget at the end of the row or drag a widget to change the order.',
        elementNameDescription1: 'Identify this ',
        elementNameDescription2: ' in the CMP.',
        uploadAsset: 'UPLOAD NEW ASSET',
        uploadSuccess:
            'Asset uploaded successfully. Please open the dropdown and select the asset',
        uploadFailed: 'Upload failed',
        save: 'Save',
        cancel: 'Back',

        errorMessage: [
            'Add a name to save this ',
            'Add a rail to save this page.',
            'Add a widget to save this rail.',
            'Fill the required fields in the form.',
            'Add a header to save this rail.'
        ]
    },
    errorPage: {
        title: 'Access Denied',
        description: 'You don’t have permission to access the requested page.',
        buttonText: 'Go back to Home page'
    },
    page404: {
        title: '404',
        description: 'Page Not found'
    },
    propertyDetails: {
        edit: 'Edit'
    },
    underMaintenance: {
        title: 'The Content Management Portal is Under Maintenance',
        description: `We apologize for the inconvenince. 
        Please check back soon.`
    },
    confirmMaintenanceModal: {
        title: 'Warning! CMP Maintenance Status',
        description:
            'You are about to Change CMP Maintenance Status. Do you wish to continue?',
        button: ['Yes', 'No']
    },
    errorBoundaryFallback: {
        title: 'Whoops!',
        description:
            "This site has been updated. Please clear your browser's cache to access the live site.",
        buttonText: 'CLEAR CACHE'
    },
    OnStreamDesignTexts: {
        fieldUpdateSuccess: 'Fields updated successfully.',
        filedUpdateFailed: 'Fields update failed.',
        pageDescription: 'Configure/ modify page of the property here.',
        manageDevices: 'Manage Devices',
        devicesDescription: 'Set the pages to display for each device type.'
    },
    PropertyUsersText: {
        pageTitle: 'Manage Property Users',
        button: 'ADD USER',
        pageDescription: 'Define and modify users for each property here.'
    },
    PropertyTexts: {
        manageProperties: 'Manage Properties',
        createGroup: 'Create Group',
        pageDescription: 'Organize your properties into groups by brand.',
        propertyModalHeading: 'Delete property?',
        propertyModalBody: 'This property will be erased from your account.',
        button: ['YES, DELETE', 'CANCEL'],
        proopertyEditSuccess: 'Property edited successfully.',
        propertyEditFail: 'Property edit failed.',
        search: 'Search Groups and Properties',
        expandAll: 'Expand All',
        noResultsFound: 'No results found'
    },
    OrganizationDropdown: {
        dropdownLabel: 'Organization',
        allOrganizaion: 'All Organizations'
    },
    PropertyNavigationModalTexts: {
        modalHeading: 'Leave Page?',
        bodyText1:
            'You have unsaved changes. Your changes will be discarded if you leave this page before saving.',
        bodyText2: 'Would you like to continue?',
        button: ['Yes, Leave', 'No, Cancel']
    },
    PropertySectionTexts: {
        menuOptions: 'OnStream Features',
        menuOptionsDescription:
            'Select the OnStream features you want at this property.',
        backgroundDes:
            'Please set the background image for property. Files allowed; JPEG,JPG, TIFF and PNG formats',
        upload: 'Upload',
        logo: 'Logo',
        logoDes:
            'Please set the logo for property. Files allowed; JPEG,JPG, TIFF and PNG formats',
        property: 'Property Configurations',
        code: 'Zip Code',
        privacyPolicy: 'Privacy Policy',
        termsOfUse: 'Terms Of Use',
        contact: 'Contact'
    },
    PropertyAccordinTexts: {
        addProperty: 'Add Property',
        noProperty: 'No Properties'
    },
    PropertyGroupTexts: {
        background: 'Background',
        backgroundDes:
            'Set the background image for the property. File formats allowed: JPEG, JPG, TIFF, and PNG',
        upload: 'Upload',
        logo: 'Logo',
        logoDes:
            'Add a logo to your header. For the best appearance, use a transparent PNG that will contrast against your background color.',
        propertyBrandKit: 'Property Brand Kit',
        PropertyGroupDescription:
            'Theme colors and images are shared by all properties in this group.',
        uploadImageSizeLimit: '*Upload size should be less than 5mb',
        text: 'Text',
        color: 'Color',
        colorHeadingDescription:
            'Set your primary brand color to be used as a focus color in this group.',
        buttonOption: 'Button Option',
        propertyGroupDeleteHeading: 'Delete group?',
        propertyGroupEditHeading: 'Edit Group',
        propertyGroupModalBody: 'This group will be erased from your account.',
        button: ['YES, DELETE', 'CANCEL'],
        deleteGroupSuccess: 'Group deleted successfully',
        deleteGroupFailed: 'Delete group failed',
        deleteGroupText: 'DELETE GROUP',
        editGroupTooltip: 'Edit Property Group',
        deleteGroupTooltip: 'Delete Property Group',
        editGroupLabel: 'Property Group Name'
    },
    ImageCard: {
        deleteText: 'Marked for deletion'
    },
    ElementsAccordiontext: {
        addWidget: 'Add Widget',
        noWidget: 'No records',
        elementModalHeading: 'Delete Widget?',
        elementModalBody:
            'This widget will be erased from your element library.',
        button: ['YES, DELETE', 'CANCEL'],
        deleteLivePage: {
            title: 'Error: Unable to delete Live Page',
            description:
                'This page is currently being used on a device. To delete it, remove it from all devices first.',
            yesButton: 'Okay'
        },
        deleteLiveElement: {
            title1: 'Delete ',
            title2: 'from Live Page?',
            description1: 'This ',
            description2:
                'is being used on your live page. Deleting it will remove it from the live page, all other pages, and erase it from your element library.'
        }
    },
    SaveBarTexts: {
        description:
            'You have mandatory items that needs to be completed which are highlighted below'
    },
    BrowserTexts: {
        inputLabel: 'Select Page'
    },
    DeleteImageModaltext: {
        modalHeading: 'Are you sure?',
        modalBody:
            'Do you really want to delete? This process cannot be undone.',
        button: ['No', 'Yes']
    },
    UserManagementTexts: {
        delete: 'delete',
        addUser: 'Add User',
        manageUser: 'Manage User',
        inviteSuccess: 'Invited user successfully',
        userdeleted: 'User deleted successfully'
    },
    organizationsAccordionText: {
        addWidget: 'Add Widget',
        noWidget: 'No records',
        addIntegrator: 'Add Integrator',
        emptyOrganization: 'No users added to this organization yet',
        organization: 'Organization *'
    },
    organisationManagementText: {
        pageTitle: 'Manage Organization',
        button: 'ADD ORGANIZATION'
    },
    DeleteUserTexts: {
        deleteUserHeading: 'Delete User',
        deleteUserDescription:
            'You are about to delete this user. Do you wish to continue?',
        button: ['Yes', 'No']
    },
    usersTexts: {
        SuccessfulDelete: 'User Deleted Successfully',
        UnsuccessfulDelete: 'Could Not Delete User',
        SuccessfulCreation: 'User Created Successfully',
        UnsuccessfullCreation: 'Could Not Create User',
        GenericError: 'Something went wrong',
        addUserTitle: 'Create User',
        editUserTitle: 'Edit User',
        userExistsTitle: 'User Already Exists',
        save: 'Save',
        cancel: 'Cancel',
        Role: 'Role *',
        FirstName: 'First Name',
        LastName: 'Last Name',
        Email: 'Email',
        Organization: 'Organization *',
        integratorsExistsMessage: `A User with the given email address already exists.
            Admin Partners and Partners can only belong to one organization.
            If you would like to add this user to another organization, you will need to use a different email address`,
        userExistsMessage:
            'A User with the given email address already exists.',
        pmExistsMessage:
            "{0} is already a registered user with the email address {1}. Would you like to assign new properties to this user's profile?"
    },
    addOrganisationModal: {
        modalHeading: 'Add Organization',
        cancelButton: 'CANCEL',
        createButton: 'CREATE',
        textFieldLabel: 'Organization Name',
        pageDescription:
            'Organizations are our Integrator Partners who are responsible for selling, configuring OnStream solutions at different properties.'
    },
    addWidgetModal: {
        modalHeading: 'Create a New',
        inputSelectType: 'Type',
        cancelButton: 'Close',
        submit: 'Create',
        widgetType: 'Widget Type',
        pageDescription: 'Choose a type of widget to get started.',
        widgetDescription: 'Widget Description'
    },
    smartBoxDetailsFormText: {
        alertMessage:
            "Find the Location ID of your SMARTBOX in the SMARTBOX Configurator. If you don't have the Location ID right now, you can enter a temporary placeholder and change it later. The Location ID must be accurate, and match the SMARTBOX Chassis Number, before you can activate OnStream for this property.",
        textField: ['SMARTBOX Location ID', 'Serial Number'],
        button: 'Save'
    },
    addPropertyText: {
        modalHeading: 'Add Property',
        editModalHeading: 'Edit Property',
        cancelButton: 'CANCEL',
        createButton: 'CREATE',
        editButton: 'SAVE',
        propertyTextField: 'Property Name',
        propertyDescriptionTextField: 'Property Description'
    },
    addGroupModal: {
        modalHeading: 'Create Group',
        cancelButton: 'CANCEL',
        createButton: 'CREATE'
    },
    duplicatePropertyModal: {
        modalHeading: 'Duplicate Property',
        cancelButton: 'CANCEL',
        createButton: 'SAVE',
        modalSubheading:
            'Duplication will include all pages, elements and assets'
    },
    movePropertyModal: {
        modalSubheading:
            'property into an alternative organization or property group',
        modalHeading: 'Move Property',
        cancelButton: 'CANCEL',
        createButton: 'SAVE'
    },

    railGenerator: {
        RailContents: 'Rail Contents',
        Widget: 'Widget',
        showAll: 'Show all',
        showInLine: 'Show In Line'
    },
    pageGenerator: {
        Rails: 'Rails',
        AddRail: 'Add Rail',
        showAll: 'Show all',
        showInLine: 'Show In Line',
        bgMessageDefaultImage:
            'Background is set to the DISH pre-set background image.',
        bgMessageBlack: 'Background is currently being set to black ',
        bgMessageImage: 'Background is set to the selected image.',
        bgMessageVideo:
            'Background is set to the selected video. Audio is disabled.',
        bgMessageHeroCarousel:
            'Your background is currently being set by the Hero Carousel.'
    },
    headerGenerator: {
        welcome: 'Welcome Header',
        desc: 'The Welcome Header appears on top of the image background as an overlay.'
    },
    rewardsGenerator: {
        rewardsButtonText: 'Rewards',
        modalTitle: 'Rewards Message',
        currentRewardsMessageSubHeader: 'Current Rewards Member*',
        currentRewardsMessage:
            'Thanks for being a loyal rewards member of Ocean View Hotels. You can spend your reward points on amenities at hundreds of locations worldwide.',
        currentRewardsButtonText: 'Spend Rewards',
        notARewardSubHeader: 'Not a Rewards Member*',
        notARewardMessage:
            'Learn about becoming a rewards member of Ocean View Hotels. You can spend your reward points on amenities at hundreds of locations worldwide.',
        notARewardButtonText: 'Earn Rewards',
        rewardNASubHeader: 'Rewards Information Not Available*',
        rewardNAMessage:
            'Ocean View Hotels Reward Members can spend points on amenities at hundreds of locations worldwide.',
        rewardNAButtonText: 'Earn Rewards',
        rewardsUrlHelperText: 'Will generate a QR code for the guest.'
    },
    deletePropertyMessage: {
        successDeleteMeassge: 'Property deleted successfully',
        errorDeleteMessage: 'Property delete failed'
    },
    deleteElementMessage: {
        successDeleteMeassge: 'Element deleted successfully',
        errorDeleteMessage: 'Element delete failed'
    },
    duplicateElementMessage: {
        pageDescription:
            'Duplication will include all rails, tools, assets, and copy.',
        railDescription:
            'Duplication will include all  tools, assets, and copy.',
        toolDescription: 'Duplication will include all  assets, and copy.'
    },
    organizationsComponentText: {
        organizationsHeading: 'Organizations',
        organizationInfoText: 'Make changes to the Organizations',
        addOrganizationButton: 'Organization',
        addOrgSuccess: 'Organization Added Successfully',
        addOrgFailure: 'Something went wrong'
    },
    organizationsTableText: {
        tableHeader: 'Manage Organization',
        sortText: 'Sort',
        viewPropertiesText: 'View Properties',
        viewUsersText: 'View Users',
        editTooltip: 'Edit Organizations',
        deleteToolTip: 'Delete Organizations'
    },
    editOrganizationText: {
        accessibilityAriaLabel: 'Edit Organization Modal',
        modalHeading: 'Edit Organization',
        textField: {
            label: 'Organization Name'
        },
        cancelButton: 'CANCEL',
        submitButton: 'SUBMIT',
        editSnackbarMsg: 'Organization Name Edited',
        editSnackbarErrorMsg: 'Failed To Edit Organization'
    },
    deleteOrganizationText: {
        accessibilityAriaLabel: 'Delete Organization Modal',
        modalHeading: 'Warning, you are about to delete this account',
        modalSubheading:
            'Deleting an organization removes all users  and properties for that account from the CMP, this cannot be undone',
        cancelButton: 'CANCEL',
        submitButton: 'CONFIRM',
        deleteSnackbarMsg: 'Organization Name Deleted',
        deleteSnackbarErrorMsg: 'Failed To Delete Organization',
        deleteTextField: "Type \u00a0'DELETE'\u00a0 To confirm:\u00a0\u00a0"
    },
    users: {
        heading: 'Manage Users',
        infoText: 'Create and modify users.',
        addUserButton: 'ADD USER',
        snackbarErrorMsg: 'Something went wrong',
        defaultDropdownName: 'All Organizations',
        dropdownLabel: 'Organization',
        search: 'Search Users',
        EditButtonToolTip: 'Edit',
        DeleteButtonTooltip: 'Delete',
        noResultsFoundTableMessage: 'No Results',
        searchTableMessage:
            'Search for users by their name, email, organization or role',
        noUsersMessage: 'No users found',
        viewProperties: 'View Properties'
    },
    assets: {
        heading: 'Assets',
        info: 'Manage the images and videos available for your widgets',
        uploadAssetButtonText: 'UPLOAD ASSET',
        uploadAssetInfoMessage:
            'Recommendations for images and video: 16:9 aspect ratio (min. 1280x720px, max. 3840x2160px)',
        modalHeading: 'Upload Asset',
        DownloadButtonToolTip: 'Download',
        downloadError: 'Error while downloading the file.',
        downloadStartInfoMessage: 'Your download will be completed shortly',
        DeleteButtonTooltip: 'Delete',
        name: 'Asset Name',
        save: 'Save',
        cancel: 'Cancel',
        upload: 'Upload',
        chooseFile: 'Choose a file',
        uploadSuccess: 'Asset uploaded successfully',
        editAssetModal: 'Edit Asset',
        associationsModal: 'Associations',
        noAssetAvailable: 'No Assets available',
        nameAlreadyExists: 'An asset with this name already exists.',
        invalidAssetError: 'Please upload a jpg, jpeg, png, mp4 or mov file',
        assetSizeExceeded:
            'size exceeds limit. Please upload an asset of smaller size.',
        uploadRetryMessage: 'Error uploading. Please try to upload again.',
        assetsTable: {
            name: 'Asset Name',
            file_extension: 'TYPE',
            size: 'Size',
            associations: 'Attached to'
        },
        assetAssociationTable: {
            header: 'Attached To',
            cell_1: 'Type',
            cell_2: 'Location'
        },
        delteAssetModal: {
            title: 'Delete Asset?',
            description: 'This asset will be deleted from your asset library.',
            yesButtonText: 'delete',
            noButtonText: 'cancel'
        }
    },
    preview: {
        preview: 'Preview',
        previewPage: 'Preview Page',
        exit: 'Exit Preview',
        error: 'Error occurred while getting the page preview'
    },
    generalSection: {
        heading: 'General Section'
    },
    faqSection: {
        heading: 'Property FAQ',
        description:
            'property specific frequently asked questions that directs guests to articles, websites, email lists, and online forums',
        question: 'What if I am having trouble watching my TV?',
        answer: ' We’re sorry for the inconvenience. Contact your Property Manager at (XXX) XX-XXX.',
        helperTextQuestion: 'Question Helper',
        helperTextAnswer: 'Enter Answer',
        AddButton: 'Add A Question',
        incomplete: ' *Incomplete Field',
        modalHeader: 'Delete FAQ?',
        deleteModalDescriptionProperty:
            'This FAQ will be removed from settings page, and will be erased from the property details page',
        deleteModalDescriptionGeneralSection:
            'This FAQ will be removed from settings page, and will be erased from the general section page',
        deleteModalCancelButton: 'cancel',
        deleteModalSbmitButton: 'Yes, Delete'
    },
    canvas: {
        cancel: 'Cancel',
        save: 'Save',
        search: 'Search',
        back: 'Back',
        preview: 'Preview',
        background: 'Background',
        saveAndPublish: 'Save & Publish',
        fillRequiredFieldsError: 'Please fill in the required fields',
        widgetNotSupported: 'This tool is not supported on this rail',
        widgetAlreadyAdded: 'This tool is already added to this rail',
        railNotSupported: 'This rail is not supported on this page',
        railAlreadyAdded: 'This rail is already added to this page',
        tabs: {
            template: 'Templates',
            create: 'Create',
            images: 'Images',
            videos: 'Videos'
        },
        tooltips: {
            addRail: 'Add Rail',
            addImage: 'Add Image',
            deleteImage: 'Delete Image',
            addVideo: 'Add Video',
            deleteVideo: 'Delete Video',
            addTool: 'Add Tool',
            addVideoOrImage: 'Add Image or Video',
            setBackgroundTooltip: 'Set Background with Image or Video',
            setBackgroundImageTooltip: 'Set Background with Image',
            dragAndDropImage: 'Drag and Drop Image',
            dragAndDropVideo: 'Drag and Drop Video',
            dragAndDropRail: 'Drag and Drop Rail',
            dragAndDropTool: 'Drag and Drop Tool',
            deleteWidgets: 'Delete this widget'
        },
        widgets: {
            moreInfo: {
                headlinePlaceholder: 'Enter Headline',
                subHeadingPlaceholder: 'Enter Sub Heading',
                bodyTextPlaceholder: 'Enter Body Text',
                urlPlaceholder: 'Enter URl',
                buttonLarge: 'More Information',
                buttonMini: 'MORE INFO'
            },
            liveChannel: {
                live: 'LIVE',
                tvProgram: 'TV Program',
                tvProgramDesc: 'TV Program Description...',
                bodyText: 'TV PROGRAM SEASON AND EPISODE • XM Left',
                watchLive: 'Watch live'
            }
        },
        confirmationModal: {
            publish: {
                headline: 'Override Current Elements?',
                firstText:
                    'Saving or publishing this page will modify current elements on one or more pages. To view these instances, go to the element library.',
                secondText:
                    'Would you still like to publish changes across all instances?',
                positiveButton: 'Yes, Modify',
                negativeButton: 'No, Cancel'
            },
            exit: {
                headline: 'Leave Page?',
                firstText:
                    'You have unsaved changes. Your changes will be discarded if you leave this page before saving or publishing.',
                secondText: 'Would you like to continue?',
                positiveButton: 'Yes, Leave',
                negativeButton: 'No, Cancel'
            }
        }
    },
    activityMonitoring: {
        heading: 'User Activity Logs',
        secondaryHeading: ' Property Details',
        filterHeader: ' Filter'
    },
    noResultsFound: {
        header: 'No Results Found',
        searchHelperHeader: 'Search Help'
    },
    zones: {
        zoneDeleteDescription:
            'Deleting this zone will cause all room devices associated with this zone to revert to default content.',
        eventDeleteDescription:
            'Deleting this event will cause all room devices associated with this event to revert to zone content or default content. Depending on whether the device is attached to a zone or not.',
        eventDeleteHeading: 'Delete Event?',
        zoneDeleteHeading: 'Delete Zone?',
        deleteSubheading: 'Would you like to continue?',
        nameAlreadyExists: 'A zone with this name already exists.',
        selectRooms: 'Select Rooms'
    }
};

export const ImageFieldTexts = {
    upload: 'Upload'
};

export default cmpTexts;
