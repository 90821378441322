import SearchIcon from '@mui/icons-material/Search';
import { TextFieldProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { pxToRem } from '../utils/stylesUtils';
import CustomTextField from './textField';

interface SearchTextFieldProps {
    initialSearchValue: string;
    placeholder: string;
    handleOnChange: (value: string) => void;
    sx?: {};
    fullWidth?: boolean;
}

const STANDARD_VARIANT_OVERRIDE_STYLE = {
    '& .MuiInputBase-formControl': {
        height: pxToRem(42)
    },
    '.MuiInput-root:before': {
        borderBottom: '2px solid #171725'
    },
    input: {
        '&::placeholder': {
            color: '#171725',
            opacity: 0.8
        }
    }
};

function SearchTextField(props: TextFieldProps & SearchTextFieldProps) {
    const {
        initialSearchValue,
        placeholder,
        handleOnChange,
        sx,
        fullWidth,
        variant = 'outlined',
        ...rest
    } = props;
    const [searchText, setSearchText] = useState(initialSearchValue);

    const debouncedSearch = debounce((val) => {
        handleOnChange(val);
    }, 400);

    const handleSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value.trimStart();
            setSearchText(val);
            debouncedSearch(val);
        },
        []
    );

    let finalSx = sx;

    if (variant === 'standard') {
        finalSx = {
            ...sx,
            ...STANDARD_VARIANT_OVERRIDE_STYLE
        };
    }

    return (
        <CustomTextField
            variant={variant}
            fullWidth={fullWidth}
            sx={finalSx}
            value={searchText}
            placeholder={placeholder}
            onChange={handleSearch}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        sx={
                            variant === 'standard'
                                ? { paddingLeft: pxToRem(16) }
                                : {}
                        }
                    >
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
            {...rest}
        />
    );
}

export default SearchTextField;
