import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import React from 'react';
import { CloseIconComponent } from '../../../components/closeIconComponent';
import { pxToRem, StylesDictionary } from '../../../utils/stylesUtils';
import { Platform } from './platforms';

interface PreviewPlatformSelectionDialogProps {
    platforms: Platform[];
    onPlatformSelected: (platform: Platform) => void;
    onCancel: () => void;
    open: boolean;
}

const styles: StylesDictionary = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: pxToRem(16)
    },
    heading: {
        color: '#000',
        fontSize: pxToRem(28)
    }
};

const PreviewPlatformSelectionDialog = (
    props: PreviewPlatformSelectionDialogProps
): React.ReactElement => {
    const { platforms, onCancel, onPlatformSelected, open } = props;

    return (
        <Dialog open={open} onClose={onCancel} fullWidth maxWidth="sm">
            <Box sx={styles.container}>
                <Typography variant="h5" sx={styles.heading}>
                    Select a Platform to Preview
                </Typography>

                <CloseIconComponent onClick={onCancel} />
            </Box>

            <List>
                {platforms.map((platform: Platform) => {
                    return (
                        <ListItemButton
                            onClick={() => onPlatformSelected(platform)}
                            divider
                            key={platform.name}
                        >
                            <ListItemText primary={platform.displayName} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Dialog>
    );
};

export default PreviewPlatformSelectionDialog;
