import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    EditMaintenanceStatusResponse,
    MaintenanceStatus
} from './actionPayload';

export const FETCH_MAINTENANCE_STATUS = 'FETCH_MAINTENANCE_STATUS';
export const FETCH_MAINTENANCE_STATUS_RESPONSE =
    'FETCH_MAINTENANCE_STATUS_RESPONSE';
export const UPDATE_MAINTENANCE_STATUS = 'UPDATE_MAINTENANCE_STATUS';
export const UPDATE_MAINTENANCE_STATUS_RESPONSE =
    'UPDATE_MAINTENANCE_STATUS_RESPONSE';
export const LOADER_RESET = 'LOADER_RESET';

// reset Loader
export const resetLoader = (): Action => {
    return {
        type: LOADER_RESET
    };
};

export const fetchMaintenanceStatus = (): Action => {
    return {
        type: FETCH_MAINTENANCE_STATUS
    };
};

export const fetchMaintenanceStatusResponse = (
    error: boolean,
    enabled: boolean,
    image_url?: string | undefined
): ActionPayloadOrError<MaintenanceStatus> => {
    return {
        type: FETCH_MAINTENANCE_STATUS_RESPONSE,
        error,
        payload: { enabled, image_url }
    };
};

export const updateMaintenanceStatus = (
    enabled: boolean
): ActionPayload<EditMaintenanceStatusResponse> => {
    return {
        type: UPDATE_MAINTENANCE_STATUS,
        payload: { enabled }
    };
};

export const updateMaintenanceStatusResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: UPDATE_MAINTENANCE_STATUS_RESPONSE,
        message,
        error
    };
};
