import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import propertyListReducer from './propertylist/reducer';
import propertyFieldValueReducer from './propertyValue/reducer';
import loginReducer from './login/reducer';
import userOperationsReducer from './userOperations/reducer';
import ElementsListReducer from './elementsList/reducer';
import ElementReducer from './element/reducer';
import Evolve2Reducer from './evolve2/reducer';
import OrganizationsReducer from './organizations/reducer';
import PreviewReducer from './preview/reducer';
import AssetReducer from './assets/reducer';
import UsersReducer from './users/reducer';
import GeneralSectionReducer from './generalSection/reducer';
import MaintenanceReducer from './maintenance/reducer';
import UserActivityReducer from './activityMonitoring/reducer';
import ZonesReducer from './zones/reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['login', 'userOperation', 'propertyList', 'organizations'] // only listed reducers will be persisted
};

const rootReducer = combineReducers({
    propertyList: propertyListReducer,
    login: loginReducer,
    propertyFieldValue: propertyFieldValueReducer,
    userOperation: userOperationsReducer,
    elementsList: ElementsListReducer,
    element: ElementReducer,
    organizations: OrganizationsReducer,
    preview: PreviewReducer,
    assets: AssetReducer,
    users: UsersReducer,
    generalSection: GeneralSectionReducer,
    evolve2: Evolve2Reducer,
    maintenance: MaintenanceReducer,
    userActivity: UserActivityReducer,
    zones: ZonesReducer
});

export default persistReducer(persistConfig, rootReducer);
