import { AxiosRequestConfig } from 'axios';
import { head } from 'lodash';
import { API_URL } from '../../api/apiConstants';
import { insertPropertyIdToPath } from '../../api/apiUtils';
import axios from '../../api/axios';
import { AssetStatus, CreateAssetsRequest } from './actionPayload';

export function fetchAllAssets(owner_id: string) {
    return axios.get(
        `${insertPropertyIdToPath(
            API_URL.listAssets,
            owner_id
        )}?ownerId=${owner_id}`
    );
}

export function uploadAsset(body: CreateAssetsRequest) {
    const formData = new FormData();
    const { owner_id, owner_type, files } = body;
    const { file, id } = head(files) as { file: File; id: string };
    formData.append('name', file.name);
    formData.append('owner_type', owner_type);
    formData.append('owner_id', owner_id);
    if (file !== null) formData.append('file', file);

    return new Promise((resolve) => {
        const config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'multipart/form-data' },
            signal: body.cancelUploadController?.signal,
            onUploadProgress: (progressEvent) => {
                body.onUploadProgress?.(
                    (progressEvent.loaded / progressEvent.total) * 90,
                    id,
                    AssetStatus.UPLOADING
                );
            }
        };

        axios
            .post(
                insertPropertyIdToPath(API_URL.uploadAsset, owner_id),
                formData,
                config
            )
            .then((response) => {
                // @ts-ignore
                if (response.code === 200) {
                    body.onUploadProgress?.(100, id, AssetStatus.SUCCESS);
                    setTimeout(() => {
                        resolve(response);
                    }, 500);
                } else {
                    body.onUploadProgress?.(100, id, AssetStatus.FAILED);
                    resolve(response);
                }
            })
            .catch((error) => {
                console.log(error);
                body.onUploadProgress?.(100, id, AssetStatus.FAILED);
                resolve(error);
            });
    });
}

export function deleteAsset(assetId: string, propertyId: string) {
    return axios.delete(
        `${insertPropertyIdToPath(API_URL.listAssets, propertyId)}/${assetId}`
    );
}

export function editAsset(
    asset_id: string,
    updated_name: string,
    owner_id: string
) {
    const body = {
        id: asset_id,
        name: updated_name,
        owner_id: owner_id
    };

    return axios.post(
        insertPropertyIdToPath(API_URL.editAsset, owner_id),
        body
    );
}
