import {
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Typography
} from '@mui/material';
import React from 'react';
import { pxToRem } from '../../../../utils/stylesUtils';

interface PropertySectionConsentCheckboxProps {
    checked: boolean;
    handleChange: (value: boolean) => void;
    description: string;
    helperText?: string;
}

const PropertySectionConsentCheckbox = (
    props: PropertySectionConsentCheckboxProps
): React.ReactElement => {
    const { checked, handleChange, description, helperText } = props;

    return (
        <>
            <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                }
                label={
                    <Typography
                        sx={{
                            color: '#171725',
                            padding: `${pxToRem(12)} 0 0`,
                            fontWeight: '500',
                            fontSize: pxToRem(18),
                            lineHeight: pxToRem(20)
                        }}
                    >
                        {description}
                    </Typography>
                }
            />
            <FormHelperText error={true}>{helperText}</FormHelperText>
        </>
    );
};

export default PropertySectionConsentCheckbox;
