const descriptionTexts: { [key: string]: string } = {
    'swimlane--widgets': 'This rail creates a swimlane to display widgets.',
    'hero-carousel':
        'This rail adds a hero-sized carousel to display content or widgets.',
    'swimlane--widgets-large':
        'This rail creates a swimlane to display large widgets.',
    'widget--live-channel': 'Feature a live channel for easy access.',
    'widget--weather':
        'Live weather and forecasts based on the property zip code.',
    'widget--more-info':
        'Add a carousel slide to highlights what makes your property unique. For example, information on amenities or local points of interest.',
    'widget--sports':
        'A widget to show game results and connect to live games.',
    'widget--static-ad': 'Include an ad in the hero carousel.',
    'widget--more-info-video':
        'Add a carousel slide to highlights what makes your property unique. For example, information on amenities or local points of interest.',
    'widget--deep-link-web-url':
        'Use links to bring the viewer directly to content or features that are outside of OnStream.',
    'widget--deep-link-json':
        'Use links to bring the viewer directly to content or features that are outside of OnStream.',
    'widget--notification':
        'Draw attention important events or reminders with a pop-up notification.'
};

const shortDescriptionTexts: { [key: string]: string } = {
    'swimlane--widgets': 'Create a rail to display widgets',
    'hero-carousel':
        'Create a hero sized carousel to display content or widgets',
    'swimlane--widgets-large': 'Create a rail to display large widgets',
    'widget--live-channel': 'Feature a live channel.',
    'widget--weather': 'Live weather and forecasts.',
    'widget--more-info': 'Share information about your property.',
    'widget--sports': 'Game results and connect to live games.',
    'widget--static-ad': 'Add an advertisement.',
    'widget--more-info-video': 'Share information about your property.',
    'widget--deep-link-web-url':
        'Link to a website or application with a standard URL',
    'widget--deep-link-json':
        'Link to a destination within an application with JSON.',
    'widget--notification': 'A pop-up alert.'
};

export const description = (alias: string) => {
    console.log(descriptionTexts[alias]);
    return descriptionTexts[alias];
};
export const shortDescription = (alias: string) => {
    console.log(shortDescriptionTexts[alias]);
    return shortDescriptionTexts[alias];
};
