import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    CreateUserData,
    DeleteUserData,
    GetAllUserResponse,
    UpdateUserData
} from './actionPayload';
import { UserDetails, UserExistsData, UserRole } from './model';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_RESPONSE = 'CREATE_USER_RESPONSE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_RESPONSE = 'DELETE_USER_RESPONSE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USERS_RESPONSE = 'UPDATE_USERS_RESPONSE';
export const RESET_USER_OPERATION_LOADER = 'RESET_USER_OPERATION_LOADER';
export const RESET_USER_EXISTS_LOADER = 'RESET_USER_EXISTS_LOADER';
export const CHECK_USER_EXISTS = 'CHECK_USER_EXISTS';
export const CHECK_USER_EXISTS_RESPONSE = 'CHECK_USER_EXISTS_RESPONSE';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_RESPONSE = 'GET_ALL_USERS_RESPONSE';
export const RESET_USERS_LOADER = 'RESET_USERS_LOADER';

export const createUser = (
    email: string,
    firstName: string,
    lastName: string,
    role: UserRole,
    propertyIds: string[],
    organizationId: string
): ActionPayload<CreateUserData> => {
    return {
        type: CREATE_USER,
        payload: {
            email,
            firstName,
            lastName,
            role,
            propertyIds,
            organizationId
        }
    };
};

export const createUserResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: CREATE_USER_RESPONSE,
        message,
        error
    };
};

export const checkUserExists = (email: string): ActionPayload<string> => {
    return {
        type: CHECK_USER_EXISTS,
        payload: email
    };
};

export const checkUserExistenceResponse = (
    error: boolean,
    message: string,
    userExists?: UserExistsData
): ActionPayloadOrError<UserExistsData> => {
    return {
        type: CHECK_USER_EXISTS_RESPONSE,
        payload: userExists,
        message,
        error
    };
};

export const deleteUser = (
    role: string,
    userId: string
): ActionPayload<DeleteUserData> => {
    return {
        type: DELETE_USER,
        payload: { role, userId }
    };
};

export const deleteUserResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: DELETE_USER_RESPONSE,
        message,
        error
    };
};

export const updateUser = (
    userId: string,
    firstName: string,
    lastName: string,
    propertyIds: string[],
    shouldUpdateName?: boolean
): ActionPayload<UpdateUserData> => {
    return {
        type: UPDATE_USER,
        payload: {
            userId,
            firstName,
            lastName,
            propertyIds,
            shouldUpdateName: shouldUpdateName ?? true
        }
    };
};

export const updateUsersResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: UPDATE_USERS_RESPONSE,
        message,
        error
    };
};

export const resetUsersLoader = (): Action => {
    return {
        type: RESET_USERS_LOADER
    };
};

export const resetUserOperationLoader = (): Action => {
    return {
        type: RESET_USER_OPERATION_LOADER
    };
};

export const resetUserExistsLoader = (): Action => {
    return {
        type: RESET_USER_EXISTS_LOADER
    };
};

export const getAllUsers = (): Action => {
    return {
        type: GET_ALL_USERS
    };
};

export const getAllUsersResponse = (
    data: UserDetails[],
    error: boolean,
    message: string
): ActionPayloadOrError<GetAllUserResponse> => {
    return {
        type: GET_ALL_USERS_RESPONSE,
        payload: { data },
        message,
        error
    };
};
