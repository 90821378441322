import ClearIcon from '@mui/icons-material/Clear';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp';
import { IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import TextField from '../../components/textField';
import { selectElementData } from '../../redux/element/reducer';
import { WidgetSkeleton } from '../../redux/element/model';
import { ElementCategory } from '../../redux/elementsList/model';
import { pxToRem, StylesDictionary } from '../../utils/stylesUtils';

interface TitleInputProps {
    setIsEdited: (isEdited: boolean) => void;
    formik: FormikProps<WidgetSkeleton>;
    inputBoxDisplay: boolean;
    setInputBoxDisplay: (inputBoxDisplay: boolean) => void;
    enableEdit?: boolean;
}

const ElementNameTextField = styled(TextField)(() => ({
    width: '40%',
    marginTop: '1rem',
    marginBottom: '0.4rem'
}));

const VerticalRulesIcon = styled(HorizontalRuleSharpIcon)(() => ({
    boxSizing: 'border-box',
    height: `${pxToRem(23)}`,
    width: `${pxToRem(1)}`,
    border: `${pxToRem(1)} solid #979797`
}));

const ElementNameDiv = styled('div')(() => ({
    display: 'flex',
    marginTop: pxToRem(15)
}));

const styles: StylesDictionary = {
    customInputFont: {
        fontSize: 18
    },
    containerMargin: { marginLeft: '3.5vw' }
};

const PageTitle = styled('h1')(() => ({
    marginBottom: 0,
    textAlign: 'left',
    fontSize: 28,
    fontWeight: 500,
    color: '#F01446',
    marginTop: 0
}));

const CreateIconDiv = styled('div')(() => ({
    marginLeft: 8,
    display: 'flex',
    alignItems: 'center'
}));

function TitleInput(props: TitleInputProps) {
    const {
        setIsEdited,
        formik,
        inputBoxDisplay,
        setInputBoxDisplay,
        enableEdit = false
    } = props;
    const location = useLocation();
    const elementValue = useSelector(selectElementData);
    const routerState = location?.state;

    useEffect(() => {
        formik?.values?.name && !formik.errors && setIsEdited(true);
    });

    function handlePageName() {
        setIsEdited(true);
        setInputBoxDisplay(!inputBoxDisplay);
    }

    function handleNameEditCancel() {
        setInputBoxDisplay(false);
        formik.setFieldValue(`name`, elementValue.name);
    }

    const handleEnterKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            setIsEdited(true);
            setInputBoxDisplay(!inputBoxDisplay);
        }
    };

    const elementName =
        routerState?.widget?.type === ElementCategory.PAGE
            ? 'Page'
            : routerState?.widget?.type === ElementCategory.RAIL
            ? 'Rail'
            : routerState?.widget?.type === ElementCategory.WIDGET
            ? 'Widget'
            : '';

    return (
        <>
            {inputBoxDisplay ? (
                <ElementNameTextField
                    required
                    placeholder={`Enter ${elementName} Name`}
                    variant="standard"
                    id="id"
                    maxLength={50}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handlePageName}
                                    disabled={!formik?.values?.name}
                                >
                                    <DoneIcon />
                                </IconButton>
                                <VerticalRulesIcon />
                                <IconButton
                                    onClick={handleNameEditCancel}
                                    disabled={
                                        !formik?.values?.name ||
                                        !elementValue.name
                                    }
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        style: styles.customInputFont
                    }}
                    onKeyPress={handleEnterKeyPress}
                    error={Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                    {...formik.getFieldProps('name')}
                    value={formik?.values?.name}
                />
            ) : (
                <ElementNameDiv>
                    <PageTitle>{formik?.values?.name.trimStart()}</PageTitle>
                    {enableEdit && (
                        <CreateIconDiv>
                            <CreateIcon onClick={handlePageName} />
                        </CreateIconDiv>
                    )}
                </ElementNameDiv>
            )}
        </>
    );
}

export default TitleInput;
