import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import { UserRole } from '../users/model';

import {
    ADD_BREADCRUMB,
    AUTHENTICATED_STATUS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_RESPONSE_FAILURE,
    FORGOT_PASSWORD_RESPONSE_SUCCESS,
    FORGOT_PASSWORD_SUBMIT,
    FORGOT_PASSWORD_SUBMIT_RESPONSE,
    NEW_PASSWORD_RESPONSE,
    POP_BREADCRUMB,
    PURGE_LOGIN_DATA,
    REPLACE_BREADCRUMB,
    SAVE_NEW_ELEMENT,
    SET_FORGOT_PASSWORD,
    SET_FORGOT_PASSWORD_SUBMIT_STATUS,
    SET_LOGIN_STATUS,
    USER_LOGGING_IN,
    USER_LOGIN_FAILURE,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT_RESPONSE
} from './action';
import {
    SetNewPassword,
    ForgotPasswordSubmit,
    UserAuthenticatedStatus,
    SaveNewElement
} from './actionPayload';
import {
    BreadCrumb,
    ForgotPasswordStatus,
    ForgotPasswordSubmitStatus,
    LoginResponse,
    LoginStatus,
    LoginType,
    UserLoginPayloadType
} from './model';
import { LoginState, initialLoginState } from './state';

const reducer = (
    state: LoginState = initialLoginState,
    action: any
): LoginState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialLoginState,
    action: ActionPayload<any>
): LoginState => {
    switch (action.type) {
        case POP_BREADCRUMB: {
            const newBreadCrumb = state.breadCrumb;
            newBreadCrumb.pop();
            return {
                ...state,
                breadCrumb: newBreadCrumb
            };
        }
        case SET_FORGOT_PASSWORD_SUBMIT_STATUS: {
            return {
                ...state,
                forgotPasswordSubmitStatus:
                    ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_NOT_INITIATED
            };
        }
        case USER_LOGGING_IN: {
            return {
                ...state,
                status: LoginStatus.LOGGING_IN
            };
        }
        case PURGE_LOGIN_DATA:
            return initialLoginState;
        case SET_FORGOT_PASSWORD: {
            return {
                ...state,
                loadingStatus: false,
                forgotPasswordStatus:
                    ForgotPasswordStatus.FORGOT_PASSWORD_NOT_INITIATED
            };
        }
        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state = initialLoginState,
    action: ActionPayload<
        | UserLoginPayloadType
        | LoginResponse
        | SetNewPassword
        | string
        | ForgotPasswordSubmit
        | ForgotPasswordStatus
        | UserAuthenticatedStatus
        | SaveNewElement
        | BreadCrumb[]
        | BreadCrumb
        | LoginType
    >
): LoginState => {
    switch (action.type) {
        case SAVE_NEW_ELEMENT: {
            return {
                ...state,
                newCreatedElement: action.payload as {
                    id: string;
                    type: string;
                }
            };
        }
        case REPLACE_BREADCRUMB: {
            return {
                ...state,
                breadCrumb: action.payload as BreadCrumb[]
            };
        }
        case ADD_BREADCRUMB: {
            const elementTypes = [
                'new page screen',
                'new rail screen',
                'new widget screen'
            ];
            if (
                (action.payload as BreadCrumb).link.split('/').pop() ===
                    'create' &&
                elementTypes.indexOf(
                    (action.payload as BreadCrumb).name.toLowerCase()
                ) === -1
            ) {
                return { ...state };
            }
            let newBreadCrumb = [...state.breadCrumb];
            const ind = newBreadCrumb.findIndex(
                (b: BreadCrumb) =>
                    b.name === (action.payload as BreadCrumb).name &&
                    b.link === (action.payload as BreadCrumb).link
            );
            if (ind > -1) {
                newBreadCrumb = newBreadCrumb.slice(0, ind + 1);
            } else if (
                newBreadCrumb.slice(-1).pop()?.link ===
                    (action.payload as BreadCrumb).link &&
                (action.payload as BreadCrumb).link.split('/').pop() !==
                    'create'
            ) {
                newBreadCrumb[newBreadCrumb.length - 1] =
                    action.payload as BreadCrumb;
            } else {
                newBreadCrumb.push(action.payload as BreadCrumb);
            }
            return {
                ...state,
                breadCrumb: newBreadCrumb
            };
        }
        case SET_LOGIN_STATUS: {
            return {
                ...state,
                status: action.payload as LoginType
            };
        }

        case USER_LOGIN_SUCCESS: {
            return {
                ...state,
                status:
                    (action.payload as LoginResponse).challengeName ===
                    LoginStatus.NEW_PASSWORD_REQUIRED
                        ? LoginStatus.NEW_PASSWORD_REQUIRED
                        : LoginStatus.SUCCESS,
                details: action.payload as LoginResponse
            };
        }

        case FORGOT_PASSWORD: {
            return {
                ...state,
                loadingStatus: true
            };
        }
        case FORGOT_PASSWORD_SUBMIT: {
            return {
                ...state,
                forgotPasswordSubmitStatus:
                    ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_INITIATED
            };
        }

        case FORGOT_PASSWORD_RESPONSE_SUCCESS: {
            return {
                ...state,
                loadingStatus: false,
                forgotPasswordStatus: ForgotPasswordStatus.FORGOT_PASSWORD_DONE
            };
        }

        case AUTHENTICATED_STATUS: {
            return {
                ...state,
                isAuthenticated: (action.payload as UserAuthenticatedStatus)
                    .status
            };
        }

        default: {
            return state;
        }
    }
};

const actionWithPayloadOrErrorReducer = (
    state = initialLoginState,
    action: ActionPayloadOrError<any>
): LoginState => {
    switch (action.type) {
        case NEW_PASSWORD_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.payload,
                    status: LoginStatus.NEW_PASSWORD_FAILED
                };
            }
            return {
                ...state,
                status: LoginStatus.NEW_PASSWORD_SET_SUCCESSFULLY
            };
        }
        case FORGOT_PASSWORD_RESPONSE_FAILURE: {
            return {
                ...state,
                loadingStatus: false,
                error: action.error,
                forgotPasswordStatus:
                    ForgotPasswordStatus.FORGOT_PASSWORD_FAILED
            };
        }
        case FORGOT_PASSWORD_SUBMIT_RESPONSE: {
            return {
                ...state,
                forgotPasswordSubmitStatus: action.payload.status,
                error: action.error
            };
        }
        case USER_LOGIN_FAILURE: {
            return {
                ...state,
                status: action.payload.status,
                error: action.error
            };
        }
        case USER_LOGOUT_RESPONSE: {
            return {
                ...state,
                status: action.payload.status,
                error: action.error
            };
        }
        default:
            return state;
    }
};

export default reducer;

export const selectAuthStatus = (state: coreRootState): boolean => {
    return state.login.isAuthenticated;
};

export const selectLoginstatus = (state: coreRootState): LoginType => {
    return state.login.status;
};

export const selectError = (state: coreRootState): string => {
    return state.login.error;
};

export const selectLoadingStatus = (state: coreRootState): boolean => {
    return state.login.loadingStatus;
};

export const selectForgotPasswordStatus = (
    state: coreRootState
): ForgotPasswordStatus => {
    return state.login.forgotPasswordStatus;
};
export const selectUserRole = (state: coreRootState): UserRole =>
    state.login.details.signInUserSession.idToken.payload['custom:role'];

export const selectSubId = (state: coreRootState) =>
    state.login.details.signInUserSession.idToken.payload.sub;

export const selectForgotPasswordSubmitStatus = (
    state: coreRootState
): ForgotPasswordSubmitStatus | undefined => {
    return state.login.forgotPasswordSubmitStatus;
};

export const selectUserName = (state: coreRootState) =>
    state.login.details.signInUserSession.idToken.payload.name;

export const selectUserEmail = (state: coreRootState) =>
    state.login.details.signInUserSession.idToken.payload.email;

export const selectBreadCrumb = (state: coreRootState): BreadCrumb[] => {
    return state.login.breadCrumb;
};

export const selectNewCreatedElement = (
    state: coreRootState
): { id: string; type: string } => {
    return state.login.newCreatedElement;
};

export const selectUserOrganizationId = (state: coreRootState): string => {
    return state.login.details.attributes['custom:organizationId'] ?? '';
};
