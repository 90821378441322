import { includes, map } from 'lodash';
import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { selectPropertyList } from '../propertylist/reducer';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    CHECK_USER_EXISTS,
    CHECK_USER_EXISTS_RESPONSE,
    CREATE_USER,
    CREATE_USER_RESPONSE,
    DELETE_USER,
    DELETE_USER_RESPONSE,
    GET_ALL_USERS,
    GET_ALL_USERS_RESPONSE,
    RESET_USERS_LOADER,
    RESET_USER_EXISTS_LOADER,
    RESET_USER_OPERATION_LOADER,
    UPDATE_USER,
    UPDATE_USERS_RESPONSE
} from './action';
import {
    CreateUserData,
    DeleteUserData,
    GetAllUserResponse,
    UpdateUserData
} from './actionPayload';
import { UserDetails, UserExistsData, UserRole } from './model';
import { initialUsersState, UsersState } from './state';

const reducer = (
    state: UsersState = initialUsersState,
    action: any
): UsersState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialUsersState,
    action: Action
): UsersState => {
    switch (action.type) {
        case RESET_USER_OPERATION_LOADER: {
            return {
                ...state,
                userOperationLoader: LoadingStatus.NONE
            };
        }

        case RESET_USERS_LOADER: {
            return {
                ...state,
                loader: LoadingStatus.NONE
            };
        }

        case RESET_USER_EXISTS_LOADER: {
            return {
                ...state,
                userExistsLoader: LoadingStatus.NONE
            };
        }

        case GET_ALL_USERS: {
            return {
                ...state,
                loader: LoadingStatus.INITIATED
            };
        }
        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state = initialUsersState,
    action: ActionPayload<
        UpdateUserData | DeleteUserData | string | CreateUserData
    >
): UsersState => {
    switch (action.type) {
        case CREATE_USER: {
            return {
                ...state,
                userOperationLoader: LoadingStatus.INITIATED
            };
        }
        case CHECK_USER_EXISTS: {
            return {
                ...state,
                userExistsLoader: LoadingStatus.INITIATED
            };
        }
        case DELETE_USER:
            return {
                ...state,
                userOperationLoader: LoadingStatus.INITIATED
            };
        case UPDATE_USER:
            return {
                ...state,
                userOperationLoader: LoadingStatus.INITIATED
            };

        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state = initialUsersState,
    action: ActionPayloadOrError<UserExistsData | GetAllUserResponse>
): UsersState => {
    switch (action.type) {
        case CREATE_USER_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    message: action.message ?? 'Error while creating the user.',
                    userOperationLoader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                message: action.message ?? 'User created successfully.',
                userOperationLoader: LoadingStatus.DONE
            };
        }

        case CHECK_USER_EXISTS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    userExistsLoader: LoadingStatus.FAILED
                };
            }

            return {
                ...state,
                userExistsLoader: LoadingStatus.DONE,
                userExistence: action.payload as UserExistsData
            };
        }

        case DELETE_USER_RESPONSE: {
            if (action?.error) {
                return {
                    ...state,
                    message: action.message ?? 'Error while deleting the user.',
                    userOperationLoader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                message: action.message ?? 'User deleted successfully.',
                userOperationLoader: LoadingStatus.DONE
            };
        }

        case UPDATE_USERS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    message: action.message ?? 'Error while updating the user.',
                    userOperationLoader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                message: action.message ?? 'User updated successfully.',
                userOperationLoader: LoadingStatus.DONE
            };
        }

        case GET_ALL_USERS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    message:
                        action.message ?? 'Error while getting the users list.',
                    loader: LoadingStatus.FAILED
                };
            }
            if (action.payload)
                return {
                    ...state,
                    users: (action.payload as GetAllUserResponse).data,
                    loader: LoadingStatus.DONE
                };
            return {
                ...state,
                loader: LoadingStatus.DONE
            };
        }

        default: {
            return state;
        }
    }
};

export const selectUserLoaderStatus = (state: coreRootState): LoadingStatus => {
    return state.users.loader;
};

export const selectUserOperationLoaderStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.users.userOperationLoader;
};

export const selectUserExistsLoaderStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.users.userExistsLoader;
};

export const selectUserExistsData = (
    state: coreRootState
): UserExistsData | undefined => {
    return state.users.userExistence;
};

export const selectUserMessage = (state: coreRootState): string => {
    return state.users.message;
};

export const selectAllUsers = (state: coreRootState): UserDetails[] => {
    return state.organizations.filteredOrganization
        ? state.users.users.filter(
              (u) =>
                  !u.organization_id ||
                  u.organization_id === state.organizations.filteredOrganization
          )
        : state.users.users;
};

export const selectAllAdminIntegrators = (
    state: coreRootState
): UserDetails[] => {
    return selectAllUsers(state).filter(
        (user) => user.role === UserRole.AdminIntegrator
    );
};

export const selectAllPropertyManagers = (
    state: coreRootState
): UserDetails[] => {
    if (!state.organizations.filteredOrganization) {
        return selectAllUsers(state).filter(
            (user) => user.role === UserRole.PropertyManager
        );
    }

    const propertyList = selectPropertyList(state);

    const propertyManagers: string[] = [];

    propertyList.forEach((pl) =>
        pl.properties?.forEach((property) => {
            if (property.attached_users.property_managers !== null) {
                propertyManagers.push(
                    ...map(property.attached_users.property_managers, 'id')
                );
            }
        })
    );

    return selectAllUsers(state).filter(
        (user) =>
            includes(propertyManagers, user.id) &&
            user.role === UserRole.PropertyManager
    );
};

export const selectAllSuperUsers = (state: coreRootState): UserDetails[] => {
    return selectAllUsers(state).filter(
        (user) => user.role === UserRole.SuperUser
    );
};

export const selectAllSuperAdmins = (state: coreRootState): UserDetails[] => {
    return selectAllUsers(state).filter(
        (user) => user.role === UserRole.SuperAdmin
    );
};

export const selectAllIntegrators = (state: coreRootState): UserDetails[] => {
    return selectAllUsers(state).filter(
        (user) => user.role === UserRole.Integrator
    );
};

export const selectAllConsultants = (state: coreRootState): UserDetails[] => {
    if (!state.organizations.filteredOrganization) {
        return selectAllUsers(state).filter(
            (user) => user.role === UserRole.Consultant
        );
    }

    const propertyList = selectPropertyList(state);

    const consultants: string[] = [];

    propertyList.forEach((pl) =>
        pl.properties?.forEach((property) => {
            if (property.attached_users.consultants !== null) {
                consultants.push(
                    ...map(property.attached_users.consultants, 'id')
                );
            }
        })
    );

    return selectAllUsers(state).filter(
        (user) =>
            includes(consultants, user.id) && user.role === UserRole.Consultant
    );
};

export default reducer;
