import { LoadingStatus } from '../common/state';
import { UserDetails, UserExistsData } from './model';

export interface UsersState {
    users: UserDetails[];
    message: string;
    userOperationLoader: LoadingStatus;
    loader: LoadingStatus;
    userExistsLoader: LoadingStatus;
    userExistence?: UserExistsData;
}

export const initialUsersState: UsersState = {
    users: [],
    message: '',
    loader: LoadingStatus.NONE,
    userOperationLoader: LoadingStatus.NONE,
    userExistsLoader: LoadingStatus.NONE
};
