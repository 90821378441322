import { orderBy } from 'lodash';
import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    ADD_COMMENT_TO_ACTIVITY_LOG,
    ADD_COMMENT_RESPONSE,
    FETCH_ACTIVITY_LOGS,
    FETCH_ACTIVITY_LOGS_RESPONSE,
    RESET_ACTIVITY_LOGS_OPERATION_LOADER,
    FETCH_ACTIVITY_LOGS_FILTERS_RESPONSE
} from './action';
import { ActivityLogFilters, emptyActivityLogFilters } from './model';
import {
    ActivityLog,
    initialUserActivityState,
    UserActivityState
} from './state';

const reducer = (
    state: UserActivityState = initialUserActivityState,
    action: any
): UserActivityState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: UserActivityState,
    action: Action
): UserActivityState => {
    switch (action.type) {
        case RESET_ACTIVITY_LOGS_OPERATION_LOADER:
            return {
                ...state,
                operationLoadingStatus: LoadingStatus.NONE
            };

        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state: UserActivityState,
    action: Action
): UserActivityState => {
    switch (action.type) {
        case FETCH_ACTIVITY_LOGS:
            return {
                ...state,
                loadingStatus: LoadingStatus.INITIATED,
                message: '',
                error: ''
            };
        case ADD_COMMENT_TO_ACTIVITY_LOG:
            return {
                ...state,
                operationLoadingStatus: LoadingStatus.INITIATED,
                message: '',
                error: ''
            };
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: UserActivityState = initialUserActivityState,
    action: ActionPayloadOrError<ActivityLog[] | ActivityLogFilters | undefined>
): UserActivityState => {
    switch (action.type) {
        case FETCH_ACTIVITY_LOGS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.message as string,
                    loadingStatus: LoadingStatus.FAILED
                };
            }

            return {
                ...state,
                activityLogs: action.payload as ActivityLog[],
                loadingStatus: LoadingStatus.DONE
            };
        }

        case FETCH_ACTIVITY_LOGS_FILTERS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    error: action.message as string
                };
            }
            return {
                ...state,
                filters: action.payload as ActivityLogFilters
            };
        }
        case ADD_COMMENT_RESPONSE: {
            if (action.error)
                return {
                    ...state,
                    message: action.message as string,
                    operationLoadingStatus: LoadingStatus.FAILED
                };

            return {
                ...state,
                operationLoadingStatus: LoadingStatus.DONE,
                activityLogs: action.payload as ActivityLog[],
                message: action.message as string
            };
        }

        default:
            return state;
    }
};

export const selectActivityLogsFilters = (
    state: coreRootState
): ActivityLogFilters => {
    return state.userActivity.filters ?? emptyActivityLogFilters;
};

export const selectActivityLogs = (state: coreRootState): ActivityLog[] => {
    return orderBy(state.userActivity.activityLogs ?? [], 'time', ['desc']);
};

export const selectLoadingStatus = (state: coreRootState): LoadingStatus => {
    return state.userActivity.loadingStatus;
};

export const selectMessage = (state: coreRootState): string => {
    return state.userActivity.message;
};

export const selectOperationLoadingStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.userActivity.operationLoadingStatus;
};

export default reducer;
