import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    AddOrganization,
    DeleteOrganization,
    EditOrganization,
    GetOrganizationsResponse
} from './actionPayload';
import { Organization } from './model';

export const GET_ALL_ORGANIZATION_LIST = 'GET_ALL_ORGANIZATION_LIST';
export const SET_LOADER = 'SET_LOADER';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const ADD_ORGANIZATION_RESPONSE = 'ADD_ORGANIZATION_RESPONSE';
export const GET_ORGANIZATIONS_RESPONSE = 'GET_ORGANIZATIONS_RESPONSE';
export const PURGE_ORGANIZATION = 'PURGE_ORGANIZATION';
export const EDIT_ORGANIZATION = 'EDIT_ORGANIZATION';
export const EDIT_ORGANIZATION_RESPONSE = 'EDIT_ORGANIZATION_RESPONSE';
export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_RESPONSE = 'DELETE_ORGANIZATION_RESPONSE';
export const ORGANIZATION_OPERATION_LOADER = 'ORGANIZATION_OPERATION_LOADER';
export const SET_FILTERED_ORGANIZATION = 'SET_FILTERED_ORGANIZATION';

export const getOrganizationsResponse = (
    error: boolean,
    message: string,
    organizations?: Organization[]
): ActionPayloadOrError<GetOrganizationsResponse> => {
    return {
        type: GET_ORGANIZATIONS_RESPONSE,
        payload: { organizations },
        message,
        error
    };
};

export const setFilteredOrganization = (
    orgId: string
): ActionPayload<string> => {
    return {
        type: SET_FILTERED_ORGANIZATION,
        payload: orgId
    };
};

export const getAllOrganizationList = (): Action => {
    return {
        type: GET_ALL_ORGANIZATION_LIST
    };
};

export const addOrganization = (
    name: string
): ActionPayload<AddOrganization> => {
    return {
        type: ADD_ORGANIZATION,
        payload: { name }
    };
};

export const addOrganizationResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: ADD_ORGANIZATION_RESPONSE,
        message,
        error
    };
};

export const setLoader = (): Action => {
    return {
        type: SET_LOADER
    };
};

export const purgeOrganization = (): Action => {
    return {
        type: PURGE_ORGANIZATION
    };
};

export const editOrganization = (
    name: string,
    id: string
): ActionPayload<EditOrganization> => {
    return {
        type: EDIT_ORGANIZATION,
        payload: { name, id }
    };
};

export const editOrganizationResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<void> => {
    return {
        type: EDIT_ORGANIZATION_RESPONSE,
        message,
        error
    };
};

export const setOrganizationOperationLoader = (): Action => {
    return {
        type: ORGANIZATION_OPERATION_LOADER
    };
};

export const deleteOrganization = (
    id: string
): ActionPayload<DeleteOrganization> => {
    return {
        type: DELETE_ORGANIZATION,
        payload: { id }
    };
};

export const deleteOrganizationResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<{}> => {
    return {
        type: DELETE_ORGANIZATION_RESPONSE,
        message,
        error
    };
};
