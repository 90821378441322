import { Action } from 'redux';
import { ActionPayload, ActionPayloadOrError } from '../types';
import {
    CreateAndSaveElement,
    FetchElement,
    FetchWidgetSkeleton,
    SaveAllElementsPayload
} from './actionPayload';
import { ElementCreateUpdateRequestBody, WidgetSkeleton } from './model';

export const RESET_LOADING_STATUS = 'RESET_LOADING_STATUS';
export const FETCH_ELEMENT_SKELETON = 'FETCH_ELEMENT_SKELETON';
export const FETCH_ELEMENT_SKELETON_RESPONSE =
    'FETCH_ELEMENT_SKELETON_RESPONSE';
export const FETCH_ELEMENT = 'FETCH_ELEMENT';
export const CREATE_ELEMENT = 'CREATE_ELEMENT';
export const CREATE_ELEMENT_RESPONSE = 'CREATE_ELEMENT_RESPONSE';
export const RESET_CREATE_AND_SAVE_ELEMENT_RESPONSE =
    'RESET_CREATE_AND_SAVE_ELEMENT_RESPONSE';
export const SAVE_ELEMENT = 'EDIT_ELEMENT';
export const SAVE_ELEMENT_RESPONSE = 'EDIT_ELEMENT_RESPONSE';
export const SET_ELEMENT_STATUS = 'SET_ELEMENT_STATUS';
export const PURGE_ELEMENT = 'PURGE_ELEMENT';
export const SET_PAGE_BACKGROUND_MESSAGE = 'SET_PAGE_BACKGROUND_MESSAGE';
export const SAVE_ELEMENTS = 'SAVE_ELEMENTS';
export const SAVE_ALL_ELEMENTS_RESPONSE = 'SAVE_ALL_ELEMENTS_RESPONSE';

export const setElementSatus = (status: string): ActionPayload<string> => {
    return {
        type: SET_ELEMENT_STATUS,
        payload: status
    };
};

export const fetchWidgetSkeleton = (
    widgetTypeId: string,
    propertyId: string,
    action?: string
): ActionPayload<FetchWidgetSkeleton> => {
    return {
        type: FETCH_ELEMENT_SKELETON,
        payload: { widgetTypeId, propertyId, action }
    };
};

export const fetchElement = (
    elementId: string,
    propertyId: string
): ActionPayload<FetchElement> => {
    return {
        type: FETCH_ELEMENT,
        payload: { elementId, propertyId }
    };
};

export const widgetSkeletonResponse = (
    payload: WidgetSkeleton | undefined,
    error?: string
): ActionPayloadOrError<WidgetSkeleton | undefined> => {
    return {
        type: FETCH_ELEMENT_SKELETON_RESPONSE,
        payload,
        error
    };
};

export const createElement = (
    element: WidgetSkeleton,
    propertyId: string
): ActionPayload<CreateAndSaveElement> => {
    return {
        type: CREATE_ELEMENT,
        payload: { element, propertyId }
    };
};

export const createElementResponse = (
    error: boolean,
    payload: ElementCreateUpdateRequestBody | string
): ActionPayloadOrError<ElementCreateUpdateRequestBody | string> => {
    return {
        type: CREATE_ELEMENT_RESPONSE,
        payload,
        error
    };
};

export const saveElement = (
    element: WidgetSkeleton,
    propertyId: string
): ActionPayload<CreateAndSaveElement> => {
    return {
        type: SAVE_ELEMENT,
        payload: { element, propertyId }
    };
};

export const saveAllElements = (
    elements: WidgetSkeleton[],
    propertyId: string
): ActionPayload<SaveAllElementsPayload> => {
    return {
        type: SAVE_ELEMENTS,
        payload: { elements, propertyId }
    };
};

export const saveElementResponse = (
    error: boolean,
    payload: ElementCreateUpdateRequestBody | string
): ActionPayloadOrError<ElementCreateUpdateRequestBody | string> => {
    return {
        type: SAVE_ELEMENT_RESPONSE,
        payload,
        error
    };
};

export const saveAllElementResponse = (
    error: boolean,
    message: string
): ActionPayloadOrError<undefined> => {
    return {
        type: SAVE_ALL_ELEMENTS_RESPONSE,
        error,
        message
    };
};

export const resetCreateAndSaveElementResponse = (): Action => {
    return {
        type: RESET_CREATE_AND_SAVE_ELEMENT_RESPONSE
    };
};

export const resetLoadingStatus = (): Action => {
    return {
        type: RESET_LOADING_STATUS
    };
};

export const purgeElement = (): Action => {
    return {
        type: PURGE_ELEMENT
    };
};

export const setPageBackgroundMessage = (
    message: string
): ActionPayload<string> => {
    return {
        type: SET_PAGE_BACKGROUND_MESSAGE,
        payload: message
    };
};
