import { LoadingStatus } from '../common/state';
import { PropertyDetails, PropertyGroupInterface, PropertyType } from './model';

export interface PropertyList {
    values: PropertyGroupInterface[];
    propertyLoader: LoadingStatus;
    propertyOperationLoader: LoadingStatus;
    expandedPropertyGroupIds: string[];
    currentPropertyDetails?: PropertyDetails;
    message: string;
    propertyTypes: PropertyType[];
    searchKeyword: string;
    redirectToPropertyId: string;
}

export const initialPropertyGroupState: PropertyList = {
    values: [],
    propertyLoader: LoadingStatus.NONE,
    propertyOperationLoader: LoadingStatus.NONE,
    expandedPropertyGroupIds: [],
    searchKeyword: '',
    message: '',
    propertyTypes: [],
    redirectToPropertyId: ''
};
