import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';
import {
    GET_OPERATIONS,
    OPERATIONS_RESPONSE,
    PURGE_OPERATIONS
} from './action';
import { UserOperationResponse, OperationPayload } from './actionPayload';
import { initialUserOperationState, UserOperationType } from './state';

import { Operations } from './model';

const reducer = (
    state: UserOperationType = initialUserOperationState,
    action: any
): UserOperationType => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state = initialUserOperationState,
    action: Action
): UserOperationType => {
    switch (action.type) {
        case PURGE_OPERATIONS: {
            return initialUserOperationState;
        }
        default:
            return state;
    }
};

const actionWithPayloadReducer = (
    state = initialUserOperationState,
    action: ActionPayload<OperationPayload>
): UserOperationType => {
    switch (action.type) {
        case GET_OPERATIONS:
            return {
                ...state,
                operationLoader: LoadingStatus.INITIATED
            };
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state = initialUserOperationState,
    action: ActionPayloadOrError<UserOperationResponse>
): UserOperationType => {
    switch (action.type) {
        case OPERATIONS_RESPONSE:
            if (action.error) {
                return {
                    ...state,
                    operationLoader: LoadingStatus.FAILED
                };
            }
            return {
                ...state,
                operations: (action.payload as UserOperationResponse)
                    .operations as Operations,
                isInitialState: false,
                operationLoader: LoadingStatus.DONE
            };

        default:
            return state;
    }
};

export const selectOperationsInitialised = (state: coreRootState): boolean =>
    !state.userOperation.isInitialState;

export const selectUserAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.is_module_accessible;

export const selectPropertiesAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.is_module_accessible;

export const selectOperations = (state: coreRootState): Operations =>
    state.userOperation.operations;

export const selectUserModuleAccessible = (state: coreRootState): boolean =>
    state.userOperation.operations.users.is_module_accessible;

export const selectPropertiesModuleAccessible = (
    state: coreRootState
): boolean => state.userOperation.operations.properties.is_module_accessible;

export const selectTemplatesModuleAccessible = (
    state: coreRootState
): boolean => state.userOperation.operations.templates.is_module_accessible;

export const selectOrganizationModuleAccessible = (
    state: coreRootState
): boolean => state.userOperation.operations.organizations.is_module_accessible;

export const selectAddPropertyAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.PropertyResource.Add;

export const selectAddPropertyGroupAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property_group
        .PropertyGroupResource.Add;

export const selectViewOrgUserAllowed = (state: coreRootState): boolean =>
    state?.userOperation?.operations.users.user.IntegratorResource.View;

export const selectAddOrganizationAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.organizations.organisation
        .OrganizationResource.Add;

export const isOrganizationAccessible = (state: coreRootState): boolean =>
    state?.userOperation?.operations.organizations.is_module_accessible;

export const selectViewUsersInOrganization = (state: coreRootState): boolean =>
    state?.userOperation?.operations.organizations.organisation
        .OrganizationResource.View;

export const selectDeletePropertyGroupAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.properties.property_group
        .PropertyGroupResource.Delete;

export const selectEditPropertyGroupAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property_group
        .PropertyGroupResource.Edit;

export const selectOperationLoaderStatus = (
    state: coreRootState
): LoadingStatus => {
    return state.userOperation.operationLoader;
};
// ****************** View Users ************************
export const selectViewSuperAdminAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperAdminResource.View;

export const selectViewSuperUserAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperUserResource.View;

export const selectViewAdminIntegratorAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.AdminIntegratorResource.View;

export const selectViewIntegratorAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.IntegratorResource.View;

export const selectViewPropertyManagerAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.PropertyManagerResource.View;

export const selectViewConsultantAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.ConsultantResource.View;

// ****************** Edit Users ************************
export const selectEditSuperAdminAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperAdminResource.Edit;

export const selectEditSuperUserAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperUserResource.Edit;

export const selectEditAdminIntegratorAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.AdminIntegratorResource.Edit;

export const selectEditIntegratorAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.IntegratorResource.Edit;

export const selectEditPropertyManagerAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.PropertyManagerResource.Edit;

export const selectEditConsultantAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.ConsultantResource.Edit;

// ****************** Add Users ************************
export const selectAddSuperAdminAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperAdminResource.Add;

export const selectAddSuperUserAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperUserResource.Add;

export const selectAddAdminIntegratorAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.AdminIntegratorResource.Add;

export const selectAddIntegratorAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.IntegratorResource.Add;

export const selectAddPropertyManagerAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.PropertyManagerResource.Add;

export const selectAddConsultantAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.ConsultantResource.Add;

// ****************** Delete Users ************************
export const selectDeleteSuperAdminAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperAdminResource.Delete;

export const selectDeleteSuperUserAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.SuperUserResource.Delete;

export const selectDeleteAdminIntegratorAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.AdminIntegratorResource.Delete;

export const selectDeleteIntegratorAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.IntegratorResource.Delete;

export const selectDeletePropertyManagerAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.users.user.PropertyManagerResource.Delete;

export const selectDeleteConsultantAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.users.user.ConsultantResource.Delete;

// ****************** Organization ************************

export const selectViewOrganizationAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.organizations.organisation
        .OrganizationResource.View;

export const selectEditOrganizationAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.organizations.organisation
        .OrganizationResource.Edit;

export const selectDeleteOrganizationAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation.operations.organizations.organisation
        .OrganizationResource.Delete;

// ****************** General Section ************************
export const selectGeneralSectionAccessible = (state: coreRootState): boolean =>
    state.userOperation?.operations.platformAccess.is_module_accessible;

export const selectGeneralSectionEditAllowed = (
    state: coreRootState
): boolean =>
    state.userOperation?.operations.platformAccess.platform
        .GeneralSectionResource.Edit;
// ****************** Activity Monitoring ************************
export const selectActivityMonitoringAccessible = (
    state: coreRootState
): boolean => state.userOperation?.operations.activityLogs.is_module_accessible;
// ****************** Elements ************************
export const selectViewElementAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.templates.elements.ElementResource.View;

export const selectAddElementAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.templates.elements.ElementResource.Add;

export const selectViewAssetsAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.templates.asset.AssetResource.View;

export const selectViewPropertyAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.PropertyResource.View;

export const selectPropertyGroupSectionDisabled = (
    state: coreRootState
): boolean => {
    return (
        state.userOperation?.operations.properties.property_group
            .PropertyGroupResource.View === true &&
        state.userOperation?.operations.properties.property_group
            .PropertyGroupResource.Edit === false
    );
};

export const selectPropertyDeleteAllowed = (state: coreRootState): boolean => {
    return state.userOperation.operations.properties.property.PropertyResource
        .Delete;
};

export const selectPropertyMoveAllowed = (state: coreRootState): boolean => {
    return state.userOperation.operations.properties.property.PropertyResource
        .MoveProperty;
};

export const selectPropertyDuplicateAllowed = (
    state: coreRootState
): boolean => {
    return state.userOperation.operations.properties.property.PropertyResource
        .DuplicateProperty;
};

export const selectPropertyEditAllowed = (state: coreRootState): boolean => {
    return state.userOperation.operations.properties.property.PropertyResource
        .Edit;
};

export const selectElementDeleteAllowed = (state: coreRootState): boolean => {
    return state.userOperation.operations.templates.elements.ElementResource
        .Delete;
};

export const selectWidgetDuplicationAllowed = (
    state: coreRootState
): boolean => {
    return state.userOperation.operations.templates.elements.ElementResource
        .DuplicateWidget;
};

export const selectRailDuplicationAllowed = (state: coreRootState): boolean => {
    return state.userOperation.operations.templates.elements.ElementResource
        .DuplicateRail;
};
export const selectPageDuplicationAllowed = (state: coreRootState): boolean => {
    return state.userOperation.operations.templates.elements.ElementResource
        .DuplicatePage;
};

export const selectViewEvolve2Allowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.Evolve2Resource.View;

// ****************** Zones ************************
export const selectAddZoneAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.ZoneResource.Add;

export const selectDeleteZoneAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.ZoneResource.Delete;

export const selectViewZoneAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.ZoneResource.View;

export const selectEditZoneAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.ZoneResource.Edit;

export const selectEditZoneRoomsAllowed = (state: coreRootState): boolean =>
    state.userOperation.operations.properties.property.ZoneResource
        .AssociateRooms;

export default reducer;
