import { API_URL } from '../../api/apiConstants';
import axios from '../../api/axios';

export function getMaintenanceStatus() {
    return axios.get(API_URL.getMaintenance);
}

export const updateMaintenanceStatus = (enabled: boolean) => {
    const body = {
        enabled
    };
    return axios.post(API_URL.updateMaintenance, body);
};
