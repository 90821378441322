import { Auth } from 'aws-amplify';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import LogoutBroadcastChannel from '../../auth/logoutBroadcastChannel';
import { purgeOrganization } from '../organizations/action';
import { ActionPayload } from '../types';
import { purgeOperations } from '../userOperations/action';
import { purgePropertyList } from './../propertylist/action';
import {
    forgotPasswordResponseFailure,
    forgotPasswordResponseSuccess,
    forgotPasswordSubmitResponse,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUBMIT,
    newPasswordResponse,
    purgeLoginData,
    retrieveCurrentSession,
    RETRIEVE_CURRENT_SESSION,
    setNewPassword,
    SET_NEW_PASSWORD,
    userAuthenticatedStatus,
    userLoggingIn,
    userLoginFailure,
    userLoginSuccess,
    userLogoutResponse,
    USER_LOGIN,
    USER_LOGOUT
} from './action';
import { ForgotPasswordSubmit } from './actionPayload';
import { updateUserStatus } from './helper';
import {
    ForgotPasswordStatus,
    ForgotPasswordSubmitStatus,
    LoginResponse,
    LoginStatus,
    LogoutStatus,
    UserLoginPayloadType,
    UserSession
} from './model';
/***************************************************************************************
 *
 *                              User Login saga
 ***************************************************************************************/

function* loginWorker(data: ActionPayload<UserLoginPayloadType>) {
    const { email, password, newPassword } = data.payload;
    try {
        yield put(userLoggingIn());
        //@ts-ignore
        const loginDetails: LoginResponse = yield call(
            [Auth, 'signIn'],
            email,
            password
        );

        if (loginDetails.username) {
            if (
                loginDetails.challengeName === LoginStatus.NEW_PASSWORD_REQUIRED
            ) {
                yield put(setNewPassword({ newPassword, loginDetails }));
            } else {
                yield put(userLoginSuccess(loginDetails));
                yield put(retrieveCurrentSession());
                LogoutBroadcastChannel.initLogoutChannel();
            }
        } else {
            yield put(
                userLoginFailure(LoginStatus.FAILED, String(loginDetails))
            );
        }
    } catch (_e) {
        const e: any = _e;
        const result = e.message;
        if (newPassword) {
            yield put(
                userLoginFailure(LoginStatus.NEW_PASSWORD_FAILED, result)
            );
        } else {
            yield put(userLoginFailure(LoginStatus.FAILED, result));
        }
    }
}

/***************************************************************************************
 *
 *                              New password saga
 ***************************************************************************************/

function* newPasswordWorker(data: {
    type: string;
    payload: { newPassword: string; loginDetails: LoginResponse };
}) {
    const { newPassword, loginDetails } = data.payload;
    try {
        const completeNewPasswordRes: { username: string } = yield call(
            [Auth, 'completeNewPassword'],
            loginDetails,
            newPassword
        );
        yield call(updateUserStatus, completeNewPasswordRes.username);
        yield put(newPasswordResponse(false));
    } catch (error) {
        const e: any = error;
        const result = e.message;
        yield put(newPasswordResponse(true, result));
    }
}

/***************************************************************************************
 *
 *                              Forgot Password saga
 ***************************************************************************************/

function* forgotPasswordWorker(data: ActionPayload<string>) {
    try {
        yield call([Auth, 'forgotPassword'], data.payload);
        yield put(
            forgotPasswordResponseSuccess(
                ForgotPasswordStatus.FORGOT_PASSWORD_DONE
            )
        );
    } catch (error) {
        const e: any = error;
        const result = e.message;
        yield put(
            forgotPasswordResponseFailure(
                ForgotPasswordStatus.FORGOT_PASSWORD_FAILED,
                result
            )
        );
    }
}

/***************************************************************************************
 *
 *                              Forgot Password Submit saga
 ***************************************************************************************/

function* forgotPasswordSubmitWorker(
    data: ActionPayload<ForgotPasswordSubmit>
) {
    try {
        const { email, code, newPassword } = data.payload;
        yield call([Auth, 'forgotPasswordSubmit'], email, code, newPassword);
        yield put(
            forgotPasswordSubmitResponse(
                ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_DONE
            )
        );
    } catch (error) {
        const e: any = error;
        const result = e.message;
        yield put(
            forgotPasswordSubmitResponse(
                ForgotPasswordSubmitStatus.FORGOT_PASSWORD_SUBMIT_FAILED,
                result
            )
        );
    }
}

/***************************************************************************************
 *
 *                              User Logout saga
 ***************************************************************************************/

function* userLogoutWorker() {
    try {
        yield call([Auth, 'signOut']);
        yield put(userLogoutResponse(LogoutStatus.LOGGED_OUT));
        yield put(purgeLoginData());
        yield put(purgeOperations());
        yield put(purgePropertyList());
        yield put(purgeOrganization());
    } catch (error) {
        const e: any = error;
        const result = e.message;
        yield put(userLogoutResponse(LogoutStatus.LOGOUT_FAILED, result));
    }
}

/***************************************************************************************
 *
 *                              Retrieve current session saga
 ***************************************************************************************/

function* retrieveCurrentSessionWorker() {
    try {
        const currentSession: UserSession = yield call([
            Auth,
            'currentSession'
        ]);
        console.log('currentSession ', currentSession);
        if (currentSession.accessToken.jwtToken) {
            yield put(userAuthenticatedStatus(true));
        } else {
            // authenticatedStatus(false);
        }
    } catch (error) {
        // authenticatedStatus(false);
    }
}

// export all worker Sagas
export function* watchLoginSagas() {
    yield all([
        takeLatest(USER_LOGIN, loginWorker),
        takeEvery(SET_NEW_PASSWORD, newPasswordWorker),
        takeLatest(FORGOT_PASSWORD, forgotPasswordWorker),
        takeLatest(FORGOT_PASSWORD_SUBMIT, forgotPasswordSubmitWorker),
        takeEvery(USER_LOGOUT, userLogoutWorker),
        takeLatest(RETRIEVE_CURRENT_SESSION, retrieveCurrentSessionWorker)
    ]);
}
