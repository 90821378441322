import { OpenInNew } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button, {
    ButtonSize,
    ButtonVariation
} from '../../../components/button';
import cmpTexts from '../../../locale/en';
import { PageConfigDump } from '../../../redux/preview/model';
import { getAllZones, resetZonesData } from '../../../redux/zones/action';
import { selectAvailablePlatforms } from '../../../redux/zones/reducer';
import PagePreviewDialog from './pagePreviewDialog';
import { findPlatform, Platform, Platforms } from './platforms';
import PreviewPlatformSelectionDialog from './previewPlatformSelectionDialog';

interface PreviewButtonProps {
    pageId: string;
    propertyId: string;
    pageConfigDump?: PageConfigDump;
    platform?: string;
    availablePlatforms?: string[];
    label?: string;
    mode?: 'default' | 'canvas';
}

const PreviewButton = (props: PreviewButtonProps) => {
    const {
        pageId,
        propertyId,
        pageConfigDump,
        platform,
        label,
        mode = 'default'
    } = props;
    const [openPreviewDialog, setOpenPreviewDialog] = useState<boolean>(false);
    const [openPlatformSelectionDialog, setOpenPlatformSelectionDialog] =
        useState<boolean>(false);
    const [selectedPlatform, setSelectedPlatform] = useState(
        findPlatform(platform)
    );

    const dispatch = useDispatch();

    const availablePlatforms = useSelector(selectAvailablePlatforms);
    let previewablePlatforms = Platforms;

    if (!isEmpty(availablePlatforms)) {
        previewablePlatforms = [];
        availablePlatforms.forEach((pName) => {
            const platform = findPlatform(pName);
            platform && previewablePlatforms.push(platform);
        });
    }

    const handlePreviewButtonClick = () => {
        if (platform && selectedPlatform) setOpenPreviewDialog(true);
        else setOpenPlatformSelectionDialog(true);
    };

    const handlePlatformSelection = (platform: Platform) => {
        setSelectedPlatform(platform);
        setOpenPlatformSelectionDialog(false);
        setOpenPreviewDialog(true);
    };

    const handleExitClick = () => {
        setOpenPlatformSelectionDialog(false);
        setOpenPreviewDialog(false);
    };

    useEffect(() => {
        if (!platform) {
            dispatch(getAllZones(propertyId));
            return () => {
                dispatch(resetZonesData());
            };
        }
    }, [propertyId, platform]);

    return (
        <>
            {mode === 'default' ? (
                <Button
                    onClick={handlePreviewButtonClick}
                    variant="text"
                    sx={{ textTransform: 'none', color: '#171725' }}
                    buttonSize={ButtonSize.SMALL}
                    startIcon={<RemoveRedEyeIcon />}
                >
                    <Typography fontWeight="bold" variant="body2">
                        {label ?? cmpTexts.preview.previewPage}
                    </Typography>
                </Button>
            ) : (
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    endIcon={<OpenInNew />}
                    sx={{
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        textTransform: 'capitalize'
                    }}
                    onClick={handlePreviewButtonClick}
                >
                    Preview
                </Button>
            )}

            <PreviewPlatformSelectionDialog
                platforms={previewablePlatforms}
                onPlatformSelected={handlePlatformSelection}
                onCancel={handleExitClick}
                open={openPlatformSelectionDialog}
            />

            {selectedPlatform && openPreviewDialog && (
                <PagePreviewDialog
                    isOpen={openPreviewDialog}
                    pageId={pageId}
                    platform={selectedPlatform}
                    propertyId={propertyId}
                    onExitClick={handleExitClick}
                    pageConfigDump={pageConfigDump}
                />
            )}
        </>
    );
};

export default PreviewButton;
