import WarningIcon from '@mui/icons-material/ReportProblem';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { pxToRem } from '../utils/stylesUtils';

interface WarningMessageProps {
    message: string;
}

const WarningMessage = (props: WarningMessageProps): React.ReactElement => {
    return (
        <Box
            sx={{
                background: '#EAB700',
                padding: pxToRem(16),
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <WarningIcon />
            <Typography
                sx={{
                    lineHeight: pxToRem(24),
                    fontSize: pxToRem(16),
                    marginLeft: pxToRem(8)
                }}
            >
                {props.message}
            </Typography>
        </Box>
    );
};

export default WarningMessage;
