import { all, call, put, takeLatest } from 'redux-saga/effects';
import cmpTexts from '../../locale/en';
import { ActionPayload } from '../types';
import { ApiResponse } from './../../api/apiInterfaces';
import {
    fetchMaintenanceStatus,
    fetchMaintenanceStatusResponse,
    FETCH_MAINTENANCE_STATUS,
    updateMaintenanceStatusResponse,
    UPDATE_MAINTENANCE_STATUS
} from './action';
import {
    EditMaintenanceStatusResponse,
    MaintenanceStatus
} from './actionPayload';
import { getMaintenanceStatus, updateMaintenanceStatus } from './helper';

//=====================================================================
//             get maintenance Workers
//=====================================================================

function* getMaintenanceStatusWorker() {
    try {
        const MaintenanceStatus: ApiResponse<MaintenanceStatus> = yield call(
            getMaintenanceStatus
        );
        yield put(
            fetchMaintenanceStatusResponse(
                false,
                MaintenanceStatus.response_body.enabled,
                MaintenanceStatus.response_body.image_url
            )
        );
    } catch (err: any) {
        console.log('getMaintenanceStatusWorker', err);
        fetchMaintenanceStatusResponse(
            true,
            err.message || cmpTexts.common.genericError
        );
    }
}

function* getUpdateMaintenanceWorker(
    data: ActionPayload<EditMaintenanceStatusResponse>
) {
    try {
        const enabled = data.payload.enabled;
        const res: ApiResponse<MaintenanceStatus> = yield call(
            updateMaintenanceStatus,
            enabled
        );

        yield put(updateMaintenanceStatusResponse(false, res.message));
        yield put(fetchMaintenanceStatus());
    } catch (err: any) {
        yield put(
            updateMaintenanceStatusResponse(
                true,
                err.message || cmpTexts.common.genericError
            )
        );
    }
}

export function* watchMaintenanceSagas() {
    yield all([
        takeLatest(FETCH_MAINTENANCE_STATUS, getMaintenanceStatusWorker),
        takeLatest(UPDATE_MAINTENANCE_STATUS, getUpdateMaintenanceWorker)
    ]);
}
