import { Action } from 'redux';
import { LoadingStatus } from '../common/state';
import { coreRootState } from '../store.types';
import { ActionPayload, ActionPayloadOrError } from '../types';
import { ActionTypes, getActionType } from '../typesUtil';

import {
    GET_ALL_ZONES,
    GET_ALL_ZONES_RESPONSE,
    RESET_ZONES_LOADER,
    RESET_ZONES_OPERATION_LOADER,
    CREATE_ZONE,
    CREATE_ZONE_RESPONSE,
    EDIT_ZONE,
    EDIT_ZONE_RESPONSE,
    DELETE_ZONE,
    DELETE_ZONE_RESPONSE,
    GET_ALL_ROOMS,
    GET_ALL_ROOMS_RESPONSE,
    PURGE_ROOMS,
    UPDATE_ZONE_FIELDS,
    UPDATE_ZONE_FIELDS_RESPONSE,
    RESET_ZONES_DATA
} from './action';
import { ZonesState, initialZonesState } from './state';
import {
    DefaultZone,
    EventZone,
    Room,
    StandardZone,
    ZonesFieldValues
} from './model';
import { orderBy, uniq } from 'lodash';
import { UpdateZoneFieldsPayload } from './actionPayload';

const reducer = (
    state: ZonesState = initialZonesState,
    action: any
): ZonesState => {
    switch (getActionType(action)) {
        case ActionTypes.Action:
            return actionReducer(state, action);
        case ActionTypes.ActionPayload:
            return actionWithPayloadReducer(state, action);
        default:
            return actionWithPayloadOrErrorReducer(state, action);
    }
};

const actionReducer = (
    state: ZonesState = initialZonesState,
    action: Action
): ZonesState => {
    switch (action.type) {
        case RESET_ZONES_LOADER: {
            return {
                ...state,
                message: '',
                loader: LoadingStatus.NONE
            };
        }
        case RESET_ZONES_OPERATION_LOADER: {
            return {
                ...state,
                message: '',
                zoneOperationLoader: LoadingStatus.NONE
            };
        }
        case RESET_ZONES_DATA:
            return {
                ...initialZonesState
            };
        default: {
            return state;
        }
    }
};

const actionWithPayloadReducer = (
    state: ZonesState = initialZonesState,
    action: ActionPayload<
        string | StandardZone | EventZone | UpdateZoneFieldsPayload
    >
): ZonesState => {
    switch (action.type) {
        case GET_ALL_ZONES:
        case GET_ALL_ROOMS:
            return {
                ...state,
                loader: LoadingStatus.INITIATED
            };
        case CREATE_ZONE:
        case DELETE_ZONE:
        case EDIT_ZONE:
        case UPDATE_ZONE_FIELDS:
            return {
                ...state,
                zoneOperationLoader: LoadingStatus.INITIATED
            };
        default:
            return state;
    }
};

const actionWithPayloadOrErrorReducer = (
    state: ZonesState = initialZonesState,
    action: ActionPayloadOrError<void | ZonesFieldValues | Room[]>
): ZonesState => {
    switch (action.type) {
        case GET_ALL_ZONES_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    loader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while getting the Zones.'
                };
            }
            return {
                ...state,
                loader: LoadingStatus.DONE,
                zones: action.payload as ZonesFieldValues
            };
        }

        case CREATE_ZONE_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    zoneOperationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while creating the zone'
                };
            }
            return {
                ...state,
                zoneOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'zone created successfully.'
            };
        }

        case DELETE_ZONE_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    zoneOperationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while deleting the asset.'
                };
            }

            return {
                ...state,
                zoneOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Zone deleted successfully.'
            };
        }

        case UPDATE_ZONE_FIELDS_RESPONSE:
        case EDIT_ZONE_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    zoneOperationLoader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while updating the zone.'
                };
            }

            return {
                ...state,
                zoneOperationLoader: LoadingStatus.DONE,
                message: action.message ?? 'Zone updated successfully.'
            };
        }
        case GET_ALL_ROOMS_RESPONSE: {
            if (action.error) {
                return {
                    ...state,
                    loader: LoadingStatus.FAILED,
                    message: action.message ?? 'Error while getting the Zones.'
                };
            }
            return {
                ...state,
                loader: LoadingStatus.DONE,
                rooms: action.payload as Room[]
            };
        }

        case PURGE_ROOMS: {
            return {
                ...state,
                rooms: []
            };
        }

        default: {
            return state;
        }
    }
};

export const selectAllZones = (state: coreRootState): ZonesFieldValues => {
    return state.zones.zones;
};

export const selectAvailablePlatforms = (state: coreRootState): string[] => {
    const availablePlatforms: string[] = [];
    Object.keys(state.zones.zones.default.platforms ?? {}).forEach((key) => {
        if (state.zones.zones.default.platforms?.[key])
            availablePlatforms.push(key);
    });

    return uniq(availablePlatforms);
};

export const selectAllRooms = (state: coreRootState): Room[] => {
    return orderBy(state.zones.rooms, 'room_no', 'asc');
};

export const selectZoneLoader = (state: coreRootState): LoadingStatus => {
    return state.zones.loader;
};

export const selectMessage = (state: coreRootState): string => {
    return state.zones.message;
};

export const selectZoneOperationLoadingState = (
    state: coreRootState
): LoadingStatus => {
    return state.zones.zoneOperationLoader;
};

export const selectExistingZoneNames = (state: coreRootState): string[] => {
    const { zones } = state.zones;
    const zoneArray: (StandardZone | EventZone | DefaultZone)[] = [
        zones.default,
        ...zones.event,
        ...zones.standard
    ];
    return zoneArray.map((zone) => zone.name);
};

export default reducer;
